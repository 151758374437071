import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_JOB_ADVANCE = 'CREATE_JOB_ADVANCE';
export const FETCH_JOB_ADVANCE_LIST = 'FETCH_JOB_ADVANCE_LIST';
export const DELETE_JOB_ADVANCE = 'DELETE_JOB_ADVANCE';
export const FETCH_JOB_ADVANCE = 'FETCH_JOB_ADVANCE';
export const UPDATE_JOB_ADVANCE = 'UPDATE_JOB_ADVANCE';
export function createJobAdvance(data) {
    const config = {};
    const request = axios.post(`${ROOT_API_ERP}payment/job-advance`, data, config);
    return {
        type: CREATE_JOB_ADVANCE,
        promise: request,
    };
}
export function fetchJobAdvanceList(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}payment/job-advance`, config);
    return {
        type: FETCH_JOB_ADVANCE_LIST,
        promise: request,
    };
}
export const deleteJobAdvance = (id) => {
    const config = {};
    const request = axios.delete(`${ROOT_API_ERP}payment/job-advance/${id}`, config);
    return {
        type: DELETE_JOB_ADVANCE,
        promise: request,
    };
};
export function fetchJobAdvance(id) {
    const config = {};
    const request = axios.get(`${ROOT_API_ERP}payment/job-advance/${id}`, config);
    return {
        type: FETCH_JOB_ADVANCE,
        promise: request,
    };
}
export function updateJobAdvance(data) {
    const config = {};
    const request = axios.put(`${ROOT_API_ERP}payment/job-advance`, data, config);
    return {
        type: UPDATE_JOB_ADVANCE,
        promise: request,
    };
}
