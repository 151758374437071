import { round, startCase, toLower, includes, get, uniqBy, isUndefined } from 'lodash';
import { REPORT_FORMAT } from 'utils/report_utils';
import { getTimeDiff, HOURS_1 } from 'utils/date_utils';
import { getNearestAddressFromAddressbook, isInsideNearestAddress } from 'utils/map/geocode_utils';
import { getFuelUnitForDevice } from 'utils/fuel_utils';
import { getBatteryPowerLevelForDisplay } from 'utils/battery_utils';
import { DATE_FORMAT_TIMESTAMP_DAY, MINUTES_1, getHumanizeTime, getMomentTime, getTimeDiffInHours } from './date_utils';
import { isFastCharging } from 'utils/ev_vehicle_utils';
import { FxBadge, FxTag, FxToolTip } from 'sharedV2';
import { FxThunderboltOutlined } from 'sharedV2/FxIcons';
import { COLOR_WARNING } from '../theme/colors';
import { SAFETY_ALARMS } from 'components/dashboard/alerts/analytics/Utils';

export const ALERT_TYPE_OPTIONS = [
    // {value: 'Crash', label: 'Crash'},
    { value: 'Emergency', label: 'SOS Alarm' },
    { value: 'No driver', label: 'No driver' },
    {
        value: 'Exhaust Emission',
        label: 'Exhaust Emission',
    },
    { value: 'Fatigue Driving', label: 'Fatigue Driving' },
    { value: 'FuelLevel', label: 'Fuel Level' },
    { value: 'FuelLevel2', label: 'Fuel Level 2' },
    { value: 'FuelLevel3', label: 'Fuel Level 3' },
    { value: 'FuelLevel4', label: 'Fuel Level 4' },
    { value: 'numberOfFills', label: 'Fuel Fills' },
    { value: 'numberOfThefts', label: 'Fuel Thefts' },
    { value: 'numberOfPilferage', label: 'Fuel Pilferage' },
    { value: 'Adblue Level', label: 'Adblue Level' },
    { value: 'Low Adblue Level', label: 'Low Adblue Level' },
    { value: 'Lock Tamper', label: 'Lock Tamper' },
    { value: 'Portable Device Low Battery', label: 'Portable Device Low Battery' },
    { value: 'EV Low Battery', label: 'EV Low Battery' },
    {
        value: 'Geofence',
        label: 'Geofence',
    },
    { value: 'Hard Acceleration', label: 'Hard Acceleration' },
    {
        value: 'Hard Deceleration',
        label: 'Hard Deceleration',
    },
    { value: 'Hard Deceleration(Video)', label: 'Hard Deceleration(Video)' },
    { value: 'Hard Acceleration(Video)', label: 'Hard Acceleration(Video)' },
    { value: 'Fatigue Driving(Video)', label: 'Fatigue Driving(Video)' },
    { value: 'High Engine Coolant Temperature', label: 'High Engine Temp.' },
    {
        value: 'High Rpm',
        label: 'High RPM',
    },
    { value: 'Idle Engine', label: 'Idle Engine' },
    { value: 'Temperature', label: 'Temperature' },
    { value: 'Temperature2', label: 'Temperature 2' },
    { value: 'Temperature3', label: 'Temperature 3' },
    { value: 'External Machine Status', label: 'External Machine Status' },
    { value: 'External Machine 2 Status', label: 'External Machine 2 Status' },
    { value: 'Door Open', label: 'Door Open' },
    { value: 'Door Close', label: 'Door Close' },
    { value: 'Door Sensor Tampered', label: 'Door Sensor Tampered' },
    { value: 'Drum Status', label: 'Drum Status' },
    { value: 'Dumper Status', label: 'Dumper Status' },
    { value: 'Refer AC Status', label: 'Refer AC Status' },
    { value: 'Cabin AC Status', label: 'Cabin AC Status' },
    { value: 'Charging Stop', label: 'Charging Stop' },
    { value: 'Charging Start', label: 'Charging Start' },
    { value: 'Charging Full', label: 'Charging Full' },
    {
        value: 'Low Voltage',
        label: 'Low Voltage',
    },
    { value: 'Quick Lane Change', label: 'Quick Lane Change' },
    { value: 'Free Wheeling', label: 'Free Wheeling' },
    { value: 'Parked', label: 'Parked' },
    { value: 'Belated Job,', label: 'Belated Job' },
    { value: 'Geofence Detention', label: 'Geofence Detention' },
    { value: 'Maximum Stoppage', label: 'Maximum Stoppage' },
    { value: 'Maximum Driving', label: 'Maximum Driving' },
    {
        value: 'Sharp Turn',
        label: 'Sharp Turn',
    },
    { value: 'Speeding', label: 'Speeding' },
    {
        value: 'Misuse',
        label: 'Misuse Alert',
    },
    {
        value: 'Route Deviation',
        label: 'Route Deviation',
    },
    {
        value: 'Lock Low Battery',
        label: 'Lock Low Battery',
    },
    {
        value: 'Lock Critical Battery',
        label: 'Lock Critical Battery',
    },
    {
        value: 'Lock Status',
        label: 'Lock Status',
    },
    {
        value: 'EV Charge',
        label: 'EV Charge',
    },
    {
        value: 'Power Off',
        label: 'Disconnect',
    },
    {
        value: 'LowFuel',
        label: 'Low Fuel',
    },
    {
        value: 'Fuel Sensor Disconnected',
        label: 'Fuel Sensor Disconnected',
    },
    {
        value: 'Engine Oil Pressure',
        label: 'Engine Oil Pressure',
    },
    {
        value: 'Drowsiness L1',
        label: 'Drowsiness L1',
    },
    {
        value: 'Drowsiness L2',
        label: 'Drowsiness L2',
    },
    {
        value: 'Drowsiness L3',
        label: 'Drowsiness L3',
    },
    {
        value: 'Distraction',
        label: 'Distraction',
    },
    {
        value: 'Camera Obstruction',
        label: 'Camera Obstruction',
    },
    {
        value: 'Camera Low Voltage',
        label: 'Camera Low Voltage',
    },
    {
        value: 'Camera Power Failure',
        label: 'Camera Power Failure',
    },
    {
        value: 'Phone usage-texting',
        label: 'Phone usage-texting',
    },
    {
        value: 'Smoking',
        label: 'Smoking',
    },
    {
        value: 'Yawning',
        label: 'Yawning',
    },
    {
        value: 'Risk Point Stoppage',
        label: 'Risk Point Stoppage',
    },
    {
        value: 'Tail Gating',
        label: 'Tail Gating',
    },
    {
        value: 'Traffic Speed Violated',
        label: 'Traffic Speed Violated',
    },
    {
        value: 'Cornering',
        label: 'Cornering',
    },
    {
        value: 'Drowsy Driving',
        label: 'Drowsy Driving',
    },
    {
        value: 'Forward Collision Warning',
        label: 'Forward Collision Warning',
    },
    {
        value: 'Cellphone Distracted Driving',
        label: 'Cellphone Distracted Driving',
    },
    {
        value: 'Ignition On',
        label: 'Ignition On',
    },
    {
        value: 'Ignition Off',
        label: 'Ignition Off',
    },
    {
        value: 'Sudden Stoppage',
        label: 'Sudden Stoppage',
    },
    {
        value: 'Unknown Stoppage',
        label: 'Unknown Stoppage',
    },
    {
        value: 'Job Overrun',
        label: 'Job Overrun',
    },
    {
        value: 'Lane Departure Warning',
        label: 'Lane Departure Warning',
    },
    {
        value: 'Pedestrian/Small Vehicle Collision Warning',
        label: 'Pedestrian/Small Vehicle Collision Warning',
    },
    {
        value: 'Headway Monitoring Warning',
        label: 'Headway Monitoring Warning',
    },
    {
        value: 'Camera Cover',
        label: 'Camera Cover',
    },
    {
        value: 'Frequent Lane Change',
        label: 'Frequent Lane Change',
    },

    {
        value: 'Road Sign Limit Breached',
        label: 'Road Sign Limit Breached',
    },
    {
        value: 'Obstacle Alarm',
        label: 'Obstacle Alarm',
    },
    {
        value: 'No Visual',
        label: 'No Visual',
    },
    {
        value: 'Check DMS System',
        label: 'Check DMS System',
    },
    {
        value: 'Seat Belt',
        label: 'Seat Belt',
    },
    {
        value: 'Driver Not Detected',
        label: 'Driver Not Detected',
    },
    {
        value: 'Hands Off Steering',
        label: 'Hands Off Steering',
    },
    {
        value: 'Driver Abnormal',
        label: 'Driver Abnormal',
    },
    {
        value: 'Calling',
        label: 'Calling',
    },
    {
        value: 'Night Driving',
        label: 'Night Driving',
    },
    {
        value: 'Vehicle Load Status',
        label: 'Vehicle Load Status',
    },
    {
        value: 'Loaded Destination',
        label: 'Loaded Destination',
    },
    {
        value: 'Unloaded Destination',
        label: 'Unloaded Destination',
    },
    {
        value: 'Driver Authentication',
        label: 'Driver Authentication',
    },
    {
        value: 'Humanoid Detection',
        label: 'Humanoid Detection',
    },
    {
        value: SAFETY_ALARMS,
        label: SAFETY_ALARMS,
    },
    // {value: 'Malfunctioned Indicator Lamp', label: 'Malfunctioned Indicator Lamp'}
    // { value: 'Towing', label: 'Towing' },
    // {value: 'Privacy Status', label: 'Privacy Status'},
    // {value: 'No Gps', label: 'No Gps'},
    // {value: 'OBD Error', label: 'OBD Error'},
    // {value: 'Unlock', label: 'Unlock'},
    // {value: 'No Card', label: 'No Card'}
];
export const ALERT_SUB_TYPE_OPTIONS = [
    { value: 'Entry', label: 'Geofence Entry', type: 'Geofence' },
    { value: 'Exit', label: 'Geofence Exit', type: 'Geofence' },
    { value: 'Ignition On', label: 'Misuse Ignition On', type: 'Misuse' },
    { value: 'Ignition Off', label: 'Misuse Ignition Off', type: 'Misuse' },
    { value: 'Night Driving', label: 'Misuse Night Driving', type: 'Misuse' },
    { value: 'Fill', label: 'Fuel Fill', type: 'FuelLevel' },
    { value: 'Theft', label: 'Fuel Theft', type: 'FuelLevel' },
    { value: 'Inflow', label: 'Fuel Inflow', type: 'FuelLevel' },
    { value: 'Outflow', label: 'Fuel Outflow', type: 'FuelLevel' },
    { value: 'AC START', label: 'Cabin AC On', type: 'Cabin AC Status' },
    { value: 'AC STOP', label: 'Cabin AC Off', type: 'Cabin AC Status' },
    { value: 'Maximum', label: 'Maximum', type: 'Temperature' },
    { value: 'Minimum', label: 'Minimum', type: 'Temperature' },
    { value: 'Locked', label: 'Locked', type: 'Lock Status' },
    { value: 'Unlocked', label: 'Unlocked', type: 'Lock Status' },
    { value: 'start', label: 'Charging Start', type: 'EV Charge' },
    { value: 'stop', label: 'Charging Stop', type: 'EV Charge' },
    { value: 'full', label: 'Charging Full', type: 'EV Charge' },
    { value: 'Failed', label: 'Failed', type: 'Driver Authentication' },
    { value: 'Success', label: 'Success', type: 'Driver Authentication' },
    { value: 'Loose Wiring', label: 'Loose Wiring', type: 'Power Off' },
    { value: 'Level One', label: 'Level One', type: SAFETY_ALARMS },
    { value: 'Level Two', label: 'Level Two', type: SAFETY_ALARMS },
];

export const ACCOUNT_SPECIFIC_ALARMS_OPTIONS = uniqBy(
    [
        ...ALERT_TYPE_OPTIONS,
        {
            value: 'Distraction',
            label: 'Distraction',
        },
    ],
    'value'
);

export const ALERT_TYPES = {
    chargingStart: 'Charging Start',
    chargingStop: 'Charging Stop',
    chargingFull: 'Charging Full',
    hardAcceleration: 'Hard Acceleration',
    hardDeceleration: 'Hard Deceleration',
    fuellevel: 'FuelLevel',
    fuellevel2: 'FuelLevel2',
    fuellevel3: 'FuelLevel3',
    fuellevel4: 'FuelLevel4',
    speeding: 'Speeding',
};

const getAccDeAccValueWithUnit = (value) => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case 7653:
        case 9379:
        case 11046:
            return round(value * 35.3, 2) + ' KM/(h.s)';
        default:
            return round(value, 2) + ' g';
    }
};

export function getAlarmDetail(
    type,
    currentValue,
    thresholdValue,
    subType,
    isGrouped,
    reportFormat,
    row,
    addressBook,
    loggedInUser
) {
    let detail = '';
    const isFromReportPreparation = get(row, 'isFromReportPreparation', false);

    type = toLower(type);
    if (type === 'speeding') {
        if (currentValue && thresholdValue) {
            if (thresholdValue <= 60) {
                detail = `Overspeeding for ${round(currentValue, 1)} min`;
            } else {
                detail = `${round(currentValue)}km/h (Limit ${thresholdValue}km/h)`;
            }
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'geofence') {
        if (subType) {
            detail = '-';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }

    if (type === 'Job Overrun') {
        if (isGrouped) {
            detail = 'Click for more';
        }
        if (currentValue && thresholdValue) {
            detail = `${round(currentValue)} Kms`;
        }
    }

    if (type === 'adblue level') {
        if (currentValue && thresholdValue) {
            detail = `${round(currentValue)}L (Limit ${thresholdValue}L)`;
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }

    if (type === 'unknown stoppage') {
        if (isGrouped) {
            detail = 'Click for more';
        }
        if (currentValue && thresholdValue) {
            detail = `${round(currentValue)} Minutes`;
        }
    }
    if (type === 'misuse') {
        if (currentValue) {
            detail = `${round(currentValue, 2)}Km`;
        } else if (isGrouped) {
            detail = 'Click for more';
        }
        if (subType) {
            detail = `${detail} ${subType}`;
        }
    }
    if (type === 'high engine coolant temperature') {
        if (currentValue && thresholdValue) {
            const unit = `${!reportFormat || reportFormat === REPORT_FORMAT.PDF ? '°' : ''}C`;
            detail = `${currentValue}${unit} (Limit ${thresholdValue}${unit})`;
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'low voltage') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue, 1) + 'V (Limit ' + thresholdValue + 'V)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'high rpm') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + ' (Limit ' + thresholdValue + ')';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'idle engine') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + 'm (Limit ' + thresholdValue + 'm)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'temperature') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + '°C (Limit ' + thresholdValue + '°C)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'temperature2') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + '°C (Limit ' + thresholdValue + '°C)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'temperature3') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + '°C (Limit ' + thresholdValue + '°C)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'free wheeling') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue * 60) + ' secs';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'Lock Tamper') {
        if (subType) {
            detail = startCase(subType);
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'Lock Low Battery') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + '% (Limit ' + thresholdValue + '%)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'Lock Critical Battery') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + '% (Limit ' + thresholdValue + '%)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'route deviation') {
        if (currentValue && thresholdValue) {
            detail = round(currentValue) + 'KM (Limit ' + thresholdValue + 'KM)';
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'external machince status' || type === 'external machince 2 status') {
        if (subType) {
            detail = startCase(subType);
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'parked') {
        if (subType) {
            detail = startCase(subType);
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'ev charge') {
        if (subType == 'stop') {
            const chargeDetected = get(row, 'otherAttributes.detectedCharge', '');
            const totalChargeTime = get(row, 'otherAttributes.totalChargeTime', '');
            const finalChargeValue = get(
                row,
                'otherAttributes.chargingEndValue',
                get(row, 'otherAttributes.voltage', '')
            );
            const evPowerType = get(row, 'otherAttributes.evPowerType', '');
            const unit = evPowerType == 'voltage' ? 'V' : '%';
            if (!isFromReportPreparation) {
                detail = [];
                detail.push(
                    <div>
                        <span className="text-muted">End Charge:</span>
                        {` ${round(finalChargeValue, 2)}${unit}`}
                    </div>
                );
                detail.push(
                    <div>
                        <span className="text-muted">Rise:</span> {` ${round(chargeDetected, 2)}${unit}`}
                    </div>
                );
                detail.push(
                    <div>
                        <span className="text-muted">Duration:</span>
                        {` ${getTimeDiff(totalChargeTime, true, true, true)}`}
                    </div>
                );
                if (isFastCharging(row)) {
                    detail.push(
                        <div>
                            <FxToolTip title={window.t('fastCharging')}>
                                <FxTag color={COLOR_WARNING} icon={<FxThunderboltOutlined />} />
                            </FxToolTip>
                        </div>
                    );
                }
            } else {
                detail = `End Charge: ${round(finalChargeValue, 2)}${unit}, Rise: ${round(
                    chargeDetected,
                    2
                )}${unit} ,Duration: ${getTimeDiff(totalChargeTime, true, true, true)}`;
            }
        } else if (subType == 'start') {
            const chargingStartTime = get(row, 'otherAttributes.chargingStartTime', '');
            const chargingStartValue = get(row, 'otherAttributes.chargingStartValue', '');
            const evPowerType = get(row, 'otherAttributes.evPowerType', '');
            const unit = evPowerType == 'voltage' ? 'V' : '%';
            if (!isFromReportPreparation) {
                detail = [];
                detail.push(
                    <div>
                        <span className="text-muted">Start Charge:</span>
                        {` ${round(chargingStartValue, 2)}${unit}`}
                    </div>
                );
                detail.push(
                    <div>
                        <span className="text-muted">Start Time:</span>
                        {` ${getHumanizeTime(chargingStartTime)}`}
                    </div>
                );
                if (isFastCharging(row)) {
                    detail.push(
                        <div>
                            <FxToolTip title={window.t('fastCharging')}>
                                <FxTag color={COLOR_WARNING} icon={<FxThunderboltOutlined />} />
                            </FxToolTip>
                        </div>
                    );
                }
            } else {
                detail = `Start Charge: ${round(chargingStartValue, 2)}${unit}, Start Time: ${getHumanizeTime(
                    chargingStartTime
                )}`;
            }
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }

    if (type === 'charging stop' || type === 'charging start' || type === 'charging full') {
        if (subType) {
            detail = startCase(subType);
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }

    if (type === 'fuellevel' || type === 'fuellevel2' || type === 'fuellevel3' || type === 'fuellevel4') {
        if (currentValue) {
            const fuelUnit = getFuelUnitForDevice(null, row.deviceId);
            if (!isFromReportPreparation) {
                detail = `${startCase(subType)} ${round(currentValue)}${fuelUnit} (Limit ${thresholdValue}${fuelUnit})`;
                if (subType === 'fill') {
                    detail = (
                        <div>
                            {detail}
                            <div>
                                <span className="text-muted">Distance: </span>
                                {round(get(row, 'tOdoFromLastReading', 0))} Kms
                            </div>
                            <div>
                                <span className="text-muted">Fuel Consumed: </span>
                                {round(get(row, 'fuelFromLastReading', 0))} {fuelUnit}
                            </div>
                            {!!get(row, 'mileageFromLastReading') && (
                                <div>
                                    <span className="text-muted">Mileage: </span>
                                    {round(get(row, 'mileageFromLastReading', 0))} Kms/{fuelUnit}
                                </div>
                            )}
                        </div>
                    );
                }
            } else {
                detail = `${startCase(subType)} ${round(currentValue)}${fuelUnit} (Limit ${thresholdValue}${fuelUnit})`;
            }
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'lowfuel') {
        if (thresholdValue) {
            detail = `${round(currentValue)}L (Limit ${thresholdValue}L)`;
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'maximum driving') {
        if (currentValue && thresholdValue) {
            const thours = thresholdValue;
            detail = `Driving time: ${getTimeDiff(currentValue * HOURS_1, false, true)} (Limit: ${thours}h)`;
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'fatigue driving') {
        if (currentValue && thresholdValue) {
            const tMins = thresholdValue;
            detail = `Driving time: ${getTimeDiff(currentValue * MINUTES_1, false, true)} (Limit: ${tMins}mins)`;
        } else if (isGrouped) {
            detail = 'Click for more';
        }
    }
    if (type === 'charging start' || type === 'charging stop' || type === 'charging full') {
        if (isGrouped) {
            detail = 'Click for more';
        } else if (currentValue) {
            detail = `Battery Level (Volt): ${getBatteryPowerLevelForDisplay(currentValue)}`;
        }
    }

    if (includes(['hard acceleration', 'hard deceleration'], type)) {
        if (isGrouped) {
            detail = 'Click for more';
        } else if (currentValue) {
            detail = `Current Value: ${getAccDeAccValueWithUnit(currentValue)} (Limit: ${getAccDeAccValueWithUnit(
                thresholdValue
            )})`;
        }
    }
    if (type === 'sharp turn') {
        const speed = get(row, 'speed');
        if (isGrouped) {
            detail = 'Click for more';
        } else if (currentValue || speed) {
            if (currentValue) {
                detail = `Current Value: ${getAccDeAccValueWithUnit(currentValue)} (Limit: ${getAccDeAccValueWithUnit(
                    thresholdValue
                )})`;
            }
            if (!isUndefined(speed)) {
                if (isFromReportPreparation) {
                    detail += `Speed: ${round(speed)}`;
                } else {
                    detail = (
                        <div>
                            {detail}
                            <div>Speed: {round(speed)} km/h</div>
                        </div>
                    );
                }
            }
        }
    }

    if (
        includes(
            [
                'emergency',
                'door open',
                'door close',
                'door sensor tampered',
                'drum status',
                'lock status',
                'dumper status',
                'refer ac status',
                'cabin ac status',
                'external machine status',
                'power off',
                'geofence detention',
            ],
            type
        )
    ) {
        const nearestAddress = getNearestAddressFromAddressbook(
            row.latitude,
            row.longitude,
            addressBook,
            true,
            loggedInUser
        );
        const isInside = nearestAddress ? isInsideNearestAddress(nearestAddress) : false;
        if (reportFormat && nearestAddress) {
            //wii be used in reports
            detail = `Address: ${isInside ? nearestAddress.addressName : nearestAddress.addressText}`;
        } else {
            let subDetails = '';
            let subDetails2 = '';
            if (
                type === 'drum status' ||
                type === 'dumper status' ||
                type === 'refer ac status' ||
                type === 'cabin ac status' ||
                type === 'external machine status'
            ) {
                if (subType) {
                    subDetails = startCase(subType);
                } else if (isGrouped) {
                    subDetails2 = 'Click for more';
                }
            }
            if (type === 'lock status') {
                const otherAttributes = get(row, 'otherAttributes', {});
                const unlockMethod = get(otherAttributes, 'unlockMethod', '');
                const unlockSource = get(otherAttributes, 'unlockSource', '');
                if (unlockMethod) {
                    subDetails = `${startCase(subType)} ${
                        unlockMethod === 'RFID Card'
                            ? ` (${unlockMethod} : ${unlockSource})`
                            : unlockMethod === 'Bluetooth'
                            ? ` (Driver App)`
                            : unlockMethod === 'Other'
                            ? ` (Unknown)`
                            : ' (Dashboard)'
                    }`;
                } else {
                    subDetails = startCase(subType);
                }
            }

            detail = (
                <div className="d-inline-block">
                    {subDetails && <div className="mb-1">{subDetails}</div>}
                    {nearestAddress && (
                        <span>
                            <span className="text-muted mr-1">Address:</span>
                            <span className={`${isInside ? 'text-success' : ''}`}>
                                {isInside ? nearestAddress.addressName : nearestAddress.addressText}
                            </span>
                        </span>
                    )}
                    {subDetails2 && <div className="mt-1">{subDetails2}</div>}
                </div>
            );
        }
    }
    if (type === 'lock tamper') {
        if (isGrouped) {
            detail = 'Click for more';
        } else if (subType) {
            detail = startCase(subType);
        }
    }
    return detail;
}

export const getAlarmMapping = (alarmName, type) => {
    const alarmMapping = [
        {
            name: ['emergency', 'sos'],
            desc: 'Triggers when SoS is pressed. ',
        },
        {
            name: ['speeding', 'over speeding'],
            desc: 'Triggers when the speed of the vehicle exceeds set threshold.',
        },
        {
            name: ['hard acceleration'],
            desc: 'Hard Acceleration.',
        },
        {
            name: ['hard deceleration'],
            desc: 'Hard Deceleration.',
        },
        {
            name: ['drowsy driving', 'eye closed', 'fatigue driving', 'fatigue driving(video)', 'drowsiness'],
            desc:
                'The alarm will be triggered when the duration of continuous eyes (two eyes)closure exceeds the set threshold',
        },
        {
            name: ['lane deviation'],
            desc:
                'Triggers when a vehicle drifts from its lane, signalling potential distraction or fatigue for safer driving.',
        },

        {
            name: ['lane deviation'],
            desc:
                'Triggers when a vehicle drifts from its lane, signalling potential distraction or fatigue for safer driving.',
        },
        {
            name: ['yawning'],
            desc: 'The alarm will be triggered when the duration of yawning exceeds the set threshold',
        },
        {
            name: ['distracted driving', 'distraction'],
            desc:
                'the alarm will be trigger if the head turn left / right or turn up / down, and duration time exceeds the set threshold ',
        },
        {
            name: ['smoking'],
            desc:
                'the alarm will trigger when the duration of continuous smoking exceeds the set threshold or when cigarette is detected',
        },
        {
            name: ['phone call', 'cellphone distracted driving', 'calling'],
            desc: 'When the call time exceeds the set threshold, the alarm will be triggered',
        },
        {
            name: ['camera cover'],
            desc: 'the alarm will be triggered when camera cover time exceeds the set threshold. ',
        },
        {
            name: ['sunglasses', 'camera blocked or blurred'],
            desc:
                'the alarm will be triggered when the duration of infrared was blocking by sunglasses, could not go through sunglasses to detect human eyes exceeds the set threshold',
        },
        {
            name: ['driver absent', 'no driver'],
            desc: 'No Driver',
        },
        {
            name: ['driver abnormal'],
            desc:
                'Detects unusual driver behaviors like slouching or sudden movements, indicating distraction or discomfort to ensure safety.',
        },

        {
            name: ['driver abnormal'],
            desc:
                'Detects unusual driver behaviors like slouching or sudden movements, indicating distraction or discomfort to ensure safety.',
        },
        {
            name: ['driver shift/change'],
            desc:
                'Trigger when the camera image is not covered and the driver cannot be detected for a long time exceeding the set threshold. ',
        },
        {
            name: ['forward collision warning'],
            desc:
                'The alarm will be Triggered when the relative collision time with the front vehicle is less than configured threshold.',
        },
        {
            name: ['tailgating', 'headway monitoring', 'headway monitoring warning'],
            desc:
                'When the vehicle in front runs at a constant speed, and the collision time with the front vehicle is less than configured threshold, it will be triggered.',
        },
        {
            name: ['pedestrian collision warning', 'pedestrian/small vehicle collision warning'],
            desc: 'if there are pedestrian or human on non motor vehicle, the alarm will be triggered',
        },
        {
            name: ['lane departure warning', 'frequent lane change'],
            desc: 'When the driver shift the lane to left/ right, the alarm will be triggered',
        },
        {
            name: ['seat belt'],
            desc: 'Triggers when no seat belt is detected.',
        },
        {
            name: ['camera low voltage'],
            desc: 'When supply to the camera is below the required voltage.',
        },
    ];
    const alarm = alarmMapping.find((alarm) => alarm.name.includes(toLower(alarmName)));
    return alarm ? alarm.desc : '-';
};

export function getAlarmDisplayName(alarmName) {
    switch (toLower(alarmName)) {
        case 'emergency':
            return 'SOS Alarm';
        case 'sms location':
            return 'Location on SMS';
        case 'high engine coolant temperature':
            return 'High Engine Temp.';
        case 'high rpm':
            return 'High RPM';
        case 'crash':
            return 'Crash';
        case 'total':
            return 'Total';
        case 'power off':
            return 'Disconnect';
        case 'power on':
            return 'Connected';
        case 'ewaybillexpiry':
            return 'E-Way Bill Expiry';
        case 'parked':
            return 'Parked On Job';
        case 'mintemp':
            return 'Minimum Temp.';
        case 'fuellevel':
            return 'Fuel Level';
        case 'numberoffills':
            return 'Fuel Fills';
        case 'numberofthefts':
            return 'Fuel Thefts';
        case 'numberofpilferage':
            return 'Fuel Pilferage';
        case 'maximum driving':
            return 'Maximum Driving';
        case 'geofence detention':
            return 'Geofence Detention';
        case 'free wheeling':
            return 'Free Wheeling';
        case 'lowfuel':
            return 'Low Fuel';
        case 'Harsh Acceleration':
            return 'Hard Acc(Video)';
        case 'Harsh Deceleration':
            return 'Hard Dec(Video)';
        case 'inflow':
            return 'Fuel In Flow';
        case 'outflow':
            return 'Fuel Out Flow';
        default:
            return alarmName;
    }
}

export function getAlarmLimitDisplayName(alarmName) {
    switch (toLower(alarmName)) {
        case 'temperature':
            return 'Maximum Temp.';
        default:
            return 'Limit';
    }
}

export function getAlarmLimitUnit(alarmName, unit) {
    switch (toLower(alarmName)) {
        case 'fuellevel':
            return 'Liters or % - based on device';
        default:
            return unit;
    }
}

export const ALERT_SETTINGS_FIELDS = {
    1: {
        id: 1,
        name: 'Speeding',
        defaultValue: 80,
    },
    2: {
        id: 2,
        name: 'Geofence',
    },
    4: {
        id: 4,
        name: 'Towing',
    },
    14: {
        id: 14,
        name: 'Emergency',
    },
    15: {
        id: 15,
        name: 'Crash',
    },
    16: {
        id: 16,
        name: 'Location on SMS',
    },
    20: {
        id: 20,
        name: 'Overspeed Duration',
    },
    31: {
        id: 31,
        name: 'Temperature',
    },
    32: {
        id: 32,
        name: 'Min Temperature',
    },
    34: {
        id: 34,
        name: 'Job Booking',
    },
    38: {
        id: 38,
        name: 'Lock Tamper',
    },
    39: {
        id: 39,
        name: 'Lock Low Battery',
    },
    40: {
        id: 40,
        name: 'Lock Critical Battery',
    },
    54: {
        id: 54,
        name: 'Temperature2',
    },
    55: {
        id: 55,
        name: 'Min. Temperature 2',
    },
    56: {
        id: 56,
        name: 'Temperature3',
    },
    57: {
        id: 57,
        name: 'Min. Temperature 3',
    },
    13: {
        id: 13,
        name: 'Fatigue Driving',
    },
    97: {
        id: 97,
        name: 'Fatigue Driving Stoppage',
    },
};
export const TIME_FENCE_ALARM_TYPE = {
    POWER_OFF: 'POWER_OFF',
    IGNITION_ON: 'IGNITION_ON',
    GEOFENCE: 'GEOFENCE',
    NIGHT_DRIVING: 'NIGHT_DRIVING',
};
export const TIME_FENCE_ALARM_TYPE_NAME = {
    POWER_OFF: 'Device disconnected',
    IGNITION_ON: 'Vehicle started',
    GEOFENCE: 'Geofence exit',
    NIGHT_DRIVING: 'Night Driving',
};

export const getReferAcDuration = (alertList, index) => {
    if (index < 1) {
        return '';
    }
    const prevAlert = get(alertList, `${index - 1}`);
    const prevTimeStamp = get(prevAlert, 'timeStamp', '');
    const currentAlert = get(alertList, `${index}`);
    const currentTimeStamp = get(currentAlert, 'timeStamp', '');
    return getMomentTime(currentTimeStamp).valueOf() - getMomentTime(prevTimeStamp).valueOf();
};
export const getCabinAcDuration = (alertList, index) => {
    if (index < 1) {
        return '';
    }
    const prevAlert = get(alertList, `${index - 1}`);
    const prevTimeStamp = get(prevAlert, 'timeStamp', '');
    const currentAlert = get(alertList, `${index}`);
    const currentTimeStamp = get(currentAlert, 'timeStamp', '');
    return getMomentTime(currentTimeStamp).valueOf() - getMomentTime(prevTimeStamp).valueOf();
};

export const getExternalMachineOnDuration = (alertList, index, subType) => {
    if (subType !== 'DEVICE OFF' || index < 1) {
        return '';
    }
    const prevAlert = get(alertList, `${index - 1}`);
    const prevSubType = get(prevAlert, 'subType', '');
    const prevTimeStamp = get(prevAlert, 'timeStamp', '');
    const currentAlert = get(alertList, `${index}`);
    const currentSubType = get(currentAlert, 'subType', '');
    const currentTimeStamp = get(currentAlert, 'timeStamp', '');
    if (prevSubType === 'DEVICE ON' && currentSubType === 'DEVICE OFF' && currentTimeStamp && prevTimeStamp) {
        return getMomentTime(currentTimeStamp).valueOf() - getMomentTime(prevTimeStamp).valueOf();
    }
    return '';
};

export function isAnyTemperatureAlarm(alarmName) {
    return includes(['temperature', 'temperature2', 'temperature3'], toLower(alarmName));
}

export function isTimeRangeEnabled(alertSetting) {
    return get(alertSetting, 'timeRangeEnabled', false);
}

export const AGGREGATE_ALARMS_OPTIONS = [
    { label: 'Time', value: 'TIME' },
    { label: 'Occurence', value: 'FREQUENCY' },
];

export const AGGREGATE_ALARMS_TIME_BASED_OPTIONS = [
    { label: 'Every Hour', value: 'EVERY_HOUR' },
    { label: 'Two Hour', value: 'TWO_HOUR' },
    { label: 'Four Hour', value: 'FOUR_HOUR' },
    { label: 'Eight Hour', value: 'EIGHT_HOUR' },
    { label: 'Twelve Hour', value: 'TWELVE_HOUR' },
    { label: 'Once a Day', value: 'ONCE_A_DAY' },
];

export const PERSON_NOTIFY_TYPE = [
    { label: 'ALL', value: 'ALL' },
    { label: 'NORMAL', value: 'NORMAL' },
    { label: 'HIGH', value: 'HIGH' },
];

export function getModifiedAlertAggregation(aggr) {
    if (aggr.type === 'Fuel Thefts') {
        return {
            ...aggr,
            type: 'numberOfThefts',
        };
    } else if (aggr.type === 'Fuel Fills') {
        return {
            ...aggr,
            type: 'numberOfFills',
        };
    } else if (aggr.type === 'Pilferage') {
        return {
            ...aggr,
            type: 'numberOfPilferage',
        };
    } else {
        return aggr;
    }
}

export const AlARM_SUB_TYPE_ICON = {
    'Disconnect Alarm': {
        image: '/public/img/icons/alarmSubType/Disconnect Alarm.svg',
        name: 'Disconnect Alarm',
    },
    'Emergency': {
        image: '/public/img/icons/alarmSubType/Emergency.svg',
        name: 'Emergency',
    },
    'Fatigue Driving': {
        image: '/public/img/icons/alarmSubType/Fatigue Driving.svg',
        name: 'Fatigue Driving',
    },
    'Free Wheeling': {
        image: '/public/img/icons/alarmSubType/Free Wheeling.svg',
        name: 'Free Wheeling',
    },
    'Fuel Level': {
        image: '/public/img/icons/alarmSubType/Fuel Level.svg',
        name: 'Fuel Level',
    },
    'Hard Acceleration': {
        image: '/public/img/icons/alarmSubType/Hard Acceleration.svg',
        name: 'Hard Acceleration',
    },
    'Hard Deceleration': {
        image: '/public/img/icons/alarmSubType/Hard Deceleration.svg',
        name: 'Hard Deceleration',
    },
    'Idle Engine': {
        image: '/public/img/icons/alarmSubType/Idle Engine.svg',
        name: 'Idle Engine',
    },
    'Job Deviation': {
        image: '/public/img/icons/alarmSubType/Job Deviation.svg',
        name: 'Job Deviation',
    },
    'Maximum Driving': {
        image: '/public/img/icons/alarmSubType/Maximum Driving.svg',
        name: 'Maximum Driving',
    },
    'Maximum Stoppage': {
        image: '/public/img/icons/alarmSubType/Maximum Stoppage.svg',
        name: 'Maximum Stoppage',
    },
    'Misuse': {
        image: '/public/img/icons/alarmSubType/Misuse.svg',
        name: 'Misuse',
    },
    'parked': {
        image: '/public/img/icons/alarmSubType/parked.svg',
        name: 'parked',
    },
    'Power Off': {
        image: '/public/img/icons/alarmSubType/Power Off.svg',
        name: 'Power Off',
    },
    'Route Deviation': {
        image: '/public/img/icons/alarmSubType/Route Deviation.svg',
        name: 'Route Deviation',
    },
    'Speeding': {
        image: '/public/img/icons/alarmSubType/Speeding.svg',
        name: 'Speeding',
    },
    'Zigzag Alarm': {
        image: '/public/img/icons/alarmSubType/Zigzag Alarm.svg',
        name: 'Zigzag Alarm',
    },
    'Not Online': {
        image: '/public/img/icons/alarmSubType/Not Online.svg',
        name: 'Not Online',
    },
    'Other': {
        image: '/public/img/icons/alarmSubType/Zigzag Alarm.svg',
        name: 'Zigzag Alarm',
    },
};
