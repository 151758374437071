import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const RightArrow = () => {
    return (_jsx("svg", { width: "14", height: "6", viewBox: "0 0 14 6", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M0.617188 2.625C0.410081 2.625 0.242188 2.79289 0.242188 3C0.242188 3.20711 0.410081 3.375 0.617188 3.375V2.625ZM13.9441 3L10.1941 0.834936V5.16506L13.9441 3ZM0.617188 3.375H0.98738V2.625H0.617188V3.375ZM1.72776 3.375H2.46815V2.625H1.72776V3.375ZM3.20853 3.375H3.94892V2.625H3.20853V3.375ZM4.6893 3.375H5.42969V2.625H4.6893V3.375ZM6.17007 3.375H6.91046V2.625H6.17007V3.375ZM7.65084 3.375H8.39123V2.625H7.65084V3.375ZM9.13161 3.375H9.872V2.625H9.13161V3.375ZM10.6124 3.375H11.3528V2.625H10.6124V3.375ZM12.0932 3.375H12.8335V2.625H12.0932V3.375Z", fill: "#C8C8C8" }) }));
};
const RightArrowIconV3 = (props) => {
    const {} = props;
    return _jsx(Icon, { component: RightArrow, ...props });
};
export default RightArrowIconV3;
