import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { FxCol, FxRow } from 'sharedV2/FxGrid';
import { DatePicker } from 'sharedV2/FxFormItems';
import BillNumberComponentV2 from 'components/dashboard/bill_number/bill_number_component_v2';
import FxTextFieldStackedLabelV2 from 'sharedV2/FxTextFieldStackedLabelV2';
import FxTextAreaStackedLabelV2 from 'sharedV2/FxTextAreaStackedLabelV2';
import RecoveryComponentJobAdvance from './RecoveryComponentJobAdvance';
import { FxSelectField } from 'sharedV2/index';
import { mappedOnlyConsignorsConsignees } from 'utils/form_utils';
const JobAdvanceFormComponent = (props) => {
    const { accesstoken, formikProps, editId, enableTransactionDetails, setEnableTransactionDetails, ledgers, customers, handleFilterChange, } = props;
    const { t } = useTranslation();
    const options = {
        customers: useMemo(() => {
            return mappedOnlyConsignorsConsignees(filter(customers, (el) => el.ledgerId));
        }, [customers]),
    };
    return (_jsxs(_Fragment, { children: [_jsxs(FxRow, { children: [_jsx(DatePicker, { name: `paymentDate`, label: "Payment Date", required: true, "data-testid": "basic-details-billing-date", colSpan: 8 }), _jsx(BillNumberComponentV2, { formikProps: formikProps, isEdit: !!editId, isReadyToFetchBillNumber: true, colClass: 8, removeDivWrapper: true, branchRequired: true }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { name: `creditAccountId`, component: FxSelectField, options: options.customers, label: 'Credit Account', isRequired: true, onChange: (val) => {
                                handleFilterChange('customerId', val);
                            } }) }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { type: 'text', name: `adviceNumber`, component: FxTextFieldStackedLabelV2, label: "Advice No.", isRequired: true }) }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { type: 'text', name: `advanceAmount`, component: FxTextFieldStackedLabelV2, label: "Advance Amount", isRequired: true, disabled: true }) }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { name: "narration", type: "text", component: FxTextAreaStackedLabelV2, label: "Narration" }) })] }), _jsx("h6", { className: "mt-4", children: "Recovery Section" }), _jsx("hr", {}), _jsx(RecoveryComponentJobAdvance, { formikProps: formikProps, editId: editId, enableTransactionDetails: enableTransactionDetails, setEnableTransactionDetails: setEnableTransactionDetails, ledgers: props.ledgers })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        ledgers: state.consignments.ledgers,
        customers: get(state, 'customers.customers', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(JobAdvanceFormComponent);
