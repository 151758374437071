import { jsx as _jsx } from "react/jsx-runtime";
import PaymentsComponent from 'components/dashboard/payment_forms/PaymentsComponent';
import ErrorBoundary from 'components/utils/error_boundary_component';
import AddEditPaymentFormComponent from 'components/dashboard/payment_forms/forms/AddEditPaymentFormComponent';
import AddEditOnAccountFormComponent from 'components/dashboard/payment_forms/forms/AddEditOnAccountFormComponent';
import AddEditJobAdvanceComponent from 'components/dashboard/payment_forms/forms/AddEditJobAdvanceComponent';
const advanceRoutes = [
    {
        path: 'all',
        element: (_jsx(ErrorBoundary, { children: _jsx(PaymentsComponent, {}) })),
    },
    {
        path: 'driver/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "DRIVER_ADVANCE" }) })),
    },
    {
        path: 'driver/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "DRIVER_ADVANCE" }) })),
    },
    {
        path: 'cn-advance/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "CN_ADVANCE" }) })),
    },
    {
        path: 'cn-advance/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "CN_ADVANCE" }) })),
    },
    {
        path: 'job-advance/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditJobAdvanceComponent, {}) })),
    },
    {
        path: 'job-advance/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditJobAdvanceComponent, {}) })),
    },
    {
        path: 'payment-receipt/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "PAYMENT_RECEIPT" }) })),
    },
    {
        path: 'payment-receipt/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "PAYMENT_RECEIPT" }) })),
    },
    {
        path: 'balance-payment/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "HIRE_SLIP" }) })),
    },
    {
        path: 'balance-payment/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "HIRE_SLIP" }) })),
    },
    {
        path: 'payment-receipt-job/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "JOB_BILL_RECEIPT" }) })),
    },
    {
        path: 'payment-receipt-job/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditPaymentFormComponent, { advanceType: "JOB_BILL_RECEIPT" }) })),
    },
    {
        path: 'account/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditOnAccountFormComponent, {}) })),
    },
    {
        path: 'account/edit/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddEditOnAccountFormComponent, {}) })),
    },
];
export default advanceRoutes;
