import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map, set, filter, find, forEach, remove, unset } from 'lodash';
import { Field, FieldArray, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { fetchStores } from '../../../actions/dashboard/client_stores_actions';
import { getPageTitle } from '../../../../white_label_constants';
import { FxSelectField, FxSelectSingleDate, FxTextFieldStackedLabel } from '../../../shared';
import { Button, Col, Row } from 'reactstrap';
import { mappedClientStores, mappedOptionsFromArray } from '../../utils/mapping_utils';
import { fetchLedgers } from '../../../actions/dashboard/consignments/ledgersActions';
import { mappedTags } from '../../../utils/tag_utils';
import { fetchTags } from '../../../actions/dashboard/tags_action';
import { DATE_FORMAT_LOCAL_DATE_TIME, DATE_FORMAT_TIMESTAMP, getMomentTime } from '../../../utils/date_utils';
import { handleError } from '../../../constant';
import { issueRemoveTyre, tyreFilter, fetchTyreById } from '../../../actions/dashboard/tyres/v2/tyre_actions';
import { fetchVehicleById, fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { mappedVehiclesMini } from '../../../utils/form_utils';
import { fetchAttachedTyresToVehicle, fetchTyreAccountConfiguration } from '../../../actions/dashboard/tyres_action';
import BillNumberComponent from '../bill_number/bill_number_component';
import { isCreateIssuanceAllowedForUser, isCreateRemovalAllowedForUser, isManageTags } from '../roles/permission_utils';
import { issueRemoveBattery } from 'actions/dashboard/battery/battery_actions';
import { fetchBatteryList } from 'actions/dashboard/battery/battery_actions';
import { mappedBatteryList } from './battery_fields';
import { useNavigate } from 'react-router';
import '../vendor_management/FreightSettlement/index.scss';
const IssuanceRemovalBatteryComponent = (props) => {
    const { accesstoken, ledgers, vehicleListMini, batteryFlow } = props;
    const bookType = batteryFlow === 'ISSUANCE' ? 'BATTERY_ISSUANCE' : 'BATTERY_REMOVAL';
    const filteredLedgers = filter(ledgers, (ledger) => get(ledger, 'group.groupName') === 'Direct Expenses');
    const canIssue = batteryFlow == 'ISSUANCE' && isCreateIssuanceAllowedForUser(get(props.login, 'scope'));
    const canRemove = batteryFlow == 'REMOVAL' && isCreateRemovalAllowedForUser(get(props.login, 'scope'));
    const [initialValues, setInitialValues] = useState({
        nature: batteryFlow == 'ISSUANCE' ? 'Battery Issue' : 'Battery Remove',
        branch: null,
        stationaryId: null,
        storeId: null,
        date: null,
        ledgerId: null,
        batteryRequestList: [],
        bookType,
    });
    const [stores, setStores] = useState([]);
    const [tyres, setTyres] = useState([]);
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [tyreConfigurationList, setTyreConfigurationList] = useState([]);
    const [batteriesList, setBatteriesList] = useState([]);
    const [batteriesListLoading, setBatteriesListLoading] = useState(false);
    const navigate = useNavigate();
    const fetchBatteryData = (storeId) => {
        const params = {
            storeId: storeId,
            attachedStatus: 'NEW,IDLE',
        };
        props.fetchBatteryList(props.accesstoken, params).then((res) => {
            let batteryList = get(res, 'payload.data.content', []);
            setBatteriesList(batteryList);
        });
    };
    const mappedVehicles = useMemo(() => {
        return mappedVehiclesMini(vehicleListMini);
    }, [vehicleListMini]);
    const storeOptions = useMemo(() => {
        return mappedClientStores(stores);
    }, [stores]);
    useEffect(() => {
        props.fetchStores(accesstoken, 1000).then((res) => {
            if (!res.error) {
                setStores(get(res, 'payload.data.content'));
            }
        });
        props.fetchLedgers(accesstoken);
        if (isManageTags(get(props.login, 'scope'))) {
            props.fetchTags(props.accesstoken, 'BATTERY');
        }
        props.fetchVehicleListMini(accesstoken, true);
        if (batteryFlow === 'REMOVAL') {
            // fetchTyres(null);
        }
    }, []);
    const addEditSchema = useMemo(() => {
        let odometerReading = batteryFlow === 'ISSUANCE' ? 'attachOdometerReading' : 'detachOdometerReading';
        return Yup.object().shape({
            branch: Yup.string().required('Brand Required').nullable(),
            stationaryId: Yup.string().required('Book Name Required').nullable(),
            storeId: Yup.string().required('Store Required').nullable(),
            date: Yup.string().required(' Date Required').nullable(),
            ledgerId: Yup.string().required('Ledger Required').nullable(),
            batteryRequestList: Yup.array().of(Yup.object().shape({
                id: Yup.string().required('Tyre is Required.').nullable(),
                vehicleId: Yup.number().required('Vehicle is Required.').nullable(),
                [odometerReading]: Yup.number().required('Odometer is Required.').nullable(),
                eventTime: Yup.string().required('Date Required').nullable(),
                currentAmount: Yup.number().required('Current Amount is Required.').nullable(),
            })),
        });
    }, []);
    const onSubmit = (values, { setSubmitting }) => {
        const data = JSON.parse(JSON.stringify(values));
        if (batteryFlow === 'ISSUANCE') {
            set(data, 'nature', 'BATTERY_ISSUANCE');
            set(data, 'entityType', 'BATTERY');
        }
        else {
            set(data, 'nature', 'BATTERY_REMOVAL');
            set(data, 'entityType', 'BATTERY');
        }
        if (data.date) {
            set(data, 'date', getMomentTime(data.date).format(DATE_FORMAT_LOCAL_DATE_TIME));
        }
        forEach(data.batteryRequestList, (battery) => {
            unset(battery, 'capacity');
            unset(battery, 'cellNumber');
        });
        remove(get(data, 'batteryRequestList'), (battery) => !get(battery, 'vehicleId') || !get(battery, 'id'));
        forEach(get(data, 'batteryRequestList'), (battery, index) => {
            const { vehicleId, id, eventTime, tags } = battery;
            if (id && vehicleId) {
                if (eventTime) {
                    set(get(data, 'batteryRequestList'), `[${index}].eventTime`, getMomentTime(eventTime).format(DATE_FORMAT_TIMESTAMP));
                }
            }
            if (tags && tags.length > 0) {
                let tagsList = map(tags, (tag) => {
                    return {
                        'id': tag,
                    };
                });
                set(get(data, 'batteryRequestList'), `[${index}].tags`, tagsList);
            }
        });
        if (data.branch) {
            set(data, 'branchId', data.branch);
            unset(data, 'branch');
        }
        if (canIssue || canRemove) {
            props
                .issueRemoveBattery(accesstoken, data)
                .then((res) => {
                setSubmitting(false);
                if (!res.error) {
                    navigate('/dashboard/battery');
                }
                else {
                    throw new Error(handleError(res.payload.response));
                }
            })
                .catch((e) => {
                setSubmitting(false);
                alert(handleError(e));
            });
        }
    };
    const fetchVehicleById = async (vehicleId) => {
        let vehicleDetailsTemp = JSON.parse(JSON.stringify(get(vehicleDetails, vehicleId, {})));
        try {
            const resp = await props.fetchVehicleById(vehicleId, accesstoken);
            const vehicleDetails = get(resp, 'payload.data');
            if (vehicleDetails) {
                vehicleDetailsTemp = vehicleDetails;
                setVehicleDetails((prev) => {
                    return {
                        ...prev,
                        [vehicleId]: vehicleDetails,
                    };
                });
            }
        }
        catch (e) { }
        return vehicleDetailsTemp;
    };
    const handleVehicleSelelection = (vehicleId, setFieldValue, removeTyreId, index) => {
        if (props.batteryFlow === 'REMOVAL') {
            setBatteriesListLoading(true);
            fetchVehicleById(vehicleId).then((vehicleDetailsTemp) => {
                const batteryDetails = get(vehicleDetailsTemp, 'batteryConfigurations', []);
                setBatteriesList(batteryDetails);
                setBatteriesListLoading(false);
            });
        }
    };
    return (_jsx("div", { className: "card-group mt-4 mb-0", children: _jsx("div", { className: "card p-1", children: _jsxs("div", { className: "card-block", children: [_jsx(Helmet, { title: getPageTitle(`${props.batteryFlow === 'ISSUANCE' ? 'Issue' : 'Remove'} Battery`) }), _jsxs("h4", { children: [props.batteryFlow === 'ISSUANCE' ? 'Issue' : 'Remove', " Battery"] }), _jsx("hr", {}), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validate: (value) => {
                            try {
                                validateYupSchema(value, addEditSchema, true, value);
                            }
                            catch (err) {
                                return yupToFormErrors(err); //for rendering validation errors
                            }
                            return {};
                        }, enableReinitialize: true, children: (formikProps) => {
                            const { values, setFieldValue, errors, isSubmitting } = formikProps;
                            const { batteryRequestList } = values;
                            const isAnyBatteryAdded = !!(batteryRequestList && batteryRequestList.length);
                            return (_jsx(Form, { children: _jsxs("div", { className: "form-group", children: [_jsx(Row, { children: _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "nature", type: "text", component: FxTextFieldStackedLabel, label: "Nature", disabled: true }) }) }), _jsx(BillNumberComponent, { formikProps: formikProps, isEdit: false, isReadyToFetchBillNumber: true }), _jsxs(Row, { children: [_jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "date", timePicker: true, label: batteryFlow == 'ISSUANCE' ? 'Issue Date' : 'Removal Date', component: FxSelectSingleDate, isRequired: true, valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "storeId", options: storeOptions, component: FxSelectField, label: "Store", onSelected: (value) => {
                                                            if (batteryFlow === 'ISSUANCE') {
                                                                fetchBatteryData(value);
                                                            }
                                                        }, isRequired: true }) }), _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "ledgerId", options: mappedOptionsFromArray(filteredLedgers, 'ledgerName'), component: FxSelectField, label: "Expense A/C", isRequired: true }) })] }), _jsx(FieldArray, { name: "batteryRequestList", children: ({ push, remove }) => {
                                                return (_jsxs(_Fragment, { children: [isAnyBatteryAdded &&
                                                            map(batteryRequestList, (tyre, index) => {
                                                                const currentvehiclId = batteryRequestList?.[index]?.vehicleId;
                                                                const selectedVehicle = vehicleDetails[currentvehiclId];
                                                                const selectedVehicleBatteryDetails = get(selectedVehicle, 'batteryDetails');
                                                                const alreadySelectedTyres = [];
                                                                forEach(batteryRequestList, (item) => {
                                                                    if (item.id) {
                                                                        alreadySelectedTyres.push(item.id);
                                                                    }
                                                                });
                                                                return (_jsx("div", { className: "card", children: _jsxs("div", { className: "card-body", children: [_jsxs("div", { className: "row align-items-center", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].vehicleId`, options: mappedVehicles, onSelected: (value) => {
                                                                                                handleVehicleSelelection(value, setFieldValue, null, index);
                                                                                            }, component: FxSelectField, label: "Select Vehicle", isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].id`, options: mappedBatteryList(batteriesList), component: FxSelectField, isLoading: batteriesListLoading, onSelected: (value) => {
                                                                                                // get the battery from the list
                                                                                                let battery = find(batteriesList, (b) => b.id === value);
                                                                                                setFieldValue(`batteryRequestList[${index}].capacity`, get(battery, 'capacity'));
                                                                                                setFieldValue(`batteryRequestList[${index}].cellNumber`, get(battery, 'cellNumber'));
                                                                                            }, label: "Select Battery", isRequired: true }) }), _jsx("div", { className: "col-md-2 ml-4", children: _jsx("i", { title: "Remove", className: "fa fa-minus-circle cursor-pointer  text-primary", style: { fontSize: '22px' }, onClick: () => {
                                                                                                remove(index);
                                                                                            } }) })] }), _jsxs("div", { className: "row align-items-center", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: batteryFlow === 'ISSUANCE'
                                                                                                ? `batteryRequestList[${index}].attachOdometerReading`
                                                                                                : `batteryRequestList[${index}].detachOdometerReading`, label: batteryFlow === 'ISSUANCE'
                                                                                                ? 'Start Odometer'
                                                                                                : 'Remove Odometer', component: FxTextFieldStackedLabel, type: "number", isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].capacity`, label: 'Capacity', component: FxTextFieldStackedLabel, 
                                                                                            // isRequired={true}
                                                                                            disabled: true, type: "number" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].cellNumber`, label: 'Cell Number', component: FxTextFieldStackedLabel, disabled: true, type: "number" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].currentAmount`, label: "Current Amount", component: FxTextFieldStackedLabel, type: "number" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].eventTime`, timePicker: true, label: batteryFlow === 'ISSUANCE'
                                                                                                ? 'Attach Date'
                                                                                                : 'Detach Date', component: FxSelectSingleDate, isRequired: true, valueFormatter: (value) => getMomentTime(value).format(DATE_FORMAT_TIMESTAMP) }) }), isManageTags(get(props.login, 'scope')) && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `batteryRequestList[${index}].tags`, label: "Tags", options: mappedTags(props.tags), outerDivStyle: 'p-1', className: 'mt-2', component: FxSelectField, multi: true }) }))] })] }) }, `batteryRequestList-${index}`));
                                                            }), _jsx("div", { className: "row justify-content-center", children: _jsxs(Button, { type: "button", color: "secondary", onClick: () => {
                                                                    let odometerReading = batteryFlow === 'ISSUANCE'
                                                                        ? 'attachOdometerReading'
                                                                        : 'detachOdometerReading';
                                                                    push({
                                                                        id: null,
                                                                        vehicleId: null,
                                                                        [odometerReading]: '',
                                                                        eventTime: '',
                                                                        currentAmount: '',
                                                                    });
                                                                }, children: [batteryFlow === 'ISSUANCE' &&
                                                                        (isAnyBatteryAdded
                                                                            ? 'Add More Battery'
                                                                            : 'Add  Battery'), batteryFlow == 'REMOVAL' &&
                                                                        (isAnyBatteryAdded
                                                                            ? 'Remove More Battery'
                                                                            : 'Remove Battery')] }) })] }));
                                            } }), _jsx(Button, { disabled: isSubmitting || !(canIssue || canRemove), type: "submit", className: " float-right btn btn-primary mr-2", children: "Submit" })] }) }));
                        } })] }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStores,
        fetchLedgers,
        fetchBatteryList,
        fetchVehicleListMini,
        fetchVehicleById,
        fetchAttachedTyresToVehicle,
        tyreFilter,
        issueRemoveTyre,
        issueRemoveBattery,
        fetchTyreAccountConfiguration,
        fetchTags,
        fetchTyreById,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        ledgers: state.consignments.ledgers,
        vehicleListMini: state.vehicles.vehicleListMini,
        tags: state.tags.tags,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(IssuanceRemovalBatteryComponent);
