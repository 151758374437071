import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forEach, get, groupBy, set } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REPORT_TEMPLATE_TYPES } from 'utils/reportTemplatesUtils';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchAllTransporters } from '../../../actions/dashboard/finances/transporter_actions';
import { fetchAdvances, fetchPayments } from '../../../actions/dashboard/payment_forms/actions';
import Loader from '../../utils/loader';
import GenerateReportForSelectedTemplateModal from '../reportTemplates/GenerateReportForSelectedTemplateModal';
import PaymentListingComponent from './PaymentListingComponent';
import { fetchJobAdvanceList } from 'actions/dashboard/payment_forms/jobAdvnaceActions';
const PaymentComponent = (props) => {
    const { accesstoken, login, location, groupId, customers, loggedInUser, allBranches, transporters } = props;
    const [openFilters, setOpenFilters] = useState(false);
    const [advances, setAdvances] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isGenerateReportForTemplateModalOpen, setIsGenerateReportForTemplateModalOpen] = useState(false);
    const [metaDataForTemplateGeneration, setMetaDataForTemplateGeneration] = useState({});
    const scope = login.scope;
    const { startDate, endDate, currentPage, sizePerPage, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, } = props;
    const fetchData = async () => {
        const params = {
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
            branchId,
            paymentNature,
            adviceNumber,
            creditAccountId,
            voucherNumber,
        };
        if (paymentNature === 'JOB_ADVANCE') {
            props
                .fetchJobAdvanceList(params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                forEach(data, (d) => {
                    set(d, 'paymentNature', 'JOB_ADVANCE');
                    set(d, 'voucherNumber', get(d, 'advanceNumber'));
                    set(d, 'voucherDate', get(d, 'paymentDate'));
                    set(d, 'adviceAmount', get(d, 'advanceAmount'));
                });
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
        else if (!paymentNature) {
            const jobAdvanceParams = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature: 'JOB_ADVANCE',
                adviceNumber,
                creditAccountId,
                voucherNumber,
            };
            const paymentParams = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature: 'PAYMENT',
                adviceNumber,
                creditAccountId,
                voucherNumber,
            };
            const responses = await Promise.allSettled([
                props.fetchJobAdvanceList(jobAdvanceParams),
                props.fetchPayments(accesstoken, paymentParams),
            ]);
            setIsInitialLoad(false);
            const jobAdvanceData = get(responses[0], 'value.payload.data.content', []);
            forEach(jobAdvanceData, (d) => {
                set(d, 'paymentNature', 'JOB_ADVANCE');
                set(d, 'voucherNumber', get(d, 'advanceNumber'));
                set(d, 'voucherDate', get(d, 'paymentDate'));
                set(d, 'adviceAmount', get(d, 'advanceAmount'));
            });
            const paymentData = get(responses[1], 'value.payload.data.content', []);
            const combinedData = [...jobAdvanceData, ...paymentData];
            setAdvances(combinedData);
        }
        else {
            props
                .fetchPayments(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
    };
    useEffect(() => {
        fetchData();
    }, [startDate.valueOf(), endDate.valueOf(), branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber]);
    const onOpenReportForTemplate = (row) => {
        setMetaDataForTemplateGeneration({
            id: row.id,
            formatType: REPORT_TEMPLATE_TYPES.ERP_MONEY_RECEIPT,
        });
        setIsGenerateReportForTemplateModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: isInitialLoad ? (_jsx(Loader, {})) : (_jsx(PaymentListingComponent, { onPageChange: props.onPageChange, currentPage: currentPage, sizePerPage: sizePerPage, data: advances, groupedBranches: groupBy(allBranches, 'id'), groupedCreditAccounts: groupBy(customers, 'id'), groupedTransporters: groupBy(transporters, 'id'), onOpenReportForTemplate: onOpenReportForTemplate, fetchData: fetchData })) }), isGenerateReportForTemplateModalOpen && (_jsx(GenerateReportForSelectedTemplateModal, { isOpen: isGenerateReportForTemplateModalOpen, onClose: () => {
                    setIsGenerateReportForTemplateModalOpen((v) => !v);
                }, formatType: metaDataForTemplateGeneration.formatType, itemId: metaDataForTemplateGeneration.id, onSuccess: () => {
                    setIsGenerateReportForTemplateModalOpen((v) => !v);
                } }))] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAdvances, fetchPayments, fetchBranches, fetchAllTransporters, fetchJobAdvanceList }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
        groupId: state.local.defaultGroupId,
        transporters: state.transporters.transporterList,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);
