import {
    DEVICE_CATEGORY,
    DOCUMENT_TYPE,
    TRANSACTION_STATUS_TYPES,
    USER_MATCH_FLAGS,
    DEVICE_STATUS,
    DEVICE_TYPE,
} from '../../constant';
// import { DEVICE_TYPE } from 'fx-utils';

import { map, filter, get, find, includes, isEmpty, forEach, pickBy, toLower, uniqBy } from 'lodash';
import {
    ACCOUNT_SUPERVISOR_TYPES,
    ACCOUNT_SUPERVISOR_TYPES_LABEL,
    showVehicleNumber,
    getSimPingMinThresholdForAccount,
    getSimPingMaxThresholdForAccount,
    PO_AMOUNT_TYPES,
    PO_AMOUNT_FIELDS,
    PO_AMOUNT_TYPES_LABEL,
    PO_AMOUNT_FIELDS_LABEL,
    getDummyVehicleTagId,
    getSystemTagIdForFiltering,
    showSmsForAccount,
    isShowSmsButtonForGeofence,
} from '../../utils/account_utils';
import { JOB_TIMING_STATE_VALUES, RISK_LEVEL, RISK_LEVEL_DEF } from '../../utils/job_route_utils';
import { PING_FREQUENCY } from '../../utils/device_utils';
import {
    ISSUES_ENTITY_TYPES,
    ISSUES_ENTITY_TYPES_LABEL,
    ISSUES_PRIORITY,
    ISSUES_PRIORITY_LABEL,
    ISSUES_STATUS,
    ISSUES_STATUS_LABEL,
    ISSUES_TYPES,
    ISSUES_TYPES_LABEL,
    ISSUES_CATEGORY,
    ISSUES_CATEGORY_LABEL,
    getIssuesSubType,
} from '../../utils/issues_utils';
import { ISO_LANGUAGE_CODES, ISO_LANGUAGE_LABEL } from '../../utils/internationalization_utils';
import {
    CLIENT_ISSUES_TYPES,
    CLIENT_ISSUES_TYPES_LABEL,
    FLEETX_ISSUES_TYPES,
    FLEETX_ISSUES_TYPES_LABEL,
    INTERNAL_ISSUE_CATEGORY,
    INTERNAL_ISSUES_DOCUMENT_TAGS,
    INTERNAL_ISSUES_DOCUMENT_TAGS_LABEL,
} from '../../utils/internal_tools/issues_utils';
import { getAllWordsCapitalized } from '../../utils/string_utils';
import { TRANSACTION_ORDERS } from '../../utils/part_inventory_utils';
import { MATERIAL_QUANTITY_UNIT, MATERIAL_QUANTITY_UNIT_LABELS } from '../../utils/vendor_management/auction_utils';
import { CHALAN_TYPES } from '../../utils/challanUtils';
import { VOUCHER_TYPES } from '../../utils/advance_form_utils';
import { FxFlex } from '../../shared';

export function mappedDeviceCategory(device_category = DEVICE_CATEGORY) {
    return map(device_category, (value) => {
        return {
            value: value.id,
            label: `${value.name}`,
        };
    });
}

export function mappedTransactionStatus() {
    return map(TRANSACTION_STATUS_TYPES, (value, key) => {
        return {
            value: key,
            label: value,
        };
    });
}

export function mappedTechnicianDutyReasons(dutyReasons) {
    return map(dutyReasons, (value) => {
        return {
            value: value,
            label: value.replace(/_/g, ' '),
        };
    });
}

export function mappedTechnicianDutyTrackingReasons(dutyTrackingReasons) {
    return map(dutyTrackingReasons, (reason) => {
        return {
            value: reason.code,
            label: reason.value,
        };
    });
}

export function mappedUserMatchFlags() {
    return map(USER_MATCH_FLAGS, (value, key) => {
        return {
            value: key,
            label: value,
        };
    });
}

export function mappedVehiclesSizesList(vehicleSizes) {
    return map(vehicleSizes, (vs) => {
        return {
            value: vs.id,
            label: vs.name,
        };
    });
}

export function mappedBudgetList(budgetList, vehicleSize) {
    const budgetListFinal = map(
        filter(budgetList, (b) => {
            return get(b, 'vehicleSize.id', b.vehicleSizeId) === get(vehicleSize, 'id', null);
        }),
        (b2) => {
            return {
                value: b2.id,
                label: `${get(b2.vehicleSize, 'name', '')} -Tonnage:${b2.vehicleLoadCapacity} - EstimatedTotal:${
                    b2.estimatedTotal
                }`,
            };
        }
    );

    if (budgetListFinal.length) {
        return budgetListFinal;
    } else {
        return map(budgetList, (b2) => {
            return {
                value: b2.id,
                label: `${get(b2.vehicleSize, 'name', '')} -Tonnage:${b2.vehicleLoadCapacity} - EstimatedTotal:${
                    b2.estimatedTotal
                }`,
            };
        });
    }
}

export function mappedClientStores(stores) {
    return map(stores, (store) => {
        return {
            value: store.id,
            label: store.name,
            address: store.address,
            ledgerId: store.ledgerId,
        };
    });
}

export function mappedClientStoresFromEntries(entries) {
    return map(entries, (entry) => {
        return {
            value: entry.store.id,
            label: entry.store.name,
            quantity: entry.quantity,
        };
    });
}

export function mappedVehicleListMiniOptions(
    vehicleListMini,
    loggedInUser,
    isValueInt = false,
    assetType,
    extraParams = {}
) {
    const { showVehicleName = true, showDisabled = false } = extraParams;

    const systemTags = getSystemTagIdForFiltering();
    let vehicles = [];
    if (assetType == 'CAMERA') {
        vehicleListMini = vehicleListMini.filter((v) => {
            return (
                includes(get(v, 'assetSuppliers'), 'HOWEN') ||
                includes(get(v, 'assetSuppliers'), 'JIMI_JC400') ||
                includes(get(v, 'assetSuppliers'), 'JIMI_JC400P') ||
                includes(get(v, 'assetSuppliers'), 'LOTIM') ||
                includes(get(v, 'assetSuppliers'), 'TRACKSOLID')
            );
        });
    }

    map(vehicleListMini, (o) => {
        let skipTaggedVehicle = false;
        const status = o?.vehicleStatusName;
        if (!isEmpty(systemTags)) {
            map(systemTags, (tag) => {
                if (includes(o.tagIdsSet, tag)) {
                    skipTaggedVehicle = true;
                }
            });
        }

        let isDisabled = showDisabled && status && status.toLowerCase() !== 'active';

        if (!skipTaggedVehicle) {
            vehicles.push({
                value: isValueInt ? o.id : `${o.id}`,
                label: showVehicleName
                    ? `${o.name} ${showVehicleNumber(loggedInUser) ? `(${o['licensePlate']})` : ''}`
                    : `${showVehicleNumber(loggedInUser) ? `(${o['licensePlate']})` : ''}`,
                vehicleNumber: o['licensePlate'],
                vehicleName: `${o.name}`,
                disabled: isDisabled,
                vehicleStatus: status,
            });
        }
    });
    return vehicles;
}
export const vehicleDocumentTypesValueToLabelMap = {
    INSURANCE: 'Insurance',
    FITNESS: 'Fitness',
    ROADTAX: 'Road Tax',
    PERMIT: 'National Permit',
    STATE_PERMIT: 'State Permit',
    FIVE_YEAR_PERMIT: 'Five Year Permit',
    OTHERS: 'Others',
    CROSS_BORDER_PERMIT: 'Cross Border Permit',
    ZAMBIAN_INSURANCE: 'Zambian Insurance',
    IDENTITY: 'Identity',
    COMESA: 'Comesa',
    MOZAMBIQUE_INSURANCE: 'Mozambique Insurance',
    BEIRA_PORT_ENTRY: 'Beira Port Entry',
    ZIMBABWE_CVG: 'Zimbabwe CVG',
    TANZANIA_C29: 'Tanazania C29',
    MV_TAX: 'MV Tax',
    EXPLOSIVE_LICENSE: 'Explosive License',
    VEHICLE_AMC: 'Vehicle AMC',
    REGISTRATION_CERTIFICATE: 'Registration Certificate',
    PURE_OTHERS: 'Others',
};
export function mappedVehicleDocumentTypes() {
    return [
        {
            value: 'INSURANCE',
            label: 'Insurance',
        },
        {
            value: 'FITNESS',
            label: 'Fitness',
        },
        {
            value: 'ROADTAX',
            label: 'Road Tax',
        },
        {
            value: 'PERMIT',
            label: 'National Permit',
        },
        {
            value: 'STATE_PERMIT',
            label: 'State Permit',
        },
        {
            value: 'FIVE_YEAR_PERMIT',
            label: 'Five Year Permit',
        },
        {
            value: 'OTHERS',
            label: 'PUC / Other Goods',
        },
        {
            value: 'MV_TAX',
            label: 'MV Tax',
        },
        {
            value: 'EXPLOSIVE_LICENSE',
            label: 'Explosive License',
        },
        {
            value: 'VEHICLE_AMC',
            label: 'Vehicle AMC',
        },
        {
            value: 'REGISTRATION_CERTIFICATE',
            label: 'Registration Certificate',
        },
        {
            value: 'PURE_OTHERS',
            label: 'Others',
        },
    ];
}

export function mappedAdditionalVehicleDocumentTypes() {
    return [
        {
            value: 'FITNESS',
            label: 'Fitness',
        },
        {
            value: 'ROADTAX',
            label: 'Road Tax',
        },
        {
            value: 'PERMIT',
            label: 'National Permit',
        },
        {
            value: 'STATE_PERMIT',
            label: 'State Permit',
        },
        {
            value: 'FIVE_YEAR_PERMIT',
            label: 'Five Year Permit',
        },
        {
            value: 'OTHERS',
            label: 'PUC / Other Goods',
        },
        {
            value: 'CROSS_BORDER_PERMIT',
            label: 'Cross Border Permit',
        },
        {
            value: 'ZAMBIAN_INSURANCE',
            label: 'Zambian Insurance',
        },
        {
            value: 'IDENTITY',
            label: 'Identity',
        },
        {
            value: 'COMESA',
            label: 'Comesa',
        },
        {
            value: 'MOZAMBIQUE_INSURANCE',
            label: 'Mozambique Insurance',
        },
        {
            value: 'BEIRA_PORT_ENTRY',
            label: 'Beira Port Entry',
        },
        {
            value: 'ZIMBABWE_CVG',
            label: 'Zimbabwe CVG',
        },
        {
            value: 'TANZANIA_C29',
            label: 'Tanazania C29',
        },
        {
            value: 'MV_TAX',
            label: 'MV Tax',
        },
        {
            value: 'EXPLOSIVE_LICENSE',
            label: 'Explosive License',
        },
        {
            value: 'VEHICLE_AMC',
            label: 'Vehicle AMC',
        },
        {
            value: 'REGISTRATION_CERTIFICATE',
            label: 'Registration Certificate',
        },
        {
            value: 'PURE_OTHERS',
            label: 'Others',
        },
    ];
}

export function mappedVehicleListMiniOptionsForCustomReport(vehicleListMini, loggedInUser, isValueInt = false) {
    const systemTags = getSystemTagIdForFiltering();
    let vehicles = [];
    map(vehicleListMini, (o) => {
        let skipTaggedVehicle = false;
        if (!isEmpty(systemTags)) {
            map(systemTags, (tag) => {
                if (includes(o.tagIdsSet, tag)) {
                    skipTaggedVehicle = true;
                }
            });
        }
        if (!skipTaggedVehicle) {
            vehicles.push({
                value: o['licensePlate'],
                label: `${o.name} ${showVehicleNumber(loggedInUser) ? `(${o['licensePlate']})` : ''}`,
                vehicleNumber: o['licensePlate'],
                vehicleName: o.name,
            });
        }
    });
    return vehicles;
}

export function mappedGeoFenceList(geoFenceList, loggedInUser) {
    const geofenceFilterList = [];
    map(geoFenceList, (geoFence) => {
        if (geoFence.name)
            geofenceFilterList.push({
                label: geoFence.name,
                value: geoFence.name,
            });
    });
    return geofenceFilterList;
}

export function mappedRiskLevels(riskLevels = RISK_LEVEL) {
    return map(riskLevels, (value) => {
        return {
            value: value,
            label: `${RISK_LEVEL_DEF[value]}`,
        };
    });
}

export function mappedLocks(locks) {
    return map(locks, (lock) => {
        const licensePlate = get(lock, 'vehicle.licensePlate', null);
        const vehicleName = get(lock, 'vehicle.name', null);
        const vehicle = licensePlate && vehicleName ? `(${vehicleName}-${licensePlate})` : '';
        const batteryPercent = get(lock, 'attributes.battery', '') ? `${get(lock, 'attributes.battery', '')}%` : '';
        return {
            ...lock,
            value: lock.assetId,
            label: `${get(lock, 'name', '')} ${get(lock, 'serialNumber', '')} ${batteryPercent} ${vehicle}`,
        };
    });
}

export function cameraLocks(cameras) {
    return map(cameras, (camera) => {
        const licensePlate = get(camera, 'vehicle.licensePlate', null);
        const vehicleName = get(camera, 'vehicle.name', null);
        // const vehicle = licensePlate && vehicleName ? `(${vehicleName}-${licensePlate})` : '';
        return {
            ...camera,
            value: camera.assetId,
            label: camera.assetId,
        };
    });
}

export function mappedDummyLocks(locks) {
    const dummyTagId = getDummyVehicleTagId();
    let filteredLocks = [];
    map(locks, (lock) => {
        const licensePlate = get(lock, 'vehicle.licensePlate', null);
        const vehicleName = get(lock, 'vehicle.name', null);
        const vehicle = licensePlate && vehicleName ? `(${vehicleName}-${licensePlate})` : '';
        const attachedTags = get(lock, 'vehicle.tags', []);
        const batteryPercent = get(lock, 'attributes.battery', '') ? `${get(lock, 'attributes.battery', '')}%` : '';
        if (dummyTagId && !isEmpty(attachedTags)) {
            let foundDummyTag = false;
            map(attachedTags, (tag) => {
                if (tag.id === dummyTagId) {
                    foundDummyTag = true;
                }
            });
            if (foundDummyTag) {
                filteredLocks.push({
                    ...lock,
                    value: lock.assetId,
                    label: `${get(lock, 'name', '')} ${get(lock, 'serialNumber', '')} ${batteryPercent} ${vehicle}`,
                });
            }
        } else if (!vehicle) {
            filteredLocks.push({
                ...lock,
                value: lock.assetId,
                label: `${get(lock, 'name', '')} ${get(lock, 'serialNumber', '')} ${batteryPercent} ${vehicle}`,
            });
        }
    });
    return filteredLocks;
}

export function mappedAccountSupervisors(list = ACCOUNT_SUPERVISOR_TYPES, labels = ACCOUNT_SUPERVISOR_TYPES_LABEL) {
    return map(list, (value) => {
        return {
            value: value,
            label: labels[value],
        };
    });
}

export function mappedPingFrequencies(loggedInUser, simProvider = null) {
    if (get(loggedInUser, 'role.id') === 14629) return PING_FREQUENCY;
    const pingMinThreshold = getSimPingMinThresholdForAccount(loggedInUser, simProvider);
    const pingMaxThreshold = getSimPingMaxThresholdForAccount(loggedInUser, simProvider);
    return filter(PING_FREQUENCY, (option) => {
        return option.value >= pingMinThreshold && option.value <= pingMaxThreshold;
    });
}

export function mappedDeviceTypesStatic() {
    return map(DEVICE_TYPE, (value, key) => {
        return {
            value: key,
            label: key,
        };
    });
}

export function mappedIssuesStatus() {
    return map(ISSUES_STATUS, (key) => {
        return {
            value: key,
            label: ISSUES_STATUS_LABEL[key],
        };
    });
}

export function mappedIssuesTypes() {
    return map(ISSUES_TYPES, (key) => {
        return {
            value: key,
            label: ISSUES_TYPES_LABEL[key],
        };
    });
}

export function mappedIssuesPriorities() {
    return map(ISSUES_PRIORITY, (key) => {
        return {
            value: key,
            label: ISSUES_PRIORITY_LABEL[key],
        };
    });
}

export function mappedIssuesCategories() {
    return map(ISSUES_CATEGORY, (key) => {
        return {
            value: key,
            label: ISSUES_CATEGORY_LABEL[key],
        };
    });
}

export function mappedIssueEntitiesTypes() {
    return map(ISSUES_ENTITY_TYPES, (key) => {
        return {
            value: key,
            label: ISSUES_ENTITY_TYPES_LABEL[key],
        };
    });
}

export function mappedIssuesOptions(issuesList) {
    return map(issuesList, (item) => {
        return {
            value: item.id,
            label: `id:${item.id} - ${item.summary}`,
            description: item.description,
        };
    });
}

export function jobRunningStatusOptions() {
    return map(JOB_TIMING_STATE_VALUES, (item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });
}

export function mappedIssuesSubTypes() {
    return map(getIssuesSubType().ALARM, (key, index) => {
        return {
            value: index,
            label: key,
        };
    });
}

export function mappedDocumentsDownloadCategory(categories) {
    return map(categories, (value, key) => {
        return {
            value: value,
            label: value,
        };
    });
}

export function mappedISOLanguagesCodes() {
    return map(ISO_LANGUAGE_CODES, (value) => {
        return {
            value: value,
            label: ISO_LANGUAGE_LABEL[value],
        };
    });
}

export function mappedPOAmountTypes() {
    return map(PO_AMOUNT_TYPES, (type) => {
        return {
            label: PO_AMOUNT_TYPES_LABEL[type],
            value: type,
        };
    });
}

export function mappedPOAmountFields(amountType) {
    let returnMappedPOAmountFields;
    const poAmountFieldsLabel = map(PO_AMOUNT_FIELDS, (field) => {
        return {
            label: PO_AMOUNT_FIELDS_LABEL[field],
            value: field,
        };
    });

    let removeFieldsLabel = ['perPingAmount', 'dailyAmount'];
    let fields;

    switch (PO_AMOUNT_TYPES_LABEL[amountType]) {
        case PO_AMOUNT_TYPES_LABEL.OBD:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.NON_OBD:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.API:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.PORT:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.AIS:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.TEMP_DEVICE:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.SIM_TRACKING:
            removeFieldsLabel = ['firstYearAmount', 'secondYearAmount'];
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.APP_TRACKING:
            removeFieldsLabel = ['perPingAmount'];
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        case PO_AMOUNT_TYPES_LABEL.PORTABLE_DEVICE:
            fields = filter(poAmountFieldsLabel, (value) => {
                return !includes(removeFieldsLabel, value.value);
            });
            returnMappedPOAmountFields = fields;
            break;
        default:
            returnMappedPOAmountFields = poAmountFieldsLabel;
    }

    return returnMappedPOAmountFields;
}

export function mappedCommodities(commodities, selected, allSelectedCommodityAtRouteLevel) {
    let filterMappedCommodity = [];
    forEach(commodities, (commodity) => {
        if (
            get(selected, 'commodityId') === commodity.id ||
            !find(allSelectedCommodityAtRouteLevel, { commodityId: commodity.id })
        ) {
            filterMappedCommodity.push({
                value: commodity.id,
                label: commodity.name,
            });
        }
    });
    return filterMappedCommodity;
}

export function mappedConsignmentMaterial(commodities, selected, allSelectedCommodityAtRouteLevel) {
    let filterMappedCommodity = [];
    forEach(commodities, (commodity) => {
        if (get(selected, 'consignmentMaterial.materialId') === commodity.id) {
            filterMappedCommodity.push({
                value: commodity.id,
                label: commodity.materialName,
            });
        }
    });
    return filterMappedCommodity;
}

export function mappedMaterial(commodities, selected, allSelectedCommodityAtRouteLevel) {
    let filterMappedCommodity = [];
    forEach(commodities, (commodity) => {
        if (get(selected, 'materialId') === commodity.id) {
            filterMappedCommodity.push({
                value: commodity.id,
                label: commodity.materialName,
            });
        }
    });
    return filterMappedCommodity;
}

export function mappedInternalIssuesTypes(category) {
    const issueTypes = category === INTERNAL_ISSUE_CATEGORY.FLEETX ? FLEETX_ISSUES_TYPES : CLIENT_ISSUES_TYPES;
    const issuesLabel =
        category === INTERNAL_ISSUE_CATEGORY.FLEETX ? FLEETX_ISSUES_TYPES_LABEL : CLIENT_ISSUES_TYPES_LABEL;
    return map(issueTypes, (key) => {
        return {
            value: key,
            label: issuesLabel[key],
        };
    });
}

export function mappedInternalIssuesDocumentTags() {
    return map(INTERNAL_ISSUES_DOCUMENT_TAGS, (key) => {
        return {
            value: key,
            label: INTERNAL_ISSUES_DOCUMENT_TAGS_LABEL[key],
        };
    });
}

export function mappedTeamNames() {
    return [
        {
            value: 'SALES_TEAM',
            label: 'Sales Team',
        },
        {
            value: 'CUSTOMER_SUCCESS',
            label: 'Customer Success Team',
        },
    ];
}

export function mappedTeamNamesNew() {
    return [
        {
            value: 'CS_SUPPORT_OPS',
            label: 'CS_SUPPORT_OPS',
        },
        {
            value: 'SALES_OPERATIONS',
            label: 'SALES_OPERATIONS',
        },
        {
            value: 'SALES',
            label: 'SALES',
        },
        {
            value: 'OPERATIONS',
            label: 'OPERATIONS',
        },
        {
            value: 'KAM',
            label: 'KAM',
        },
        {
            value: 'IOT',
            label: 'IOT',
        },
        {
            value: 'CS_IMPLEMENTATION',
            label: 'CS_IMPLEMENTATION',
        },
        {
            value: 'ENTERPRISE_OPS',
            label: 'ENTERPRISE_OPS',
        },
        {
            value: 'CS_OPS',
            label: 'CS_OPS',
        },
        {
            value: 'CUSTOMER_SUCCESS',
            label: 'CUSTOMER_SUCCESS',
        },
    ];
}

export function mappedInternalTags(list) {
    return map(list, (tag) => {
        return {
            value: tag.id,
            label: getAllWordsCapitalized(tag.jointName),
        };
    });
}

export function mappedPartCategories(categories) {
    return map(categories, (item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });
}

export const showSmsAlertFor = [
    'Emergency',
    'Crash',
    'SMS Location',
    'Misuse',
    'EWayBillExpiry',
    'FuelLevel',
    'Lock Tamper',
    'Lock Critical Battery',
    'Immobilizer OTP',
    'FuelLevel2',
    'FuelLevel3',
    'FuelLevel4',
    'Fuel Theft',
    'Fuel Fill',
    'Unlock Outside Geofence',
    'Portable Device Low Battery',
    'Unlocked Movement Detected',
    'Wheel Jam',
];

export function isShowSmsAlert(alerts, ptn) {
    const smsValue = get(ptn, 'sms');
    if (smsValue) {
        return true;
    }
    const alertName = get(alerts, 'name');
    if (toLower(alertName) === 'geofence') {
        return isShowSmsButtonForGeofence();
    }
    if (get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) >= 2000) {
        if (includes(showSmsAlertFor, alertName)) {
            return true;
        } else return false;
    } else return showSmsForAccount();
}

export function mappedPartModels(list) {
    return map(list, (item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });
}

export function mappedPartMakers(list) {
    return map(list, (item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });
}

export function mappedPartMeasurementOptions(list) {
    return map(list, (item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });
}

export function mappedParts(list, showDescription = false) {
    return map(list, (item) => {
        return {
            value: item.id,
            label:
                item.name +
                ' - Code No: ' +
                item.codeNumber +
                ` - Category: ${item.partCategory?.name} ${
                    showDescription && item.description ? `| ${item.description}` : ''
                }`,
        };
    });
}

export function mappedPartEntries(list, partList) {
    return map(list, (item) => {
        return {
            value: item.id,
            label: get(find(partList, { id: item.partId }), 'name') + ' - Bill No: ' + item.billNumber,
        };
    });
}

export function mappedDocumentType(types = DOCUMENT_TYPE) {
    return map(types, (value) => {
        return {
            value: value,
            label: value,
        };
    });
}

export function mappedTagType(types) {
    return map(types, (value) => {
        return {
            value: value,
            label: value,
        };
    });
}

export function mappedReasons(reasons) {
    return map(reasons, (reason) => {
        return {
            value: reason.name,
            label: reason.name,
        };
    });
}

export function mappedPartInventoryTransactionOrders(list = TRANSACTION_ORDERS) {
    return map(list, (key) => {
        return {
            value: key,
            label: key,
        };
    });
}

export function mappedObjectToOptions(obj, labelObj) {
    return map(obj, (value) => {
        return {
            value: value,
            label: get(labelObj, value, value),
        };
    });
}

export function mappedBillType(obj) {
    return map(obj, (value) => {
        return {
            value: value.id,
            label: get(value, 'name', '-'),
        };
    });
}

export function mappedAuctionQuantityUnits() {
    return map(MATERIAL_QUANTITY_UNIT, (value) => {
        return {
            value: value,
            label: MATERIAL_QUANTITY_UNIT_LABELS[value],
        };
    });
}

export function mappedRfTags(arr) {
    return map(arr, (value) => {
        return {
            value: value.id,
            label: value.name,
        };
    });
}

export function mappedBranches(arr) {
    return map(arr, (value) => {
        return {
            value: value.id,
            label: value.officeName,
        };
    });
}

export function mappedStationaries(arr) {
    return map(
        filter(arr, (elem, index) => elem.bookName),
        (value) => {
            return {
                value: value.id,
                label: value.bookName,
            };
        }
    );
}

export function mappedStationariesWithBookType(stationaries) {
    return stationaries.map((stationary) => ({
        label: `${stationary.bookName} (${stationary.bookType})`, // Concatenate bookName and bookType
        value: stationary.id,
    }));
}

export function getMappedTyreInwardNature() {
    return [
        {
            value: 'TYRE_MRN',
            label: 'Tyre MRN ',
        },
        {
            value: 'CHASSIS_MRN',
            label: 'Chassis MRN',
        },
        {
            value: 'REMOULD_TYRE_MRN',
            label: 'Remould Tyre MRN',
        },
        {
            value: 'TYRE_AGAINST_CLAIM_MRN',
            label: 'Tyre Against Claim MRN',
        },
        {
            value: 'TYRE_STOCK_IN',
            label: 'Tyre Stock In/Store In',
        },
        {
            value: 'REPAIR_TYRE_MRN',
            label: 'Repair Tyre MRN',
        },
    ];
}

export function getMappedTyreOutwardNature() {
    return [
        {
            value: 'SENT_TO_REMOULD',
            label: 'Send to remould ',
        },
        {
            value: 'SENT_TO_CLAIM',
            label: 'Send to claim',
        },
        {
            value: 'TYRE_THEFT',
            label: 'Tyre Theft',
        },
        {
            value: 'TYRE_SCRAPPING',
            label: 'Tyre Scrapping',
        },
        {
            value: 'TYRE_STOCK_OUT',
            label: 'Tyre Stock Out',
        },
        {
            value: 'SENT_TO_REPAIR',
            label: 'Sent To Repair',
        },
    ];
}

export function getMappedBatteryInwardNature() {
    return [
        {
            value: 'BATTERY_MRN',
            label: 'Battery MRN ',
        },
        {
            value: 'CHASSIS_MRN',
            label: 'Chassis MRN ',
        },
        {
            value: 'REFURBISHED_BATTERY',
            label: 'Refurbished Battery',
        },
        {
            value: 'BATTERY_AGAINST_CLAIM',
            label: 'Battery Against Claim ',
        },
        {
            value: 'BATTERY_STOCK_IN',
            label: 'Battery Stock In',
        },
        {
            value: 'REFURBISHED_REJECTION',
            label: 'Refurbished Rejection',
        },
        {
            value: 'CLAIM_REJECTION',
            label: 'Claim Rejection',
        },
    ];
}

export function getMappedBatteryOutwardNature() {
    return [
        {
            value: 'SENT_TO_REMOULD',
            label: 'Sent to Refurbished',
        },
        {
            value: 'SENT_TO_CLAIM',
            label: 'Send to Claim',
        },
        {
            value: 'BATTERY_THEFT',
            label: 'Battery Theft',
        },
        {
            value: 'BATTERY_SCRAPPING',
            label: 'Battery Scrapping',
        },
        {
            value: 'BATTERY_STOCK_OUT',
            label: 'Battery Stock Out',
        },
    ];
}

export function getMappedBookTypesTransporter() {
    return [
        {
            value: 'FREIGHT_SETTLEMENT',
            label: 'Freight Settlement',
        },
    ];
}

export function getMappedBookTypes() {
    return [
        {
            value: 'JOB_NUMBER',
            label: 'Job Number',
        },
        {
            value: 'JOB_BOOKING',
            label: 'Job Booking',
        },
        {
            value: 'INVOICE',
            label: 'Invoice',
        },
        {
            value: 'PAYMENT_RECEIPTS',
            label: 'Payment Receipts',
        },
        {
            value: 'TRANSACTIONS',
            label: 'Transactions',
        },
        {
            value: 'TRIP_SHEET',
            label: 'Trip Sheet',
        },
        {
            value: 'TYRE_ENTRY',
            label: 'Tyre Entry',
        },
        {
            value: 'SERVICE_ENTRY',
            label: 'Service Entry',
        },
        {
            value: 'ADD_PERSON',
            label: 'Add Person',
        },
        {
            value: 'WORK_ORDER',
            label: 'Work Order',
        },
        {
            value: 'RATE_CHART',
            label: 'Rate Chart',
        },
        {
            value: 'CONSIGNMENT_FORM',
            label: 'Consignment Form',
        },
        {
            value: 'CONSIGNMENT_BILL_DETAILS',
            label: 'Consignment Bill Details',
        },
        {
            value: 'CONSIGNMENT_MR_DETAILS',
            label: 'Consignment MR Details',
        },
        // {
        //     value: 'FREIGHT_INVOICE',
        //     label: 'Freight Form',
        // },
        {
            value: 'CONSIGNMENT_DRIVER_ADVANCE',
            label: 'Consignment Driver Advance',
        },
        {
            value: 'CHALLAN_FORM',
            label: 'Challan Form',
        },
        {
            value: 'ARRIVAL_FORM',
            label: 'Arrival Form',
        },
        {
            value: 'GATE_PASS',
            label: 'Gate Pass',
        },
        {
            value: 'SUPPLEMENTARY_BILL',
            label: 'Supplementary Bill',
        },
        {
            value: 'NON_LOGISTICS_SUPPLEMENTARY_BILL',
            label: 'Supplementary Non Logistic Bill',
        },
        {
            value: 'HIRE_SLIP',
            label: 'Hire Slip',
        },
        {
            value: 'VOUCHER',
            label: 'Voucher',
        },
        {
            value: 'TYRE_ISSUANCE',
            label: 'Tyre Issuance',
        },
        {
            value: 'TYRE_REMOVAL',
            label: 'Tyre Removal',
        },
        {
            value: 'BATTERY_ISSUANCE',
            label: 'Battery Issuance',
        },
        {
            value: 'BATTERY_REMOVAL',
            label: 'Battery Removal',
        },
        {
            value: 'EPOD',
            label: 'EPOD',
        },
        {
            value: 'TYRE_BILL',
            label: 'Tyre Bill',
        },
        {
            value: 'PART_BILL',
            label: 'Part Bill',
        },
        {
            value: 'BATTERY_BILL',
            label: 'Battery Bill',
        },
        {
            value: 'VEHICLE_DOCUMENTATION',
            label: 'Vehicle Documentation',
        },
        {
            value: 'BATTERY_ENTRY',
            label: 'Battery Entry',
        },
        {
            value: 'CONTRACT_CHART',
            label: 'Client Contract Chart',
        },
        {
            value: 'FUEL_ENTRY',
            label: 'Fuel Entry',
        },
        {
            value: 'PART_ENTRY',
            label: 'Part Entry',
        },
        {
            value: 'PROCUREMENT_INDENT',
            label: 'Procurement Indent',
        },
        {
            value: 'PROCUREMENT_CHART',
            label: 'Procurement Chart',
        },
        {
            value: 'SALARY_PAYMENT',
            label: 'Salary Payment',
        },
        {
            label: 'Part Issuance Vehicle',
            value: 'PART_ISSUANCE_VEHICLE',
        },
        {
            value: 'OPERATION_BILL',
            label: 'Operation Bill',
        },
        {
            value: 'PURCHASE_ORDER',
            label: 'Purchase Order',
        },
        {
            value: 'BALANCE_PAYMENT',
            label: 'Balance Payment',
        },
        {
            value: 'ON_ACCOUNT_PAYMENT',
            label: 'On Account Payment',
        },
        {
            value: 'ON_ACCOUNT_RECEIPT',
            label: 'On Account Receipt',
        },
        {
            value: 'JOB_BILL_RECEIPT',
            label: 'Job Bill Receipt',
        },
        {
            value: 'VEHICLE_EMI',
            label: 'VEHICLE_EMI',
        },
        {
            value: 'CREDIT_NOTE',
            label: 'CREDIT NOTE',
        },
        {
            value: 'DEBIT_NOTE',
            label: 'DEBIT NOTE',
        },
        {
            value: 'JOB_ADVANCE',
            label: 'Job Advance',
        },
    ];
}

export function getBillingEntities() {
    return [
        {
            value: 'TYRES',
            label: 'Tyres',
        },
        {
            value: 'PARTS',
            label: 'Parts',
        },
        {
            value: 'BATTERIES',
            label: 'Battery',
        },
    ];
}

export const vehicleOptionRenderer = (option) => {
    const customFieldsMappings = get(option, 'customFieldsMappings', []);
    const kmRunLabel = get(customFieldsMappings, '0.field.keyName', '');
    const kmRunValue = get(customFieldsMappings, '0.value', '');

    const driverAtttendanceLabel = get(customFieldsMappings, '1.field.keyName', '');
    const driverAtttendanceValue = get(customFieldsMappings, '1.value', '');

    const workingHoursLabel = get(customFieldsMappings, '2.field.keyName', '');
    const workingHoursValue = get(customFieldsMappings, '2.value', '');

    return (
        <FxFlex direction="row" id={option.value} alignItems="center">
            <FxFlex direction="column">
                <div className="font-semi-bold">{option.label}</div>
                <div
                    className="font-light"
                    style={{
                        fontSize: '0.8rem',
                        color: '#A2A4AC',
                    }}
                >
                    {`${kmRunLabel ? kmRunLabel + ': ' + kmRunValue + ',' : ''} `}
                    {`${driverAtttendanceLabel ? driverAtttendanceLabel + ': ' + driverAtttendanceValue + ',' : ''} `}
                    {`${workingHoursLabel ? workingHoursLabel + ': ' + workingHoursValue : ''} `}
                </div>
            </FxFlex>
        </FxFlex>
    );
};

export const vehicleOptionRendererLessDetails = (option) => {
    return (
        <FxFlex direction="row" id={option.value} alignItems="center">
            <FxFlex direction="column">
                <div className="font-semi-bold">{option.label}</div>
            </FxFlex>
        </FxFlex>
    );
};

export function mappedOptionsFromArray(
    arr,
    labelKey = 'name',
    valueKey = 'id',
    fallbackLabelKey = '',
    fallbackValueKey = ''
) {
    if (arr && typeof arr[0] !== 'object') {
        return map(arr, (v) => {
            return {
                value: v,
                label: v,
            };
        });
    }

    return map(arr, (item) => {
        return {
            value: get(item, valueKey),
            label: get(item, labelKey, get(item, fallbackLabelKey, fallbackLabelKey)),
        };
    });
}

export function mappedOptionsFromArrayLedgersGroupHasTax(
    arr,
    labelKey = 'name',
    valueKey = 'id',
    fallbackLabelKey = '',
    fallbackValueKey = ''
) {
    if (!arr || arr.length === 0) return [];

    // If array contains non-object elements
    if (typeof arr[0] !== 'object') {
        // Remove duplicates in array
        const uniqueArr = [...new Set(arr)];
        // Map and filter to include only those with 'Tax' in the label
        return map(uniqueArr, (v) => ({
            value: v,
            label: v,
        })).filter((item) => item.label.includes('Tax'));
    }

    // If array contains objects
    // Remove duplicates based on the valueKey
    const uniqueArr = uniqBy(arr, valueKey);

    // Map and filter to include only those with 'Tax' in the label
    return filter(
        map(uniqueArr, (item) => ({
            value: get(item, valueKey, get(item, fallbackValueKey, fallbackValueKey)),
            label: get(item, labelKey, get(item, fallbackLabelKey, fallbackLabelKey)),
        })),
        (item) => item.label.includes('Tax')
    );
}

export function mappedIntegrationList(arr) {
    return map(arr, (item) => {
        return {
            value: item.id,
            label: item.name,
            url: item.url,
        };
    });
}

export function mappedGstRates(arr) {
    return map(arr, (obj) => {
        return {
            value: obj.id,
            label: `${obj.codeType} - ${obj.code}`,
        };
    });
}

export function mappedChallanTypes(arr) {
    return map(CHALAN_TYPES, (value) => {
        return {
            value: value.value,
            label: value.label,
        };
    });
}

export function mappedVoucherTypes(arr) {
    return map(VOUCHER_TYPES, (value) => {
        return {
            value: value.value,
            label: value.label,
        };
    });
}

export function mappedSimVehicles(simVehicles) {
    return map(simVehicles, (v) => {
        return {
            value: get(v, 'deviceId'),
            label: get(v, 'vehicleNumber'),
        };
    });
}

export function mappedDeviceStatus() {
    return map(DEVICE_STATUS, (v) => {
        return {
            value: v.value,
            label: v.label,
        };
    });
}

export function mappedMaterials(allMaterials) {
    return map(allMaterials, (material) => {
        return {
            value: get(material, 'id'),
            label: get(material, 'consignmentMaterial.material.materialName'),
            count: get(material, 'dispatchQuantity'),
            tolerance: get(material, 'consignmentMaterial.material.tolerance'),
            itemRate: get(material, 'consignmentMaterial.material.itemRate'),
        };
    });
}

export function getAllocationTypes() {
    return [
        {
            value: 'Dispatch',
            label: 'Dispatch',
        },
        {
            value: 'Day',
            label: 'Day',
        },
        {
            value: 'Week',
            label: 'Week',
        },
        {
            value: 'Month',
            label: 'Month',
        },
        {
            value: 'IndentSet',
            label: 'Indent Set',
        },
        {
            value: 'Cost',
            label: 'Cost',
        },
        {
            value: 'Priority',
            label: 'Priority',
        },
        {
            value: 'Priority_Day',
            label: 'Priority Based Day Wise',
        },
        {
            value: 'Priority_Week',
            label: 'Priority Based Week Wise',
        },
        {
            value: 'Priority_Month',
            label: 'Priority Based Day Wise',
        },
        {
            value: 'Level',
            label: 'Level Based',
        },
        {
            value: 'Sob_Day',
            label: 'Sob Based Day Wise',
        },
        {
            value: 'Sob_Week',
            label: 'Sob Based Week Wise',
        },
        {
            value: 'Sob_Month',
            label: 'Sob Based Month Wise',
        },
    ];
}

export const allocationTypes = [
    {
        label: 'Priority Based',
        value: 'Level',
    },
    {
        label: 'SOB Based (Day)',
        value: 'Sob_Day',
    },
    {
        label: 'SOB Based (Week)',
        value: 'Sob_Week',
    },
    {
        label: 'SOB Based (Month)',
        value: 'Sob_Month',
    },
    {
        label: 'Priority Based SOB (Day)',
        value: 'Priority_Day',
    },
    {
        label: 'Priority Based SOB (Week)',
        value: 'Priority_Week',
    },
    {
        label: 'Priority Based SOB (Month)',
        value: 'Priority_Month',
    },
    {
        label: 'Level Based SOB (Day)',
        value: 'Level_Sob_Day',
    },
    {
        label: 'Level Based SOB (Week)',
        value: 'Level_Sob_Week',
    },
    {
        label: 'Level Based SOB (Month)',
        value: 'Level_Sob_Month',
    },
];

export function getAllocationTypesPtl() {
    return [
        {
            value: 'Cost',
            label: 'Lowest Cost',
        },
        {
            value: 'Level',
            label: 'Level Based',
        },
        {
            value: 'Sob_Day',
            label: 'Sob Based Day Wise',
        },
        {
            value: 'Sob_Week',
            label: 'Sob Based Week Wise',
        },
        {
            value: 'Sob_Month',
            label: 'Sob Based Month Wise',
        },
        {
            value: 'FCFS',
            label: 'First Come First Serve',
        },
    ];
}

export function getTextColorWrtKey(value) {
    const blueColorKeys = ['storeIn', 'STORE_ENTRY'];
    const redColoreKeys = ['storeOut', 'STORE_OUT'];
    const blackColoreKeys = ['workOrder_store'];
    const greenColorsKey = ['partEntry'];
    if (blueColorKeys.includes(value)) {
        return '#0f4ad1';
    }
    if (redColoreKeys.includes(value)) {
        return '#d61f14';
    }
    if (blackColoreKeys.includes(value)) {
        return '#0f4ad1';
    }
    if (greenColorsKey.includes(value)) {
        return '#09ab5e';
    } else return '#0f4ad1';
}

export function mappedTyreConfigurations(tyreConfigurationList) {
    return map(tyreConfigurationList, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedStoreTypes() {
    return [
        {
            value: 'fuel_tank',
            label: 'Fuel Tank',
        },
        {
            value: 'vehicle',
            label: 'Vehicle',
        },
    ];
}

export function mappedUnsettledVouchers(vouchers) {
    return map(vouchers, (voucher) => {
        return {
            label: get(voucher, 'voucherNumber'),
            value: get(voucher, 'id'),
            voucher,
        };
    });
}
export function mappedBillingPartyBranches(branches) {
    return map(branches, (branch) => {
        return {
            value: branch.id,
            label: `${get(branch, 'address.state')} (${get(branch, 'officeName')})`,
        };
    });
}

export function mappedVehicleStatusById(vehicle_status) {
    return map(vehicle_status, (value) => {
        return {
            value: value.id,
            label: `${value.name}`,
        };
    });
}
