import {
    EXPRESS_ROADWAYS_SYSTEM_TAGS,
    FLEETX_ACCOUNTS,
    getAccountIdForLogo,
    isRMCAccount,
    isTodaysKmOpenForAcccount,
    isYesterdaysKmOpenForAcccount,
    showGearAndRPMForAccount,
    showOdometerReadingInReport,
    showTimeInGeofence,
} from '../utils/account_utils';

import {
    concat,
    difference,
    filter,
    find,
    first,
    floor,
    forEach,
    forOwn,
    get,
    groupBy,
    includes,
    isEmpty,
    join,
    keyBy,
    keys,
    map,
    reduce,
    replace,
    round,
    set,
    sortBy,
    split,
    toArray,
    toSafeInteger,
    trim,
} from 'lodash';
import axios from 'axios';
import {
    DATE_FORMAT,
    DATE_FORMAT_HUMANIZE_REPORT,
    DATE_FORMAT_HUMANIZE_TIME_SECOND,
    DATE_FORMAT_REPORT,
    DATE_FORMAT_TIME,
    DATE_FORMAT_TIME_REPORT,
    DATE_FORMAT_TIMESTAMP,
    DATE_FORMAT_UNIX_TIMESTAMP,
    DEFAULT_START_DATE_DAYS,
    getDurationAsDays,
    getDurationAsYears,
    getEndDate,
    getHumanizeTime,
    getMomentTime,
    getReportTime,
    getTimeDiff,
    getTimeDiffHours,
    HOURS_24,
    parseDateFromUserInput,
} from './date_utils';
import {
    EXCEL_COL,
    formatOdometer,
    getCityFromAddress,
    getDeviceTypeFromDeviceId,
    getGroupName,
    getMergedListWithVehicles,
    getTotalOdometerTillNow,
    getTripOdo,
    getVehicleTemperature,
    handleError,
    parseAddress,
    pushUrl,
    timeFrequencyText,
} from '../constant';
import {
    getVehicleCategory,
    isEngineOn,
    isIdle,
    isParked,
    isRunning,
    isUnreachable,
    VEHICLE_STATUS,
    VEHICLE_STATUS_NAME,
} from './vehicle_utils';
import {
    getAddressFromGeoCodedLocations,
    getGeoCodedLocationsFromBackendInBulk,
    getNearestAddressFromAddressbook,
    isInsideNearestAddress,
} from './map/geocode_utils';
import { getFuelLevel, getFuelName, getFuelUnitsForAccount } from './fuel_utils';
import { fetchServiceReminders } from '../actions/dashboard/easy/service_reminders_action';
import { fetchVehicleRenewalReminders } from '../actions/dashboard/easy/vehicle_renewal_reminder_action';
import { fetchContactRenewalReminders } from '../actions/dashboard/easy/contact_renewal_reminder_action';
import { searchFuelEntries } from '../actions/dashboard/easy/fuel_entry_action';
import { findvehicleStateFromId, isVehicleOnJob, VEHICLE_JOB_STATE } from './job_route_utils';
import {
    fetchAccountVehicleAnalytics,
    fetchNodeRealtimeVehicleReport,
    fetchVehicleTagHistoryData,
} from '../actions/dashboard/vehicles_action';
import {
    addFromToBranchStationDataToRow,
    getTagNamesList,
    getTagNamesListStation,
    getVehicleJobTags,
} from './tag_utils';
import { getCommentText } from './comment_utils';
import {
    fetchDetailedAlarmForAcccount,
    fetchIssuesDTOForAccount,
    filterUnloadingStopForAccount,
    getAlarmReportColMappingForAccount,
    getWhiteLabelLogo,
    isAlarmReportColCustomOrderingForAccount,
    isBillNumberColumnShownToAccount,
    isColCustomOrderingForAccount,
    iscurrentTimeWithDateColumnShown,
    isDeviceTypeShownInRealtimeReport,
    isJobDistanceColumnShown,
    isJobSourceColumnShown,
    realtimeNodeReportTypeForAccount,
    showAddressNameInLocationConfig,
    showCurrentLatLngInRealtimeReport,
    showFxIdInRealtimeReportForAccount,
    showLogoInReport,
    showPDBatteryToAcccount,
    showReportMetadata,
    toShowCityForAccount,
} from './account_utils';
import { fetchTags } from '../actions/dashboard/tags_action';
import { getGoogleMapLinksForListOfLocations } from './map/map_utils';
import { getAlarmDetail, getAlarmDisplayName } from 'utils/alarm_utils';
import { toDataURL } from './img_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { fetchUserListMini, fetchUserListMiniV2 } from '../actions/dashboard/users_action';
import { BILL_NUMBER_TYPES, getJobLink } from './job_utils';
import { fetchInvoices } from '../actions/dashboard/finances/invoice_action';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
    DRIVER_APP_STATUS,
    DRIVER_APP_STATUS_LABEL,
    getLabel,
    GPS_BOX_STATUS,
    GPS_BOX_STATUS_LABEL,
    SIM_BASED_STATUS_LABEL,
} from '../utils/deviceHealthUtils';
import { getCabinAcDuration, getExternalMachineOnDuration, getReferAcDuration } from './alarm_utils';
import { fetchDeviceFXIds } from 'actions/dashboard/device_action';
import { fetchTrips } from '../actions/dashboard/trips_action';
import { fetchAddressBookPaginated } from '../actions/dashboard/route_dispatch/address_book_action';
import { fetchCustomFieldsForAccount } from 'actions/dashboard/custom_field_actions';
import { CUSTOM_ACCOUNT_FIELD_TYPE } from './custom_field_utils';
import { isViewBranchAllowedForUser, isViewVendorsAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { getDoorStatus, getIdleTimeInMs } from 'utils/realtime_utils';
import { fetchAlerts } from '../actions/dashboard/alerts_action';
import { EXPENSE_TYPES } from '../components/dashboard/expenses/expenses_utils';
import { isJobColInAlertEnabled, isTransporterColInAlertEnabled } from 'utils/accountUtils/alerts_utils';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';
import { getDurationString } from 'utils/stoppage_analytics_util';
import { isBlobUrl } from 'utils/http_utils';

export const ORIENTATION = {
    POTRAIT: {
        key: 'p',
        width: 595,
        height: 842,
    },
    LANDSCAPE: {
        key: 'l',
        width: 842,
        height: 595,
    },
};
const PAGE_MARGIN_X = 40;

export const REPORT_FORMAT = {
    PDF: 'pdf',
    CSV: 'xlsx', //CSV just a placeholder for xlsx format,
};
export const REPORT_FORMAT_LABEL = {
    pdf: 'PDF',
    xlsx: 'Excel',
};
export const REPORT_MIME_FORMAT = {
    pdf: 'application/pdf',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'text/csv',
    json: 'application/json',
};
export const REPORT_REQUEST_MODE = {
    DOWNLOAD: 'DOWNLOAD',
    EMAIL: 'EMAIL',
    S3_LINK_EMAIL: 'S3_LINK_EMAIL',
    DATA: 'DATA',
    DASHBOARD_REPORTS: 'DASHBOARD_REPORTS',
};

export const VEHICLE_REPORT_TYPE = {
    ALL_SUMMARY: 'ALL_SUMMARY',
    MONTH_SUMMARY: 'monthlySummaryReport',
    NIGHT_DRIVING: 'NIGHT_DRIVING',
    VEHICLE_DETAILS_REPORT: 'VEHICLE_DETAILS_REPORT',
    VEHICLE_PNL_REPORT: 'VEHICLE_PNL_REPORT',
    DEVICE: 'DEVICE',
    UPTIME: 'UPTIME',
    UPTIME_DAY_WISE: 'UPTIME_DAY_WISE',
    SPEED_STRATIFICATION: 'SPEED_STRATIFICATION',
    DEVICE_HEALTH: 'DEVICE_HEALTH',
    SLOT_WISE: 'SLOT_WISE',
    VEHICLE_PERFORMANCE: 'VEHICLE_PERFORMANCE',
    VEHICLE_TAG: 'VEHICLE_TAG',
    BULK_DEVICE_REPORT: 'BULK_DEVICE_REPORT',
    ADVANCE_FUEL_REPORT: 'ADVANCE_FUEL_REPORT',
    TSM_DAY_WISE_REPORT: 'TSM_DAY_WISE_REPORT',
    SHIFT_WISE_DRIVER_PERFORMANCE_REPORT: 'SHIFT_WISE_DRIVER_PERFORMANCE_REPORT',
    GEAR_PREDICTION_REPORT: 'GEAR_PREDICTION_REPORT',
    ALL_VEHICLES_LOCATION_REPORT: 'ALL_VEHICLES_LOCATION_REPORT',
    VEHICLE_MAINTENANCE_LOG_REPORT: 'VEHICLE_MAINTENANCE_LOG_REPORT',
};
export const TRIP_REPORT_TYPE = {
    TRIP: 'TRIP',
    TIMELINE: 'TIMELINE',
    DAY_WISE: 'DAY_WISE',
    ALL_SUMMARY: 'ALL_SUMMARY',
    MOVEMENT: 'MOVEMENT',
    BULK_MOVEMENT: 'BULK_MOVEMENT',
    MINUTE_LOCATION: 'MINUTE_LOCATION',
    SUMMARY: 'SUMMARY',
    FIRST_TRIP: 'FIRST_TRIP',
    SPEEDING: 'SPEEDING',
    TRIP_SPLIT_DISTANCE: 'TRIP_SPLIT_DISTANCE',
    STOPPAGE_REPORT: 'TRIP_STOPPAGE',
    TRIPS_BETWEEN_SITES: 'TRIP_BETWEEN_SITES',
    TRIPS_BETWEEN_SITES_BULK: 'TRIP_BETWEEN_SITES_BULK',
    TRIP_RPM_REPORT: 'TRIPS_RPM_REPORT',
    MINES_REPORT: 'MINES_REPORT',
};

export const REALTIME_REPORT_TYPE = {
    REALTIME: 'REALTIME',
    REALTIME_YESTERDAY_MONTH: 'REALTIME_YESTERDAY_MONTH',
    REALTIME_VEHICLE_NODE: 'REALTIME_VEHICLE_NODE',
    REALTIME_JINDAL_DISCONNECT_REPORT: 'REALTIME_JINDAL_DISCONNECT_REPORT',
};

export const TRANSACTION_REPORT_TYPE = {
    TRANSACTION_REPORT: 'TRANSACTION_REPORT',
    BVC_TRANSACTION_REPORT: 'BVC_TRANSACTION_REPORT',
};

export const FUEL_TOGGLE_BUTTONS = {
    FUEL_1: {
        name: 'Fuel Sensor 1',
        order: 1,
        key: 'FUEL_1',
        alarmType: 'FuelLevel',
        pilferageType: 'Pilferage',
    },
    FUEL_2: {
        name: 'Fuel Sensor 2',
        order: 2,
        key: 'FUEL_2',
        alarmType: 'FuelLevel2',
        pilferageType: 'Pilferage2',
    },
    FUEL_3: {
        name: 'Fuel Sensor 3',
        order: 3,
        key: 'FUEL_3',
        alarmType: 'FuelLevel3',
        pilferageType: 'Pilferage3',
    },
    FUEL_4: {
        name: 'Fuel Sensor 4',
        order: 4,
        key: 'FUEL_4',
        alarmType: 'FuelLevel4',
        pilferageType: 'Pilferage4',
    },
};

export function createReportFilename(
    reportFormat,
    type,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    alertType,
    dtcType,
    customer
) {
    const vehicleNumberQuery = !isEmpty(licensePlate) ? licensePlate + '-' : '';
    const groupNameQuery = !isEmpty(groupName) ? groupName.replace(/ /g, '-') + '-' : '';
    const alertTypeQuery = alertType ? alertType.replace(/ /g, '-') + '-' : '';
    const dtcTypeQuery = dtcType ? dtcType.replace(/ /g, '-') + '-' : '';
    const from = fromDate ? `${fromDate.format(DATE_FORMAT_TIME_REPORT)}` : '';
    const to = toDate ? getEndDate(toDate).format(DATE_FORMAT_TIME_REPORT) : '';
    const fromToSeperator = fromDate && toDate ? '-to-' : '';
    const customerSeperator = customer ? customer.replace(/ /g, '-') + '-' : '';
    return `${type}-Report-${vehicleNumberQuery}${groupNameQuery}${alertTypeQuery}${dtcTypeQuery}${from}${fromToSeperator}${to}.${reportFormat}`;
}

function addjsPDFScript(callback) {
    if (typeof window.jspdf !== 'undefined') {
        callback(true);
    } else {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.0.0/jspdf.umd.min.js');
        // s.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.4.1/jspdf.debug.js');
        s.onload = () => {
            callback(false);
        };
        document.body.appendChild(s);
    }
}

function preprocessDataForPdf(data, isAutoTable = false) {
    return map(data, (d) => {
        const newD = {};
        forEach(d, (value, key) => {
            set(newD, key, `${value}`);
        });
        return newD;
    });
}

function headerMappingForPDF(headers, isAutoTable = false) {
    return map(
        filter(headers, (h) => !h.ignoreInsidePdf),
        (header) => {
            return isAutoTable ? { dataKey: header.name, header: header.prompt } : header;
        }
    );
}

export async function getClientReportLogo() {
    const fleetxLogo = `/public/img/logos/fleetx/fleetx-logo-final-full-header-icon.png`;
    try {
        const accountId = getAccountIdForLogo();

        let logoUrl = accountId === 113 ? fleetxLogo : `/public/img/logos/clients/${accountId}.png`;
        const hostName = get(window, 'location.hostname');
        if (getWhiteLabelLogo(hostName)) {
            logoUrl = getWhiteLabelLogo(hostName);
        }

        //get logo image
        const logoData = await toDataURL(logoUrl);
        return logoData;
    } catch (e) {
        console.log(e);
        try {
            const logoData = await toDataURL(fleetxLogo);
            return logoData;
        } catch (e) {}
    }
}

async function createPDF(orientation, callback) {
    const xLandscape = 339;
    const xPotrait = 216;
    const logoWidth = 137;
    const logoHeight = 35;
    const yTop = 20;
    const xLeft = (orientation.width - logoWidth) / 2;

    let logoData = null;
    let showLogo = true; //showLogoInReport();
    if (showLogo) {
        logoData = await getClientReportLogo();
    }
    addjsPDFScript((wasAlreadyLoaded) => {
        const doc = new jsPDF(orientation.key, 'pt', 'a4');
        doc.setFont('helvetica');
        doc.ORIENTATION = orientation;
        if (logoData) {
            doc.addImage(logoData, 'PNG', xLeft, yTop, logoWidth, logoHeight);
        }
        callback(doc);
    });
}

function createExcel(user, callback) {
    addExceljsScript(() => {
        const workbook = new window.ExcelJS.Workbook();
        workbook.creator = user;
        workbook.lastModifiedBy = user;
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        callback(workbook);
    });
}

function addExceljsScript(callback) {
    if (typeof window.ExcelJS !== 'undefined') {
        callback(true);
    } else {
        var s = document.createElement('script');
        //s.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/exceljs/1.10.0/exceljs.min.js');
        s.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/exceljs/4.2.1/exceljs.min.js');
        s.onload = () => {
            callback(false);
        };
        document.body.appendChild(s);
    }
}

function setDefaults(doc) {
    //doc.setFontType('normal');
    //doc.setFontSize(12);
}

function insertTitle(doc, title, y) {
    //doc.setFontType('bold');
    doc.setFontSize(14);
    doc.text(doc.ORIENTATION.width / 2, y, title, null, null, 'center');
    setDefaults(doc);
}

function insertSubTitle(doc, subTitle, y, direction = 'left') {
    // doc.setFontType('normal');
    doc.setFontSize(12);
    let x = doc.ORIENTATION.width / 2;
    if (isEmpty(direction)) {
        direction = 'center';
    }
    if (direction === 'left') {
        direction = null;
        x = PAGE_MARGIN_X;
    }
    if (direction === 'right') {
        x = doc.ORIENTATION.width - PAGE_MARGIN_X;
    }
    doc.text(x, y, subTitle, null, null, direction);
    setDefaults(doc);
}

function insertLine(doc, y, lineWidth = 0.5) {
    doc.setLineWidth(lineWidth);
    doc.line(PAGE_MARGIN_X, y, doc.ORIENTATION.width - PAGE_MARGIN_X, y);
}

function insertCsvTitle(title, noOfCols) {
    if (!title) {
        return '';
    }
    let csvString = '';
    const half = floor((noOfCols + 1) / 2);
    for (let i = 1; i <= noOfCols; i++) {
        if (i === half) {
            csvString += title;
        }
        if (i < noOfCols) {
            csvString += ',';
        }
    }
    return csvString + '\n';
}

function insertCsvNameAndTime(licensePlate, fromDate, toDate, noOfCols) {
    let csvString = '';
    if (!isEmpty(licensePlate)) {
        for (let i = 1; i <= noOfCols; i++) {
            if (i === 1) {
                csvString += `Vehicle : ${licensePlate}`;
            }
            if (i < noOfCols) {
                csvString += ',';
            }
        }
        csvString += '\n';
    }
    for (let i = 1; i <= noOfCols; i++) {
        if (i === 1) {
            csvString += `${fromDate ? getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT) : ''}${
                fromDate && toDate ? ' to ' : ''
            }${toDate ? getEndDate(toDate).format(DATE_FORMAT_HUMANIZE_REPORT) : ''}`;
        }
        if (i < noOfCols) {
            csvString += ',';
        }
    }
    return csvString + '\n';
}

function insertCsvSubTitleRow(data, noOfCols) {
    let csvString = '';
    for (let i = 1; i <= noOfCols; i++) {
        if (i === 1 && !isEmpty(data)) {
            csvString += `${data}`;
        }
        if (i < noOfCols) {
            csvString += ',';
        }
    }
    return csvString + '\n';
}

function insertCsvHeaders(headers) {
    let csvString = '';
    const noOfCols = headers.length;
    map(headers, (header, index) => {
        csvString += replace(header.prompt, /\n/g, ' ');
        if (index < noOfCols - 1) {
            csvString += ',';
        }
    });
    return csvString + '\n';
}

function insertCsvData(data, noOfCols, forceIncludeAll) {
    let csvString = '';

    for (let i = 1, count = 1; count <= noOfCols; i++) {
        const cellText = data[i] !== undefined ? data[i] : '-';
        if (forceIncludeAll || data[i] !== undefined) {
            count++;
            let formattedData = replace(cellText, /\n/g, ' ');
            formattedData = replace(formattedData, /,/g, '.');
            csvString += formattedData;
            if (count <= noOfCols) {
                csvString += ',';
            }
        }
    }
    return csvString + '\n';
}

export function triggerReportDownloadFromServer(
    type,
    data,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    alertType,
    dtcType
) {
    const blob = new Blob([data], { type: REPORT_MIME_FORMAT[reportFormat] });
    const filename = createReportFilename(
        reportFormat,
        type,
        licensePlate,
        groupName,
        fromDate,
        toDate,
        alertType,
        dtcType
    );
    if (window.navigator && window.navigator.msSaveBlob) {
        // IE 10+
        // https://stackoverflow.com/a/44869314/1472869
        //window.navigator.msSaveBlob(blob, filename);
        return navigator.msSaveBlob(
            new Blob(['\ufeff', data], {
                type: 'application/octet-stream;charset=utf-8',
            }),
            filename
        );
        // var IEwindow = window.open();
        // IEwindow.document.write('sep=,\r\n' + data);
        // IEwindow.document.close();
        // IEwindow.document.execCommand('SaveAs', true, filename);
        // IEwindow.close();
    } else {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link); // Required for FF
        link.click();
    }
}

export function addHeader(toInclude, headers, name, prompt, width, footerRow) {
    if (toInclude) {
        headers.push({
            name: name,
            prompt: prompt ? prompt : name,
            width: width,
        });
        if (footerRow) {
            footerRow[name] = prompt;
        }
        return 1;
    } else {
        return 0;
    }
}

export function addDataRow(toInclude, dataRow, key, value) {
    if (toInclude) {
        dataRow[key] = value;
    }
}

export function getBlobDataFromUrl(url) {
    const config = {
        responseType: 'blob',
    };
    return axios.get(url, config);
}

export function isCrossDomainUrl(url) {
    if (includes(url, '//') || includes(url, 'http') || includes(url, 'www')) {
        const arr = url.split('/');
        const result = arr[0] + '//' + arr[2];
        if (includes(result, 'fleetx.io')) {
            return false;
        }
        return true;
    }
    return false;
}

function downloadFile(link, uri, filename) {
    link.href = uri;
    link.download = filename;
    //Firefox requires the link to be in the body
    document.body.appendChild(link);
    //simulate click
    link.click();
    // //remove the link when done
    document.body.removeChild(link);
}

export function saveAsRemote(uri, filename, forceRemote = false) {
    const link = document.createElement('a');
    if (!forceRemote && typeof link.download === 'string') {
        getBlobDataFromUrl(uri).then((res) => {
            const exportBlob = new Blob([res.data]);
            const fileUrl = window.URL.createObjectURL(exportBlob);
            downloadFile(link, fileUrl, filename);
        });
    } else {
        window.location.href = uri;
    }
}

export function saveAs(uri, filename) {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
        downloadFile(link, uri, filename);
    } else {
        window.location.href = uri;
    }
    try {
        if (isBlobUrl(uri)) {
            // url is created by window.URL.createObjectURL
            window.URL.revokeObjectURL(uri);
        }
    } catch (e) {}
}

export function triggerExcelReportDownload(workbook, filename) {
    workbook.xlsx
        .writeBuffer()
        .then(function (buffer) {
            let blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            let url = window.URL.createObjectURL(blob);
            saveAs(url, filename);
        })
        .catch((err) => {
            console.error('Error:triggerExcelReportDownload', err);
        });
}

export async function createExcelFromData(
    workbook,
    headers,
    data,
    title,
    fromDate,
    toDate,
    licensePlate,
    reportName,
    subTitle,
    alertType,
    headerRowIndex,
    borderStyles,
    styleTotalRow = true,
    mergeCell,
    headerStyles,
    isAutoWidth = true,
    logoInfoOverride,
    rowStyles,
    conditionalFormatting,
    imagesToAddData,
    colorConfig = {},
    freezeHeaderRowFalse = false
) {
    //sheet name
    //let sheetTabName = title ? title : 'Report sheet 1';
    if (typeof title === 'string') {
        //Add a Worksheet
        await addWorkSheet(
            workbook,
            headers,
            data,
            title,
            fromDate,
            toDate,
            licensePlate,
            reportName,
            subTitle,
            alertType,
            headerRowIndex,
            borderStyles,
            styleTotalRow,
            mergeCell,
            headerStyles,
            isAutoWidth,
            logoInfoOverride,
            rowStyles,
            conditionalFormatting,
            imagesToAddData,
            colorConfig,
            freezeHeaderRowFalse
        );
    } else if (typeof title === 'object') {
        const wbArray = map(title, (sheetTitle, index) => {
            return addWorkSheet(
                workbook,
                headers[index],
                data[index],
                sheetTitle,
                fromDate,
                toDate,
                licensePlate,
                reportName,
                subTitle,
                alertType,
                headerRowIndex,
                borderStyles,
                styleTotalRow,
                mergeCell,
                headerStyles,
                isAutoWidth,
                logoInfoOverride,
                rowStyles,
                conditionalFormatting,
                imagesToAddData,
                colorConfig,
                freezeHeaderRowFalse
            );
        });
        await Promise.all(wbArray);
    }
    triggerExcelReportDownload(workbook, reportName);
}

export function addMetaDataToRow(showMetaDataRow, { fromDate = '', toDate = '', licensePlate = '' }) {
    const timeString = `${fromDate ? `${`${getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT)}`}` : ''}${
        fromDate ? ' to ' : ''
    }${toDate || fromDate ? getEndDate(toDate).format(DATE_FORMAT_HUMANIZE_REPORT) : ''}`;
    const c2 = showMetaDataRow.getCell(2);
    c2.value = licensePlate;

    const c3 = showMetaDataRow.getCell(3);
    c3.value = timeString;
}

export async function addWorkSheet(
    workbook,
    headers,
    data,
    title,
    fromDate,
    toDate,
    licensePlate,
    reportName,
    subTitle,
    alertType,
    headerRowIndex,
    borderStyles,
    styleTotalRow = true,
    mergeCell,
    headerStyles = {},
    isAutoWidth = true,
    logoInfoOverride = null,
    rowStyles = {},
    conditionalFormatting,
    imagesToAddData,
    colorConfig = {},
    freezeHeaderRowFalse
) {
    let sheetTabName = title ? title : 'Report sheet 1';
    const worksheet = workbook.addWorksheet(`${sheetTabName}`);
    //set default height for each row
    worksheet.properties.defaultRowHeight = 20;

    let showLogo = showLogoInReport();

    let showMetadata = showReportMetadata() && (fromDate || toDate || licensePlate);

    if (showLogo) {
        let logoInfo = null;
        if (showLogo) {
            try {
                const accountId = getAccountIdForLogo();

                const logoUrl =
                    accountId === 113
                        ? '/public/img/logos/fleetx/fleetx-logo-final-full-header-icon.png'
                        : `/public/img/logos/clients/${accountId}.png`;

                //get logo image
                const dataUrl = await toDataURL(logoUrl);
                logoInfo = {
                    dataUrl,
                    ...logoInfoOverride,
                };
            } catch (e) {
                console.log(e);
                showLogo = null;
            }
        }

        if (logoInfo) {
            const { imageId, dataUrl, range = {}, rowHeight = 60, logoRowIndex = 1, extension = 'png' } = logoInfo;
            if (!headerRowIndex || headerRowIndex === 1) {
                headerRowIndex = 2;
            }
            const logoImageId = imageId
                ? imageId
                : workbook.addImage({
                      base64: dataUrl,
                      extension: extension,
                  });
            worksheet.addImage(logoImageId, {
                tl: { col: 2, row: 0.7 },
                ext: { width: 150, height: 50 },
                ...range,
            });
            const logoRow = worksheet.getRow(logoRowIndex);
            logoRow.height = rowHeight;
            const mergeCellConfig = {
                startColumn: EXCEL_COL[1],
                endColumn: EXCEL_COL[36],
                text: '',
                row: logoRowIndex,
            };
            if (!mergeCell) {
                mergeCell = [mergeCellConfig];
            } else {
                mergeCell.push(mergeCellConfig);
            }
        }
    }

    // add images just before header and after logo
    if (imagesToAddData && imagesToAddData.length) {
        let startRowForImage = headerRowIndex ? headerRowIndex : 1;
        forEach(imagesToAddData, ({ range, dataUrl, extension }) => {
            const imageId = workbook.addImage({
                base64: dataUrl,
                extension: extension,
            });
            worksheet.addImage(imageId, {
                ...range,
            });
            const row = worksheet.getRow(startRowForImage);
            row.height = range.rowHeight || range.ext.height || 190;
            const mergeCellConfig = {
                startColumn: EXCEL_COL[1],
                endColumn: EXCEL_COL[36],
                text: '',
                row: startRowForImage,
            };
            if (!mergeCell) {
                mergeCell = [mergeCellConfig];
            } else {
                mergeCell.push(mergeCellConfig);
            }
            headerRowIndex = startRowForImage + 1;
            startRowForImage++;
        });
    }

    if (showMetadata) {
        let showMetaDataIndex = showLogo ? 2 : 1;
        if (!headerRowIndex) {
            showMetaDataIndex = 1;
            headerRowIndex = 2;
        } else {
            showMetaDataIndex = headerRowIndex;
            headerRowIndex = headerRowIndex + 1;
        }
        const showMetaDataRow = worksheet.getRow(showMetaDataIndex);
        showMetaDataRow.height = 40;
        showMetaDataRow.font = { name: 'Arial', family: 2, size: 11, bold: true };
        showMetaDataRow.alignment = { vertical: 'middle', horizontal: 'center' };
        addMetaDataToRow(showMetaDataRow, { fromDate, toDate, licensePlate });
    }

    //maker model group

    let columns = '';
    if (mergeCell) {
        mergeCell.map((cell) => {
            worksheet.mergeCells(`${cell.startColumn}${cell.row}`, `${cell.endColumn}${cell.row}`);
            worksheet.getCell(`${cell.startColumn}${cell.row}`).value = cell.text;
            if (cell.alignment) {
                worksheet.getCell(`${cell.startColumn}${cell.row}`).alignment = cell.alignment;
            }
            if (cell.font) worksheet.getRow(cell.row).font = cell.font;
        });
    }

    if (headerRowIndex) {
        columns = map(headers, (header) => {
            return { key: header.name, width: header.width };
        });
        worksheet.getRow(headerRowIndex).values = headers.map((header) => header.prompt);
    } else {
        columns = map(headers, (header) => {
            return { header: header.prompt, key: header.name, width: 20 };
        });
    }
    worksheet.columns = columns;

    //add rows
    let rowData = {};
    forEach(data, (row) => {
        forEach(columns, (col) => {
            rowData[col.key] = row[col.key];
        });
        worksheet.addRow(rowData);
    });

    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell({ includeEmpty: true }, function (cell) {
            //add styles
            if (borderStyles) {
                cell.border = borderStyles;
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                };
            }
            //check for hyperlink
            if (typeof cell.value === 'string' && cell.value.match(/^http|^www/)) {
                cell.value = {
                    text: cell.value,
                    hyperlink: cell.value,
                    tooltip: cell.value,
                };
            }
        });
    });

    if (conditionalFormatting) {
        map(conditionalFormatting, (cF) => {
            worksheet.addConditionalFormatting(cF);
        });
    }

    //style header
    let headerRow = worksheet.getRow(headerRowIndex ? headerRowIndex : 1);
    headerRow.eachCell({ includeEmpty: true }, function (cell) {
        if (colorConfig[cell._column?._key]) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: colorConfig[cell._column?._key] },
            };
        }
    });

    if (headerRow) {
        headerRow.font = headerStyles.font ? headerStyles.font : { name: 'Arial', family: 2, size: 12, bold: true };
    }
    if (headerStyles.alignment) {
        headerRow.alignment = headerStyles.alignment;
    }
    if (headerStyles.fill) {
        headerRow.eachCell(function (cell) {
            cell.fill = headerStyles.fill;
        });
    }
    headerRow.height = 40;

    //bold last total row
    if (styleTotalRow) {
        let lastRow = worksheet.lastRow;
        if (lastRow) {
            lastRow.font = { size: 12, bold: true };
        }
    }

    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        if (rowStyles?.[rowNumber]) {
            const keys = rowStyles[rowNumber];
            map(keys, (key) => {
                row.style = rowStyles[rowNumber];
            });
            row.fill = rowStyles[rowNumber].fill;
        }
    });

    if (isAutoWidth) {
        excelAutoColumnWidth(worksheet, 1);
    }

    //frezee  header or above rows
    worksheet.views = [{ state: freezeHeaderRowFalse ? undefined : 'frozen', xSplit: 0, ySplit: headerRowIndex || 1 }];

    return worksheet;
}

export function getGroupList(groups) {
    let groupsName = map(groups, (gName) => {
        return gName.name;
    });
    return groupsName.join();
}

export function startReportCreation(
    orientation,
    headers,
    data,
    reportFormat,
    title,
    fromDate,
    toDate,
    licensePlate,
    reportName,
    noOfCols,
    alertType,
    subTitle,
    centerAllCSVHeaders,
    forceIncludeAll,
    headerRowIndex,
    borderStyles, // border Styles for all cells
    styleTotalRow,
    mergeCell, // object containg startCol, endCol, text, alignment, font for a merged cell
    headerStyles = {}, // header styles containing font, alignment, fill
    isAutoWidth = true, // disable automatic column width,
    logoInfoOverride,
    rowStyles = {},
    conditionalFormatting,
    imagesToAddData,
    loggingSummaryConfig,
    largeSizePDF,
    isSideBySideTable,
    showHeaderInAllPDFPages,
    isAutoTable = false,
    moreConfig = {}
) {
    const { sideBySideTableCount = 2, colorConfig, summaryData, freezeHeaderRowFalse } = moreConfig || {};
    if (reportFormat === REPORT_FORMAT.PDF) {
        createPDF(orientation, (doc) => {
            const hDate = 80,
                top = 90;
            insertTitle(doc, title, top);
            let y = top + 30;

            const addSectionHeading = (label, marginTop = 5, marginBottom = 40) => {
                y += marginTop;
                doc.setTextColor('#20c7ca');
                doc.setFontSize(14);
                insertSubTitle(doc, label, y, 'left');
                y += 10;
                doc.line(PAGE_MARGIN_X, y, doc.ORIENTATION.width - PAGE_MARGIN_X / 2, y);
                doc.setTextColor('#000000');
                y += marginBottom;
                doc.setFontSize(11);
            };

            if (loggingSummaryConfig) {
                addSectionHeading('Logging Summary');

                insertSubTitle(doc, `Vehicle : ${licensePlate}`, y, 'left');
                insertSubTitle(
                    doc,
                    `Lowest Temperature (°C) : ${get(loggingSummaryConfig, 'lowestTemp.temp')}`,
                    y,
                    'center'
                );
                y += 30;
                insertSubTitle(
                    doc,
                    `Start Record Date & time : ${getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT)}`,
                    y,
                    'left'
                );
                insertSubTitle(
                    doc,
                    `Highest Temperature (°C) : ${get(loggingSummaryConfig, 'highestTemp.temp')}`,
                    y,
                    'center'
                );
                y += 30;
                insertSubTitle(
                    doc,
                    `End Record Date & time : ${getMomentTime(toDate).format(DATE_FORMAT_HUMANIZE_REPORT)}`,
                    y,
                    'left'
                );
                insertSubTitle(doc, `Interval (m) : ${loggingSummaryConfig.interval}`, y, 'center');
                y += 30;

                insertSubTitle(
                    doc,
                    `Threshold Temperature Range (°C) : ${loggingSummaryConfig.thresholdValueTempMin} - ${loggingSummaryConfig.thresholdValueTempMax}`,
                    y,
                    'left'
                );
                insertSubTitle(doc, `Report Created Date : ${getReportTime()}`, y, 'center');
                y += 30;

                if (!isEmpty(loggingSummaryConfig.dataSummary)) {
                    addSectionHeading('Data Summary', 10);

                    const alarmCounts = loggingSummaryConfig.dataSummary;
                    doc.setFontSize(14);
                    insertSubTitle(doc, `Alarm Type`, y, 'left');
                    insertSubTitle(doc, `Total Events`, y, 'center');
                    doc.setFontSize(11);
                    y += 30;

                    forEach(alarmCounts, (item) => {
                        insertSubTitle(doc, `${item.label}`, y, 'left');
                        insertSubTitle(doc, `${item.value}`, y, 'center');
                        y += 30;
                    });
                    doc.addPage();
                    y = 20;
                }

                doc.setFontSize(12);
            } else {
                insertSubTitle(
                    doc,
                    `Start Date & Time   ${
                        fromDate ? `${`${getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT)}`}` : ''
                    }${fromDate && (toDate || fromDate) ? '\n              \n' : ''} End Date & Time   ${
                        toDate || fromDate ? getEndDate(toDate).format(DATE_FORMAT_HUMANIZE_REPORT) : ''
                    }`,
                    y,
                    'right'
                );
                if (!isEmpty(licensePlate)) {
                    insertSubTitle(
                        doc,
                        `Vehicle : ${licensePlate}\n${!!get(summaryData, 'curGroupName', '') ? 'Group :' : ''} ${get(
                            summaryData,
                            'curGroupName',
                            ''
                        )}\nTotal Count : ${data.length - 1}`,
                        y,
                        'left'
                    );

                    y += 20;
                }
                if (!isEmpty(subTitle)) {
                    insertSubTitle(doc, `${subTitle}`, y, 'left');
                    y += 20;
                }
                if (!isEmpty(alertType)) {
                    insertSubTitle(doc, `${getAlarmDisplayName(alertType)}`, y, 'left');
                    y += 20;
                }
            }

            // insert images just before header
            if (!isEmpty(imagesToAddData)) {
                addSectionHeading('Charts', 30, 0);
                forEach(imagesToAddData, (imgInfo) => {
                    y += 20;
                    doc.addImage(
                        imgInfo.dataUrl,
                        imgInfo.extension || 'png',
                        PAGE_MARGIN_X,
                        y,
                        doc.ORIENTATION.width - PAGE_MARGIN_X - 10,
                        imgInfo.range.ext.height
                    );
                    y += imgInfo.range.ext.height + 10;
                });
            }

            y = y > top + hDate ? y : top + hDate;

            //hack for headers
            const headerConfig = {
                printHeaders: !showHeaderInAllPDFPages,
                autoSize: false,
                margins: { left: 0, top: PAGE_MARGIN_X, bottom: 0 },
                fontSize: largeSizePDF ? 4 : 10,
            };
            const dataConfig = {
                printHeaders: !!showHeaderInAllPDFPages, // if printHeaders is true headers will be printed in all pages
                autoSize: false,
                margins: { left: 0, top: PAGE_MARGIN_X, bottom: 0 },
                fontSize: largeSizePDF ? 5 : 8,
            };

            if (isSideBySideTable) {
                const shouldAddPageBreak = !!get(loggingSummaryConfig, 'dataSummary') && !isEmpty(imagesToAddData);
                // addSectionHeading('Data', 50);

                if (shouldAddPageBreak) {
                    doc.addPage();
                    y = 0;
                }
                const startingPage = doc.internal.getCurrentPageInfo().pageNumber;
                const printWidth = doc.internal.pageSize.width;
                const spacing = 5;
                const tableCounts = sideBySideTableCount || 2;
                const margins = {
                    left: 10,
                    right: 10,
                };
                const tableWidth =
                    (printWidth - (tableCounts - 1) * spacing - margins.right - margins.left) / tableCounts;
                const tablesData = [];

                let leftMargin = margins.left;

                let sliceStartIndex = 0;
                let sliceSize = 17; // in pdf on 1 page number of rows on this font size
                let dataToPush = data.slice(sliceStartIndex, sliceSize);

                let j = 0;
                while (dataToPush.length) {
                    const index = j % tableCounts;

                    if (!tablesData[index]) {
                        tablesData[index] = [];
                    }
                    tablesData[index].push(...dataToPush);

                    sliceStartIndex = sliceStartIndex + sliceSize;

                    dataToPush = data.slice(sliceStartIndex, sliceStartIndex + sliceSize);
                    j++;
                }

                const header = headerMappingForPDF(headers, true);

                for (let i = 0; i < tableCounts; i++) {
                    if (tablesData[i] && tablesData[i].length) {
                        doc.autoTable({
                            columns: header,
                            body: tablesData[i],
                            showHead: 'everyPage',
                            startY: y + 30,
                            styles: { fontSize: 8 },
                            avoidPageSplit: true,
                            margin: { left: leftMargin },
                            tableWidth: tableWidth,
                            rowPageBreak: 'avoid',
                        });
                        leftMargin = leftMargin + tableWidth + spacing;

                        // activate last page for further printing
                        doc.setPage(startingPage);
                    }
                }
            } else {
                const tableData = preprocessDataForPdf(data, isAutoTable);
                if (isAutoTable) {
                    const autoTableHeaderStyle = {
                        fillColor: '#858483',
                        textColor: 'white',
                    };
                    doc.autoTable({
                        startY: y,
                        body: tableData,
                        columns: headerMappingForPDF(headers, isAutoTable),
                        headStyles: autoTableHeaderStyle,
                        theme: 'striped',
                    });
                    y = get(doc, 'lastAutoTable.finalY', y);
                } else {
                    doc.table(PAGE_MARGIN_X, y, [], headers, headerConfig);
                    doc.table(PAGE_MARGIN_X, (y += 30), tableData, headers, dataConfig);
                }
            }
            doc.save(reportName);
        });
    } else if (reportFormat === REPORT_FORMAT.CSV) {
        //(which is just a placeholder for xlsx format )
        createExcel('fleetx.io', (workbook) => {
            createExcelFromData(
                workbook,
                headers,
                data,
                title,
                fromDate,
                toDate,
                licensePlate,
                reportName,
                subTitle,
                alertType,
                headerRowIndex,
                borderStyles,
                styleTotalRow,
                mergeCell,
                headerStyles, // header styles containing font, alignment, fill
                isAutoWidth,
                logoInfoOverride,
                rowStyles,
                conditionalFormatting,
                imagesToAddData,
                colorConfig,
                freezeHeaderRowFalse
            );
        });
    } else {
        let csvContent = '';
        if (window.navigator && window.navigator.msSaveBlob) {
            csvContent = '';
        } else {
            csvContent = 'data:text/csv;charset=utf-8,';
        }
        csvContent += insertCsvTitle(title, noOfCols);
        if (centerAllCSVHeaders) {
            //Insert an empty row
            csvContent += insertCsvSubTitleRow('', noOfCols);
        }
        if (!centerAllCSVHeaders) {
            csvContent += insertCsvNameAndTime(licensePlate, fromDate, toDate, noOfCols);
        } else {
            csvContent += insertCsvTitle(licensePlate, noOfCols);
            csvContent += insertCsvTitle(
                `${getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT)} to ${getEndDate(toDate).format(
                    DATE_FORMAT_HUMANIZE_REPORT
                )}`,
                noOfCols
            );
        }

        if (!isEmpty(alertType)) {
            if (!centerAllCSVHeaders) {
                csvContent += insertCsvSubTitleRow(`Alarm : ${alertType}`, noOfCols);
            } else {
                csvContent += insertCsvTitle(`Alarm : ${alertType}`, noOfCols);
            }
        }

        if (!isEmpty(subTitle)) {
            if (!centerAllCSVHeaders) {
                csvContent += insertCsvSubTitleRow(subTitle, noOfCols);
            } else {
                csvContent += insertCsvTitle(subTitle, noOfCols);
            }
        }
        //Insert an empty row
        csvContent += insertCsvSubTitleRow('', noOfCols);
        csvContent += insertCsvHeaders(headers);

        map(data, (d) => {
            csvContent += insertCsvData(d, noOfCols, forceIncludeAll);
        });

        const encodedUri = encodeURI(csvContent);

        //////////////////////////////////////////
        if (window.navigator && window.navigator.msSaveBlob) {
            // IE 10+
            // https://stackoverflow.com/a/44869314/1472869
            //window.navigator.msSaveBlob(blob, filename);
            // type: 'application/octet-stream;charset=utf-8'
            return navigator.msSaveBlob(
                new Blob(['\ufeff', csvContent], {
                    type: 'text/csv;charset=utf-8;',
                }),
                reportName
            );

            // var IEwindow = window.open();
            // IEwindow.document.write('sep=,\r\n' + data);
            // IEwindow.document.close();
            // IEwindow.document.execCommand('SaveAs', true, filename);
            // IEwindow.close();
        } else {
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', reportName);
            document.body.appendChild(link); // Required for FF
            link.click();
        }
        ////////////////////////////////////////
    }
}

export function getWOverFlow(includeColumn, wColumn) {
    if (!includeColumn) {
        return wColumn;
    } else {
        return 0;
    }
}

export function generateReportCustomOrderingForHeader(headers, colMapping) {
    const newHeader = [];
    for (let i = 0; i < colMapping.length; i++) {
        let col = colMapping[i];
        let headerEntry = find(headers, { name: col.name });
        if (headerEntry && get(headerEntry, 'prompt')) {
            set(headerEntry, 'prompt', col.prompt);
            newHeader.push(headerEntry);
        }
    }
    return [...newHeader];
}

function getRunningHours(alertType, duration, runningTime) {
    let hours = '-';
    if (alertType === 'Misuse') {
        if (duration) {
            hours = getTimeDiff(duration, true, false, true);
        }
    } else {
        if (runningTime) {
            hours = getTimeDiff(runningTime, true, false, true);
        }
    }
    return hours;
}

function getGeoFenceName(geoFenceNames) {
    let finalGfN = '-';
    if (geoFenceNames) {
        const splitResult = split(geoFenceNames, ':', 2);
        finalGfN = get(splitResult, '[1]', get(splitResult, '[0]'));
    }
    return finalGfN;
}

function mergeGeoFenceAlarmsSubTypes(geoFenceAlerts, loggedInUser) {
    const includeTimeInGeofence = showTimeInGeofence(loggedInUser);
    // group by vehicleId
    const groupedList = groupBy(geoFenceAlerts, (alert) => get(alert, 'vehicleId'));

    // sort according to time
    forOwn(groupedList, (value, key) => {
        set(
            groupedList,
            key,
            sortBy(value, (o) => [
                includeTimeInGeofence ? get(o, 'geoFenceNames') : null,
                getMomentTime(get(o, 'timeStamp')).valueOf(),
            ])
        );
    });

    //merge data
    let geoFenceModified = [];
    forOwn(groupedList, (value, key) => {
        const vehicleArray = value;

        forEach(vehicleArray, (row) => {
            const subType = get(row, 'subType');
            const timeStamp = get(row, 'timeStamp');
            const geoFenceModifiedLength = get(geoFenceModified, 'length', 0);
            const lastRow = geoFenceModified[geoFenceModifiedLength - 1];
            const lastRowGeofenceName = getGeoFenceName(get(lastRow, 'geoFenceNames'));
            const rowGeoFenceName = getGeoFenceName(get(row, 'geoFenceNames'));

            if (subType === 'ENTRY') {
                set(row, 'entryTime', timeStamp);
                geoFenceModified.push(row);
            } else if (subType === 'EXIT') {
                if (geoFenceModifiedLength > 0) {
                    if (get(lastRow, 'subType') === 'EXIT') {
                        set(row, 'exitTime', timeStamp);
                        geoFenceModified.push(row);
                    } else {
                        if (
                            get(lastRow, 'vehicleId') === get(row, 'vehicleId') &&
                            lastRowGeofenceName === rowGeoFenceName &&
                            !get(lastRow, 'exitTime')
                        ) {
                            set(lastRow, 'exitTime', timeStamp);
                        } else {
                            set(row, 'exitTime', timeStamp);
                            geoFenceModified.push(row);
                        }
                    }
                } else {
                    set(row, 'exitTime', timeStamp);
                    geoFenceModified.push(row);
                }
            }
        });
    });
    if (includeTimeInGeofence) {
        geoFenceModified = sortBy(geoFenceModified, (o) => getMomentTime(get(o, 'entryTime')).valueOf());
    }
    return geoFenceModified;
}

export async function triggerSecondaryEngineReport(
    accesstoken,
    fromDate,
    toDate,
    vehicleId,
    alertType,
    fetchDetails,
    subType,
    vehicleListMiniAsMap,
    licensePlate,
    grupList
) {
    const alertResponse = await fetchAlerts(
        accesstoken,
        fromDate,
        toDate,
        vehicleId,
        alertType,
        null,
        null,
        fetchDetails,
        null,
        null,
        null,
        null,
        subType
    ).promise;

    let headers = [];

    const alarms = get(alertResponse, 'data.alarms', []);

    const fills = filter(alarms, (alarm) => alarm.subType === 'fill');
    const thefts = filter(alarms, (alarm) => alarm.subType === 'theft');
    const pilferage = filter(alarms, (alarm) => alarm.subType === 'Pilferage');

    let alarmData;
    let alertList = [];

    if (alertType === 'numberOfFills') {
        alarmData = fills;
    } else if (alertType === 'numberOfThefts') {
        alarmData = thefts;
    } else if (alertType === 'numberOfPilferage') {
        alarmData = pilferage;
    } else {
        alarmData = alarms;
    }

    alertList = map(alarmData, (o) => {
        set(o, 'key', o.vehicleId + o.type + o.timeStamp + Math.random());
        return o;
    });
    alertList = getMergedListWithVehicles(alertList, vehicleListMiniAsMap);
    alertList = sortBy(alertList, 'timeStamp');

    const reportFormat = REPORT_FORMAT.CSV;
    const footerRow = {};
    const reportName = createReportFilename(reportFormat, 'Alarms', licensePlate, null, fromDate, toDate, alertType);

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 'srNo', 'S. No', 170, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleNumber', 'Vehicle Number', 170, footerRow);
    noOfCols += addHeader(true, headers, 'group', 'Group', 170, footerRow);
    noOfCols += addHeader(true, headers, 'maker', 'Maker', 170, footerRow);
    noOfCols += addHeader(true, headers, 'model', 'Model', 170, footerRow);
    noOfCols += addHeader(true, headers, 'event', 'Event', 170, footerRow);
    // noOfCols += addHeader(true, headers, 'fuelConsumptionDuringEMOn', 'Fuel consumption', 170, footerRow);
    noOfCols += addHeader(true, headers, 'time', 'Time', 170, footerRow);
    noOfCols += addHeader(true, headers, 'machineStatusDuration', 'External Machine On/Off Duration', 170, footerRow);
    noOfCols += addHeader(true, headers, 'mapLink', 'Map Link', 170, footerRow);
    const data = [];
    let totalExternalMachineTime = 0;
    let totalConsume = 0;
    map(
        alertList,
        (
            {
                type,
                fuelType,
                vehicleName,
                vehicleNumber,
                driverName,
                vehicleMake,
                vehicleModel,
                timeStamp,
                vehicleYear,
                currentValue,
                thresholdValue,
                subType,
                latitude,
                longitude,
                groupId,
                deviceId,
                geoFenceNames,
                startDate,
                endDate,
                startLocation,
                endLocation,
                distance,
                duration,
                driverNumber,
                maxSpeed,
                durationEngineOn,
                otherAttributes,
                vehicleId,
                createDate,
                startLat,
                startLon,
                endLat,
                endLon,
                issueDTO,
                speed,
                entryTime,
                exitTime,
                jobId,
                jobName,
                tags,
                gear,
                rpm,
            },
            index
        ) => {
            const dataRow = {};
            addDataRow(true, dataRow, 'srNo', index + 1);
            addDataRow(true, dataRow, 'vehicleNumber', vehicleNumber);
            const groupObject = find(grupList, { id: groupId });
            addDataRow(true, dataRow, 'group', get(groupObject, 'name', '-'));
            addDataRow(true, dataRow, 'maker', vehicleMake ? vehicleMake : '-');
            addDataRow(true, dataRow, 'model', vehicleModel ? vehicleModel : '-');
            const fuelConsume = get(otherAttributes, 'fuelConsumptionDuringEMOn', 0);
            totalConsume = totalConsume + fuelConsume;
            // addDataRow(true, dataRow, 'fuelConsumptionDuringEMOn', fuelConsume ? round(fuelConsume, 2) : '-');
            addDataRow(true, dataRow, 'event', subType && type !== 'Geofence' ? subType : '-');
            addDataRow(true, dataRow, 'time', `${getReportTime(timeStamp)}`);
            let externalMachineOnDuration;
            externalMachineOnDuration = getExternalMachineOnDuration(alertList, index, subType);
            if (externalMachineOnDuration && externalMachineOnDuration != '') {
                totalExternalMachineTime = totalExternalMachineTime + externalMachineOnDuration;
            }
            addDataRow(
                true,
                dataRow,
                'machineStatusDuration',
                externalMachineOnDuration ? getTimeDiffHours(externalMachineOnDuration, false) : '-'
            );
            let mapLink = [];
            pushUrl(
                mapLink,
                `https://app.fleetx.io/dashboard/trips/`,
                vehicleId,
                getMomentTime(timeStamp).valueOf(),
                getMomentTime(timeStamp).add(1, 'minute').valueOf(),
                `&ZOOM_TO_TIME=${getMomentTime(createDate).format(DATE_FORMAT_UNIX_TIMESTAMP)}&REDIRECT_TO_TRIP=true`
            );
            addDataRow(true, dataRow, 'mapLink', mapLink[0]);
            data.push(dataRow);
        }
    );
    data.push({
        srNo: '',
        vehicleNumber: '',
        group: '',
        maker: '',
        model: 'Total',
        fuelConsumptionDuringEMOn: `${round(totalConsume, 2)} L`,
        event: '',
        time: '',
        machineStatusDuration: getTimeDiffHours(totalExternalMachineTime, false),
        mapLink: '',
    });

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Seconday engine Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        alertType
    );
}

export async function triggerAlarmReportDownload(
    accesstoken,
    _alertList,
    reportFormat,
    licensePlate,
    groupName,
    alertType,
    fromDate,
    toDate,
    filterConfig,
    addressBook,
    loggedInUser,
    groupList,
    { vehicleId, scope, alertSummary }
) {
    if (!_alertList || _alertList.length <= 0) {
        return;
    }
    let alertList = map(_alertList, (alert, index) => {
        return {
            ...alert,
            uId: index,
        };
    });
    const alarmsForWhichLocationRequired = ['Unknown Stoppage', 'Risk Point Stoppage', 'Parked', 'Known Stoppage'];
    const alarmsForWhichNearestAddressRequired = ['Known Stoppage'];
    let coordinatesForWhichLocationRequired = [];
    const viewVendorAllowed = isViewVendorsAllowedForUser(scope);

    let geoFenceAlerts = [];
    let nonGeoFenceAlerts = [];

    // separate Geofence and non geofence
    forEach(alertList, (alert) => {
        if (get(alert, 'type') === 'Geofence') {
            geoFenceAlerts.push(alert);
        } else {
            nonGeoFenceAlerts.push(alert);
        }
        if (alarmsForWhichLocationRequired.includes(alert.type)) {
            coordinatesForWhichLocationRequired.push({
                longitude: alert.longitude,
                latitude: alert.latitude,
                id: alert.uId,
            });
        }
    });

    let addressMap = {};
    if (coordinatesForWhichLocationRequired.length) {
        try {
            let addresses = await getGeoCodedLocationsFromBackendInBulk(
                accesstoken,
                coordinatesForWhichLocationRequired
            );
            if (addresses?.length) {
                forEach(addresses, (item) => {
                    addressMap[item.id] = item.address;
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const geoFenceModified = mergeGeoFenceAlarmsSubTypes(geoFenceAlerts, loggedInUser);

    alertList = geoFenceModified.concat(nonGeoFenceAlerts);

    const reportName = createReportFilename(reportFormat, 'Alarms', licensePlate, groupName, fromDate, toDate);

    const includeVehicleTags = get(filterConfig, 'includeVehicleTags', false);

    let tagsList = [];
    if (includeVehicleTags) {
        const tagsResp = await fetchTags(accesstoken).promise;
        tagsList = get(tagsResp, 'data.tags', []);
    }
    let headers = [];
    const footerRow = {};

    const includeAlarmDetails = get(filterConfig, 'includeAlarmDetails', true);
    const includeDriverDetail = get(filterConfig, 'includeDriverDetail', true);
    const includeAlarmTime = true; //get(filterConfig, 'includeAlarmTime', true);
    const includeLatitude = true; // isLatitudeColumnShown(loggedInUser);
    const includeLongitude = true; // isLongitudeColumnShown(loggedInUser);
    const includeExtraAlarmDetails = fetchDetailedAlarmForAcccount();
    const includeIssuesDetails = fetchIssuesDTOForAccount();
    const includeTimeInGeofence = showTimeInGeofence(loggedInUser);
    const showTransporterDetails = isTransporterColInAlertEnabled();
    const showJobDetails = isJobColInAlertEnabled();
    const includeJobName = get(filterConfig, 'includeJobName', true);
    const includeJobId = get(filterConfig, 'includeJobId', true);
    let isACReferReport = false;
    let isDrumStatus = false;
    let isSpeedingReport = false;
    let isCabinACReport = false;
    let isExternalMachineStatus = false;
    let acDurationOn = 0;
    let totalReferACDurationOn = 0;
    let totalReferACDurationOff = 0;
    let referACDurationOn = 0;
    let referACDurationOff = 0;
    let acCabinDurationOn = 0;
    let totalCabinACDurationOn = 0;
    let totalCabinACDurationOff = 0;
    let acCabinDurationOff = 0;
    let externalMachineOnDurationTotal = 0;
    if (`${alertType}`.trim() === 'Refer AC Status' && !!vehicleId) {
        isACReferReport = true;
        alertList = sortBy(alertList, 'timeStamp');
    }
    if (`${alertType}`.trim() === 'Cabin AC Status' && !!vehicleId) {
        isCabinACReport = true;
        alertList = sortBy(alertList, 'timeStamp');
    }
    if (`${alertType}`.trim() === 'Speeding') {
        isSpeedingReport = true;
    }

    if (`${alertType}`.trim() === 'External Machine Status' && !!vehicleId) {
        isExternalMachineStatus = true;
        alertList = sortBy(alertList, 'timeStamp');
    }
    if (`${alertType}`.trim() === 'Drum Status') {
        isDrumStatus = true;
        if (filterUnloadingStopForAccount()) {
            alertList = filter(alertList, (alert) => {
                return get(alert, 'subType', '').trim() !== 'UNLOADING STOP';
            });
        }
    }

    const isFleetxAccount = get(loggedInUser, 'accountId') == FLEETX_ACCOUNTS.FLEETX;

    let noOfCols = 0;

    const jobNameLabel = isRMCAccount() || isFleetxAccount ? 'Ticket_No.' : 'Job Name';
    const jobIdLabel = isRMCAccount() || isFleetxAccount ? 'Job_ID' : 'Job ID';
    noOfCols += addHeader(includeJobId, headers, 'jobId', jobIdLabel, 170, footerRow);
    noOfCols += addHeader(includeJobName, headers, 'jobName', jobNameLabel, 170, footerRow);

    noOfCols += addHeader(true, headers, 'vehicleName', 'Vehicle Name', 170, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleNumber', 'Vehicle Number', 170, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleModel', 'Vehicle Model', 170, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleBrand', 'Vehicle Brand', 170, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Alarm', 180, footerRow);
    noOfCols += addHeader(true, headers, 'alarmSubType', 'Alarm Sub Type', 180, footerRow);
    noOfCols += addHeader(true, headers, 'geofenceName', 'Geofence Name', 180, footerRow);
    noOfCols += addHeader(includeAlarmDetails, headers, 3, 'Alarm Details', 180, footerRow);
    noOfCols += addHeader(isSpeedingReport, headers, 'speed', 'Speed', 180, footerRow);
    noOfCols += addHeader(includeDriverDetail, headers, 'driverName', 'Driver Name', 180, footerRow);
    noOfCols += addHeader(includeDriverDetail, headers, 'driverNumber', 'Driver Number', 180, footerRow);
    noOfCols += addHeader(true, headers, 'groupName', 'Group', 180, footerRow);
    noOfCols += addHeader(includeAlarmTime, headers, 4, 'Time', 160, footerRow);
    noOfCols += addHeader(isACReferReport, headers, 'referACDurationOn', 'Refer AC On Duration', 180, footerRow);
    noOfCols += addHeader(isACReferReport, headers, 'referACDurationOff', 'Refer AC Off Duration', 180, footerRow);
    noOfCols += addHeader(isCabinACReport, headers, 'acCabinDurationOn', 'Cabin AC On Duration', 180, footerRow);
    noOfCols += addHeader(isCabinACReport, headers, 'acCabinDurationOff', 'Cabin AC Off Duration', 180, footerRow);
    noOfCols += addHeader(
        isExternalMachineStatus,
        headers,
        'machineStatusDuration',
        'External Machine Status On/Off Duration',
        180,
        footerRow
    );
    noOfCols += addHeader(includeLatitude, headers, 'latitude', 'Latitude', 180, footerRow);
    noOfCols += addHeader(includeLongitude, headers, 'longitude', 'Longitude', 160, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'startDate', 'Start Date', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'startTime', 'Start Time', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'startLatitude', 'Start Latitude', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'startLongitude', 'Start Longitude', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'startLocation', 'Start Location', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'endDate', 'End Date', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'endTime', 'End Time', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'endLatitude', 'End Latitude', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'endLongitude', 'End Longitude', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'endLocation', 'End Location', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'distance', 'Distance(km)', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'duration', 'Duration(dd:hh:mm:ss) ', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'maxSpeed', 'Max Speed (km/hr)', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'runningHrs', 'Running Time(dd:hh:mm:ss)', 180, footerRow);
    noOfCols += addHeader(includeExtraAlarmDetails, headers, 'stopHrs', 'Stop Time (dd:hh:mm:ss)', 180, footerRow);
    noOfCols += addHeader(includeIssuesDetails, headers, 'issueStatus', 'Issue Status', 180, footerRow);
    noOfCols += addHeader(includeIssuesDetails, headers, 'issueLastModified', 'Issue Last Modified On', 180, footerRow);
    noOfCols += addHeader(includeIssuesDetails, headers, 'issueLastComment', 'Issue Last Comment', 180, footerRow);
    noOfCols += addHeader(true, headers, 'entryTime', 'Entry Time', 180, footerRow);
    noOfCols += addHeader(true, headers, 'exitTime', 'Exit Time', 180, footerRow);
    noOfCols += addHeader(includeTimeInGeofence, headers, 'timeInGeoFence', 'Time in Geo Fence', 180, footerRow);
    noOfCols += addHeader(true, headers, 'mapLink', 'Map Link', 180, footerRow);
    noOfCols += addHeader(includeVehicleTags, headers, 'tags', 'Tags', 150, footerRow);
    noOfCols += addHeader(showGearAndRPMForAccount(), headers, 'rpm', 'RPM', 180, footerRow);
    noOfCols += addHeader(showGearAndRPMForAccount(), headers, 'gear', 'Gear', 180, footerRow);

    noOfCols += addHeader(showJobDetails, headers, 'jobDetails', 'Job', 180, footerRow);
    noOfCols += addHeader(showTransporterDetails, headers, 'transporterName', 'Transporter Name', 180, footerRow);
    noOfCols += addHeader(showTransporterDetails, headers, 'transporterDetails', 'Transporter', 180, footerRow);
    noOfCols += addHeader(true, headers, 'stoppedSince', 'Stopped Since (minutes)', 180, footerRow);
    noOfCols += addHeader(true, headers, 'location', 'Location', 180, footerRow);

    if (isAlarmReportColCustomOrderingForAccount()) {
        const colMapping = getAlarmReportColMappingForAccount(loggedInUser);
        headers = generateReportCustomOrderingForHeader(headers, colMapping);
    }

    const data = [];
    let count = 0;
    map(alertList, (row, index) => {
        const {
            type,
            fuelType,
            vehicleName,
            vehicleNumber,
            driverName,
            vehicleMake,
            vehicleModel,
            timeStamp,
            vehicleYear,
            currentValue,
            thresholdValue,
            subType,
            latitude,
            longitude,
            groupId,
            deviceId,
            geoFenceNames,
            startDate,
            endDate,
            startLocation,
            endLocation,
            distance,
            duration,
            driverNumber,
            maxSpeed,
            durationEngineOn,
            otherAttributes,
            vehicleId,
            createDate,
            startLat,
            startLon,
            endLat,
            endLon,
            issueDTO,
            speed,
            entryTime,
            exitTime,
            jobId,
            jobName,
            tags,
            gear,
            rpm,
            transporterName = '',
            transporterCode = '',
            uId,
        } = row;
        count++;
        const dataRow = {};
        const driverNameString = `Driver: ${driverName ? driverName : 'Not assigned'}\n`;
        const vehicleModelString = `${vehicleYear > 0 ? vehicleYear + ' ' : ''}${vehicleMake} ${vehicleModel}`;
        const groupName = get(find(groupList, { id: groupId }), 'name', '');
        const groupString = groupName ? `Group:${groupName}` : '';
        let runningTime, stoppageTime;
        if (type == 'Fatigue Driving') {
            runningTime = get(otherAttributes, 'runningTime', '');
            stoppageTime = get(otherAttributes, 'stoppageTime', '');
        }

        if (isCabinACReport && type == 'Cabin AC Status' && subType === 'AC STOP') {
            acCabinDurationOn = +getCabinAcDuration(alertList, index, subType);
            totalCabinACDurationOn = totalCabinACDurationOn + acCabinDurationOn;
            acCabinDurationOff = 0;
        }
        if (isCabinACReport && type == 'Cabin AC Status' && subType === 'AC START') {
            acCabinDurationOff = +getCabinAcDuration(alertList, index, subType);
            totalCabinACDurationOff = totalCabinACDurationOff + acCabinDurationOff;
            acCabinDurationOn = 0;
        }

        if (isACReferReport && type == 'Refer AC Status' && subType === 'AC STOP') {
            referACDurationOn = +getReferAcDuration(alertList, index);
            totalReferACDurationOn = totalReferACDurationOn + referACDurationOn;
            referACDurationOff = 0;
        }

        if (isACReferReport && type == 'Refer AC Status' && subType === 'AC START') {
            referACDurationOff = +getReferAcDuration(alertList, index);
            totalReferACDurationOff = totalReferACDurationOff + referACDurationOff;
            referACDurationOn = 0;
        }

        if (isExternalMachineStatus) {
            externalMachineOnDurationTotal += +getExternalMachineOnDuration(alertList, index, subType);
        }
        addDataRow(includeJobId, dataRow, 'jobId', jobId);
        addDataRow(includeJobName, dataRow, 'jobName', jobName);

        if (showJobDetails) {
            addDataRow(true, dataRow, 'jobDetails', jobId ? getJobLink(jobId) : '');
        }
        if (showTransporterDetails) {
            addDataRow(
                true,
                dataRow,
                'transporterDetails',
                transporterName || transporterCode
                    ? `${transporterName}${transporterCode ? `(${transporterCode})` : ''}`
                    : ''
            );
            addDataRow(true, dataRow, 'transporterName', transporterName ? transporterName : '-');
        }

        addDataRow(true, dataRow, 'vehicleName', vehicleName);
        addDataRow(true, dataRow, 'vehicleNumber', vehicleNumber);
        addDataRow(true, dataRow, 'vehicleModel', vehicleModelString);
        addDataRow(true, dataRow, 'entryTime', entryTime);
        addDataRow(true, dataRow, 'exitTime', exitTime);
        if (includeTimeInGeofence) {
            let timeInGeoFence = '-';
            if (entryTime && exitTime) {
                timeInGeoFence = getTimeDiff(getMomentTime(exitTime).diff(getMomentTime(entryTime)), true, true);
            }
            addDataRow(true, dataRow, 'timeInGeoFence', timeInGeoFence);
        }
        addDataRow(true, dataRow, 'vehicleBrand', vehicleMake);
        let finalGfN = '-';
        if (geoFenceNames) {
            const splitResult = split(geoFenceNames, ':', 2);
            finalGfN = get(splitResult, '[1]', get(splitResult, '[0]'));
        }
        addDataRow(true, dataRow, 'geofenceName', finalGfN);

        addDataRow(true, dataRow, 2, `${getAlarmDisplayName(type)}`);
        addDataRow(true, dataRow, 'alarmSubType', subType && type !== 'Geofence' ? subType : '-');
        addDataRow(
            includeAlarmDetails,
            dataRow,
            3,
            `${getAlarmDetail(
                type,
                currentValue,
                thresholdValue,
                subType,
                false,
                reportFormat,
                { ...row, isFromReportPreparation: true },
                addressBook,
                loggedInUser
            )}`
        );
        addDataRow(includeDriverDetail, dataRow, 'driverName', driverName ? driverName : '-');
        addDataRow(includeDriverDetail, dataRow, 'driverNumber', driverNumber ? driverNumber : '-');
        addDataRow(true, dataRow, 'groupName', groupString ? groupString : '-');
        addDataRow(includeAlarmTime, dataRow, 4, timeStamp ? `${getReportTime(timeStamp)}` : '');
        addDataRow(
            isACReferReport,
            dataRow,
            'referACDurationOn',
            referACDurationOn ? getTimeDiffHours(referACDurationOn, false) : '-'
        );
        addDataRow(
            isACReferReport,
            dataRow,
            'referACDurationOff',
            referACDurationOff ? getTimeDiffHours(referACDurationOff, false) : '-'
        );
        addDataRow(
            isCabinACReport,
            dataRow,
            'acCabinDurationOn',
            acCabinDurationOn ? getTimeDiffHours(acCabinDurationOn, false) : '-'
        );
        addDataRow(
            isCabinACReport,
            dataRow,
            'acCabinDurationOff',
            acCabinDurationOff ? getTimeDiffHours(acCabinDurationOff, false) : '-'
        );
        let externalMachineOnDuration;
        if (isExternalMachineStatus) {
            externalMachineOnDuration = getExternalMachineOnDuration(alertList, index, subType);
        }
        addDataRow(
            isExternalMachineStatus,
            dataRow,
            'machineStatusDuration',
            externalMachineOnDuration ? getTimeDiffHours(externalMachineOnDuration, false) : '-'
        );
        addDataRow(isSpeedingReport, dataRow, 'speed', speed ? speed : '');
        addDataRow(includeLatitude, dataRow, 'latitude', latitude);
        addDataRow(includeLongitude, dataRow, 'longitude', longitude);
        addDataRow(
            includeExtraAlarmDetails,
            dataRow,
            'startDate',
            startDate ? getMomentTime(startDate).format(DATE_FORMAT) : ''
        );
        addDataRow(
            includeExtraAlarmDetails,
            dataRow,
            'startTime',
            startDate ? getMomentTime(startDate).format(DATE_FORMAT_HUMANIZE_TIME_SECOND) : ''
        );
        addDataRow(includeExtraAlarmDetails, dataRow, 'startLatitude', startLat);
        addDataRow(includeExtraAlarmDetails, dataRow, 'startLongitude', startLon);
        addDataRow(includeExtraAlarmDetails, dataRow, 'startLocation', startLocation);
        addDataRow(
            includeExtraAlarmDetails,
            dataRow,
            'endDate',
            endDate ? getMomentTime(endDate).format(DATE_FORMAT) : ''
        );
        addDataRow(
            includeExtraAlarmDetails,
            dataRow,
            'endTime',
            endDate ? getMomentTime(endDate).format(DATE_FORMAT_HUMANIZE_TIME_SECOND) : ''
        );

        addDataRow(includeExtraAlarmDetails, dataRow, 'endLatitude', endLat);
        addDataRow(includeExtraAlarmDetails, dataRow, 'endLongitude', endLon);

        addDataRow(includeExtraAlarmDetails, dataRow, 'endLocation', endLocation);
        addDataRow(includeExtraAlarmDetails, dataRow, 'distance', distance);
        addDataRow(
            includeExtraAlarmDetails,
            dataRow,
            'duration',
            duration ? getTimeDiff(duration, true, false, true) : ''
        );
        addDataRow(includeExtraAlarmDetails, dataRow, 'maxSpeed', round(maxSpeed, 2));
        addDataRow(includeExtraAlarmDetails, dataRow, 'runningHrs', getRunningHours(alertType, duration, runningTime));
        addDataRow(
            includeExtraAlarmDetails,
            dataRow,
            'stopHrs',
            stoppageTime ? getTimeDiff(stoppageTime, true, false, true) : '-'
        );
        addDataRow(includeIssuesDetails, dataRow, 'issueStatus', issueDTO ? get(issueDTO, 'issueStatus') : '-');
        addDataRow(
            includeIssuesDetails,
            dataRow,
            'issueLastModified',
            issueDTO ? getReportTime(get(issueDTO, 'lastModifiedDate')) : '-'
        );
        addDataRow(includeIssuesDetails, dataRow, 'issueLastComment', issueDTO ? get(issueDTO, 'comments') : '-');

        let mapLink = [];
        pushUrl(
            mapLink,
            `https://app.fleetx.io/dashboard/trips/`,
            vehicleId,
            getMomentTime(timeStamp).valueOf(),
            getMomentTime(timeStamp).add(1, 'minute').valueOf(),
            `&ZOOM_TO_TIME=${getMomentTime(createDate).format(DATE_FORMAT_UNIX_TIMESTAMP)}&REDIRECT_TO_TRIP=true`
        );
        addDataRow(true, dataRow, 'mapLink', mapLink[0]);
        const tagJointNames = [];
        let tagObject;
        map(tags, (tagId) => {
            tagObject = find(tagsList, { id: tagId });
            if (tagObject) {
                tagJointNames.push(tagObject.jointName);
            }
        });
        addDataRow(true, dataRow, 'tags', join(tagJointNames, ', '));
        addDataRow(showGearAndRPMForAccount(), dataRow, 'rpm', rpm ? rpm : '-');
        addDataRow(showGearAndRPMForAccount(), dataRow, 'gear', gear ? gear : '-');
        const locationVisible =
            coordinatesForWhichLocationRequired.length && alarmsForWhichLocationRequired.includes(type);
        addDataRow(locationVisible, dataRow, 'stoppedSince', currentValue ? round(currentValue, 2) : '');
        let nearestAddressName = '';
        if (alarmsForWhichNearestAddressRequired.includes(type)) {
            let nearestAddressObject = getNearestAddressFromAddressbook(latitude, longitude, addressBook, true);

            if (isInsideNearestAddress(nearestAddressObject)) {
                nearestAddressName = get(nearestAddressObject, 'addressName', '');
            }
        }
        addDataRow(locationVisible, dataRow, 'location', nearestAddressName || addressMap[uId] || '');
        data.push(dataRow);
    });

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL`);
    addDataRow(true, totalRow, 2, `${count} Alarms`);
    addDataRow(includeAlarmDetails, totalRow, 3, `-`);
    addDataRow(includeAlarmTime, totalRow, 4, `-`);
    addDataRow(isACReferReport, totalRow, 'referACDurationOn', getTimeDiffHours(totalReferACDurationOn, false));
    addDataRow(isACReferReport, totalRow, 'referACDurationOff', getTimeDiffHours(totalReferACDurationOff, false));
    addDataRow(
        isExternalMachineStatus,
        totalRow,
        'machineStatusDuration',
        getTimeDiffHours(externalMachineOnDurationTotal, false)
    );

    data.push(totalRow);
    //data.push(footerRow);

    let alarmSummary = {};
    forEach(alertSummary, (el) => {
        alarmSummary[el.type] = el.count;
    });

    let alarmSummaryData = [];
    alarmSummaryData.push({
        startColumn: 'A',
        endColumn: 'B',
        text: 'Alarm Type',
        row: '2',
        alignment: { vertical: 'middle', horizontal: 'center' },
        font: { name: 'Arial', family: 2, size: 11, bold: true },
    });

    alarmSummaryData.push({
        startColumn: 'C',
        endColumn: 'D',
        text: 'Count',
        row: '2',
        alignment: { vertical: 'middle', horizontal: 'center' },
        font: { name: 'Arial', family: 2, size: 11, bold: true },
    });

    let rowIndex = 3;
    for (let type in alarmSummary) {
        alarmSummaryData.push({
            startColumn: 'A',
            endColumn: 'B',
            text: type,
            row: rowIndex.toString(),
            alignment: { vertical: 'middle', horizontal: 'center' },
            font: { name: 'Arial', family: 2, size: 15 },
        });
        alarmSummaryData.push({
            startColumn: 'C',
            endColumn: 'D',
            text: alarmSummary[type].toString(),
            row: rowIndex.toString(),
            alignment: { vertical: 'middle', horizontal: 'center' },
            font: { name: 'Arial', family: 2, size: 11 },
        });
        rowIndex++;
    }

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Alarm Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        alertType,
        '',
        null,
        null,
        alertSummary.length + 3,
        {},
        {},
        alarmSummaryData, //mergecell
        {}, // header styles containing font, alignment, fill
        true, // disable automatic column width,
        null,
        {},
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        { freezeHeaderRowFalse: true }
    );
}

export function generateCustomOrderingForHeader(
    headers,
    colMapping,
    includeExtraHeaders = true,
    extraHeadersToInclude = [],
    isExtraFirst = false
) {
    const newHeader = [];
    let extraHeaders = [];
    map(colMapping, (col) => {
        let headerEntry = find(headers, { name: col.name });
        headerEntry = set(headerEntry, 'prompt', col.prompt);
        if (headerEntry) {
            newHeader.push(headerEntry);
        }
    });
    if (includeExtraHeaders) {
        extraHeaders = difference(headers, newHeader);
    } else if (!isEmpty(extraHeadersToInclude)) {
        extraHeaders = filter(headers, (h) => {
            return includes(extraHeadersToInclude, h.name);
        });
    }
    return isExtraFirst ? [...extraHeaders, ...newHeader] : [...newHeader, ...extraHeaders];
}

export const shreeMarutiCourierTATTimeReportMapping = [
    { prompt: 'Route Name', order: 1, name: 'routeName' },
    { prompt: 'Vehicle No.', order: 2, name: 'vehicleNumber' },
    { prompt: 'Standard Departure Time  at Origin', order: 3, name: 'scheduledDepartureTimeOrigin' },
    { prompt: 'Actual Departure Time at Origin', order: 4, name: 'departureTimeOrigin' },
    { prompt: 'Standard Arrival  Time at Destination', order: 5, name: 'scheduledArrivalTimeAtDestination' },
    { prompt: 'Actual Arrival Time at Destination', order: 6, name: 'arrivalTimeAtDestination' },
    { prompt: 'Trip TAT Time', order: 7, name: 'tripTAT' },
];

export const shreeMarutiCourierJobCustomisedReportMapping = [
    { prompt: 'Route Name', order: 1, name: 'routeName' },
    { prompt: 'Vehicle No.', order: 2, name: 'vehicleNumber' },
    { prompt: 'Touch point Details', order: 3, name: 'jobName' },
    { prompt: 'Origin', order: 4, name: 'originLocation' },
    { prompt: 'Out Time', order: 5, name: 'departureTimeOrigin' },
    { prompt: 'Total Kms', order: 10, name: 'jobDistance' },
    { prompt: 'Total Hrs', order: 11, name: 'duration' },
    { prompt: 'Halt Time', order: 12, name: 'haltTime' },
];

export const aryaTransColMapping = [
    { prompt: 'Job Name', order: 1, name: 'jobName' },
    { prompt: 'Vehicle Number', order: 2, name: 'vehicleNumber' },
    { prompt: 'Driver Name', order: 3, name: 'driverName' },
    { prompt: 'Driver Number', order: 4, name: 'driverNumber' },
    { prompt: 'Route Id', order: 5, name: 'routeId' },
    { prompt: 'Route Name', order: 6, name: 'routeName' },
    { prompt: 'Job Link', order: 7, name: 'jobLink' },
    { prompt: 'Created On', order: 8, name: 'createdDate' },
    { prompt: 'Start Date', order: 9, name: 'startDate' },
    { prompt: 'Origin Location', order: 10, name: 'originLocation' },
    { prompt: 'Destination', order: 11, name: 'destination' },
    { prompt: 'Arrival Time At Origin', order: 12, name: 'arrivalTimeOrigin' },
    { prompt: 'Arrival Time At Destination(End Point)', order: 13, name: 'arrivalTimeAtDestination' },
    { prompt: 'End Date', order: 14, name: 'endDate' },
    { prompt: 'Duration', order: 16, name: 'duration' },
    { prompt: 'Trip TAT', order: 17, name: 'tripTAT' },
];

export const INTER_CITY_JOB_REPORT_COL_MAPPING = [
    { prompt: 'Standard Fuel (l)', order: 1, name: 'fuelQuantityInLtr' },
    { prompt: 'Standard Fuel Cost', order: 2, name: 'fuelCost' },
    { prompt: 'Consumed Fuel (l)', order: 3, name: 'totalFuelQuantity' },
    { prompt: 'Consumed Fuel Cost', order: 4, name: 'totalFuelExpense' },
    { prompt: 'Difference in Fuel Cost', order: 5, name: 'fuelCostDiff' },
];

export const expressColMapping = [
    { prompt: 'Current Date', order: 1, name: 'currentDate' },
    { prompt: 'Name', order: 2, name: 1 },
    { prompt: 'Number', order: 3, name: 2 },
    { prompt: 'From Branch', order: 4, name: 'fromBranch' },
    { prompt: 'From Station', order: 5, name: 'fromStation' },
    { prompt: 'To Branch', order: 6, name: 'toBranch' },
    { prompt: 'To Station', order: 7, name: 'toStation' },
    { prompt: 'Job Status', order: 8, name: 12 },
    { prompt: 'Status Time', order: 9, name: 'statusTime' },
    { prompt: 'Consigner', order: 10, name: 'jobConsigner' },
    { prompt: 'Comment', order: 11, name: 14 },
    { prompt: 'Group', order: 12, name: 3 },
    { prompt: 'Driver Name', order: 13, name: 4 },
    { prompt: 'Driver Number', order: 14, name: 5 },
    { prompt: 'Status', order: 15, name: 6 },
    { prompt: 'Status Since', order: 16, name: 'statusSince' },
    { prompt: 'Speed km/h', order: 17, name: 7 },
    { prompt: 'Distance', order: 18, name: 9 },
    { prompt: 'Location', order: 19, name: 10 },
    { prompt: 'Nearest Address', order: 20, name: 11 },
    { prompt: 'Job Name', order: 21, name: 13 },
    { prompt: 'Job Start', order: 22, name: 'jobStartLocation' },
    { prompt: 'Job End', order: 23, name: 'jobEndLocation' },
];

export function getColHorizontalJobReportMappingForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case FLEETX_ACCOUNTS.INTERCITY_CARRIERS:
            return INTER_CITY_JOB_REPORT_COL_MAPPING;
        default:
            return null;
    }
}

export function getTagDurationDataInRealtimeReportForAccount() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    if (accountId == FLEETX_ACCOUNTS.EXPRESS_ROADWAYS) {
        return true;
    }
    return false;
}

export async function fetchVehicleTagData(accessToken, tagId) {
    const result = await fetchVehicleTagHistoryData(
        accessToken,
        null,
        tagId,
        getMomentTime().startOf('day').valueOf(),
        getMomentTime().valueOf(),
        1,
        2000
    ).promise;
    let vehicleTagHistorymap = {};

    if (!result.error) {
        vehicleTagHistorymap = keyBy(get(result, 'data.content', []), 'licencePlate');
    }
    return vehicleTagHistorymap;
}

export async function triggerRealtimeVehicleReportDownload(
    accesstoken,
    vehicleList,
    groupList,
    geoCodedLocations,
    reportFormat,
    groupName,
    filterConfig,
    vehiclesOnJob,
    progressUpdateCallback,
    allAccounts,
    latestComments,
    addressBook,
    loggedInUser,
    tags,
    addressBookAsMap,
    multiVehicleAnalytics
) {
    if (!vehicleList || vehicleList.length <= 0) {
        return;
    }

    const latestCommentsObject = reduce(
        latestComments,
        (obj, item) => {
            obj[get(item, 'vehicleId', get(item, 'vehicle.id'))] = item;
            return obj;
        },
        {}
    );

    const isCsv = reportFormat === REPORT_FORMAT.CSV;

    const curTime = getMomentTime();
    const allGeoCodedLocation = { ...geoCodedLocations };

    const isSwitchedUser = get(filterConfig, 'isSwitchedUser', false);
    const isSwitchUserAllowed = get(filterConfig, 'isSwitchUserAllowed', false);
    let headers = [];
    const reportName =
        (isSwitchedUser ? `ACCOUNT-${get(loggedInUser, 'accountId')}-` : '') +
        createReportFilename(reportFormat, 'Realtime-Vehicles', null, groupName, null, curTime);
    const footerRow = {};

    const includeGroups = groupList && groupList.length > 0;
    const includeDriverNameAndNumber = get(filterConfig, 'includeDriverNameAndNumber', true);
    const includeSpeed = !isSwitchUserAllowed && get(filterConfig, 'includeSpeed', true);
    const includeTime = get(filterConfig, 'includeTime', true);
    const includeDistance = !isSwitchUserAllowed && get(filterConfig, 'includeDistance', true);
    const includeCurrentLocation = !isSwitchUserAllowed && get(filterConfig, 'includeCurrentLocation', false);
    const includeCurrentLatLng = isCsv && includeCurrentLocation && showCurrentLatLngInRealtimeReport();
    const includeNearestAddress = !isSwitchUserAllowed && get(filterConfig, 'includeNearestAddress', false);
    const includeMasterData = get(filterConfig, 'includeMasterData', false);
    const includeDeviceType = isDeviceTypeShownInRealtimeReport();
    const includeJobStatus = !!vehiclesOnJob;
    const includeJobDetails = get(filterConfig, 'isJobModuleEnabled', false);
    const includeVehiclesTags = get(filterConfig, 'includeVehicleTags', true);
    const includeJobTimeDetails = get(filterConfig, 'includeJobTimeDetails', true);
    const includeSource = isJobSourceColumnShown(loggedInUser);
    const includeJobDistance = isJobDistanceColumnShown(loggedInUser);
    const includeCurrentTimeWithDate = iscurrentTimeWithDateColumnShown(loggedInUser);
    const includeBillNumber =
        isBillNumberColumnShownToAccount(loggedInUser) && get(filterConfig, 'includeBillNumber', true);
    const showAddressNameInLocation = showAddressNameInLocationConfig();
    const includePDBattery = showPDBatteryToAcccount(loggedInUser) && get(filterConfig, 'includePDBattery', true);
    const includeLastTripDetails = get(filterConfig, 'includeLastTripDetails', false);
    const includeTodaysKm = get(filterConfig, 'includeTodaysKm', false);
    const includeStoppageTime = get(filterConfig, 'includeStoppageTime', false);
    const includeTagDurationData = getTagDurationDataInRealtimeReportForAccount();
    const isBLRAccount = FLEETX_ACCOUNTS.BLR_LOGISTICS === get(loggedInUser, 'accountId');

    let groupedVehicles = {};
    if (includeLastTripDetails) {
        const result = await fetchTrips(
            accesstoken,
            getMomentTime().subtract(1, 'day').valueOf(),
            getMomentTime().valueOf(),
            null,
            null
        ).promise;
        groupedVehicles = groupBy(get(result, 'data.trips'), (trip) => trip.vehicleId);
    }

    let accidentTagVehicleDataMap = {},
        withoutDriverTagVehicleDataMap = {},
        maintenanceTagVehicleDataMap = {};
    if (includeTagDurationData && reportFormat === REPORT_FORMAT.CSV) {
        let accidentTagId = get(find(tags, { name: 'Accident' }), 'id');
        let withoutDriverTagId = get(find(tags, { name: 'Without Driver' }), 'id');
        accidentTagVehicleDataMap = accidentTagId ? await fetchVehicleTagData(accesstoken, accidentTagId) : {};
        withoutDriverTagVehicleDataMap = withoutDriverTagId
            ? await fetchVehicleTagData(accesstoken, withoutDriverTagId)
            : {};
        const tagsForMaintenance = [
            EXPRESS_ROADWAYS_SYSTEM_TAGS.ON_ROUTE_MAINTENANCE,
            EXPRESS_ROADWAYS_SYSTEM_TAGS.ATN_HUB,
            EXPRESS_ROADWAYS_SYSTEM_TAGS.MEDCHAL_HUB,
            EXPRESS_ROADWAYS_SYSTEM_TAGS.TALOJA_HUB,
            EXPRESS_ROADWAYS_SYSTEM_TAGS.BILASPUR_HUB,
            EXPRESS_ROADWAYS_SYSTEM_TAGS.Dhulagarh_HUB,
        ];
        const allMaintenanceTags = await Promise.all(
            map(tagsForMaintenance, (maintenanceTagId) => {
                return fetchVehicleTagData(accesstoken, maintenanceTagId);
            })
        );
        forEach(allMaintenanceTags, (tagData) => {
            maintenanceTagVehicleDataMap = { ...maintenanceTagVehicleDataMap, ...tagData };
        });
    }

    const includeFxId = includeMasterData && showFxIdInRealtimeReportForAccount();
    const deviceIdsList = [];
    let fxIdsData = [];
    let locationsForGeocoding = {};
    map(vehicleList, (vehicle, index) => {
        if (vehicle.deviceId) {
            deviceIdsList.push(vehicle.deviceId);
        }
        const geoAddress = getAddressFromGeoCodedLocations(vehicle.vehicleId, geoCodedLocations);
        const address =
            isEngineOn(vehicle.currentStatus) ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.API_UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT_PARKED ||
            vehicle.currentStatus === VEHICLE_STATUS.GPS_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.GPS_0
                ? geoAddress
                    ? geoAddress
                    : ''
                : vehicle.address
                ? vehicle.address
                : '';
        if (!address) {
            locationsForGeocoding[vehicle.vehicleId] = {
                latitude: vehicle.latitude,
                longitude: vehicle.longitude,
            };
        }
    });

    if (includeCurrentLocation && !isSwitchUserAllowed) {
        locationsForGeocoding = await getGoogleMapLinksForListOfLocations(
            accesstoken,
            locationsForGeocoding,
            progressUpdateCallback,
            loggedInUser,
            vehicleList
        );
    }

    if (includeFxId && !isEmpty(deviceIdsList)) {
        const result = await fetchDeviceFXIds(accesstoken, deviceIdsList).promise;
        fxIdsData = get(result, 'data', []);
    }

    const showOdometerReading = showOdometerReadingInReport();

    const wSpeed = 70,
        wTime = 70,
        wDistance = 90,
        wCurrentLocation = 180,
        wNearestAddress = 180,
        wJobStatus = 120;
    let wOverflow = 0;
    wOverflow += getWOverFlow(includeSpeed, wSpeed);
    wOverflow += getWOverFlow(includeTime, wTime);
    wOverflow += getWOverFlow(includeDistance, wDistance);
    wOverflow += getWOverFlow(includeCurrentLocation, wCurrentLocation);
    wOverflow += getWOverFlow(includeNearestAddress, wNearestAddress);
    wOverflow += getWOverFlow(includeJobStatus, wJobStatus);
    wOverflow = floor(
        wOverflow /
            (2 +
                includeSpeed +
                includeTime +
                includeDistance +
                includeCurrentLocation +
                includeNearestAddress +
                includeJobStatus)
    );

    let noOfCols = 0;
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle', 200 + wOverflow, footerRow);
    } else {
        noOfCols += addHeader(
            true,
            headers,
            'currentDate',
            includeCurrentTimeWithDate ? 'Current Date & Time' : 'Current Date',
            150,
            footerRow
        );
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
        noOfCols += addHeader(includeGroups, headers, 3, 'Group', 150, footerRow);
        noOfCols += addHeader(includeDriverNameAndNumber, headers, 4, 'Driver Name', 150, footerRow);
        noOfCols += addHeader(includeDriverNameAndNumber, headers, 5, 'Driver Number', 150, footerRow);
    }

    noOfCols += addHeader(true, headers, 6, 'Status', 90 + wOverflow, footerRow);
    noOfCols += addHeader(
        reportFormat === REPORT_FORMAT.CSV,
        headers,
        'statusSince',
        'Status Since (dd:hh:mm:ss)',
        wTime + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        true,
        headers,
        'sensorDisTime',
        'Temp. Sensor Disconnected Since',
        wNearestAddress + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeTime, headers, 'statusTime', 'Status Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(
        includeSpeed,
        headers,
        7,
        `Speed${reportFormat === REPORT_FORMAT.PDF ? '' : ' km/h'}`,
        wSpeed + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeDistance, headers, 9, 'Distance', wDistance + wOverflow, footerRow);
    if (showOdometerReading) {
        noOfCols += addHeader(
            true,
            headers,
            'odometerReading',
            'Odometer Reading (KM)',
            wDistance + wOverflow,
            footerRow
        );
    }
    noOfCols += addHeader(includeCurrentLocation, headers, 10, 'Location', wCurrentLocation + wOverflow, footerRow);
    noOfCols += addHeader(
        includeCurrentLocation && isCsv,
        headers,
        'isKnownAddress',
        'Known Address',
        wCurrentLocation + wOverflow,
        footerRow
    );

    noOfCols += addHeader(toShowCityForAccount(), headers, 'city', 'City', wCurrentLocation + wOverflow, footerRow);
    noOfCols += addHeader(
        includeCurrentLatLng,
        headers,
        'currentLatLng',
        'Lat/Lng',
        wCurrentLocation + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeNearestAddress,
        headers,
        11,
        'Nearest Address',
        wNearestAddress + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeJobStatus, headers, 12, 'Job Status', wJobStatus + wOverflow, footerRow);
    if (reportFormat === REPORT_FORMAT.CSV) {
        noOfCols += addHeader(includeSource, headers, 'source', 'Job Source', 0);
        noOfCols += addHeader(includeJobDistance, headers, 'jobDistance', 'Job Distance', 0);
        noOfCols += addHeader(includeJobDetails, headers, 13, 'Job Name', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'jobConsigner', 'Consigner', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'jobStartLocation', 'Job Start', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'jobEndLocation', 'Job End', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'estimatedTimeOfArrival', 'ETA', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'scheduledTimeOfArrival', 'STA', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'originActualArrival', 'Loading Arrival', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'originActualDeparture', 'Loading Departure', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'currentKm', 'Job Km', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'remainingKm', 'Job Remaining Km', 0);
        noOfCols += addHeader(includeJobTimeDetails, headers, 'actualTimeOfArrival', 'Unloading Arrival', 0);
        noOfCols += addHeader(true, headers, 'predictedDelay', 'Job Delay', 0);
        noOfCols += addHeader(true, headers, 14, 'Comment', 0, footerRow);
        noOfCols += addHeader(true, headers, 'latestCommentBy', 'Latest Comment By', 0, footerRow);
        noOfCols += addHeader(includeVehiclesTags && !isBLRAccount, headers, 'vTags', 'Tags', 0);
        if (includeVehiclesTags && isBLRAccount) {
            let maxTagIdsLength = 0;
            forEach(vehicleList, (vehicle) => {
                const tagIds = vehicle.tagIds;
                if (tagIds.length > maxTagIdsLength) {
                    maxTagIdsLength = tagIds.length;
                }
            });

            for (let i = 0; i < maxTagIdsLength; i++) {
                noOfCols += addHeader(true, headers, `vTags${i + 1}`, `Tag ${i + 1}`, 0);
            }
        }
        noOfCols += addHeader(includeVehiclesTags, headers, 'branch', 'Current Branch', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'fromBranch', 'From Branch', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'fromStation', 'From Station', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'toBranch', 'To Branch', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'toStation', 'To Station', 0);
        noOfCols += addHeader(includeJobDetails, headers, 'notOnJobDays', 'Not On Job(Since Days)', 0);
        noOfCols += addHeader(
            includeTagDurationData,
            headers,
            'withoutDriverDuration',
            'Without Driver Duration(Since Days)',
            0
        );
        noOfCols += addHeader(
            includeTagDurationData,
            headers,
            'maintenanceDuration',
            'Maintenance Duration(Since Days)',
            0
        );
        noOfCols += addHeader(includeTagDurationData, headers, 'accidentDuration', 'Accident Duration(Since Days)', 0);
        noOfCols += addHeader(
            includeTagDurationData,
            headers,
            'actualNotOnJobDays',
            'Actual Not On Job(Since Days)',
            0
        );
        noOfCols += addHeader(isYesterdaysKmOpenForAcccount(), headers, 'yesterdayKm', 'Yesterday KM', 0);
        noOfCols += addHeader(isTodaysKmOpenForAcccount() || includeTodaysKm, headers, 'todayKm', 'Today KM', 0);
        noOfCols += addHeader(
            includeStoppageTime,
            headers,
            'todayStoppageDuration',
            'Today Stoppage Duration (HH:MM:SS)',
            0
        );
        noOfCols += addHeader(includeStoppageTime, headers, 'todayIdleDuration', 'Today Idle Duration (HH:MM:SS)', 0);
        noOfCols += addHeader(includeBillNumber, headers, 'eWayBillNumber', 'E-way Bill Number (Expiry)', 0);
        noOfCols += addHeader(includeMasterData, headers, 15, 'Days Since Status', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 16, 'Fuel Data', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 17, 'Vehicle ID', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 18, 'Vehicle Year', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 19, 'Vehicle Category', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 20, 'Vehicle Make', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 21, 'Vehicle Model', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 22, 'Account ID', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 23, 'Account Name', 0, footerRow);
        // noOfCols += addHeader(true, headers, 24, 'Account email', 0, footerRow);
        noOfCols += addHeader(includeMasterData || includeDeviceType, headers, 25, 'Device Type', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 26, 'Device Id', 0, footerRow);
        noOfCols += addHeader(includeFxId, headers, 'fxId', 'FX Id', 0, footerRow);
        // noOfCols += addHeader(true, headers, 27, 'Priority', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 28, 'Last Status Time', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 29, 'Last Updated At', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 'temp', 'Temperature', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 'fuelLevel', 'Fuel Level', 0, footerRow);
        noOfCols += addHeader(includeMasterData, headers, 'adblue', 'Adblue', 0, footerRow);
        noOfCols += addHeader(includePDBattery, headers, 'pdBatteryLevel', 'Battery Level', 0, footerRow);
        noOfCols += addHeader(true, headers, 'doorStatus', 'Door Status', 0, footerRow);
    }

    noOfCols += addHeader(
        includeLastTripDetails,
        headers,
        'startLocation',
        'Start Location',
        wCurrentLocation + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeLastTripDetails,
        headers,
        'startTime',
        'Start Time',
        wCurrentLocation + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeLastTripDetails,
        headers,
        'endLocation',
        'End Location',
        wCurrentLocation + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeLastTripDetails,
        headers,
        'endTime',
        'End Time',
        wCurrentLocation + wOverflow,
        footerRow
    );

    if (isColCustomOrderingForAccount(loggedInUser)) {
        headers = generateCustomOrderingForHeader(headers, expressColMapping);
    }
    const data = [];
    let i = 1;
    map(vehicleList, (vehicle, index) => {
        const name = vehicle.vehicleName;
        const vehicleOnJob = find(get(vehiclesOnJob, 'vehicles', []), ['vehicleId', vehicle.vehicleId]);
        const isOnJob = isVehicleOnJob(vehicleOnJob);
        const lastJobStatusTime = get(vehicleOnJob, 'lastStatusTime');
        const billNumbers = get(vehicleOnJob, 'otherAttributes.billNumbers', []);
        const licensePlate = vehicle.vehicleNumber;
        const driverName = vehicle.driverName ? vehicle.driverName : '';
        const driverNumber = vehicle.driverNumber ? vehicle.driverNumber : '';
        const status = VEHICLE_STATUS_NAME[vehicle.currentStatus];
        const lastTempShownTime = get(vehicle, 'otherAttributes.lastTempShownTime');
        const todayOdo = round(get(vehicle, 'otherAttributes.todaysOdo', 0), 2);
        const yesterdayOdo = round(get(vehicle, 'yesterdayOdo', 0), 2);

        let idleTime = isIdle(vehicle.currentStatus, vehicle.speed) ? getIdleTimeInMs(vehicle) : 0;
        const runningTime = vehicle.durationEngineOn;
        const { isDoorStatusAvailable, doorStatus } = getDoorStatus(vehicle);

        const speed =
            (isRunning(vehicle.currentStatus) ? round(vehicle.speed) : 0) +
            `${reportFormat === REPORT_FORMAT.PDF ? ' km/h' : ''}`;

        const statusSince = isRunning(vehicle.currentStatus, vehicle.speed)
            ? getTimeDiff(runningTime, true, false, true)
            : isParked(vehicle.currentStatus)
            ? `${getTimeDiff(
                  getMomentTime().diff(getMomentTime(vehicle.lastStatusTime).format(DATE_FORMAT_TIMESTAMP)),
                  true,
                  false,
                  true
              )}`
            : isIdle(vehicle.currentStatus, vehicle.speed)
            ? getTimeDiff(idleTime, true, false, true)
            : `${getTimeDiff(
                  getMomentTime().diff(
                      getMomentTime(
                          isUnreachable(vehicle.currentStatus) ? vehicle.lastUpdatedAt : vehicle.lastStatusTime
                      ).format(DATE_FORMAT_TIMESTAMP)
                  ),
                  true,
                  false,
                  true
              )}`;
        const statusTime = isEngineOn(vehicle.currentStatus)
            ? getReportTime(vehicle.lastStatusTime)
            : isParked(vehicle.currentStatus)
            ? getReportTime(vehicle.lastStatusTime)
            : getReportTime(isUnreachable(vehicle.currentStatus) ? vehicle.lastUpdatedAt : vehicle.lastStatusTime);

        let eta =
            get(vehicleOnJob, 'estimatedTimeOfArrival') != 0
                ? getReportTime(get(vehicleOnJob, 'estimatedTimeOfArrival'))
                : '-';
        let sta =
            get(vehicleOnJob, 'scheduledTimeOfArrival') != 0
                ? getReportTime(get(vehicleOnJob, 'scheduledTimeOfArrival'))
                : '-';
        let originArrival = get(vehicleOnJob, 'originActualArrival')
            ? getReportTime(get(vehicleOnJob, 'originActualArrival'))
            : '-';
        let originDeparture = get(vehicleOnJob, 'originActualDeparture')
            ? getReportTime(get(vehicleOnJob, 'originActualDeparture'))
            : '-';
        let unloadingArrival = get(vehicleOnJob, 'actualTimeOfArrival')
            ? getReportTime(get(vehicleOnJob, 'actualTimeOfArrival'))
            : '-';
        let currentKm = get(vehicleOnJob, 'currentKm') ? round(get(vehicleOnJob, 'currentKm'), 2) : '-';
        let remainingKm = get(vehicleOnJob, 'remainingKm') ? round(get(vehicleOnJob, 'remainingKm'), 2) : '-';

        const days = getMomentTime().diff(
            isUnreachable(vehicle.currentStatus) ? vehicle.lastUpdatedAt : vehicle.lastStatusTime,
            'days'
        );
        const distance = isEngineOn(vehicle.currentStatus)
            ? getTripOdo(get(vehicle, 'otherAttributes.tripOdometer', 0))
            : '-';
        const currentFuelConsumption = get(vehicle, 'otherAttributes.currentFuelConsumption');
        const fuelConsumption = get(vehicle, 'otherAttributes.fuelConsumption');
        const geoAddress = getAddressFromGeoCodedLocations(vehicle.vehicleId, geoCodedLocations);
        const pdBatteryLevel = round(get(vehicle, 'otherAttributes.PDBattery', 0), 2);
        // getBatteryPercent(
        //     vehicle.deviceId,
        //     get(vehicle, 'otherAttributes.PDBattery', 0),
        //     get(vehicle, 'otherAttributes.oaDeviceType', get(vehicle, 'otherAttributes.deviceType'))
        // );
        let address =
            isEngineOn(vehicle.currentStatus) ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.API_UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT_PARKED ||
            vehicle.currentStatus === VEHICLE_STATUS.GPS_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.GPS_0
                ? geoAddress
                    ? geoAddress
                    : ''
                : get(vehicle, 'address', '');
        if (
            !address &&
            locationsForGeocoding[vehicle.vehicleId] &&
            get(
                locationsForGeocoding[vehicle.vehicleId],
                'resultObj.address',
                get(locationsForGeocoding[vehicle.vehicleId], 'address')
            )
        ) {
            address = get(
                locationsForGeocoding[vehicle.vehicleId],
                'resultObj.address',
                get(locationsForGeocoding[vehicle.vehicleId], 'address', '-')
            );
        } else if (
            !address &&
            allGeoCodedLocation[vehicle.vehicleId] &&
            get(
                allGeoCodedLocation[vehicle.vehicleId],
                'resultObj.address',
                get(allGeoCodedLocation[vehicle.vehicleId], 'address')
            )
        ) {
            address = get(
                allGeoCodedLocation[vehicle.vehicleId],
                'resultObj.address',
                get(allGeoCodedLocation[vehicle.vehicleId], 'address', '-')
            );
        } else {
            address = parseAddress(address ? address : get(vehicle, 'address', ''));
            //address = parseAddress(address);
        }

        const city = getCityFromAddress(address);

        let nearestAddress = '';
        let nearestAddressObject;
        if (includeNearestAddress || includeCurrentLocation) {
            nearestAddressObject = getNearestAddressFromAddressbook(
                vehicle.latitude,
                vehicle.longitude,
                addressBook,
                true
            );
            nearestAddress = get(nearestAddressObject, 'addressText', '');

            if (isInsideNearestAddress(nearestAddressObject) && showAddressNameInLocation) {
                address = get(nearestAddressObject, 'addressName', address);
            }
        }
        const groupName = getGroupName(vehicle.groupId, groupList);
        let jobStatus = findvehicleStateFromId(
            vehicleOnJob ? vehicleOnJob.status : VEHICLE_JOB_STATE.NOT_ON_JOB,
            loggedInUser
        );
        const latestCommentForThisVehicle = latestCommentsObject[vehicle.vehicleId];
        const last2DaysAnalytics = get(multiVehicleAnalytics, vehicle.vehicleId);
        const startAddressId = get(vehicleOnJob, 'startAddressBookId', '');
        let startAddressObj = get(addressBookAsMap, startAddressId, null);

        const dataRow = {};
        forEach(headers, (h) => {
            addDataRow(true, dataRow, h.name, ` `);
        });
        if (reportFormat === REPORT_FORMAT.PDF) {
            addDataRow(
                true,
                dataRow,
                1,
                `Name: ${name}\nNo: ${licensePlate}${includeGroups ? `\nGroup: ${groupName ? groupName : '-'}` : ''}${
                    includeDriverNameAndNumber && driverName ? `\nDriver: ${trim(driverName)}` : ''
                }${includeDriverNameAndNumber && driverNumber ? `\nNo: ${driverNumber}` : ''}`
            );
        } else {
            addDataRow(
                true,
                dataRow,
                'currentDate',
                `${getMomentTime().format(includeCurrentTimeWithDate ? DATE_FORMAT_TIME : DATE_FORMAT)}`
            );
            addDataRow(true, dataRow, 1, `${name}`);
            addDataRow(true, dataRow, 2, `${licensePlate}`);
            addDataRow(includeGroups, dataRow, 3, `${groupName ? groupName : '-'}`);
            addDataRow(includeDriverNameAndNumber, dataRow, 4, `${trim(driverName)}`);
            addDataRow(includeDriverNameAndNumber, dataRow, 5, `${driverNumber}`);
        }
        if (includeLastTripDetails) {
            addDataRow(
                includeLastTripDetails,
                dataRow,
                'startLocation',
                get(groupedVehicles, `${vehicle.vehicleId}[0].sAddress`, '-')
            );
            addDataRow(
                includeLastTripDetails,
                dataRow,
                'startTime',
                get(groupedVehicles, `${vehicle.vehicleId}[0].sDate`, '-')
            );
            addDataRow(
                includeLastTripDetails,
                dataRow,
                'endLocation',
                get(groupedVehicles, `${vehicle.vehicleId}[0].eAddress`, '-')
            );
            addDataRow(
                includeLastTripDetails,
                dataRow,
                'endTime',
                get(groupedVehicles, `${vehicle.vehicleId}[0].eDate`, '-')
            );
        }
        addDataRow(true, dataRow, 6, `${status}`);
        addDataRow(includeSpeed, dataRow, 7, `${speed}`);
        addDataRow(includeTime, dataRow, 'statusSince', `${statusSince}`);
        addDataRow(includeTime, dataRow, 'statusTime', `${statusTime}`);
        addDataRow(includeDistance, dataRow, 9, `${distance}`);
        if (showOdometerReading) {
            addDataRow(true, dataRow, 'odometerReading', round(getTotalOdometerTillNow(vehicle), 2));
        }
        addDataRow(includeCurrentLocation, dataRow, 10, `${address}`);
        addDataRow(
            includeCurrentLocation,
            dataRow,
            'isKnownAddress',
            isInsideNearestAddress(nearestAddressObject) ? 'Y' : 'N'
        );
        addDataRow(toShowCityForAccount(), dataRow, 'city', city);
        if (includeCurrentLatLng) {
            addDataRow(includeCurrentLatLng, dataRow, 'currentLatLng', `${vehicle.latitude},${vehicle.longitude}`);
        }
        addDataRow(includeNearestAddress, dataRow, 11, `${parseAddress(nearestAddress)}`);
        addDataRow(includeJobStatus, dataRow, 12, `${jobStatus ? jobStatus : '-'}`);
        addDataRow(
            includeSource,
            dataRow,
            'source',
            get(startAddressObj, 'name', get(vehicleOnJob, 'startLocation', '-'))
        );
        addDataRow(includeJobDistance, dataRow, 'jobDistance', 'Job Distance', get(vehicleOnJob, 'currentKm', '-'));

        if (reportFormat === REPORT_FORMAT.CSV) {
            addDataRow(includeJobDetails, dataRow, 13, `${get(vehicleOnJob, 'jobName', '-')}`);
            addDataRow(includeJobDetails, dataRow, 'jobConsigner', `${get(vehicleOnJob, 'consigner', '-')}`);
            addDataRow(includeJobDetails, dataRow, 'jobStartLocation', `${get(vehicleOnJob, 'startLocation', '-')}`);
            addDataRow(includeJobDetails, dataRow, 'jobEndLocation', `${get(vehicleOnJob, 'endLocation', '-')}`);

            let notOnJobDays = '';
            let withoutDriverDuration = '',
                maintenanceDuration = '',
                accidentDuration = '';
            if (!isOnJob && lastJobStatusTime && includeJobDetails) {
                notOnJobDays = getDurationAsDays(getMomentTime().valueOf() - lastJobStatusTime);
            }
            if (includeTagDurationData) {
                let accidentDateForVehicle = get(accidentTagVehicleDataMap, `${licensePlate}.attachDate`);
                let maintenanceDateForVehicle = get(maintenanceTagVehicleDataMap, `${licensePlate}.attachDate`);
                let withoutDriverDateForVehicle = get(withoutDriverTagVehicleDataMap, `${licensePlate}.attachDate`);
                if (!!accidentDateForVehicle) {
                    accidentDuration = getDurationAsDays(
                        getMomentTime().valueOf() -
                            getMomentTime(parseDateFromUserInput(accidentDateForVehicle)).valueOf()
                    );
                }
                if (!!maintenanceDateForVehicle) {
                    maintenanceDuration = getDurationAsDays(
                        getMomentTime().valueOf() -
                            getMomentTime(parseDateFromUserInput(maintenanceDateForVehicle)).valueOf()
                    );
                }
                if (!!withoutDriverDateForVehicle) {
                    withoutDriverDuration = getDurationAsDays(
                        getMomentTime().valueOf() -
                            getMomentTime(parseDateFromUserInput(withoutDriverDateForVehicle)).valueOf()
                    );
                }
            }

            let actualNotOnJobDays = notOnJobDays
                ? notOnJobDays - (withoutDriverDuration + maintenanceDuration + accidentDuration)
                : '';

            addDataRow(includeJobDetails, dataRow, 'notOnJobDays', notOnJobDays);
            addDataRow(true, dataRow, 'doorStatus', isDoorStatusAvailable ? (doorStatus ? 'CLOSED' : 'OPEN') : '-');
            addDataRow(includeTagDurationData, dataRow, 'withoutDriverDuration', withoutDriverDuration);
            addDataRow(includeTagDurationData, dataRow, 'maintenanceDuration', maintenanceDuration);
            addDataRow(includeTagDurationData, dataRow, 'accidentDuration', accidentDuration);
            addDataRow(includeTagDurationData, dataRow, 'actualNotOnJobDays', actualNotOnJobDays);

            addDataRow(includeJobTimeDetails, dataRow, 'estimatedTimeOfArrival', eta);
            addDataRow(includeJobTimeDetails, dataRow, 'scheduledTimeOfArrival', sta);
            addDataRow(includeJobTimeDetails, dataRow, 'originActualArrival', originArrival);
            addDataRow(includeJobTimeDetails, dataRow, 'originActualDeparture', originDeparture);
            addDataRow(includeJobTimeDetails, dataRow, 'currentKm', currentKm);
            addDataRow(includeJobTimeDetails, dataRow, 'remainingKm', remainingKm);
            addDataRow(includeJobTimeDetails, dataRow, 'actualTimeOfArrival', unloadingArrival);
            addDataRow(
                true,
                dataRow,
                14,
                `${latestCommentForThisVehicle ? getCommentText(latestCommentForThisVehicle) : ''}`
            );
            addDataRow(
                true,
                dataRow,
                'latestCommentBy',
                `${
                    latestCommentForThisVehicle
                        ? `${get(latestCommentForThisVehicle, 'lastModifiedByName', '')} ${
                              get(latestCommentForThisVehicle, 'eventTime', '')
                                  ? `(${get(latestCommentForThisVehicle, 'eventTime', '')})`
                                  : ''
                          }`
                        : ''
                }`
            );
            //tags
            let tagsAttachedToSavedAddress = [];

            if (isInsideNearestAddress(nearestAddressObject)) {
                tagsAttachedToSavedAddress = get(addressBookAsMap, `${nearestAddressObject.id}.tagIdsList`, []);
            }
            const attachedTags = [
                ...get(vehicle, 'tagIds', []),
                ...getVehicleJobTags(vehicleOnJob),
                ...tagsAttachedToSavedAddress,
            ];
            const [tagNames, branch] = getTagNamesListStation(tags, attachedTags);
            addDataRow(includeVehiclesTags && !isBLRAccount, dataRow, 'vTags', tagNames.join(','));
            if (includeVehiclesTags && isBLRAccount) {
                const [tagNames, branch] = getTagNamesListStation(tags, attachedTags);
                forEach(tagNames, (tag, i) => {
                    addDataRow(true, dataRow, `vTags${i + 1}`, tag);
                });
            }
            addDataRow(includeVehiclesTags, dataRow, 'branch', branch);
            addDataRow(includePDBattery, dataRow, 'pdBatteryLevel', `${pdBatteryLevel}`);
            addDataRow(
                includeMasterData,
                dataRow,
                'adblue',
                `${get(
                    vehicle,
                    'otherAttributes.adblueInLitres',
                    get(vehicle, 'otherAttributes.dieselExhaustFluid', '')
                )}`
            );

            // const yesterdayAnalytics = find(last2DaysAnalytics, (entry) => {
            //     return getMomentTime().subtract(1, 'days').startOf('day').date() === entry.day;
            // });

            const todayAnalytics = find(last2DaysAnalytics, (entry) => {
                return getMomentTime().date() === entry.day;
            });

            addDataRow(isYesterdaysKmOpenForAcccount(), dataRow, 'yesterdayKm', yesterdayOdo);
            addDataRow(isTodaysKmOpenForAcccount() || includeTodaysKm, dataRow, 'todayKm', todayOdo);

            const todaysStart = getMomentTime().startOf('day').valueOf();
            const elapsedTime = getMomentTime().valueOf() - todaysStart;
            const idleDuration = get(todayAnalytics, 'idleDuration', 0);
            const durationEngineOnToday = get(todayAnalytics, 'durationEngineOn', 0);
            const stoppageTime = elapsedTime - durationEngineOnToday;
            addDataRow(includeTodaysKm, dataRow, 'todayStoppageDuration', getTimeDiff(stoppageTime, true, false, true));
            addDataRow(
                includeTodaysKm,
                dataRow,
                'todayIdleDuration',
                idleDuration ? getTimeDiff(idleDuration, true, false, true) : '0'
            );
            if (includeBillNumber && billNumbers && billNumbers.length) {
                let valueString = '';
                const eWayBillNumbers = filter(billNumbers, { type: BILL_NUMBER_TYPES.EWAY });
                map(eWayBillNumbers, (eWayBill) => {
                    valueString += `${eWayBill.value} (${getReportTime(eWayBill.expiryDate)}), `;
                });
                addDataRow(includeBillNumber, dataRow, 'eWayBillNumber', valueString);
            }
            const account = find(allAccounts, { id: vehicle.accountId });
            addDataRow(includeMasterData, dataRow, 15, `${days}`);
            addDataRow(
                includeMasterData,
                dataRow,
                16,
                `${
                    (currentFuelConsumption && currentFuelConsumption > 0) || (fuelConsumption && fuelConsumption > 0)
                        ? 'YES'
                        : 'NO'
                }`
            );
            addDataRow(includeMasterData, dataRow, 17, `${vehicle.vehicleId}`);
            addDataRow(includeMasterData, dataRow, 18, `${vehicle.vehicleYear}`);
            addDataRow(
                includeMasterData,
                dataRow,
                19,
                `${getVehicleCategory(vehicle.vehicleMake, vehicle.vehicleModel)}`
            );
            addDataRow(includeMasterData, dataRow, 20, `${vehicle.vehicleMake}`);
            addDataRow(includeMasterData, dataRow, 21, `${vehicle.vehicleModel}`);
            addDataRow(includeMasterData, dataRow, 22, `${vehicle.accountId}`);
            addDataRow(includeMasterData, dataRow, 23, `${account ? account.name : ''}`);
            // addDataRow(true, dataRow, 24, `${account ? account.email : ''}`);

            const deviceType = get(
                vehicle,
                'otherAttributes.deviceType',
                get(getDeviceTypeFromDeviceId(vehicle.deviceId), 'name', '')
            );
            addDataRow(includeMasterData || includeDeviceType, dataRow, 25, deviceType);
            addDataRow(includeMasterData, dataRow, 26, `${vehicle.deviceId}`);

            if (includeFxId) {
                const fxId = get(
                    find(fxIdsData, (item) => vehicle.deviceId && item[1] === vehicle.deviceId),
                    '[0]',
                    ''
                );
                addDataRow(true, dataRow, 'fxId', fxId);
            }

            // addDataRow(true, dataRow, 27, `${calculateVehiclePriority(vehicle)}`);
            addDataRow(
                includeMasterData,
                dataRow,
                28,
                `${getMomentTime(vehicle.lastStatusTime).format(DATE_FORMAT_TIMESTAMP)}`
            );
            addDataRow(
                includeMasterData,
                dataRow,
                29,
                `${getMomentTime(vehicle.lastUpdatedAt).format(DATE_FORMAT_TIMESTAMP)}`
            );
            addDataRow(includeMasterData, dataRow, 'temp', getVehicleTemperature(vehicle));
            addDataRow(
                includeMasterData,
                dataRow,
                'fuelLevel',
                `${getFuelLevel(get(vehicle, 'otherAttributes.fuel'), false)}`
            );
            addDataRow(
                true,
                dataRow,
                'predictedDelay',
                `${
                    get(vehicle, 'predictedDelay') < 0
                        ? '-' + getTimeDiff(Math.abs(get(vehicle, 'predictedDelay')), true)
                        : getTimeDiff(get(vehicle, 'predictedDelay'), true)
                }`
            );
            addDataRow(true, dataRow, 'sensorDisTime', lastTempShownTime ? getHumanizeTime(lastTempShownTime) : '-');

            //

            let tagsAttachedToSavedEndAddress = [];
            let tagsAttachedToSavedStartAddress = [];
            if (includeJobDetails && vehicleOnJob && vehicleOnJob.endAddressBookId) {
                const key = get(vehicleOnJob, 'startAddressBookId', '');
                let addressObj = get(addressBookAsMap, key, null);
                tagsAttachedToSavedStartAddress = addressObj && addressObj.tagIdsList ? addressObj.tagIdsList : [];

                const key1 = get(vehicleOnJob, 'endAddressBookId', '');
                addressObj = get(addressBookAsMap, key1, null);
                tagsAttachedToSavedEndAddress = addressObj && addressObj.tagIdsList ? addressObj.tagIdsList : [];

                addFromToBranchStationDataToRow(
                    tagsAttachedToSavedStartAddress,
                    'fromBranch',
                    'fromStation',
                    dataRow,
                    tags,
                    true
                );

                addFromToBranchStationDataToRow(
                    tagsAttachedToSavedEndAddress,
                    'toBranch',
                    'toStation',
                    dataRow,
                    tags,
                    true
                );
            }
        }

        data.push(dataRow);
    });
    const totalRow = {};

    forEach(headers, (h) => {
        addDataRow(true, totalRow, h.name, `-`);
    });

    addDataRow(true, totalRow, 1, `TOTAL\n${vehicleList.length} Vehicles`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, `-`);
        addDataRow(includeGroups, totalRow, 3, `-`);
        addDataRow(includeDriverNameAndNumber, totalRow, 4, `-`);
        addDataRow(includeDriverNameAndNumber, totalRow, 5, `-`);
    }
    addDataRow(true, totalRow, 6, `-`);
    addDataRow(includeSpeed, totalRow, 7, `-`);
    addDataRow(includeTime, totalRow, 8, `-`);
    addDataRow(includeDistance, totalRow, 9, `-`);
    addDataRow(includeCurrentLocation, totalRow, 10, `-`);
    addDataRow(includeCurrentLocation, totalRow, 'isKnownAddress', `-`);
    addDataRow(includeNearestAddress, totalRow, 11, `-`);
    addDataRow(includeJobStatus, totalRow, 12, `-`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(includeJobDetails, totalRow, 13, `-`);
        addDataRow(includeJobDetails, totalRow, 'jobConsigner', `-`);
        addDataRow(includeJobDetails, totalRow, 'jobStartLocation', `-`);
        addDataRow(includeJobDetails, totalRow, 'jobEndLocation', `-`);
        addDataRow(true, totalRow, 14, `-`);
        addDataRow(true, totalRow, 'vTags', `-`);
        if (includeMasterData) {
            addDataRow(true, totalRow, 15, `-`);
            addDataRow(true, totalRow, 16, `-`);
            addDataRow(true, totalRow, 17, `-`);
            addDataRow(true, totalRow, 18, `-`);
            addDataRow(true, totalRow, 19, `-`);
            addDataRow(true, totalRow, 20, `-`);
            addDataRow(true, totalRow, 21, `-`);
            addDataRow(true, totalRow, 22, `-`);
            addDataRow(true, totalRow, 23, `-`);
            // addDataRow(true, totalRow, 24, `-`);
            addDataRow(true, totalRow, 25, `-`);
            addDataRow(true, totalRow, 26, `-`);
            // addDataRow(true, totalRow, 27, `-`);
            addDataRow(true, totalRow, 28, `-`);
            addDataRow(true, totalRow, 29, `-`);
            addDataRow(true, totalRow, 'temp', `-`);
            addDataRow(true, totalRow, 'fuelLevel', `-`);
        }
    }
    addDataRow(includeLastTripDetails, totalRow, 'startLocation', '-');
    addDataRow(includeLastTripDetails, totalRow, 'startTime', '-');
    addDataRow(includeLastTripDetails, totalRow, 'endLocation', '-');
    addDataRow(includeLastTripDetails, totalRow, 'endTime', '-');
    data.push(totalRow);
    //data.push(footerRow);

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Realtime Vehicles Report',
        null,
        curTime,
        null,
        reportName,
        noOfCols
    );
}

export async function triggerRealtimeVehicleReportNode(
    accesstoken,
    reportFormat,
    progressUpdateCallback,
    loggedInUser
) {
    const type = realtimeNodeReportTypeForAccount(loggedInUser);
    const startDate = getMomentTime().subtract(DEFAULT_START_DATE_DAYS, 'days').startOf('day');
    const roundedMinutes = getMomentTime().minute();
    const endDate = getMomentTime().clone().minute(roundedMinutes).second(0);
    progressUpdateCallback(10);
    const result = await fetchNodeRealtimeVehicleReport(
        accesstoken,
        'DOWNLOAD',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        startDate.valueOf(),
        endDate.valueOf()
    ).promise;
    if (!result.error) {
        let blob = new Blob([result.data], { type: `${REPORT_MIME_FORMAT.xlsx}` });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `realtime_report.xlsx`;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);
    } else {
        alert(handleError(get(result, 'response')));
    }
}

export async function triggerRealtimeVehicleWithYesterdayAndMonthRunningReportDownload(
    accesstoken,
    vehicleList,
    groupList,
    geoCodedLocations,
    reportFormat,
    groupName,
    filterConfig,
    progressUpdateCallback,
    allAccounts,
    latestComments,
    addressBook,
    loggedInUser
) {
    if (!vehicleList || vehicleList.length <= 0) {
        return;
    }
    const curTime = getMomentTime();

    const reportName = createReportFilename(reportFormat, 'Yesterday-Summary', null, groupName, null, curTime);
    const headers = [];
    const footerRow = {};

    const isSwitchUserAllowed = get(filterConfig, 'isSwitchUserAllowed', false);
    const isSwitchedUser = get(filterConfig, 'isSwitchedUser', false);

    const includeRealtimeVehicleGroups = groupList && groupList.length > 0;
    const includeRealtimeVehicleDriverNameAndNumber = get(
        filterConfig,
        'includeRealtimeVehicleDriverNameAndNumber',
        true
    );
    const includeRealtimeVehicleTime = get(filterConfig, 'includeRealtimeVehicleTime', true);
    const includeRealtimeVehicleCurrentLocation =
        !isSwitchUserAllowed && get(filterConfig, 'includeRealtimeVehicleCurrentLocation', false);
    const includeRealtimeVehicleNearestAddress =
        !isSwitchUserAllowed && get(filterConfig, 'includeRealtimeVehicleNearestAddress', false);
    const includeRealtimeVehicleYesterdayRunning =
        !isSwitchUserAllowed && get(filterConfig, 'includeRealtimeVehicleYesterdayRunning', false);
    const includeRealtimeVehicleYesterdayFuel =
        !isSwitchUserAllowed && get(filterConfig, 'includeRealtimeVehicleYesterdayFuel', false);
    const includeRealtimeVehicleMonthRunning =
        !isSwitchUserAllowed && get(filterConfig, 'includeRealtimeVehicleMonthRunning', false);

    const includeJobName = get(filterConfig, 'isJobModuleEnabled', false);

    let locationsForGeocoding = {};
    map(vehicleList, (vehicle, index) => {
        const geoAddress = getAddressFromGeoCodedLocations(vehicle.vehicleId, geoCodedLocations);
        const address =
            isEngineOn(vehicle.currentStatus) ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
            vehicle.currentStatus === VEHICLE_STATUS.API_UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT_PARKED ||
            vehicle.currentStatus === VEHICLE_STATUS.GPS_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.GPS_0
                ? geoAddress
                    ? geoAddress
                    : ''
                : vehicle.address
                ? vehicle.address
                : '';
        if (!address) {
            locationsForGeocoding[index] = {
                latitude: vehicle.latitude,
                longitude: vehicle.longitude,
                id: index,
            };
        }
    });

    const yesterdayData = {};
    const monthData = {};
    if (includeRealtimeVehicleYesterdayRunning || includeRealtimeVehicleYesterdayFuel) {
        const startDateYesterday = getMomentTime().subtract(1, 'day').startOf('day');
        const endDateYesterday = getMomentTime().subtract(1, 'day').endOf('day');
        const response = await fetchAccountVehicleAnalytics(accesstoken, startDateYesterday, endDateYesterday).promise;
        map(response.data, (data) => {
            if (data && data.vehicleId) {
                yesterdayData[data.vehicleId] = data;
            }
        });
    }

    if (includeRealtimeVehicleMonthRunning) {
        const startDateMonth = getMomentTime().startOf('month');
        const endDateMonth = getMomentTime();
        const response = await fetchAccountVehicleAnalytics(accesstoken, startDateMonth, endDateMonth).promise;
        map(response.data, (data) => {
            if (data && data.vehicleId) {
                monthData[data.vehicleId] = data;
            }
        });
    }

    if (includeRealtimeVehicleCurrentLocation && !isSwitchUserAllowed) {
        progressUpdateCallback(25);
        let locationsForGeocodingBulk = await getGeoCodedLocationsFromBackendInBulk(
            accesstoken,
            toArray(locationsForGeocoding)
        );
        progressUpdateCallback(50);
        let locationsBulk = {};
        map(locationsForGeocodingBulk, (location) => {
            locationsBulk[location.id] = location;
        });
        locationsForGeocoding = locationsBulk;
    }

    const wTime = 90,
        wCurrentLocation = 150,
        wNearestAddress = 150,
        wDistance = 70;
    let wOverflow = 0;
    wOverflow += getWOverFlow(includeRealtimeVehicleTime, wTime);
    wOverflow += getWOverFlow(includeRealtimeVehicleCurrentLocation, wCurrentLocation);
    wOverflow += getWOverFlow(includeRealtimeVehicleNearestAddress, wNearestAddress);
    wOverflow += getWOverFlow(includeRealtimeVehicleYesterdayRunning, wNearestAddress);
    wOverflow = floor(
        wOverflow /
            (1 +
                includeRealtimeVehicleTime +
                includeRealtimeVehicleCurrentLocation +
                includeRealtimeVehicleNearestAddress +
                3 * includeRealtimeVehicleYesterdayRunning +
                3 * includeRealtimeVehicleYesterdayFuel +
                includeRealtimeVehicleMonthRunning)
    );

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Sr', 40, footerRow);
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 2, 'Vehicle', 150 + wOverflow, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 2, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 3, 'Number', 150, footerRow);
        noOfCols += addHeader(includeRealtimeVehicleGroups, headers, 4, 'Group', 150, footerRow);
        noOfCols += addHeader(includeRealtimeVehicleDriverNameAndNumber, headers, 5, 'Driver Name', 150, footerRow);
        noOfCols += addHeader(includeRealtimeVehicleDriverNameAndNumber, headers, 6, 'Driver Number', 150, footerRow);
    }

    noOfCols += addHeader(
        includeRealtimeVehicleCurrentLocation,
        headers,
        7,
        'Location',
        wCurrentLocation + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleNearestAddress,
        headers,
        8,
        'Nearest Address',
        wNearestAddress + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeRealtimeVehicleTime, headers, 9, 'Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(
        includeRealtimeVehicleYesterdayRunning,
        headers,
        10,
        'Distance\nYesterday\n(KM)',
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleYesterdayRunning,
        headers,
        11,
        'Running\nYesterday\n(Hrs)',
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleYesterdayRunning,
        headers,
        12,
        'Stoppage\nYesterday\n(Hrs)',
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleYesterdayFuel,
        headers,
        13,
        'Mileage\nYesterday',
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleYesterdayFuel,
        headers,
        14,
        'Fuel\nUsed\nYesterday',
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleYesterdayFuel,
        headers,
        15,
        'Fuel\nWasted\nYesterday',
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeRealtimeVehicleMonthRunning,
        headers,
        16,
        'Distance\nthis\nMonth (KM)',
        wDistance + wOverflow,
        footerRow
    );

    noOfCols += addHeader(includeJobName && reportFormat !== REPORT_FORMAT.PDF, headers, 17, 'Job Name', 0);

    noOfCols += addHeader(true, headers, 18, 'Job Delay', 150, footerRow);
    const data = [];
    map(vehicleList, (vehicle, index) => {
        const name = vehicle.vehicleName;

        const licensePlate = vehicle.vehicleNumber;
        const driverName = vehicle.driverName ? vehicle.driverName : '';
        const driverNumber = vehicle.driverNumber ? vehicle.driverNumber : '';
        const status = VEHICLE_STATUS_NAME[vehicle.currentStatus];
        const time = getReportTime(
            isUnreachable(vehicle.currentStatus) ? vehicle.lastUpdatedAt : vehicle.lastStatusTime
        );
        const geoAddress = getAddressFromGeoCodedLocations(vehicle.vehicleId, geoCodedLocations);
        let address =
            isEngineOn(vehicle.currentStatus) ||
            vehicle.currentStatus === VEHICLE_STATUS.UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.API_UNREACHABLE ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT ||
            vehicle.currentStatus === VEHICLE_STATUS.WIRING_DEFECT_PARKED
                ? geoAddress
                    ? geoAddress
                    : ''
                : vehicle.address
                ? vehicle.address
                : '';
        if (!address && locationsForGeocoding[index] && locationsForGeocoding[index].address) {
            address = locationsForGeocoding[index].address;
        }

        let nearestAddress = '';
        if (includeRealtimeVehicleNearestAddress) {
            const nearestAddressObject = getNearestAddressFromAddressbook(
                vehicle.latitude,
                vehicle.longitude,
                addressBook,
                true
            );
            nearestAddress = get(nearestAddressObject, 'addressText', '');
        }
        const groupName = getGroupName(vehicle.groupId, groupList);

        const dataRow = {};
        addDataRow(true, dataRow, 1, index + 1);
        if (reportFormat === REPORT_FORMAT.PDF) {
            addDataRow(
                true,
                dataRow,
                2,
                `Name: ${name}\nNo: ${licensePlate}${
                    includeRealtimeVehicleGroups ? `\nGroup: ${groupName ? groupName : '-'}` : ''
                }${includeRealtimeVehicleDriverNameAndNumber && driverName ? `\nDriver: ${driverName}` : ''}${
                    includeRealtimeVehicleDriverNameAndNumber && driverNumber ? `\nNo: ${driverNumber}` : ''
                }`
            );
        } else {
            addDataRow(true, dataRow, 2, `${name}`);
            addDataRow(true, dataRow, 3, `${licensePlate}`);
            addDataRow(includeRealtimeVehicleGroups, dataRow, 4, `${groupName ? groupName : '-'}`);
            addDataRow(includeRealtimeVehicleDriverNameAndNumber, dataRow, 5, `${driverName}`);
            addDataRow(includeRealtimeVehicleDriverNameAndNumber, dataRow, 6, `${driverNumber}`);
            addDataRow(includeJobName, dataRow, 17, `${vehicle.jobName}`);
        }
        addDataRow(includeRealtimeVehicleCurrentLocation, dataRow, 7, `${parseAddress(address)}`);
        addDataRow(includeRealtimeVehicleNearestAddress, dataRow, 8, `${parseAddress(nearestAddress)}`);
        addDataRow(includeRealtimeVehicleTime, dataRow, 9, `${time}`);
        addDataRow(
            includeRealtimeVehicleYesterdayRunning,
            dataRow,
            10,
            getTripOdo(get(yesterdayData, `[${vehicle.vehicleId}].totalOdometer`, 0), false)
        );
        addDataRow(
            includeRealtimeVehicleYesterdayRunning,
            dataRow,
            11,
            getTimeDiffHours(
                get(yesterdayData, `[${vehicle.vehicleId}].durationEngineOn`, 0),
                false,
                REPORT_FORMAT.CSV === reportFormat
            )
        );
        addDataRow(
            includeRealtimeVehicleYesterdayRunning,
            dataRow,
            12,
            getTimeDiffHours(
                HOURS_24 - get(yesterdayData, `[${vehicle.vehicleId}].durationEngineOn`, 0),
                false,
                REPORT_FORMAT.CSV === reportFormat
            )
        );
        addDataRow(
            includeRealtimeVehicleYesterdayFuel,
            dataRow,
            13,
            round(get(yesterdayData, `[${vehicle.vehicleId}].mileage`, 0), 2)
        );
        addDataRow(
            includeRealtimeVehicleYesterdayFuel,
            dataRow,
            14,
            round(get(yesterdayData, `[${vehicle.vehicleId}].totalFuelConsumption`, 0), 2)
        );
        addDataRow(
            includeRealtimeVehicleYesterdayFuel,
            dataRow,
            15,
            round(get(yesterdayData, `[${vehicle.vehicleId}].wastedFuel`, 0), 2)
        );
        addDataRow(
            includeRealtimeVehicleMonthRunning,
            dataRow,
            16,
            getTripOdo(get(yesterdayData, `[${monthData.vehicleId}].totalOdometer`, 0))
        );
        addDataRow(true, dataRow, 18, get(yesterdayData, `predictedDelay`, '-'));

        data.push(dataRow);
    });

    //data.push(footerRow);
    progressUpdateCallback(100);
    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Yesterday Summary Report',
        null,
        curTime,
        null,
        reportName,
        noOfCols
    );
}

export async function triggerServiceRemindersReportDownload(
    accessToken,
    reportFormat,
    stateObj,
    vehicleNumber,
    groupName,
    serviceTaskName,
    vehicleListMiniAsMap,
    vehicleList
) {
    const { totalCount, overdue, dueSoon, overdueCount, dueSoonCount, groupId, vehicleId, serviceTaskId } = stateObj;
    let sizeReminders = totalCount;
    if (overdue) {
        sizeReminders = overdueCount;
    } else if (dueSoon) {
        sizeReminders = dueSoonCount;
    }
    if (sizeReminders <= 0) {
        return;
    }
    const result = await fetchServiceReminders(
        accessToken,
        1,
        sizeReminders,
        groupId,
        vehicleId,
        serviceTaskId,
        overdue,
        dueSoon
    ).promise;
    let usersMap = {};

    try {
        const users = await fetchUserListMiniV2(accessToken, null).promise;
        forEach(get(users, 'data', []), (arr) => {
            usersMap[arr[0]] = arr[1] + ' ' + arr[2];
        });
    } catch (e) {
        console.log(e);
    }

    const reportName = createRemindersReportFilename(
        reportFormat,
        'Service-Reminders',
        vehicleNumber,
        groupName,
        serviceTaskName,
        overdue,
        dueSoon
    );
    const headers = [];
    const footerRow = {};

    let noOfCols = 0;
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle', 160, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
        noOfCols += addHeader(true, headers, 'group', 'Group', 150, footerRow);
        noOfCols += addHeader(true, headers, 'vehicleMaker', 'Vehicle Maker', 150, footerRow);
        noOfCols += addHeader(true, headers, 'vehicleModel', 'Model', 150, footerRow);
    }

    noOfCols += addHeader(true, headers, 3, 'Service Task', 180, footerRow);
    noOfCols += addHeader(true, headers, 4, 'Due Date', 160, footerRow);
    noOfCols += addHeader(true, headers, 5, 'Next Due Meter', 160, footerRow);

    noOfCols += addHeader(true, headers, 6, 'Status', 160, footerRow);
    noOfCols += addHeader(true, headers, 7, 'Last Occurred At', 160, footerRow);
    if (reportFormat === REPORT_FORMAT.CSV) {
        noOfCols += addHeader(true, headers, 'createdBy', 'Created By', 160, footerRow);
        noOfCols += addHeader(true, headers, 'createdDate', 'Created Date', 160, footerRow);
        noOfCols += addHeader(true, headers, 'lastModifiedBy', 'Last Modified By', 160, footerRow);
    }

    const data = [];

    const reminders = result.data.content;

    map(
        reminders,
        ({
            vehicleId,
            nextDueAt,
            overdue,
            dueSoon,
            serviceTask,
            meterInterval,
            timeInterval,
            timeFrequency,
            nextDueMeterValue,
            lastOccurredAt,
            createdBy,
            lastModifiedBy,
            createdDate,
        }) => {
            const dataRow = {};
            const vehicle = vehicleListMiniAsMap[vehicleId];
            const vehicleObj = find(vehicleList, { id: +vehicleId }, {});
            const group = get(vehicleObj, 'group.name', '-');
            const vehicleModel = get(vehicleObj, 'vehicleModel.name', '-');
            const vehicleMaker = get(vehicleObj, 'vehicleModel.vehicleMaker.name', '-');

            if (reportFormat === REPORT_FORMAT.PDF) {
                const vehicleModelString = vehicle ? `${vehicle['name']} ${vehicle['licensePlate']}` : '';
                addDataRow(true, dataRow, 1, vehicleModelString);
            } else {
                addDataRow(true, dataRow, 1, get(vehicle, 'name', '-'));
                addDataRow(true, dataRow, 2, get(vehicle, 'licensePlate', '-'));
                addDataRow(true, dataRow, 'group', group);
                addDataRow(true, dataRow, 'vehicleModel', vehicleModel);
                addDataRow(true, dataRow, 'vehicleMaker', vehicleMaker);
            }

            let text;
            if (meterInterval && timeInterval) {
                text = `${timeInterval} ${timeFrequencyText(timeFrequency)} or ${meterInterval}km`;
            } else if (meterInterval) {
                text = `${meterInterval} km`;
            } else {
                text = `${timeInterval} ${timeFrequencyText(timeFrequency)}`;
            }

            addDataRow(true, dataRow, 3, `${serviceTask.name} ${text}`);
            addDataRow(true, dataRow, 4, `${nextDueAt ? getReportTime(nextDueAt) : '-'}`);
            addDataRow(true, dataRow, 5, `${nextDueMeterValue ? round(nextDueMeterValue) + 'km' : '-'}`);

            addDataRow(true, dataRow, 6, `${overdue || dueSoon ? (overdue ? 'Overdue' : 'Due Soon') : '-'}`);
            addDataRow(true, dataRow, 7, `${lastOccurredAt ? getReportTime(lastOccurredAt) : '-'}`);
            if (reportFormat === REPORT_FORMAT.CSV) {
                addDataRow(true, dataRow, 'createdBy', usersMap[createdBy] || '');
                addDataRow(true, dataRow, 'createdDate', createdDate ? getHumanizeTime(createdDate) : createdDate);
                addDataRow(true, dataRow, 'lastModifiedBy', usersMap[lastModifiedBy] || '');
            }

            data.push(dataRow);
        }
    );

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, `-`);
    }
    addDataRow(true, totalRow, 3, `${data.length} Service Tasks`);
    addDataRow(true, totalRow, 4, `-`);
    addDataRow(true, totalRow, 5, `-`);
    addDataRow(true, totalRow, 6, `-`);
    addDataRow(true, totalRow, 7, `-`);

    data.push(totalRow);
    //data.push(footerRow);

    startRemindersReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Service Reminders Report',
        vehicleNumber,
        null,
        reportName,
        noOfCols,
        serviceTaskName
    );
}

export async function triggerAddressBookDownload(
    accessToken,
    reportFormat,
    adresses,
    customerName,
    groupName,
    tags,
    filterConfig,
    category,
    apiCallSize,
    totalApiCall,
    groupId,
    tagIdList,
    searchTerm,
    setDownloadFlag
) {
    let usersList = {};
    try {
        const result = await fetchUserListMini(accessToken).promise;
        usersList = get(result, 'data', '');
    } catch (error) {
        console.log('Error while fetching fetchUserListMini ', error);
    }

    const branchAllowed = isViewBranchAllowedForUser(get(window.FLEETX_LOGGED_IN_DATA, 'scope'));

    let dataForDownload = [];
    for (let i = 0; i < totalApiCall; i++) {
        let result = [];
        try {
            result = await fetchAddressBookPaginated(
                accessToken,
                groupId,
                false,
                category,
                apiCallSize,
                i,
                true,
                tagIdList,
                customerName,
                searchTerm
            ).promise;
        } catch (error) {
            console.log('Error while fetching Address book Data ', error);
        }
        setDownloadFlag(true, ((i + 1) / totalApiCall) * 100);
        const content = get(result, 'data.content', '');
        const allAddresses = map(content, (d) => {
            return {
                ...d,
                customerName: get(d, 'customer.name', ''),
            };
        });
        dataForDownload = concat(dataForDownload, allAddresses);
    }

    let allBranches = [];
    if (branchAllowed) {
        let branches = await fetchBranches(accessToken).promise;
        allBranches = branches.data;
    }
    const reportName = createReportFilename(
        reportFormat,
        'Address-Book',
        customerName,
        groupName,
        null,
        null,
        null,
        null,
        null
    );
    const headers = [];
    const footerRow = {};

    const includeName = get(filterConfig, 'includeName', true);
    const includeLastModifiedBy = get(filterConfig, 'includeLastModifiedBy', true);
    const includeAddress = get(filterConfig, 'includeAddress', true);
    const includeLatitude = get(filterConfig, 'includeLatitude', true);
    const includeLongitude = get(filterConfig, 'includeLongitude', true);
    const includeRadius = get(filterConfig, 'includeRadius', true);
    const includeType = get(filterConfig, 'includeType', true);
    const includeCategory = get(filterConfig, 'includeCategory', true);
    const includeCustomers = get(filterConfig, 'includeCustomers', true);
    const includeTags = get(filterConfig, 'includeTags', true);
    const includeAddressCode = get(filterConfig, 'includeAddressCode', true);
    const includeGroupName = get(filterConfig, 'includeGroupName', true);
    const includeAlertRadiusInKm = get(filterConfig, 'includeAlertRadiusInKm', true);
    const includeRiskTimeThreshold = get(filterConfig, 'includeRiskTimeThreshold', true);
    const includeCustomFields = get(filterConfig, 'includeCustomFields', true);
    const includeCreatedBy = get(filterConfig, 'includeCreatedBy', true);
    const includeLastModifiedAt = get(filterConfig, 'includeLastModifiedAt', true);
    const includeCreatedDate = get(filterConfig, 'includeCreatedDate', true);
    const includeCity = get(filterConfig, 'includeCity', true);
    const includePincode = get(filterConfig, 'includePincode', true);

    const resp = includeCustomFields && (await fetchCustomFieldsForAccount(accessToken).promise);
    let allCustomFields = get(resp, 'data.customFields');
    const allMandatoryFields = filter(allCustomFields, (customField) => {
        return customField.type === CUSTOM_ACCOUNT_FIELD_TYPE.ADDRESS_BOOK;
    });
    let noOfCols = 0;
    noOfCols += addHeader(includeName, headers, 'name', 'Name', 140, footerRow);
    noOfCols += addHeader(includeAddress, headers, 'address', 'Address', 200, footerRow);
    noOfCols += addHeader(includeRadius, headers, 'radius', 'Radius (meters)', 140, footerRow);

    noOfCols += addHeader(includeType, headers, 'type', 'Type', 80, footerRow);
    noOfCols += addHeader(includeCategory, headers, 'category', 'Category', 120, footerRow);
    noOfCols += addHeader(includeCustomers, headers, 'customer', 'Customer', 120, footerRow);
    noOfCols += addHeader(true, headers, 'controllOffice', 'Control Office', 120, footerRow);
    noOfCols += addHeader(includeTags, headers, 'tags', 'Tags', 200, footerRow);

    noOfCols += addHeader(includeAddressCode, headers, 'code', 'Address Book Code', 140, footerRow);
    noOfCols += addHeader(includeGroupName, headers, 'groupName', 'Group', 140, footerRow);
    noOfCols += addHeader(includeAlertRadiusInKm, headers, 'alertRadiusInKm', 'Alert Radius', 140, footerRow);
    noOfCols += addHeader(
        includeRiskTimeThreshold,
        headers,
        'riskTimeThreshold',
        'Risk Time Threshold',
        140,
        footerRow
    );

    noOfCols += addHeader(includeLatitude, headers, 'latitude', 'Latitude', 140, footerRow);
    noOfCols += addHeader(includeLongitude, headers, 'longitude', 'Longitude', 140, footerRow);

    if (includeCustomFields) {
        allMandatoryFields.forEach((item) => {
            noOfCols += addHeader(true, headers, `custom-${item.id}`, get(item, 'keyName', ''), 150, footerRow);
        });
    }
    noOfCols += addHeader(includeCreatedBy, headers, 'createdBy', 'Created By', 140, footerRow);
    noOfCols += addHeader(includeCreatedDate, headers, 'createdDate', 'Created Date', 140, footerRow);
    noOfCols += addHeader(includeLastModifiedBy, headers, 'lastModifiedBy', 'Last Modified By', 80, footerRow);
    noOfCols += addHeader(includeLastModifiedAt, headers, 'lastModifiedAt', 'Last Modified At', 140, footerRow);
    noOfCols += addHeader(includeCity, headers, 'city', 'City', 140, footerRow);
    noOfCols += addHeader(includePincode, headers, 'pinCode', 'Pincode', 140, footerRow);
    noOfCols += addHeader(true, headers, 'fuelPrice', 'Fuel Price', 80, footerRow);
    noOfCols += addHeader(true, headers, 'zone', 'Zone', 80, footerRow);
    const data = [];
    map(dataForDownload, (address) => {
        const dataRow = {};
        addDataRow(includeName, dataRow, 'name', get(address, 'name', '-'));
        addDataRow(includeAddress, dataRow, 'address', get(address, 'address', '-'));
        addDataRow(includeRadius, dataRow, 'radius', get(address, 'radius', '-'));
        addDataRow(includeType, dataRow, 'type', get(address, 'addressBookType', '-'));
        addDataRow(includeCategory, dataRow, 'category', get(address, 'addressBookCategory', '-'));
        addDataRow(includeTags, dataRow, 'customer', get(address, 'customerName', '-'));

        let branch = find(allBranches, { id: address.controllingBranchId });
        addDataRow(true, dataRow, 'controllOffice', get(branch, 'officeName', '-'));

        addDataRow(
            includeCustomFields,
            dataRow,
            'tags',
            getTagNamesList(tags, get(address, 'tagIdsList', [])).join(',')
        );

        addDataRow(includeAddressCode, dataRow, 'code', get(address, 'code', '-'));
        addDataRow(includeGroupName, dataRow, 'groupName', get(address, 'group.name', '-'));
        addDataRow(includeAlertRadiusInKm, dataRow, 'alertRadiusInKm', get(address, 'alertRadiusInKm', '-'));
        addDataRow(
            includeRiskTimeThreshold,
            dataRow,
            'riskTimeThreshold',
            get(address, 'riskTimeThreshold') ? get(address, 'riskTimeThreshold', '0') / (60 * 1000) : '0'
        );

        addDataRow(includeLatitude, dataRow, 'latitude', get(address, 'latitude', '-'));
        addDataRow(includeLongitude, dataRow, 'longitude', get(address, 'longitude', '-'));

        allMandatoryFields.forEach((item) => {
            addDataRow(true, dataRow, `custom-${item.id}`, '-');
        });
        const customFields = get(address, 'customFields', []);
        customFields.forEach((item) => {
            addDataRow(includeCustomFields, dataRow, `custom-${item.fieldId}`, item.value);
        });
        addDataRow(
            includeCreatedBy,
            dataRow,
            'createdBy',
            getUserNameByUserId(usersList, get(address, 'createdBy', ''))
        );
        addDataRow(
            includeCreatedDate,
            dataRow,
            'createdDate',
            get(address, 'createdDate') ? getReportTime(get(address, 'createdDate')) : ''
        );
        addDataRow(includeCity, dataRow, 'city', get(address, 'city'));
        addDataRow(includePincode, dataRow, 'pinCode', get(address, 'pinCode'));
        addDataRow(true, dataRow, 'fuelPrice', get(address, 'fuelPrice', '-'));
        let zone = get(address, 'zone');
        addDataRow(true, dataRow, 'zone', zone ? 'Yes' : 'No');
        addDataRow(
            includeLastModifiedBy,
            dataRow,
            'lastModifiedBy',
            getUserNameByUserId(usersList, get(address, 'lastModifiedBy', '-'))
        );
        addDataRow(includeLastModifiedAt, dataRow, 'lastModifiedAt', getReportTime(get(address, 'lastModifiedDate')));

        data.push(dataRow);
    });
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
        // fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: '99CCFF' },
        // },
    };
    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Address Book',
        null,
        null,
        null,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        false,
        '',
        headerStyles
    );
    setDownloadFlag(false, 100);
}

export async function triggerVehicleRenewalsReportDownload(
    accessToken,
    reportFormat,
    stateObj,
    groupName,
    vehicleNumber,
    vehicleRenewalTypeName,
    vehicleListMiniAsMap
) {
    const {
        totalCount,
        overdueCount,
        dueSoonCount,
        groupId,
        vehicleId,
        vehicleRenewalTypeId,
        vehicleTagId,
        selectedSummaryItem,
        vehicleRenewalType,
        overdue,
        dueSoon,
    } = stateObj;
    let sizeReminders = totalCount;
    if (overdue) {
        sizeReminders = overdueCount;
    } else if (dueSoon) {
        sizeReminders = dueSoonCount;
    }
    const result = await fetchVehicleRenewalReminders(
        accessToken,
        1,
        sizeReminders,
        groupId,
        vehicleId,
        vehicleRenewalType,
        overdue,
        dueSoon,
        null,
        vehicleTagId
    ).promise;

    let usersMap = {};
    try {
        const users = await fetchUserListMiniV2(accessToken, null).promise;
        forEach(get(users, 'data', []), (arr) => {
            usersMap[arr[0]] = arr[1] + ' ' + arr[2];
        });
    } catch (e) {
        console.log(e);
    }

    const reportName = createRemindersReportFilename(
        reportFormat,
        'Vehicle-Renewals',
        vehicleNumber,
        groupName,
        vehicleRenewalTypeName,
        overdue,
        dueSoon
    );
    if (sizeReminders <= 0) {
        return;
    }

    const headers = [];
    const footerRow = {};

    let noOfCols = 0;
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle', 160, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
    }

    noOfCols += addHeader(true, headers, 3, 'Renewal Type', 180, footerRow);
    noOfCols += addHeader(true, headers, 4, 'Due Date', 160, footerRow);
    noOfCols += addHeader(true, headers, 5, 'Status', 160, footerRow);
    noOfCols += addHeader(true, headers, 'createdBy', 'Created By', 160, footerRow);
    noOfCols += addHeader(true, headers, 'createdDate', 'Created Date', 160, footerRow);
    noOfCols += addHeader(true, headers, 'lastModifiedBy', 'Last Modified By', 160, footerRow);

    const data = [];

    const reminders = result.data.content;

    map(
        reminders,
        ({ vehicleId, nextDueAt, vehicleRenewalType, overdue, dueSoon, createdBy, lastModifiedBy, createdDate }) => {
            const dataRow = {};
            const vehicle = vehicleListMiniAsMap[vehicleId];

            if (reportFormat === REPORT_FORMAT.PDF) {
                const vehicleModelString = vehicle ? `${vehicle['name']} ${vehicle['licensePlate']}` : '';
                addDataRow(true, dataRow, 1, vehicleModelString);
            } else {
                addDataRow(true, dataRow, 1, get(vehicle, 'name', ''));
                addDataRow(true, dataRow, 2, get(vehicle, 'licensePlate', ''));
            }

            addDataRow(true, dataRow, 3, `${vehicleRenewalType.name}`);
            addDataRow(true, dataRow, 4, `${getReportTime(nextDueAt)}`);
            addDataRow(true, dataRow, 5, `${overdue || dueSoon ? (overdue ? 'Overdue' : 'Due Soon') : '-'}`);
            addDataRow(true, dataRow, 'createdBy', usersMap[createdBy] || '');
            addDataRow(true, dataRow, 'createdDate', createdDate ? getHumanizeTime(createdDate) : createdDate);
            addDataRow(true, dataRow, 'lastModifiedBy', usersMap[lastModifiedBy] || '');
            data.push(dataRow);
        }
    );

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, `-`);
    }
    addDataRow(true, totalRow, 3, `${data.length} Renewals`);
    addDataRow(true, totalRow, 4, `-`);
    addDataRow(true, totalRow, 5, `-`);

    data.push(totalRow);
    //data.push(footerRow);

    startRemindersReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Vehicle Renewals Report',
        vehicleNumber,
        null,
        reportName,
        noOfCols,
        vehicleRenewalTypeName
    );
}

export async function triggerContactRenewalsReportDownload(
    accessToken,
    reportFormat,
    stateObj,
    groupName,
    userName,
    contactRenewalTypeName,
    userList
) {
    const {
        totalCount,
        overdue,
        dueSoon,
        overdueCount,
        dueSoonCount,
        groupId,
        userId,
        contactRenewalTypeId,
    } = stateObj;

    let sizeReminders = totalCount;
    if (overdue) {
        sizeReminders = overdueCount;
    } else if (dueSoon) {
        sizeReminders = dueSoonCount;
    }

    if (sizeReminders <= 0) {
        return;
    }

    const result = await fetchContactRenewalReminders(
        accessToken,
        1,
        sizeReminders,
        groupId,
        userId,
        contactRenewalTypeId,
        overdue,
        dueSoon
    ).promise;

    const reportName = createRemindersReportFilename(
        reportFormat,
        'Contact-Renewals',
        userName,
        groupName,
        contactRenewalTypeName,
        overdue,
        dueSoon
    );

    const headers = [];
    const footerRow = {};

    let noOfCols = 0;

    noOfCols += addHeader(true, headers, 1, 'User', 160, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Renewal Type', 180, footerRow);
    noOfCols += addHeader(true, headers, 3, 'Due Date', 160, footerRow);
    noOfCols += addHeader(true, headers, 4, 'Status', 160, footerRow);
    const data = [];

    const reminders = result.data.content;

    map(reminders, ({ userId, nextDueAt, contactRenewalType, overdue, dueSoon }) => {
        const dataRow = {};
        const user = find(userList, (u) => {
            return u[0] === userId;
        });
        const name = user ? `${user[1]} ${user[2]}` : '';
        addDataRow(true, dataRow, 1, `${name}`);
        addDataRow(true, dataRow, 2, `${contactRenewalType.name}`);
        addDataRow(true, dataRow, 3, `${getReportTime(nextDueAt)}`);
        addDataRow(true, dataRow, 4, `${overdue || dueSoon ? (overdue ? 'Overdue' : 'Due Soon') : '-'}`);
        data.push(dataRow);
    });

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL`);
    addDataRow(true, totalRow, 2, `${data.length} Renewals`);
    addDataRow(true, totalRow, 3, `-`);
    addDataRow(true, totalRow, 4, `-`);

    data.push(totalRow);
    //data.push(footerRow);

    startRemindersReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Contact Renewals Report',
        null,
        userName,
        reportName,
        noOfCols,
        contactRenewalTypeName
    );
}

function createRemindersReportFilename(reportFormat, reportName, entityName, groupName, type, overdue, dueSoon) {
    const entityNameQuery = !isEmpty(entityName) ? '-' + entityName.replace(/ /g, '-') : '';
    const groupNameQuery = !isEmpty(groupName) ? '-' + groupName.replace(/ /g, '-') : '';
    const typeQuery = type ? '-' + type.replace(/ /g, '-') : '';
    const overDueDueSoonText = overdue || dueSoon ? (overdue ? '-overdue' : '-due-soon') : '';
    return `${reportName}${overDueDueSoonText}-Report${entityNameQuery}${groupNameQuery}${typeQuery}.${reportFormat}`;
}

function startRemindersReportCreation(
    orientation,
    headers,
    data,
    reportFormat,
    title,
    vehicleNumber,
    userName,
    reportName,
    noOfCols,
    reminderType,
    subTitle
) {
    if (reportFormat === REPORT_FORMAT.PDF) {
        createPDF(orientation, (doc) => {
            const hDate = 80,
                top = 90;
            insertTitle(doc, title, top);
            let y = top + 30;

            if (!isEmpty(vehicleNumber)) {
                insertSubTitle(doc, `Vehicle : ${vehicleNumber}`, y, 'left');
                y += 20;
            }
            if (!isEmpty(userName)) {
                insertSubTitle(doc, `User : ${userName}`, y, 'left');
                y += 20;
            }
            if (!isEmpty(subTitle)) {
                insertSubTitle(doc, `${subTitle}`, y, 'left');
                y += 20;
            }
            if (!isEmpty(reminderType)) {
                insertSubTitle(doc, `Reminder : ${reminderType}`, y, 'left');
                y += 20;
            }

            y = y > top + hDate ? y : top + hDate;

            //hack for headers
            const headerConfig = {
                printHeaders: true,
                autoSize: false,
                margins: { left: 0, top: PAGE_MARGIN_X, bottom: 0 },
                fontSize: 12,
            };
            doc.table(PAGE_MARGIN_X, y, [], headers, headerConfig);
            const dataConfig = {
                printHeaders: false,
                autoSize: false,
                margins: { left: 0, top: PAGE_MARGIN_X, bottom: 0 },
                fontSize: 10,
            };
            doc.table(PAGE_MARGIN_X, (y += 30), data, headers, dataConfig);

            doc.save(reportName);
        });
    } else if (reportFormat === REPORT_FORMAT.CSV) {
        //(which is just a placeholder for xlsx format )

        createExcel('fleetx.io', (workbook) => {
            createExcelFromData(workbook, headers, data, title, null, null, null, reportName, subTitle);
        });
    } else {
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += insertCsvTitle(title, noOfCols);
        if (!isEmpty(reminderType)) {
            csvContent += insertCsvSubTitleRow(`Reminder : ${reminderType}`, noOfCols);
        }
        csvContent += insertCsvSubTitleRow('', noOfCols);
        csvContent += insertCsvHeaders(headers);

        map(data, (d) => {
            csvContent += insertCsvData(d, noOfCols);
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', reportName);
        document.body.appendChild(link); // Required for FF

        link.click();
    }
}

export async function triggerFuelLogReportDownload(
    accessToken,
    reportFormat,
    filterConfig,
    stateObj,
    groupName,
    vehicleNumber,
    vehicleListMiniAsMap,
    loggedInUser
) {
    const params = {
        vehicleId: stateObj.vehicleId,
        groupId: stateObj.groupId,
        vendorId: stateObj.vendorId,
        from: stateObj.startDate ? stateObj.startDate.valueOf() : null,
        to: stateObj.endDate ? stateObj.endDate.valueOf() : getEndDate().valueOf(),
        sortByDateAsc: false,
        vehicleDetails: true,
    };

    const result = await searchFuelEntries(accessToken, params, 1, stateObj.size).promise;

    let usersMap = {};

    try {
        const users = await fetchUserListMiniV2(accessToken, null).promise;
        forEach(get(users, 'data', []), (arr) => {
            usersMap[arr[0]] = arr[1] + ' ' + arr[2];
        });
    } catch (e) {
        console.log(e);
    }

    const reportName = createReportFilename(
        reportFormat,
        'Fuel-Log',
        vehicleNumber,
        groupName,
        stateObj.startDate,
        stateObj.endDate
    );

    const headers = [];
    const footerRow = {};

    const includeDate = get(filterConfig, 'includeDate', true);
    const includeOdometer = get(filterConfig, 'includeOdometer', true);
    const includeUsage = get(filterConfig, 'includeUsage', true);
    const includeVolume = get(filterConfig, 'includeVolume', true);
    const includePricePerUnit = get(filterConfig, 'includePricePerUnit', true);
    const includeAmount = get(filterConfig, 'includeAmount', true);
    const includeKmPerUnit = get(filterConfig, 'includeKmPerUnit', true);
    const includeCostPerKm = get(filterConfig, 'includeCostPerKm', true);
    const includeVendor = get(filterConfig, 'includeVendor', true);
    const includeReference = get(filterConfig, 'includeReference', true);
    const includePartial = get(filterConfig, 'includePartial', true);
    const includeReset = get(filterConfig, 'includeReset', true);
    const includeVehicleType = get(filterConfig, 'includeVehicleType', true);
    const includeDriverName = get(filterConfig, 'includeDriverName', true);
    const includeMileage = get(filterConfig, 'includeMileage', true);
    const includePrevOdometer = get(filterConfig, 'includePrevOdometer', true);
    const includePresentOdometer = get(filterConfig, 'includePresentOdometer', true);
    const includeRemarks = get(filterConfig, 'includeRemarks', true);

    const wVehicle = 100,
        wDate = 100,
        wOdometer = 80,
        wUsage = 70,
        wVolume = 70,
        wPriceUnit = 90,
        wAmount = 80,
        wKmPerUnit = 90,
        wCostPerKm = 70,
        wVendor = 70,
        wReference = 100;

    const isCSV = reportFormat === REPORT_FORMAT.CSV;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeDate, wDate);
    wOverflow += getWOverFlow(includeOdometer, wOdometer);
    wOverflow += getWOverFlow(includeUsage, wUsage);
    wOverflow += getWOverFlow(includeVolume, wVolume);
    wOverflow += getWOverFlow(includeAmount, wPriceUnit);
    wOverflow += getWOverFlow(includeAmount, wAmount);
    wOverflow += getWOverFlow(includeKmPerUnit, wKmPerUnit);
    wOverflow += getWOverFlow(includeCostPerKm, wCostPerKm);
    wOverflow += getWOverFlow(includeVendor, wVendor);
    wOverflow += getWOverFlow(includeReference, wReference);

    wOverflow = floor(
        wOverflow /
            (1 +
                includeDate +
                includeOdometer +
                includeUsage +
                includeVolume +
                includePricePerUnit +
                includeKmPerUnit +
                includeCostPerKm +
                wVendor +
                wReference)
    );
    let noOfCols = 0;
    if (!isCSV) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle', wVehicle + wOverflow, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
    }
    noOfCols += addHeader(includeVehicleType, headers, 'vehicleType', 'Vehicle Type', 150, footerRow);
    noOfCols += addHeader(includeDriverName, headers, 'driverName', 'Driver Name', 150, footerRow);
    noOfCols += addHeader(includeDriverName, headers, 'driverNumber', 'Driver Number', 150, footerRow);
    noOfCols += addHeader(includeMileage, headers, 'mileage', 'Preferred Mileage', 150, footerRow);

    noOfCols += addHeader(includeDate, headers, 3, 'Date', wDate + wOverflow, footerRow);
    noOfCols += addHeader(includePrevOdometer, headers, 'prevOdometer', 'Previous Odometer Reading', 150, footerRow);
    noOfCols += addHeader(
        includePresentOdometer,
        headers,
        'presentOdometer',
        'Present Odometer Reading',
        150,
        footerRow
    );
    noOfCols += addHeader(
        includeOdometer,
        headers,
        4,
        `Odometer Reading${isCSV ? ' (km)' : ''}`,
        wOdometer + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeUsage,
        headers,
        5,
        `Usage Odometer${isCSV ? ' (km)' : ''}`,
        wUsage + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeVolume,
        headers,
        6,
        `Volume${isCSV ? ' (L or Kg)' : ''}`,
        wVolume + wOverflow,
        footerRow
    );
    noOfCols += addHeader(true, headers, 7, `Fuel Type`, 90, footerRow);
    noOfCols += addHeader(
        includePricePerUnit,
        headers,
        8,
        `Price/unit${isCSV ? ` (${getCurrencyFromCountry(loggedInUser)}/unit)` : ''}`,
        wVolume + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeAmount,
        headers,
        9,
        `Amounts${isCSV ? ` (${getCurrencyFromCountry(loggedInUser)})` : ''}`,
        wAmount + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeKmPerUnit, headers, 10, `km/unit(Odometer)`, wKmPerUnit + wOverflow, footerRow);
    noOfCols += addHeader(
        includeCostPerKm,
        headers,
        11,
        `Cost/km${isCSV ? ` (${getCurrencyFromCountry(loggedInUser)}/km)` : ''}`,
        wCostPerKm + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeVendor, headers, 12, `Vendor`, wVendor + wOverflow, footerRow);
    noOfCols += addHeader(includeReference, headers, 13, 'Reference', wReference + wOverflow, footerRow);
    noOfCols += addHeader(includePartial, headers, 'partialFuel', 'Partial Fuel', wReference + wOverflow, footerRow);
    noOfCols += addHeader(includeReset, headers, 'resetUsage', 'Reset Usage', wReference + wOverflow, footerRow);
    noOfCols += addHeader(includeRemarks, headers, 'remarks', 'Remarks', 150, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleMaker', 'Vehicle Maker', 150, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleModel', 'Vehicle Model', 150, footerRow);
    noOfCols += addHeader(true, headers, 'vehicleSize', 'Vehicle Size', 150, footerRow);
    noOfCols += addHeader(true, headers, 'chargingStart', 'Charging Start Time', 150, footerRow);
    noOfCols += addHeader(true, headers, 'chargingEnd', 'Charging End Time', 150, footerRow);
    noOfCols += addHeader(true, headers, 'batteryStart', 'Battery Start(%)', 150, footerRow);
    noOfCols += addHeader(true, headers, 'batteryEnd', 'Battery End(%)', 150, footerRow);
    noOfCols += addHeader(true, headers, 'kWHConsumed', 'kWH Consumed', 150, footerRow);
    noOfCols += addHeader(true, headers, 'costPerUnit', 'Cost/Unit', 150, footerRow);
    noOfCols += addHeader(true, headers, 'totalCost', 'Total Cost', 150, footerRow);

    noOfCols += addHeader(true, headers, 'createdBy', 'Created By', 160, footerRow);
    noOfCols += addHeader(true, headers, 'createdDate', 'createdDate', 160, footerRow);
    noOfCols += addHeader(true, headers, 'lastModifiedBy', 'Last Modified By', 160, footerRow);

    const data = [];
    const fuelEntries = result.data.content;
    let sumTotalAmount = 0;

    map(
        fuelEntries,
        ({
            vehicleId,
            date,
            meterEntry,
            usageInKm,
            liters,
            kilograms,
            totalAmount,
            totalCalculatedAmount,
            pricePerVolumeUnit,
            kpl,
            kpkg,
            fuelType,
            reference,
            vendor,
            partial,
            reset,
            previousOdometerReading,
            presentOdometerReading,
            vehicleType,
            driverName,
            preferredMileage,
            remarks,
            chargingStartTime,
            chargingEndTime,
            costPerUnit,
            unitConsumed,
            startBatteryPercentage,
            endBatteryPercentage,
            driver,
            createdDate,
            createdBy,
            lastModifiedBy,
        }) => {
            const dataRow = {};
            const vehicle = vehicleListMiniAsMap[vehicleId];
            if (!isCSV) {
                const vehicleModelString = vehicle ? `${vehicle['name']} ${vehicle['licensePlate']}` : '';
                addDataRow(true, dataRow, 1, vehicleModelString);
            } else {
                addDataRow(true, dataRow, 1, get(vehicle, 'name', '-'));
                addDataRow(true, dataRow, 2, get(vehicle, 'licensePlate', '-'));
            }

            const usageText = usageInKm > 0 ? `${round(usageInKm, 2)}${!isCSV ? ' km' : ''}` : '';

            let volumeText = '';
            let pricePerVolumeText = '';

            if (liters > 0) {
                volumeText = `${round(liters, 2)} ${!isCSV ? ' L' : ''}`;
                pricePerVolumeText =
                    round(pricePerVolumeUnit, 2) + `${!isCSV ? ` ${getCurrencyFromCountry(loggedInUser)}/L` : ''}`;
            } else {
                volumeText = `${round(kilograms, 2)} ${!isCSV ? ' kg' : ''}`;
                pricePerVolumeText =
                    round(pricePerVolumeUnit, 2) + `${!isCSV ? ` ${getCurrencyFromCountry(loggedInUser)}/Kg` : ''}`;
            }

            let kmPerUnit = '';
            const accountId = get(loggedInUser, 'accountId');
            const fuelUnits = getFuelUnitsForAccount(accountId);
            if (kpl) {
                kmPerUnit = round(kpl, 2) + `${!isCSV ? ` ${get(fuelUnits, 'OTHERS.unit')}` : ''}`;
            } else if (kpkg) {
                kmPerUnit = round(kpkg, 2) + `${!isCSV ? ` ${get(fuelUnits, 'CNG.unit')}` : ''}`;
            }

            let costPerkm = '';
            if (usageInKm > 0) {
                costPerkm =
                    round(totalAmount / usageInKm, 2) + `${!isCSV ? ` ${getCurrencyFromCountry(loggedInUser)}` : ''}`;
            }

            addDataRow(includeDate, dataRow, 3, `${getReportTime(date)}`);
            addDataRow(includeOdometer, dataRow, 4, `${formatOdometer(meterEntry.value)} ${!isCSV ? ' km' : ''}`);
            addDataRow(includeUsage, dataRow, 5, usageText);
            addDataRow(includeVolume, dataRow, 6, volumeText);

            addDataRow(includeVehicleType, dataRow, 'vehicleType', vehicleType);
            addDataRow(includeDriverName, dataRow, 'driverName', driverName || driver?.firstName || '');
            addDataRow(includeDriverName, dataRow, 'driverNumber', driver?.phoneNumber || '');
            addDataRow(includeMileage, dataRow, 'mileage', preferredMileage);
            addDataRow(includePrevOdometer, dataRow, 'prevOdometer', formatOdometer(previousOdometerReading));
            addDataRow(includePresentOdometer, dataRow, 'presentOdometer', formatOdometer(presentOdometerReading));
            addDataRow(includeRemarks, dataRow, 'remarks', remarks);

            addDataRow(true, dataRow, 7, getFuelName(fuelType, loggedInUser));
            addDataRow(includePricePerUnit, dataRow, 8, pricePerVolumeText);
            addDataRow(
                includeAmount,
                dataRow,
                9,
                round(totalCalculatedAmount, 2) + `${!isCSV ? ` ${getCurrencyFromCountry(loggedInUser)}` : ''}`
            );
            addDataRow(includeKmPerUnit, dataRow, 10, kmPerUnit);
            addDataRow(includeCostPerKm, dataRow, 11, costPerkm);
            addDataRow(includeKmPerUnit, dataRow, 12, vendor ? vendor.name : '');
            addDataRow(includeCostPerKm, dataRow, 13, reference ? reference : '');
            addDataRow(includePartial, dataRow, 'partialFuel', partial);
            addDataRow(includeReset, dataRow, 'resetUsage', reset);
            addDataRow(true, dataRow, 'vehicleMaker', get(vehicle, 'vehicleModel.vehicleMaker.name', '-'));
            addDataRow(true, dataRow, 'vehicleModel', get(vehicle, 'vehicleModel.name', '-'));
            addDataRow(true, dataRow, 'vehicleSize', get(vehicle, 'vehicleSize', '-'));
            addDataRow(true, dataRow, 'chargingStart', chargingStartTime || '-');
            addDataRow(true, dataRow, 'chargingEnd', chargingEndTime || '-');
            addDataRow(true, dataRow, 'batteryStart', startBatteryPercentage || '-');
            addDataRow(true, dataRow, 'batteryEnd', endBatteryPercentage || '-');
            addDataRow(true, dataRow, 'kWHConsumed', unitConsumed || '-');
            addDataRow(true, dataRow, 'costPerUnit', costPerUnit || '-');
            addDataRow(true, dataRow, 'totalCost', round(unitConsumed * costPerUnit, 2) || '-');

            addDataRow(true, dataRow, 'createdBy', usersMap[createdBy] || '');
            addDataRow(true, dataRow, 'createdDate', createdDate ? getHumanizeTime(createdDate) : createdDate);
            addDataRow(true, dataRow, 'lastModifiedBy', usersMap[lastModifiedBy] || '');

            sumTotalAmount += totalCalculatedAmount;

            data.push(dataRow);
        }
    );

    const totalRow = {};

    addDataRow(true, totalRow, 1, `TOTAL \n${data.length} Fuel Log`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, `-`);
    }
    addDataRow(includeDate, totalRow, 3, `-`);
    addDataRow(includeOdometer, totalRow, 4, `-`);
    addDataRow(includeUsage, totalRow, 5, `-`);
    addDataRow(includeVolume, totalRow, 6, `-`);
    addDataRow(true, totalRow, 7, `-`);
    addDataRow(includePricePerUnit, totalRow, 8, `-`);
    addDataRow(
        includeAmount,
        totalRow,
        9,
        round(sumTotalAmount, 2) + `${!isCSV ? ` ${getCurrencyFromCountry(loggedInUser)}` : ''}`
    );
    addDataRow(includeKmPerUnit, totalRow, 10, `-`);
    addDataRow(includeCostPerKm, totalRow, 11, `-`);
    addDataRow(includeVendor, totalRow, 12, `-`);
    addDataRow(includeReference, totalRow, 13, `-`);
    addDataRow(includePartial, totalRow, 'partialFuel', `-`);
    addDataRow(includeReset, totalRow, 'resetUsage', `-`);

    data.push(totalRow);
    //data.push(footerRow);

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Fuel Log Report',
        stateObj.startDate,
        stateObj.endDate,
        vehicleNumber,
        reportName,
        noOfCols
    );
}

export async function triggerAnyArrayCSVReport(array, reportTitle, config, paramConfig = {}) {
    if (!array || array.length <= 0) {
        return;
    }
    const { startDate, endDate, licensePlate, groupName } = paramConfig || {};
    const curTime = getMomentTime();
    const reportName = createReportFilename(
        REPORT_FORMAT.CSV,
        reportTitle,
        licensePlate,
        groupName,
        startDate,
        endDate || curTime
    );
    const headers = [];
    const footerRow = {};

    const allKeys = keys(config || first(array));
    let noOfCols = allKeys.length + 1;
    addHeader(true, headers, '1', 'No.', 150, footerRow);
    map(allKeys, (key, index) => {
        if (config) {
            if (config[key]) {
                addHeader(true, headers, index + 2, config[key], 150, footerRow);
            }
        } else {
            addHeader(true, headers, index + 2, key, 150, footerRow);
        }
    });
    const data = [];
    map(array, (item, index) => {
        const dataRow = {};
        addDataRow(true, dataRow, '1', index + 1);
        map(allKeys, (key, index) => {
            addDataRow(true, dataRow, index + 2, item[key]);
        });
        data.push(dataRow);
    });

    const totalRow = {};
    data.push(totalRow);
    //data.push(footerRow);

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        REPORT_FORMAT.CSV,
        reportTitle,
        null,
        curTime,
        null,
        reportName,
        noOfCols,
        null,
        null,
        null,
        true
    );
}

export function triggerTimeOnSiteVehicleReportDownload(reportFormat, sitesData, vehicleNumber, fromDate, toDate) {
    if (!sitesData || sitesData.length <= 0) {
        return;
    }
    const reportName = createReportFilename(reportFormat, 'TimeOnSite', vehicleNumber, null, fromDate, toDate);

    //fieldsTobeIncluded
    const headers = [
        { name: 'addressType', prompt: 'Type', width: 100 },
        { name: 'name', prompt: 'Name', width: 100 },
        { name: 'address', prompt: 'Address', width: 210 },
        { name: 'numberOfVisits', prompt: 'No Of Visits', width: 100 },
        { name: 'totalTime', prompt: 'Time spent on Address', width: 180 },
        { name: 'latLon', prompt: 'Latitude/Longitude', width: 180 },
        { name: 'vehicleList', prompt: 'Vehicles', width: 480 },
    ];
    let noOfCols = 4;

    const data = [];
    let name = '';
    let address = '';
    let numberOfVisits = '';
    let totalTime = '';
    let addressType = '';
    //prepare data
    map(sitesData, (site) => {
        name = site['addressBookName'] ? site['addressBookName'] : '-';
        address = site['address'];
        numberOfVisits = site['numberOfVisits'];
        totalTime = getDurationString(site['totalTime']);
        addressType = site['addressBookName'] ? 'known' : 'unknown';
        const vehicleStoppageDataDTO = get(site, 'vehicleStoppageDataDTO', []);
        const vehicleNames = map(vehicleStoppageDataDTO, (v) => {
            return v.vehicleNumber;
        });
        const vehicleNamesAsString = join(vehicleNames, ',');
        data.push({
            addressType,
            name,
            address,
            numberOfVisits,
            totalTime,
            latLon: site['latitude'] + ', ' + site['longitude'],
            vehicleList: vehicleNamesAsString,
        });
    });

    //total row
    data.push({
        addressType: 'TOTAL ' + data.length,
        name: '',
        address: '',
        noOfVisit: '',
        timeOnSite: '',
        latLon: '',
    });
    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Time On Site',
        fromDate,
        toDate,
        vehicleNumber,
        reportName,
        noOfCols
    );
}

export function triggerOnSiteAnalyticsReportDownload({
    reportFormat,
    vehiclesOnSiteList,
    vehicleNumber,
    fromDate,
    toDate,
    siteName,
    filterConfig,
    commentList,
    vehicleListMiniAsMap,
    tagsAsMap,
}) {
    if (!vehiclesOnSiteList || vehiclesOnSiteList.length <= 0) {
        return;
    }
    const reportName = createReportFilename(
        reportFormat,
        `${siteName} Site Analytics`,
        vehicleNumber,
        null,
        fromDate,
        toDate
    );

    let noOfCols = 0;
    let data = [];
    let includeInTime = get(filterConfig, 'includeInTime', true);
    let includeOutTime = get(filterConfig, 'includeOutTime', true);
    let includeDuration = get(filterConfig, 'includeDuration', true);
    let includeComment = get(filterConfig, 'includeComment', true);
    let includedCount = 1 + includeInTime + includeOutTime + includeDuration + includeComment;
    let eachWidth = 690 / includedCount;

    let headers = [];

    noOfCols += addHeader(true, headers, 'vehicleNumber', 'Vehicle', eachWidth);
    noOfCols += addHeader(true, headers, 'vehicleName', 'Vehicle Name', eachWidth);
    noOfCols += addHeader(true, headers, 'siteName', 'Site', eachWidth);
    noOfCols += addHeader(includeInTime, headers, 'startTime', 'In Time', eachWidth);
    noOfCols += addHeader(includeOutTime, headers, 'endTime', 'Out Time', eachWidth);
    noOfCols += addHeader(includeDuration, headers, 'duration', 'Duration', eachWidth);
    noOfCols += addHeader(includeComment, headers, 'comment', 'Comment', eachWidth);
    noOfCols += addHeader(true, headers, 'groupName', 'Group Name', eachWidth);
    noOfCols += addHeader(true, headers, 'transporterName', 'Transporter Name', eachWidth);
    noOfCols += addHeader(true, headers, 'vehicleTags', 'Vehicle Tags', eachWidth);

    let vehicle;

    map(vehiclesOnSiteList, (v) => {
        let comment = '';
        if (includeComment) {
            const commentForRow = (commentList || []).find((c) => c.commentableId === v.tripId);
            comment = get(commentForRow, 'comment', '');
        }
        vehicle = get(vehicleListMiniAsMap, v.vehicleId, {});
        let vehicleTagsString = join(
            map(get(vehicle, 'tagIdsSet', []), (el) => get(get(tagsAsMap, el, {}), 'name', '-')),
            ', '
        );

        data.push({
            vehicleNumber: v.vehicleNumber,
            startTime: includeInTime && v.startTime ? getReportTime(v.startTime) : '',
            endTime: includeOutTime && v.endTime ? getReportTime(v.endTime) : '',
            siteName: get(v, 'siteName', '-'),
            duration: includeDuration
                ? getTimeDiffHours(
                      getMomentTime(v.endTime).diff(getMomentTime(v.startTime)),
                      false,
                      REPORT_FORMAT.CSV === reportFormat
                  )
                : '',
            comment,
            groupName: get(v, 'groupName', '-'),
            transporterName: get(v, 'transporterName', '-'),
            vehicleName: get(vehicle, 'name', '-'),
            vehicleTags: vehicleTagsString || '-',
        });
    });
    const totalRow = {};
    forEach(headers, (header) => {
        totalRow[header.name] = ' ';
    });
    totalRow['vehicleNumber'] = 'TOTAL ' + data.length;
    //total row
    data.push(totalRow);

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Site Analytics',
        fromDate,
        toDate,
        vehicleNumber,
        reportName,
        noOfCols
    );
}

export function triggerPaymentsSummaryReportDownload() {}

export function triggerClientsPaymentsSummaryReportDownload(
    reportFormat,
    paymentList,
    clientName,
    fromDate,
    toDate,
    filterConfig,
    totals
) {
    if (!paymentList || paymentList.length <= 0) {
        return;
    }

    const reportName = createReportFilename(
        reportFormat,
        `${clientName} payments summary`,
        null,
        null,
        fromDate,
        toDate
    );

    let noOfCols = 0;
    let data = [];
    let headers = [];

    //total w=1015  for landscape
    let adviceAmountW = 160,
        paymentDateW = 150,
        settledAmountW = 160,
        unSettledAmountW = 160,
        clientNameW = 180,
        statusW = 85,
        paymentTypeW = 120;

    let includeClientName = get(filterConfig, 'includeClientName', true);
    let includeUnsettledAmount = get(filterConfig, 'includeUnsettledAmount', true);
    let includeSettledAmount = get(filterConfig, 'includeSettledAmount', true);
    let includeStatus = get(filterConfig, 'includeStatus', true);

    let includedColCount = 3 + includeClientName + includeUnsettledAmount + includeSettledAmount + includeStatus;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeClientName, clientNameW);
    wOverflow += getWOverFlow(includeUnsettledAmount, unSettledAmountW);
    wOverflow += getWOverFlow(includeSettledAmount, settledAmountW);
    wOverflow += getWOverFlow(includeStatus, statusW);

    wOverflow = floor(wOverflow / includedColCount);

    noOfCols += addHeader(true, headers, 'paymentDate', 'Payment Date', wOverflow + paymentDateW);
    noOfCols += addHeader(includeClientName, headers, 'clientName', 'Client Name', wOverflow + clientNameW);
    noOfCols += addHeader(true, headers, 'type', 'Payment Type', wOverflow + paymentTypeW);
    noOfCols += addHeader(true, headers, 'totalAmount', 'Advice Amount', wOverflow + adviceAmountW);
    noOfCols += addHeader(includeSettledAmount, headers, 'settledAmount', 'Settled Amount', wOverflow + settledAmountW);
    noOfCols += addHeader(
        includeUnsettledAmount,
        headers,
        'UnSettledAmount',
        'UnSettled Amount',
        wOverflow + unSettledAmountW
    );
    noOfCols += addHeader(includeStatus, headers, 'status', 'Status', wOverflow + statusW);

    map(paymentList, (payment) => {
        data.push({
            totalAmount: payment.totalAmount,
            paymentDate: getHumanizeTime(payment.paymentDate),
            clientName: get(payment, 'customer.name', '-'),
            UnSettledAmount: toSafeInteger(payment.totalAmount - payment.settledAmount),
            settledAmount: payment.settledAmount,
            status: payment.status,
            type: payment.type || '-',
        });
    });

    //total row:

    data.push({
        paymentDate: `TOTAL  ${data.length}`,
        clientName: '',
        totalAmount: totals.totalOnAcPayment,
        UnSettledAmount: totals.totalUnSettledAmount,
        settledAmount: totals.totalSettledAmount,
        status: '',
        type: '',
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Payments Summary report',
        fromDate,
        toDate,
        null,
        reportName,
        noOfCols
    );
}

export async function triggerInvoicesSummaryReportDownload(
    reportFormat,
    clientName,
    group,
    fromDate,
    toDate,
    licensePlate,
    status,
    filterConfig,
    accessToken,
    vehicleId,
    customerId,
    size,
    groupId,
    invoiceNumber,
    comment
) {
    let invoicesList = [];
    try {
        const result = await fetchInvoices(
            accessToken,
            fromDate,
            toDate,
            groupId,
            vehicleId,
            customerId,
            status,
            '',
            1,
            size,
            invoiceNumber,
            comment
        ).promise;
        invoicesList = get(result, 'data.content', []);
    } catch (e) {}

    const statusText = status ? status : '';
    const reportName = createReportFilename(
        reportFormat,
        `${clientName} ${statusText} invoices summary`,
        licensePlate,
        group,
        fromDate,
        toDate
    );

    let noOfCols = 0;
    let data = [];
    let headers = [];

    //total w=1015  for landscape
    let invoiceAmountW = 120,
        invoicePeriodW = 210,
        invoiceNumberW = 110,
        commentW = 160,
        taxableAmountW = 120,
        PaidAmountW = 110,
        clientNameW = 160,
        statusW = 85,
        taxAmountW = 100;

    const includeClientName = get(filterConfig, 'includeClientName', true);
    const includeStatus = get(filterConfig, 'includeStatus', true);
    const includePaidAmount = get(filterConfig, 'includePaidAmount', true);

    const includedColCount = 4 + includeClientName + includeStatus + includePaidAmount;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeClientName, clientNameW);
    wOverflow += getWOverFlow(includeStatus, statusW);
    wOverflow += getWOverFlow(includePaidAmount, PaidAmountW);

    wOverflow = floor(wOverflow / includedColCount);

    noOfCols += addHeader(true, headers, 'invoiceNumber', 'Invoice Number', invoiceNumberW + wOverflow);
    noOfCols += addHeader(includeClientName, headers, 'clientName', 'Client Name', clientNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'invoicePeriod', 'Invoice Period', invoicePeriodW + wOverflow);
    noOfCols += addHeader(true, headers, 'amount', 'Taxable Amount', taxableAmountW + wOverflow);
    noOfCols += addHeader(true, headers, 'totalAmount', 'Invoice Amount', invoiceAmountW + wOverflow);
    noOfCols += addHeader(true, headers, 'taxAmount', 'Tax Amount', taxAmountW + wOverflow);
    noOfCols += addHeader(includePaidAmount, headers, 'paidAmount', 'Paid Amount', PaidAmountW + wOverflow);
    noOfCols += addHeader(includeStatus, headers, 'status', 'Status', statusW + wOverflow);
    noOfCols += addHeader(true, headers, 'comment', 'Comment', commentW + wOverflow);

    let sumAmount = 0,
        sumTotalAmount = 0,
        sumTaxAmount = 0,
        sumPaidAmount = 0;

    map(invoicesList, (invoice) => {
        sumAmount += invoice.amount;
        sumTotalAmount += invoice.totalAmount;
        sumPaidAmount += invoice.paidAmount;
        sumTaxAmount += toSafeInteger(invoice.totalAmount - invoice.amount);
        data.push({
            clientName: get(invoice, 'customer.name', ''),
            invoiceNumber: invoice.invoiceNumber,
            comment: invoice.comment,
            invoicePeriod: `${getHumanizeTime(invoice.startPeriod)} - ${getHumanizeTime(invoice.endPeriod)} `,
            amount: invoice.amount,
            totalAmount: invoice.totalAmount,
            paidAmount: invoice.paidAmount,
            status: invoice.status,
            taxAmount: toSafeInteger(invoice.totalAmount - invoice.amount),
            purchaseOrderDate: invoice.purchaseOrderDate ? getReportTime(invoice.purchaseOrderDate) : '',
            purchaseOrderNo: invoice.purchaseOrderNo || '',
        });
    });

    //total row:

    data.push({
        clientName: '',
        invoiceNumber: `TOTAL  ${data.length}`,
        comment: '',
        invoicePeriod: '',
        amount: sumAmount,
        totalAmount: sumTotalAmount,
        paidAmount: sumPaidAmount,
        status: '',
        taxAmount: sumTaxAmount,
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Invoices Summary report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols
    );
}

export async function triggerTransactionReportDownload(
    reportFormat,
    transList,
    fromDate,
    toDate,
    vehicleId,
    driverId,
    filterConfig,
    vehicleList,
    accesstoken,
    userListMap
) {
    if (!transList || transList.length <= 0) {
        return;
    }
    const isCsv = reportFormat === REPORT_FORMAT.CSV;

    let licensePlate = '',
        driverName = '';
    if (vehicleId) {
        licensePlate = get(transList[0], 'vehicle.licensePlate', get(transList[0], 'vehicle.vehicleNumber', ''));
    }

    if (driverId) {
        driverName = get(transList[0], 'driver.name', '');
    }

    const reportName = createReportFilename(
        reportFormat,
        `${driverName} Transaction`,
        licensePlate,
        null,
        fromDate,
        toDate
    );

    let noOfCols = 0;
    let data = [];
    let headers = [];

    //total w=1015  for landscape

    let ledgers = [];
    try {
        const result = await fetchLedgers(accesstoken).promise;
        ledgers = get(result, 'data');
        ledgers = keyBy(ledgers, 'id');
    } catch (e) {
        handleError(e);
    }

    let vehicleNameW = 130,
        driverNameW = 130,
        jobNameW = 140,
        groupNameW = 130,
        requestTransactionIdW = 130,
        transactionDateW = 120,
        expenseTypeW = 110,
        expenseNameW = 110,
        transactionTypeW = 130,
        amountW = 120,
        amountAsPerGovW = 120,
        tollCrossedW = 120,
        reverseTripW = 120,
        cardNumberW = 130,
        pairIdW = 120,
        modeOfPaymentW = 120,
        descriptionW = 120,
        statusW = 110,
        commentsW = 140,
        creditLedger = 120,
        debitLedger = 120,
        vendor = 120;

    const includeDriverName = get(filterConfig, 'includeDriverName', true);
    const includeExpenseType = get(filterConfig, 'includeExpenseType', true);
    const includeExpenseName = get(filterConfig, 'includeExpenseName', true);
    const includeTransactionType = get(filterConfig, 'includeTransactionType', true);
    const includeGroupName = get(filterConfig, 'includeGroupName', true);
    const includeCardNumber = get(filterConfig, 'includeCardNumber', true);
    const includePairId = get(filterConfig, 'includePairId', true);
    const includeAmountAsPerGovt = get(filterConfig, 'includeAmountAsPerGovt', true);
    const includeReverseTrip = get(filterConfig, 'includeReverseTrip', false);

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeDriverName, driverNameW);
    wOverflow += getWOverFlow(includeExpenseType, expenseTypeW);
    wOverflow += getWOverFlow(includeExpenseName, expenseNameW);
    wOverflow += getWOverFlow(includeTransactionType, transactionDateW);
    wOverflow += getWOverFlow(includeCardNumber, cardNumberW);
    wOverflow += getWOverFlow(includePairId, pairIdW);
    let includedColCount = 6 + includeDriverName + includeExpenseType + includeTransactionType + includePairId;
    wOverflow = floor(wOverflow / includedColCount);

    noOfCols += addHeader(true, headers, 'vehicle', 'Vehicle', vehicleNameW + wOverflow);
    noOfCols += addHeader(includeDriverName, headers, 'driverName', 'Driver', driverNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'job', 'Job', jobNameW + wOverflow);
    noOfCols += addHeader(includeGroupName, headers, 'group', 'Group', groupNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'requestTransactionId', 'Reference No.', requestTransactionIdW + wOverflow);
    noOfCols += addHeader(true, headers, 'transactionDate', 'Transaction Date', transactionDateW + wOverflow);
    noOfCols += addHeader(includeExpenseType, headers, 'expenseType', 'Expense Type', expenseTypeW + wOverflow);
    noOfCols += addHeader(includeExpenseName, headers, 'expenseName', 'Expense Name', expenseNameW + wOverflow);

    if (isCsv) {
        noOfCols += addHeader(includeExpenseType, headers, 'fuelQuantity', 'Fuel Quantity', expenseTypeW + wOverflow);
        noOfCols += addHeader(
            includeExpenseType,
            headers,
            'fuelPricePerLitre',
            'Fuel Rate(l or kg)',
            expenseTypeW + wOverflow
        );
    }

    noOfCols += addHeader(true, headers, 'amount', 'Amount', amountW + wOverflow);
    noOfCols += addHeader(true, headers, 'modeOfPayment', 'Mode of Payment', modeOfPaymentW + wOverflow);
    noOfCols += addHeader(includeCardNumber, headers, 'cardId', 'Card Number/E-Voucher', cardNumberW + wOverflow);
    noOfCols += addHeader(includePairId, headers, 'pairId', 'Pair Id', cardNumberW + wOverflow);
    noOfCols += addHeader(
        includeTransactionType,
        headers,
        'transactionType',
        'Transaction Type',
        transactionDateW + wOverflow
    );
    noOfCols += addHeader(
        includeAmountAsPerGovt,
        headers,
        'amountAsPerGovt',
        'Amount As Per Govt',
        amountAsPerGovW + wOverflow
    );
    noOfCols += addHeader(includeAmountAsPerGovt, headers, 'tollCrossed', 'Toll Crossed', tollCrossedW + wOverflow);
    noOfCols += addHeader(includeReverseTrip, headers, 'reverseTrip', 'Reverse Trip', reverseTripW + wOverflow);
    noOfCols += addHeader(true, headers, 'description', 'Description', descriptionW + wOverflow);
    noOfCols += addHeader(true, headers, 'status', 'Status', statusW + wOverflow);
    noOfCols += addHeader(true, headers, 'comments', 'Comments', commentsW + wOverflow);
    noOfCols += addHeader(true, headers, 'debitLedger', 'Debit Ledger', debitLedger + wOverflow);
    noOfCols += addHeader(true, headers, 'creditLedger', 'Credit Ledger', creditLedger + wOverflow);
    noOfCols += addHeader(true, headers, 'vendor', 'Vendor', vendor + wOverflow);
    noOfCols += addHeader(true, headers, 'createdBy', 'Created By', jobNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'lastModifiedBy', 'Last Modified By', jobNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'createdDate', 'Created Date', jobNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'lastModifiedDate', 'Last Modified Date', jobNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'stationaryNumber', 'Stationary Number', jobNameW + wOverflow);
    noOfCols += addHeader(true, headers, 'tripSheetStationaryNumber', 'Trip Sheet Number', jobNameW + wOverflow);

    let totalAmount = 0;
    map(transList, (t) => {
        const id = get(t, 'vehicle.id');
        const vehicleObj = find(vehicleList, { id: +id }, {});
        const group = get(vehicleObj, 'group.name', '-');
        const createdByUser = get(userListMap, `${get(t, 'createdBy')}`, []);
        const lastModifiedByUser = get(userListMap, `${get(t, 'lastModifiedBy')}`, []);
        const createdDate = t.createdDate ? getHumanizeTime(t.createdDate) : '';
        const lastModifiedDate = t.lastModifiedDate ? getHumanizeTime(t.lastModifiedDate) : '';

        data.push({
            vehicle: get(t, 'vehicle.licensePlate', ''),
            driverName: `${get(t, 'driver.firstName', '')} ${get(t, 'driver.lastName')}`,
            job: get(t, 'job.name', ''),
            group,
            requestTransactionId: get(t, 'requestTransactionId', ''),
            transactionDate: getMomentTime(t.transactionDate).format(DATE_FORMAT_REPORT),
            expenseType: t.expenseType ? t.expenseType : '',
            expenseName: get(t, 'transactionExpenseType.expenseName', ''),
            amount: t.amount ? t.amount : '',
            modeOfPayment: t.channel ? t.channel : '',
            transactionType: t.transactionType ? t.transactionType : '',
            cardId: t.cardId ? t.cardId : '',
            pairId: t.pairId ? t.pairId : '',
            amountAsPerGovt: t.amountAsPerGovt ? t.amountAsPerGovt : '-',
            tollCrossed: t.tollCrossed ? 'Yes' : 'No',
            reverseTrip: t.reverseTrip ? 'Yes' : 'No',
            fuelPricePerLitre:
                t.expenseType === 'Fuel' ||
                t.expenseType === EXPENSE_TYPES.FUEL_ADVANCE ||
                t.expenseType === EXPENSE_TYPES.FUEL_EXPENSE
                    ? get(t, 'fuelPricePerLitre', '-')
                    : '-',
            fuelQuantity:
                t.expenseType === 'Fuel' ||
                t.expenseType === EXPENSE_TYPES.FUEL_ADVANCE ||
                t.expenseType === EXPENSE_TYPES.FUEL_EXPENSE
                    ? get(t, 'fuelQuantity', '-')
                    : '-',
            description: t.description ? t.description : '',
            status: get(t, 'status', ''),
            comments: get(t, 'comments', ''),
            debitLedger: get(ledgers, `${get(t, 'debitLedgerId')}.ledgerName`),
            creditLedger: get(ledgers, `${get(t, 'creditLedgerId')}.ledgerName`),
            vendor: get(t, 'vendor.name'),
            createdBy: `${get(createdByUser, '[1]', '')} ${get(createdByUser, '[2]', '')}`,
            lastModifiedBy: `${get(lastModifiedByUser, '[1]', '')} ${get(lastModifiedByUser, '[2]', '')}`,
            createdDate,
            lastModifiedDate,
            stationaryNumber: get(t, 'stationaryNumber'),
            tripSheetStationaryNumber: get(t, 'tripSheetStationaryNumber'),
        });
        totalAmount += t.amount ? t.amount : 0;
    });

    const tt = {};

    forEach(headers, (h) => {
        tt[h.name] = '';
    });
    //total row
    data.push({
        ...tt,
        vehicle: `TOTAL  ${data.length}`,
        driverName: ` `,
        job: '',
        group: '',
        requestTransactionId: '',
        transactionDate: '',
        expenseType: '',
        amount: totalAmount,
        transactionType: '',
        cardId: '',
        status: '',
        comments: '',
        createdDate: '',
        lastModifiedDate: '',
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Transaction Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        '', // border Styles for all cells
        null,
        null, // object containg startCol, endCol, text, alignment, font for a merged cell
        '', // header styles containing font, alignment, fill
        null, // disable automatic column width,
        null,
        '',
        null,
        null,
        null,
        null,
        null,
        null,
        true
    );
}

export async function triggerBVCTransactionReportDownload(
    reportFormat,
    transList,
    fromDate,
    toDate,
    vehicleId,
    driverId,
    filterConfig,
    vehicleList,
    accesstoken
) {
    if (!transList || transList.length <= 0) {
        return;
    }

    const resp = await fetchAccountVehicleAnalytics(accesstoken, fromDate, getEndDate(toDate).valueOf()).promise;
    const vehicleAnalyticsData = get(resp, 'data', []);

    let licensePlate = '',
        driverName = '';
    if (vehicleId) {
        licensePlate = get(transList[0], 'vehicle.licensePlate', get(transList[0], 'vehicle.vehicleNumber', ''));
    }

    if (driverId) {
        driverName = get(transList[0], 'driver.name', '');
    }

    const reportName = createReportFilename(reportFormat, `BVC Transaction`, licensePlate, null, fromDate, toDate);

    let noOfCols = 0;
    let data = [];
    let headers = [];

    //total w=1015  for landscape

    let vehicleNameW = 120,
        barnchW = 120,
        makeW = 120,
        ageW = 120,
        totalKmW = 120,
        totalAmountW = 120,
        totalFuelW = 120,
        avgPerKMCostW = 120,
        avgPerLtrCostW = 120;

    noOfCols += addHeader(true, headers, 'branch', 'Branch', barnchW);
    noOfCols += addHeader(true, headers, 'vehicle', 'Registration', vehicleNameW);
    noOfCols += addHeader(true, headers, 'vehicleMaker', 'Make Of Vehicle', makeW);
    noOfCols += addHeader(true, headers, 'ageOfVehicle', 'Age Of Vehicle', ageW);
    noOfCols += addHeader(true, headers, 'totalKm', 'Total KM', totalKmW);
    noOfCols += addHeader(true, headers, 'totalTransactionValue', 'Total Amount', totalAmountW);
    noOfCols += addHeader(true, headers, 'totalFuel', 'Total Fuel (l)', totalFuelW);
    noOfCols += addHeader(true, headers, 'avgPerKMCost', 'Avg per km cost', avgPerKMCostW);
    noOfCols += addHeader(true, headers, 'avgPerLtrCost', 'Avg per ltr cost', avgPerLtrCostW);
    const aggregatedList = {};
    map(transList, (t) => {
        const vehicleId = get(t, 'vehicle.id');
        let totalTransactionValue = get(aggregatedList[vehicleId], 'totalTransactionValue', 0) + t.amount;
        const id = get(t, 'vehicle.id');
        const vehicleObj = find(vehicleList, { id: +id }, {});
        const branchName = get(vehicleObj, 'group.name', '-');
        const registrationDate = get(vehicleObj, 'registrationDate');
        const ageOfVehicle = registrationDate
            ? round(
                  getDurationAsYears(getMomentTime().valueOf() - getMomentTime(registrationDate).valueOf(), 'asYears'),
                  2
              )
            : '-';
        const vehicleAnalytics = find(vehicleAnalyticsData, { vehicleId: +id });
        const totalKm = get(vehicleAnalytics, 'totalOdometer');
        const totalFuel = get(vehicleAnalytics, 'totalFuelConsumption');
        const pushedData = find(data, { id: +vehicleId });
        aggregatedList[`${vehicleId}`] = {
            id: vehicleId,
            totalTransactionValue,
        };
        const avgPerKMCost = totalKm ? round(totalTransactionValue / totalKm, 2) : 0;
        const avgPerLtrCost = totalKm && totalFuel ? round(totalKm / totalFuel, 2) : 0;
        if (!pushedData) {
            data.push({
                id: vehicleId,
                branch: branchName,
                vehicle: get(t, 'vehicle.licensePlate', '-'),
                vehicleMaker: get(vehicleObj, 'vehicleModel.name', '-'),
                ageOfVehicle,
                totalKm: totalKm,
                totalTransactionValue: aggregatedList[`${vehicleId}`].totalTransactionValue,
                totalFuel: totalFuel,
                avgPerKMCost: avgPerKMCost,
                avgPerLtrCost: avgPerLtrCost,
            });
        } else {
            pushedData.totalTransactionValue = aggregatedList[`${vehicleId}`].totalTransactionValue;
            pushedData.avgPerKMCost = avgPerKMCost;
            pushedData.avgPerLtrCost = avgPerLtrCost;
        }
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'BVC Transaction Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols
    );
}

export function startJobReportCreation(
    orientation,
    headers,
    data,
    reportFormat,
    title,
    fromDate,
    toDate,
    licensePlate,
    reportName,
    noOfCols,
    driverName,
    driverNumber,
    centerAllCSVHeaders
) {
    if (reportFormat === REPORT_FORMAT.PDF) {
        createPDF(orientation, (doc) => {
            const hDate = 80,
                top = 90;
            insertTitle(doc, title, top);
            let y = top + 30;
            insertSubTitle(
                doc,
                `${fromDate ? `${`${getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT)}`}` : ''}${
                    fromDate && (toDate || fromDate) ? '\nto              \n' : ''
                }${toDate || fromDate ? getEndDate(toDate).format(DATE_FORMAT_HUMANIZE_REPORT) : ''}`,
                y,
                'right'
            );
            if (!isEmpty(licensePlate)) {
                insertSubTitle(doc, `Vehicle : ${licensePlate}`, y, 'left');
                y += 20;
            }
            if (!isEmpty(driverName)) {
                insertSubTitle(doc, `Driver Name : ${driverName}`, y, 'left');
                y += 20;
            }
            if (!isEmpty(driverNumber)) {
                insertSubTitle(doc, `Driver Number : ${driverNumber}`, y, 'left');
                y += 20;
            }

            y = y > top + hDate ? y : top + hDate;

            //hack for headers
            const headerConfig = {
                printHeaders: true,
                autoSize: false,
                margins: { left: 0, top: PAGE_MARGIN_X, bottom: 0 },
                fontSize: 12,
            };
            doc.table(PAGE_MARGIN_X, y, [], headers, headerConfig);
            const dataConfig = {
                printHeaders: false,
                autoSize: false,
                margins: { left: 0, top: PAGE_MARGIN_X, bottom: 0 },
                fontSize: 10,
            };
            doc.table(PAGE_MARGIN_X, (y += 30), data, headers, dataConfig);

            doc.save(reportName);
        });
    } else if (reportFormat === REPORT_FORMAT.CSV) {
        //(which is just a placeholder for xlsx format )

        createExcel('fleetx.io', (workbook) => {
            createExcelFromData(workbook, headers, data, title, fromDate, toDate, licensePlate, reportName);
        });
    } else {
        let csvContent = '';
        if (window.navigator && window.navigator.msSaveBlob) {
            csvContent = '';
        } else {
            csvContent = 'data:text/csv;charset=utf-8,';
        }
        csvContent += insertCsvTitle(title, noOfCols);
        if (centerAllCSVHeaders) {
            //Insert an empty row
            csvContent += insertCsvSubTitleRow('', noOfCols);
        }
        if (!centerAllCSVHeaders) {
            csvContent += insertCsvNameAndTime(licensePlate, fromDate, toDate, noOfCols);
        } else {
            csvContent += insertCsvTitle(licensePlate, noOfCols);
            csvContent += insertCsvTitle(
                `${getMomentTime(fromDate).format(DATE_FORMAT_HUMANIZE_REPORT)} to ${getEndDate(toDate).format(
                    DATE_FORMAT_HUMANIZE_REPORT
                )}`,
                noOfCols
            );
        }

        if (!isEmpty(driverName)) {
            if (!centerAllCSVHeaders) {
                csvContent += insertCsvSubTitleRow(`Driver Name : ${driverName}`, noOfCols);
            } else {
                csvContent += insertCsvTitle(`Driver Name : ${driverName}`, noOfCols);
            }
        }

        if (!isEmpty(driverNumber)) {
            if (!centerAllCSVHeaders) {
                csvContent += insertCsvSubTitleRow(`Driver Number : ${driverNumber}`, noOfCols);
            } else {
                csvContent += insertCsvTitle(`Driver Number : ${driverNumber}`, noOfCols);
            }
        }
        //Insert an empty row
        csvContent += insertCsvSubTitleRow('', noOfCols);
        csvContent += insertCsvHeaders(headers);

        map(data, (d) => {
            csvContent += insertCsvData(d, noOfCols);
        });

        const encodedUri = encodeURI(csvContent);

        //////////////////////////////////////////
        if (window.navigator && window.navigator.msSaveBlob) {
            // IE 10+
            // https://stackoverflow.com/a/44869314/1472869
            //window.navigator.msSaveBlob(blob, filename);
            // type: 'application/octet-stream;charset=utf-8'
            return navigator.msSaveBlob(
                new Blob(['\ufeff', csvContent], {
                    type: 'text/csv;charset=utf-8;',
                }),
                reportName
            );

            // var IEwindow = window.open();
            // IEwindow.document.write('sep=,\r\n' + data);
            // IEwindow.document.close();
            // IEwindow.document.execCommand('SaveAs', true, filename);
            // IEwindow.close();
        } else {
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', reportName);
            document.body.appendChild(link); // Required for FF
            link.click();
        }
    }
    ////////////////////////////////////////
}

/**
 * Returns userName by mapping against Id.
 *
 * @param {Array} userList The array contains the user details.
 * @param {Number} id The Id of the user.
 * @return {String} userName corresponding to the Id inside userList.
 */

export function getUserNameByUserId(userList, Id) {
    let userName = '';

    forEach(userList, function (userDetail) {
        const id = userDetail[0];
        const firstName = isEmpty(userDetail[1]) ? '' : userDetail[1];
        const lastName = isEmpty(userDetail[2]) ? '' : userDetail[2];
        if (id === Id) {
            userName = `${firstName} ${lastName}`;
        }
    });

    return userName;
}

export function getCodeFromUserList(userList, Id) {
    let userCode = '';

    forEach(userList, function (userDetail) {
        const id = userDetail[0];
        if (id === Id) {
            userCode = userDetail[4] ? userDetail[4] : '';
        }
    });

    return userCode;
}

export function getGPSBoxStatusData(status) {
    let text = getLabel(GPS_BOX_STATUS_LABEL, status);
    switch (status) {
        case GPS_BOX_STATUS.NOT_INSTALLED:
        case GPS_BOX_STATUS.DISCONNECTED:
        case GPS_BOX_STATUS.REMOVED:
        case GPS_BOX_STATUS.UNREACHABLE:
            break;
        default:
            text = 'Working';
            break;
    }
    return text;
}

export const SIM_CONSENT_STATUS_LABEL = {
    PENDING: 'Pending',
    NOTALLOWED: 'Not Allowed',
    ALLOWED: 'Allowed',
};

export const SIM_BASED_STATUS = {
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    PARKED: 'PARKED',
    UNREACHABLE: 'UNREACHABLE',
    REMOVED: 'REMOVED',
    NOT_INSTALLED: 'NOT_INSTALLED',
};

export const SIM_CONSENT_STATUS = {
    PENDING: 'PENDING',
    NOTALLOWED: 'NOT_ALLOWED',
    ALLOWED: 'ALLOWED',
};

export function getDriverAppStatusData(status) {
    let text = getLabel(DRIVER_APP_STATUS_LABEL, status);
    switch (status) {
        case DRIVER_APP_STATUS.NOT_AVAILABLE:
        case DRIVER_APP_STATUS.DISCONNECTED:
        case DRIVER_APP_STATUS.REMOVED:
        case DRIVER_APP_STATUS.UNREACHABLE:
            break;
        default:
            text = 'Working';
            break;
    }
    return text;
}

export function getSimBasedStatusData(status, simConsentStatus) {
    let text = getLabel(SIM_BASED_STATUS_LABEL, status);
    switch (status) {
        case SIM_BASED_STATUS.NOT_AVAILABLE:
        case SIM_BASED_STATUS.REMOVED:
        case SIM_BASED_STATUS.UNREACHABLE:
        case SIM_BASED_STATUS.NOT_INSTALLED:
            break;
        default:
            text = 'Working';
            break;
    }
    return text;
}

/** Determined this value based upon experimentation */
export const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;

export function excelAutoColumnWidth(sheet, fromRow) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return;
    }

    const maxColumnLengths = [];
    sheet.eachRow((row, rowNum) => {
        if (rowNum < fromRow) {
            return;
        }

        row.eachCell((cell, num) => {
            if (typeof cell.value === 'string') {
                if (maxColumnLengths[num] === undefined) {
                    maxColumnLengths[num] = 0;
                }
                const fontSize = cell.font && cell.font.size ? cell.font.size : 11;
                ctx.font = `${fontSize}pt Arial`;
                const metrics = ctx.measureText(cell.value);
                const cellWidth = metrics.width;

                maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth);
            }
        });
    });

    for (let i = 1; i <= sheet.columnCount; i++) {
        const col = sheet.getColumn(i);
        const width = maxColumnLengths[i];
        if (width) {
            col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 2;
        }
    }
}

export const GET_EXPENSE_DATA_FROM = {
    'TRANSACTIONS': 'transactions',
    'WITHOUT_TRIPSHEET_JOBS': 'withoutTripsheetJobs',
    'WITH_TRIPSHEET_JOBS': 'withTripsheetJobs',
};

export const GET_ODOMETER_BASED_KM = {
    'WITH_KM': 'withKm',
    'WITHOUT_KM': 'withoutKm',
};
