//TODO: Remove duplicate config
const tyreLifeCycleEventsMapping = {
    NEW: 'NEW',
    REMOULD: 'REMOULD',
    SENT_TO_REMOULD: 'SENT_TO_REMOULD',
    SENT_TO_CLAIM: 'SENT_TO_CLAIM',
    STOLEN: 'STOLEN',
    SCRAPPED: 'SCRAPPED',
    IN_TRANSIT_BETWEEN_STORES: 'IN_TRANSIT_BETWEEN_STORES',
    CLAIMED: 'CLAIMED',
    SENT_TO_REPAIR: 'SENT_TO_REPAIR',
};
export const allLifeCycleEvents = [
    'NEW',
    'REMOULD',
    'SENT_TO_REMOULD',
    'SENT_TO_CLAIM',
    'STOLEN',
    'SCRAPPED',
    'IN_TRANSIT_BETWEEN_STORES',
    'CLAIMED',
    'SENT_TO_REPAIR',
];
export function getFieldKeyByNature(nature) {
    const { STOLEN, SCRAPPED, NEW, SENT_TO_CLAIM, SENT_TO_REMOULD, REMOULD, IN_TRANSIT_BETWEEN_STORES, CLAIMED, SENT_TO_REPAIR, } = tyreLifeCycleEventsMapping;
    const fieldsMapping = {
        // INWARD EVENTS
        'TYRE_MRN': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            tyreFields: [
                'tyreNo',
                'type',
                'productionDate',
                'modelId',
                'brandId',
                'amount',
                'kmIssued',
                'openingKm',
                'plyRating',
                'treadDepth',
                'size',
                'rfTag',
                'remarks',
                'eventTime',
                'psi',
                'tags',
                'discount',
                'igstAmount',
                'cgstAmount',
                'sgstAmount',
                'tyreImages',
            ],
        },
        'CHASSIS_MRN': {
            globalFields: [
                'mrnDate',
                'storeId',
                'purchaseDate',
                'poNumber',
                'poDate',
                'billNumber',
                'chasisNo',
                'vendorId',
            ],
            tyreFields: [
                'tyreNo',
                'type',
                'productionDate',
                'modelId',
                'brandId',
                'amount',
                'kmIssued',
                'openingKm',
                'plyRating',
                'treadDepth',
                'size',
                'rfTag',
                'remarks',
                'eventTime',
                'psi',
                'tags',
                'tyreDTOIssueAndRemovalRequest',
                'discount',
                'igstAmount',
                'cgstAmount',
                'sgstAmount',
                'tyreImages',
            ],
        },
        'REMOULD_TYRE_MRN': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            tyreFields: [
                'tyre',
                'type',
                'productionDate',
                'modelId',
                'brandId',
                'extraAmount',
                'currentAmount',
                'kmIssued',
                'openingKm',
                'plyRating',
                'treadDepth',
                'size',
                'remarks',
                'eventTime',
                'psi',
                'tags',
                'discount',
                'igstAmount',
                'cgstAmount',
                'sgstAmount',
                'tyreImages',
            ],
        },
        'REPAIR_TYRE_MRN': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            tyreFields: [
                'tyre',
                'type',
                'productionDate',
                'modelId',
                'brandId',
                'extraAmount',
                'currentAmount',
                'kmIssued',
                'openingKm',
                'plyRating',
                'treadDepth',
                'size',
                'remarks',
                'eventTime',
                'psi',
                'tags',
                'discount',
                'igstAmount',
                'cgstAmount',
                'sgstAmount',
                'tyreImages',
            ],
        },
        'TYRE_AGAINST_CLAIM_MRN': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            tyreFields: [
                'tyre',
                'claimedTyreNo',
                'type',
                'productionDate',
                'modelId',
                'brandId',
                'extraAmount',
                'currentAmount',
                'kmIssued',
                'openingKm',
                'plyRating',
                'treadDepth',
                'size',
                'remarks',
                'eventTime',
                'psi',
                'tags',
                'discount',
                'igstAmount',
                'cgstAmount',
                'sgstAmount',
                'tyreImages',
            ],
        },
        'TYRE_STOCK_IN': {
            globalFields: ['mrnDate', 'inwardStoreId', 'outwardStoreId', 'stockOutStationaryNumber'],
            tyreFields: [
                'tyre',
                'type',
                'productionDate',
                'modelId',
                'brandId',
                'currentAmount',
                'kmIssued',
                'openingKm',
                'plyRating',
                'treadDepth',
                'size',
                'remarks',
                'eventTime',
                'psi',
                'tags',
                'discount',
                'igstAmount',
                'cgstAmount',
                'sgstAmount',
                'tyreImages',
            ],
        },
        // OUTWARD EVENTS
        'SENT_TO_REMOULD': {
            globalFields: ['mrnDate', 'storeId', 'vendorId'],
            tyreFields: ['tyre', 'brandId', 'modelId', 'currentAmount', 'eventTime', 'psi', 'tags'],
            stateToSkip: [STOLEN, SCRAPPED, IN_TRANSIT_BETWEEN_STORES, SENT_TO_CLAIM, SENT_TO_REMOULD, SENT_TO_REPAIR],
        },
        'SENT_TO_REPAIR': {
            globalFields: ['mrnDate', 'storeId', 'vendorId'],
            tyreFields: ['tyre', 'brandId', 'modelId', 'currentAmount', 'eventTime', 'psi', 'tags'],
            stateToSkip: [STOLEN, SCRAPPED, IN_TRANSIT_BETWEEN_STORES, SENT_TO_CLAIM, SENT_TO_REMOULD, SENT_TO_REPAIR],
        },
        'SENT_TO_CLAIM': {
            globalFields: ['mrnDate', 'storeId', 'vendorId'],
            tyreFields: ['tyre', 'brandId', 'modelId', 'currentAmount', 'remarks', 'eventTime', 'psi', 'tags'],
            stateToSkip: [
                STOLEN,
                SCRAPPED,
                IN_TRANSIT_BETWEEN_STORES,
                SENT_TO_CLAIM,
                SENT_TO_REMOULD,
                CLAIMED,
                SENT_TO_REPAIR,
            ],
        },
        'TYRE_THEFT': {
            globalFields: ['mrnDate', 'storeId', 'vendorId', 'driver', 'comment'],
            tyreFields: ['tyre', 'brandId', 'modelId', 'currentAmount', 'eventTime', 'psi', 'tags'],
            stateToSkip: [STOLEN],
        },
        'TYRE_SCRAPPING': {
            globalFields: ['mrnDate', 'storeId', 'buyer', 'comment'],
            tyreFields: ['tyre', 'brandId', 'modelId', 'currentAmount', 'remarks', 'eventTime', 'psi', 'tags'],
            stateToSkip: [SCRAPPED, STOLEN],
        },
        'TYRE_STOCK_OUT': {
            globalFields: ['mrnDate', 'outwardStoreId', 'outwardDate', 'inwardStoreId', 'inwardDate', 'comment'],
            tyreFields: ['tyre', 'brandId', 'modelId', 'currentAmount', 'eventTime', 'psi', 'tags'],
            stateToSkip: [STOLEN, IN_TRANSIT_BETWEEN_STORES, SENT_TO_CLAIM, SENT_TO_REMOULD, SENT_TO_REPAIR],
        },
    };
    return fieldsMapping[nature];
}
