import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Bluetooth = () => {
    return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_1301_103275", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "17", height: "16", children: _jsx("rect", { x: "0.310059", width: "16", height: "16", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_1301_103275)", children: _jsx("path", { d: "M6.57955 14.4361V9.20279L3.34605 12.4361L2.64355 11.7336L6.37689 8.00029L2.64355 4.26695L3.34605 3.56445L6.57955 6.79779V1.56445H7.07939L10.6487 5.13362L7.78205 8.00029L10.6487 10.867L7.07939 14.4361H6.57955ZM7.57939 6.79779L9.24355 5.13362L7.57939 3.48995V6.79779ZM7.57939 12.5106L9.24355 10.867L7.57939 9.20279V12.5106ZM11.3949 9.32329L10.0922 8.00029L11.3949 6.69779C11.4734 6.90801 11.5379 7.12168 11.5884 7.33879C11.6388 7.5559 11.6641 7.7764 11.6641 8.00029C11.6641 8.22418 11.6399 8.4464 11.5916 8.66695C11.5433 8.88751 11.4778 9.10629 11.3949 9.32329ZM13.1179 11.0055L12.4769 10.385C12.6734 10.0165 12.8264 9.6319 12.9359 9.23112C13.0452 8.83023 13.0999 8.41995 13.0999 8.00029C13.0999 7.58062 13.0452 7.17034 12.9359 6.76945C12.8264 6.36868 12.6734 5.98406 12.4769 5.61562L13.1179 4.97462C13.3973 5.43529 13.6104 5.92056 13.7571 6.43045C13.9036 6.94023 13.9769 7.46351 13.9769 8.00029C13.9769 8.53706 13.9036 9.05862 13.7571 9.56495C13.6104 10.0714 13.3973 10.5516 13.1179 11.0055Z", fill: "#52A395" }) })] }));
};
const BluetoothIconV3 = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Bluetooth, ...props });
};
export default BluetoothIconV3;
