import { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchLogin } from '../../actions/login/login_action';
import LandingHeader from '../landing/landing_header_container';
import { isValidEmail, isValidMobileNo, SA_LOGIN, SA_PASSWORD } from '../../constant';
import { omit, set, trim } from 'lodash';
import { createAccount } from '../../actions/dashboard/accounts_actions';
import { withRoute } from '../dashboard/customHocs/withRoute';

class SignUp extends Component {
    renderField(field) {
        const {
            input,
            label,
            type,
            mbcls,
            icon,
            meta: { touched, error },
        } = field;
        const className = `input-group ${touched && error ? 'has-danger' : ''} ${mbcls}`;
        return (
            <div>
                <div className="text-danger">{touched ? error : ''}</div>
                <div className={className}>
                    <span className="input-group-addon">{icon ? <i className={icon} /> : '@'}</span>
                    <input
                        className="form-control"
                        placeholder={label}
                        type={type}
                        {...input}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                </div>
            </div>
        );
    }

    onSubmit(allValues) {
        const values = omit(allValues, ['saName', 'saPassword']);

        set(values, 'name', trim(values.name));
        set(values, 'firstName', trim(values.firstName));
        set(values, 'phoneNumber', trim(values.phoneNumber));
        set(values, 'email', trim(values.email));

        if (allValues.lastName) {
            set(values, 'lastName', trim(values.lastName));
        }

        return this.props.createAccount(values).then((result) => {
            if (!result.error) {
                return this.props.fetchLogin(values).then((resultLogin) => {
                    if (!resultLogin.error) {
                        this.props.navigate('/dashboard/settings/setup');
                    } else {
                        this.props.navigate('/users/login');
                    }
                });
            } else {
                if (result.payload.response && result.payload.response.data.error_description) {
                    throw new SubmissionError({
                        _error: result.payload.response.data.error_description,
                    });
                } else {
                    throw new SubmissionError({
                        _error: 'Something went wrong, please try again later!',
                    });
                }
            }
        });
    }

    render() {
        const { handleSubmit, error, pristine, submitting } = this.props;

        return (
            <div className="app flex-row align-items-center" style={{ marginTop: '85px' }}>
                <div className="container">
                    <LandingHeader />
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card mx-4">
                                <div className="card-block p-4">
                                    <h1>Sign Up</h1>
                                    <p className="text-muted">Create your account</p>
                                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                        <Field
                                            name="name"
                                            icon="icon-briefcase"
                                            type="text"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="Company Name"
                                        />
                                        <Field
                                            name="firstName"
                                            icon="icon-user"
                                            type="text"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="First Name"
                                        />
                                        <Field
                                            name="lastName"
                                            icon="icon-user"
                                            type="text"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="Last Name"
                                        />
                                        <Field
                                            name="phoneNumber"
                                            icon="icon-phone"
                                            type="number"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="10 digit Mobile number"
                                        />
                                        <Field
                                            name="email"
                                            type="text"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="Email"
                                        />
                                        <Field
                                            name="password"
                                            icon="icon-lock"
                                            type="password"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="Password"
                                        />
                                        <Field
                                            name="matchingPassword"
                                            icon="icon-lock"
                                            type="password"
                                            mbcls="mb-4"
                                            component={this.renderField}
                                            label="Repeat password"
                                        />
                                        <hr />
                                        <Field
                                            name="saName"
                                            icon="icon-user"
                                            type="text"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="APNA NAAM"
                                        />
                                        <Field
                                            name="saPassword"
                                            icon="icon-lock"
                                            type="password"
                                            mbcls="mb-3"
                                            component={this.renderField}
                                            label="APNA PASSWORD"
                                        />
                                        <hr />
                                        <button
                                            type="submit"
                                            className="btn btn-block btn-success"
                                            disabled={pristine || submitting}
                                        >
                                            Sign Up
                                        </button>
                                    </form>
                                    {error && (
                                        <div className="my-2 text-danger text-center">
                                            <strong>{error}</strong>
                                        </div>
                                    )}

                                    <p className="text-center my-2">
                                        By signing up, I agree to fleetx's <Link to="/">Terms of Service</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.name) {
        errors.name = 'Required';
    }

    if (!values.firstName) {
        errors.firstName = 'Required';
    }

    if (!values.phoneNumber) {
        errors.phoneNumber = 'Required';
    }

    if (!values.email) {
        errors.email = 'Required';
    }
    if (!values.password) {
        errors.password = 'Required';
    }
    if (!values.matchingPassword) {
        errors.matchingPassword = 'Required';
    }

    if (!isValidEmail(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (!isValidMobileNo(values.phoneNumber)) {
        errors.phoneNumber = 'Invalid mobile number';
    }

    if (values.password) {
        if (!/^(?=.*[A-Z])/i.test(values.password)) {
            errors.password = 'Password must contain at least 1 uppercase character';
        } else if (!/^(?=.*[0-9])/i.test(values.password)) {
            errors.password = 'Password must contain at least 1 numeric character';
        } else if (!/^(?=.*[!@#\$%\^&\*])/i.test(values.password)) {
            errors.password = 'Password must contain at least 1 special character';
        } else if (!/^(?=.{8,30})/i.test(values.password)) {
            errors.password = 'Password must be eight characters or longer';
        }
    }

    if (values.password !== values.matchingPassword) {
        errors.matchingPassword = 'Repeat password does not match the password';
    }

    if (values.saName !== SA_LOGIN) {
        errors.saName = 'INVALID';
    }
    if (values.saPassword !== SA_PASSWORD) {
        errors.saPassword = 'INVALID';
    }

    return errors;
}

function mapStateToProps(state) {
    return { account: state.account };
}

export default withRoute(
    reduxForm({
        validate,
        form: 'SignUpForm',
    })(connect(mapStateToProps, { createAccount, fetchLogin })(SignUp))
);
