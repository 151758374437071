import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, find, forEach, get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray } from 'formik';
import FxTextFieldStackedLabel from 'shared/FxTextFieldStackedLabel';
import { Button } from 'reactstrap';
import FxSelectField from 'shared/FxSelectField';
import { useMemo } from 'react';
import '../../vendor_management/FreightSettlement/index.scss';
const DeductionAndDetentionChargesArrivalComponent = (props) => {
    const { accesstoken, formikProps, selectedChallanData } = props;
    const { t } = useTranslation();
    const { values, setFieldValue } = formikProps;
    const { detentionChargeDTOS } = values;
    const options = {
        consignments: useMemo(() => {
            const cnOptions = [];
            forEach(selectedChallanData, (challan) => {
                forEach(get(challan, 'materials'), (material) => {
                    cnOptions.push({
                        value: get(material, 'consignmentMaterial.consignmentId'),
                        label: get(material, 'consignmentMaterial.consignment.consignmentNumber'),
                        challanId: get(challan, 'id'),
                        consignmentNumber: get(material, 'consignmentMaterial.consignment.consignmentNumber'),
                    });
                });
            });
            return cnOptions;
        }, [selectedChallanData]),
    };
    return (_jsx(FieldArray, { name: 'detentionChargeDTOS', children: ({ push, remove }) => {
            return (_jsxs(_Fragment, { children: [map(detentionChargeDTOS, (detentionCharge, index) => {
                        return (_jsxs("div", { className: "mb-4", children: [_jsxs("h6", { children: ["Consignment ", index + 1] }), _jsx("hr", {}), _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].consignmentId`, component: FxSelectField, options: filter(options.consignments, (cn) => {
                                                return !filter(detentionChargeDTOS, (detentionCharge, index) => {
                                                    return get(detentionCharge, 'consignmentId') === cn.value;
                                                }).length;
                                            }), placeholder: 'Select Consignment', isSearchable: true, label: 'Consignment', onChange: (selectedOption) => {
                                                const optionDetails = find(options.consignments, {
                                                    value: selectedOption,
                                                });
                                                setFieldValue(`detentionChargeDTOS[${index}].challanId`, get(optionDetails, 'challanId'));
                                                setFieldValue(`detentionChargeDTOS[${index}].consignmentNumber`, get(optionDetails, 'consignmentNumber'));
                                            } }) }) }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].detentionCharges.detentionCharge`, component: FxTextFieldStackedLabel, type: "number", placeholder: 'Detention Charge', label: 'Detention Charge' }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].detentionCharges.unloadingCharge`, component: FxTextFieldStackedLabel, type: "number", placeholder: 'Unloading Charge', label: 'Unloading Charge' }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].detentionCharges.otherCharges`, component: FxTextFieldStackedLabel, type: "number", placeholder: 'Other Charges', label: 'Other Charges' }) })] }), _jsxs("div", { className: "row", children: [_jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].penaltyCharges.claimCharge`, component: FxTextFieldStackedLabel, type: "number", placeholder: 'Claim Charge', label: 'Claim Charge' }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].penaltyCharges.penaltyCharge`, component: FxTextFieldStackedLabel, type: "number", placeholder: 'Penalty Charge', label: 'Penalty Charge' }) }), _jsx("div", { className: "col-md-2", children: _jsx(Field, { name: `detentionChargeDTOS[${index}].penaltyCharges.otherDeductions`, component: FxTextFieldStackedLabel, type: "number", placeholder: 'Other Deductions', label: 'Other Deductions' }) }), _jsx("div", { className: "col-md-2", style: { marginTop: '1.9rem' }, children: _jsx(Button, { type: "button", color: "danger", onClick: () => remove(index), children: _jsx("i", { className: "fa fa-trash " }) }) })] })] }, `charge-${index}`));
                    }), _jsx("div", { className: "d-flex justify-content-center", children: _jsxs(Button, { type: "button", color: "success", onClick: () => {
                                push({
                                    consignmentId: null,
                                    detentionCharges: {
                                        detentionCharge: 0,
                                        unloadingCharge: 0,
                                        otherCharges: 0,
                                    },
                                    penaltyCharges: {
                                        claimCharge: 0,
                                        penaltyCharge: 0,
                                        otherDeductions: 0,
                                    },
                                });
                            }, children: [_jsx("i", { className: 'fa fa-plus mr-2' }), ' ', +get(detentionChargeDTOS, 'length') > 0
                                    ? ' Add More Detentions & Deductions'
                                    : 'Add Detenion & Deductions'] }) })] }));
        } }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DeductionAndDetentionChargesArrivalComponent);
