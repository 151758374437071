import {
    filter,
    findIndex,
    get,
    last,
    map,
    orderBy,
    padStart,
    toLower,
    toSafeInteger,
    toUpper,
    trim,
    find,
    forEach,
    reduce,
    pick,
    includes,
    isString,
    uniqBy,
    uniq,
    keys,
    values,
    isUndefined,
    isEmpty,
} from 'lodash';
import * as Yup from 'yup';
import {
    DATE_FORMAT_HUMANIZE,
    DATE_FORMAT_TIMESTAMP,
    getHumanizeTime,
    getMomentTime,
    getYYYYMMDDFormatStringFrom,
    HOURS_12,
    HOURS_24,
    MINUTES_1,
} from './date_utils';
import moment from 'moment-timezone';
import {
    isCarFleetType,
    isTruckFleetType,
    showLargeUiForAccount,
    showVehicleNumber,
    runningVehicleDetailedSummaryAvailable,
    parkedVehicleDetailedSummaryAvailable,
    parkedVehicleGT2AndLT4Hour,
    parkedVehicleGT4AndLT5Hour,
    parkedVehicleLT5Hour,
    FLEETX_ACCOUNTS,
    EXPRESS_ROADWAYS_SYSTEM_TAGS,
    isExpressRoadwaysAccount,
    forceNotificationFlowWithoutFirebase,
    optimizeIdlePoints,
    getIdleMarkerDelayTimeoutForAccount,
    showNextRoutePointCategoryTypeFilterInRealtime,
    isZHPOPPELTDAccount,
    parkedVehicleGTXAndLTXHours,
    idleVehicleDetailedSummaryAvailable,
    idleVehicleGTXAndLTYHours,
    parkedVehicleGT0AndLT24Hours,
    parkedVehicleGT24AndLT48Hours,
    parkedVehicleGT48Hours,
    parkedVehicleGTX4AndLTY4Hour,
    getAccountId,
} from './account_utils';
import { getFilteredTripsPoints, GPS_FILTER_LIMIT_MAX, pythagorasEquirectangular } from './map/map_utils';
import { DEFAULT_LOCATION_SEARCH_RADIUS } from '../actions/dashboard/local_action';
import {
    getTotalOdometerTillNow,
    getTripOdo,
    isAssetTrackingDevice,
    setTotalOdometerTillNow,
    emitActionValue,
    EMIT_PLAY_EMERGENCY_ALARM,
    getGroupName,
    SIM_PROVIDER,
} from '../constant';
import { getNearestAddressFromAddressbook } from './map/geocode_utils';
import { VEHICLE_JOB_STATE } from './job_route_utils';
import { sendAppAlertToSlack } from './analytics_utils';
import {
    getIdleTimeInMs,
    NO_NEXT_ROUTE_POINT_CATEGORY,
    NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY,
} from 'utils/realtime_utils';
import { isDtcOnRealtimeEnabled, shouldBatteryDischargedBeMarkedDisconnectVehicle } from 'utils/accountUtils/realtime';
import { mappedObjectToOptions, mappedOptionsFromArray } from 'components/utils/mapping_utils';
import {
    addDataRow,
    addHeader,
    createReportFilename,
    ORIENTATION,
    saveAs,
    startReportCreation,
} from 'utils/report_utils';
import JSZip from 'jszip';
import { fetchImages } from 'actions/dashboard/vehicles_action';
import { getFileNameFromUrl, getMimeTypeToExtension } from './fileUploadutils';
import { showIot } from 'components/dashboard/settings/alerts/alert_utils';
import { PH_ROLE_ID } from 'utils/job_utils';
import { validateFieldRequired } from './formTemplatesUtils';
import { isTransporter } from './permissionUtils/tmsPermissions';
import { isFormFieldRequired, isOrientCementAccount } from './accountUtils/common';

export const VEHICLE_STATUS = {
    RUNNING: 'RUNNING',
    RUNNINGLTXKM: 'RunningLTXKm',
    IDLE: 'IDLE',
    PARKED: 'PARKED',
    PARKEDGTXMINUTES: 'PARKEDGTXMINUTES',
    PARKEDLTXMINUTES: 'PARKEDLTXMINUTES',
    PARKEDGTONEANDLTTWOHOUR: 'PARKEDGTONEANDLTTWOHOUR',
    PARKEDGTTWOANDLTTFOURHOUR: 'PARKEDGTTWOANDLTTFOURHOUR',
    PARKEDGTFOURANDLTFIVEHOUR: 'PARKEDGTFOURANDLTFIVEHOUR',
    PARKEDGTX4ANDLTY4HOUR: 'PARKEDGTX4ANDLTY4HOUR',
    PARKEDGTZEROANDLTTWENTYFOURSHOUR: 'PARKEDGTZEROANDLTTWENTYFOURSHOUR',
    PARKEDGTTWENTYFOURANDLTFOURTYEIGHT: 'PARKEDGTTWENTYFOURANDLTFOURTYEIGHT',
    PARKEDVEHICLEGREATERTHAN48H: 'PARKEDVEHICLEGREATERTHAN48H',
    PARKEDGTFIVEHOUR: 'PARKEDGTFIVEHOUR',
    PARKED_10_DAYS: 'PARKED_10_DAYS',
    DISCONNECTED: 'DISCONNECTED',
    UNREACHABLE: 'UNREACHABLE',
    UNREACHABLE_3_DAYS: 'UNREACHABLE_3_DAYS',
    UNREACHABLE_4_DAYS: 'UNREACHABLE_4_DAYS',
    UNREACHABLE_5_DAYS: 'UNREACHABLE_5_DAYS',
    UNREACHABLE_6_DAYS: 'UNREACHABLE_6_DAYS',
    API_UNREACHABLE: 'API_UNREACHABLE',
    SIM_UNREACHABLE: 'SIM_UNREACHABLE',
    APP_UNREACHABLE: 'APP_UNREACHABLE',
    WIRING_DEFECT: 'WIRING_DEFECT',
    WIRING_DEFECT_PARKED: 'WIRING_DEFECT_PARKED',
    GPS_DEFECT: 'GPS_DEFECT',
    GPS_0: 'GPS_0',
    DISCONNECTED_3_DAYS: 'DISCONNECTED_3_DAYS',
    DISCONNECTED_4_DAYS: 'DISCONNECTED_4_DAYS',
    DISCONNECTED_5_DAYS: 'DISCONNECTED_5_DAYS',
    DISCONNECTED_6_DAYS: 'DISCONNECTED_6_DAYS',
    IMMOBILISED: 'IMMOBILISED',
    REMOVED: 'REMOVED',
    INSHOP: 'INSHOP',
    NO_POWER: 'NO_POWER',
    BATTERY_DISCHARGED: 'BATTERY_DISCHARGED',
    STANDBY: 'STANDBY',
    SPEEDING: 'Speeding',
    ROUTE_DEVIATION: 'Route Deviation',
    IDLEGTXMINUTES: 'IDLEGTXMINUTES',
    IDLELTXMINUTES: 'IDLELTXMINUTES',
    IDLEGTXANDLTYHOURS: 'IDLEGTXANDLTYHOURS',
    MISUSE: 'Misuse',
    CRITICAL_DTCS: 'CRITICAL_DTCS',
    COMPLETED: 'COMPLETED',
    LOW_FUEL: 'LowFuel',
    OFFLINE: 'Offline',
};

export const VEHICLE_STATUS_KEY = {
    RUNNING: 'runningVehicles',
    IDLE: 'idleVehicles',
    PARKED: 'parkedVehicles',
    PARKED_10_DAYS: 'parked10dVehicles',
    DISCONNECTED: 'disconnectedVehicles',
    UNREACHABLE: 'unreachableVehicles',
    UNREACHABLE_3_DAYS: 'unreachable3dVehicles',
    UNREACHABLE_4_DAYS: 'unreachable4dVehicles',
    UNREACHABLE_5_DAYS: 'unreachable5dVehicles',
    UNREACHABLE_6_DAYS: 'unreachable6dVehicles',
    API_UNREACHABLE: 'apiUnreachableVehicles',
    SIM_UNREACHABLE: 'simUnreachableVehicles',
    APP_UNREACHABLE: 'appUnreachableVehicles',
    WIRING_DEFECT: 'wiringDefectVehicles',
    WIRING_DEFECT_PARKED: 'wiringDefectParkedVehicles',
    GPS_DEFECT: 'gpsDefectVehicles',
    GPS_0: 'gps0Vehicles',
    DISCONNECTED_3_DAYS: 'disconnect3dVehicles',
    DISCONNECTED_4_DAYS: 'disconnect4dVehicles',
    DISCONNECTED_5_DAYS: 'disconnect5dVehicles',
    DISCONNECTED_6_DAYS: 'disconnect6dVehicles',
    IMMOBILISED: 'immobilisedVehicles',
    REMOVED: 'removedVehicles',
    INSHOP: 'inShopVehicles',
    NO_POWER: 'nopowerVehicles',
    BATTERY_DISCHARGED: 'batteryDischargedVehicles',
    STANDBY: 'standByVehicles',
};

export const VEHICLE_STATUS_NAME = {
    RUNNING: 'running',
    RUNNINGLTXKM: 'Running <X Km',
    IDLE: 'idle',
    PARKED: 'parked',
    PARKEDGTXMINUTES: 'Parked >X min',
    PARKEDLTXMINUTES: 'Parked <X min',
    PARKEDGTONEANDLTTWOHOUR: 'X1<Parked<X2 hr',
    PARKEDGTTWOANDLTTFOURHOUR: 'X1<Parked<X2 hr',
    PARKEDGTFOURANDLTFIVEHOUR: 'X1<Parked<X2 hr',
    PARKEDGTX4ANDLTY4HOUR: 'X1<Parked<X2 hr',
    PARKEDGTFIVEHOUR: 'Parked>5 hr',
    PARKEDGTZEROANDLTTWENTYFOURSHOUR: '0<Parked<24 hr',
    PARKEDGT24ANDLT48: '24<Parked<48 hr',
    PARKEDGT48H: 'Parked>48 hr',
    PARKED_10_DAYS: 'Park 10D',
    DISCONNECTED: 'disconnect',
    UNREACHABLE: 'notOnline',
    UNREACHABLE_3_DAYS: 'Offline 3D',
    UNREACHABLE_4_DAYS: 'Offline 4D',
    UNREACHABLE_5_DAYS: 'Offline 5D',
    UNREACHABLE_6_DAYS: 'Offline 6D+',
    API_UNREACHABLE: 'Offline API',
    SIM_UNREACHABLE: 'Offline Sim',
    APP_UNREACHABLE: 'Offline APP',
    WIRING_DEFECT: 'wireDefect',
    WIRING_DEFECT_PARKED: 'wireDefPark',
    GPS_DEFECT: 'gPSDefective',
    GPS_0: 'GPS 0',
    DISCONNECTED_3_DAYS: 'Disconn. 3D',
    DISCONNECTED_4_DAYS: 'Disconn. 4D',
    DISCONNECTED_5_DAYS: 'Disconn. 5D',
    DISCONNECTED_6_DAYS: 'Disconn. 6D+',
    IMMOBILISED: 'Immobilised',
    REMOVED: 'removed',
    INSHOP: 'workshop',
    NO_POWER: 'onBattery',
    BATTERY_DISCHARGED: 'discharged',
    STANDBY: 'Standby',
    SPEEDING: 'Speeding',
    ROUTE_DEVIATION: 'Route Deviation',
    IDLEGTXMINUTES: 'Idle >X min',
    IDLELTXMINUTES: 'Idle <X min',
    IDLEGTXANDLTYHOURS: 'X1<Idle<X2 hr',
    MISUSE: 'Misuse',
    LOW_FUEL: 'Low Fuel',
    OFFLINE: 'Offline',
};
export const getVehicleStatusName = (name) => {
    return window.t(name);
};

export function getVehicleStatusForExcludeFilterOptions() {
    return mappedObjectToOptions(
        filter(VEHICLE_STATUS, (status) => {
            return includes(
                [
                    VEHICLE_STATUS.RUNNING,
                    VEHICLE_STATUS.IDLE,
                    VEHICLE_STATUS.PARKED,
                    VEHICLE_STATUS.DISCONNECTED,
                    VEHICLE_STATUS.NO_POWER,
                    VEHICLE_STATUS.BATTERY_DISCHARGED,
                    VEHICLE_STATUS.UNREACHABLE,
                    VEHICLE_STATUS.REMOVED,
                    VEHICLE_STATUS.STANDBY,
                    VEHICLE_STATUS.INSHOP,
                    VEHICLE_STATUS.IMMOBILISED,
                    VEHICLE_STATUS.ROUTE_DEVIATION,
                    VEHICLE_STATUS.SPEEDING,
                ],
                status
            );
        }),
        VEHICLE_STATUS_NAME
    );
}

export const VEHICLE_TYPE_VALUE = {
    TRUCK: 'Truck',
    TRANSIT_MIXER: 'Transit Mixer',
    PUMP: 'Pump',
    TOW_VAN: 'Tow Van',
    TWO_W: '2W',
    ATV: 'ATV',
    L3: 'L3',
    L5: 'L5',
    TRAILER: 'Trailer',
    TRIP_TRAILER: 'Tip Trailer',
    BULKER: 'Bulker',
};

export const isVehicleTypeValueValidForAccount = (vehicleType) => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case 1570:
            return includes(
                [
                    VEHICLE_TYPE_VALUE.TRUCK,
                    VEHICLE_TYPE_VALUE.TRANSIT_MIXER,
                    VEHICLE_TYPE_VALUE.TOW_VAN,
                    VEHICLE_TYPE_VALUE.PUMP,
                ],
                vehicleType
            );
        case 11423:
            return includes(
                [
                    VEHICLE_TYPE_VALUE.TRUCK,
                    VEHICLE_TYPE_VALUE.BULKER,
                    VEHICLE_TYPE_VALUE.TRAILER,
                    VEHICLE_TYPE_VALUE.TRIP_TRAILER,
                ],
                vehicleType
            );
        case 10295:
            return includes(
                [
                    VEHICLE_TYPE_VALUE.TRUCK,
                    VEHICLE_TYPE_VALUE.PUMP,
                    VEHICLE_TYPE_VALUE.TOW_VAN,
                    VEHICLE_TYPE_VALUE.TWO_W,
                    VEHICLE_TYPE_VALUE.ATV,
                    VEHICLE_TYPE_VALUE.L3,
                    VEHICLE_TYPE_VALUE.L5,
                    VEHICLE_TYPE_VALUE.TWO_W,
                ],
                vehicleType
            );
    }
};
export const PLANT_DIRECTION_VALUE = {
    TO_PLANT: 'TO_PLANT',
    TO_CUSTOMER: 'TO_CUSTOMER',
};
export const PLANT_DIRECTION_LABELS = {
    TO_PLANT: 'To Plant',
    TO_CUSTOMER: 'To Customer',
};
export const VEHICLE_STATUS_NAME_LONG = {
    RUNNING: 'Running',
    IDLE: 'Idle',
    PARKED: 'Parked',
    PARKED_10_DAYS: 'Parked 10 Days',
    UNREACHABLE: 'Device Not Online',
    UNREACHABLE_3_DAYS: 'Device Not Online 3 Days',
    UNREACHABLE_4_DAYS: 'Device Not Online 4 Days',
    UNREACHABLE_5_DAYS: 'Device Not Online 5 Days',
    UNREACHABLE_6_DAYS: 'Device Not Online 6 Plus Days',
    API_UNREACHABLE: 'Device Offline (API)',
    WIRING_DEFECT: 'Wire Defect',
    WIRING_DEFECT_PARKED: 'Wire Defect Parked',
    GPS_DEFECT: 'GPS Defective. Device to be changed.',
    GPS_0: 'GPS 0,0 Lat,Lon. Check installation',
    DISCONNECTED_3_DAYS: 'Device Disconnect 3 Days',
    DISCONNECTED_4_DAYS: 'Device Disconnect 4 Days',
    DISCONNECTED_5_DAYS: 'Device Disconnect 5 Days',
    DISCONNECTED_6_DAYS: 'Device Disconnect 6 Plus Days',
    IMMOBILISED: 'Vehicle Immobilised',
    INSHOP: 'Vehicle in Workshop',
    DISCONNECTED: 'Device Disconnect',
    REMOVED: 'Device Removed',
    NO_POWER: 'Device on Internal Battery',
    BATTERY_DISCHARGED: 'Device Internal Battery Discharged',
    STANDBY: 'VEHICLE ON STANDBY',
    Offline: 'Offline',
};

export const MOTION_TYPE = {
    TRIP: 'Moving',
    IDLE: 'Idle',
    STOP: 'Stop',
};

export const VEHICLE_TAB = {
    DEFAULT: 'GENERAL',
    DRIVER: 'DRIVER',
    RUNNING: 'RUNNING',
    FUEL: 'FUEL',
    GENERAL: 'GENERAL',
    COST: 'COST',
    SAFETY: 'SAFETY',
    DEVICE: 'DEVICE',
    UPTIME: 'UPTIME',
    TYRE: 'TYRE',
    SPEED_STRATIFICATION: 'SPEED_STRATIFICATION',
    DEVICE_HEALTH: 'DEVICE_HEALTH',
    ODOMETER_READING: 'ODOMETER_READING',
    VEHICLE_TAG: 'VEHICLE_TAG',
    SHIFT_WISE_DRIVER: 'SHIFT_WISE_DRIVER',
    THIRD_PART_API_SUMMARY: 'THIRD_PART_API_SUMMARY',
};

export const VEHICLE_SIZE_CATEGORY = [
    {
        id: 'BUS',
        name: 'Bus',
        tollKey: 'busRateMulti',
    },
    {
        id: 'TRUCK',
        name: 'Truck',
        tollKey: 'busRateMulti',
    },
    {
        id: 'LCV',
        name: 'LCV Vehicle',
        tollKey: 'lcvrateSingle',
    },
    {
        id: 'THREE_AXLE',
        name: '3 Axle',
        tollKey: 'multiAxleRateSingle',
    },
    {
        id: 'FOUR_AXLE',
        name: '4 Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        id: 'FIVE_AXLE',
        name: '5 Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        id: 'SIX_AXLE',
        name: '6 Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        id: 'SEVEN_AXLE',
        name: '7 Or More Axle',
        tollKey: 'sevenOrmoreExelSingle',
    },
    {
        id: 'HCM',
        name: 'HCM Vehicle',
        tollKey: 'hcmemesingle',
    },
];

export const VEHICLE_SIZE = [
    {
        name: 'BUS',
        tollKey: 'busRateMulti',
    },
    {
        name: 'TRUCK',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 407 - Open Body - 2.5 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 407 - Closed Body - 2.5 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 14 Ft - Open Body - 4 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 14 Ft - Closed Body - 3.5 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 17 Ft - Open Body - 5 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 17 Ft - Closed Body - 5 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 19 Ft - Open Body - 7 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Tempo - 19 Ft - Closed Body - 7 Ton',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Container - 20 Ft - Single Axle - 7 Ton',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 20 Ft - Multi Axle - 9 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 24 Ft - Single Axle - 7 Ton',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 24 Ft - Multi Axle - 14 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 28 Ft - Single Axle',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 28 Ft - Multi Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 32 Ft - Single Axle - 7 Ton',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 32 Ft - Single Axle HQ - 7 Ton',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 32 Ft - Multi Axle - 14 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 32 Ft - Multi Axle HQ - 14 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 32 Ft - Triple Axle - 20 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 32 Ft - Triple Axle HQ - 20 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Container - 34 Ft - Single Axle',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 36 Ft - Single Axle',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Container - 36 Ft - Single Axle HQ',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Open - 6 Wheel - Half Body - 9 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Open - 6 Wheel - Full Body - 9 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Open - 10 Wheel - Half Body - 16 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Open - 10 Wheel - Full Body - 16 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Open - 12 Wheel - Half Body - 21 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Open - 12 Wheel - Full Body - 21 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Open - 14 Wheel - Half Body - 26 Ton',
        tollKey: 'sevenOrmoreExelSingle',
    },
    {
        name: 'Open - 14 Wheel - Full Body - 26 Ton',
        tollKey: 'sevenOrmoreExelSingle',
    },
    {
        name: 'Trailer - 20 Ft - Tuskar',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Trailer - 20 Ft - Double Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 20 Ft - Triple Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 40 Ft - Double Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 40 Ft - Triple Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 42 Ft - Low Bed - 26 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 42 Ft - Semi Bed - 26 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 42 Ft - High Bed - 26 Ton',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 55 Ft - Triple Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Trailer - 60 Ft - Triple Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TK-1',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'TK-4',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TK-6',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TK-40',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TK-5',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TR-8',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TR-9',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'TR-10',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'BC',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Other - Trailer',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - Tipper',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - Container',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - Tractor',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - Pick-up',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Other - Tempo',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Other - Refrigerated',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Other - Tanker',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - Flatbed',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - LCV',
        tollKey: 'lcvrateSingle',
    },
    {
        name: 'Other - Three Axle',
        tollKey: 'multiAxleRateSingle',
    },
    {
        name: 'Other - Four Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Other - Five Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Other - Six Axle',
        tollKey: 'fourToSixExelSingle',
    },
    {
        name: 'Other - Seven Axle',
        tollKey: 'sevenOrmoreExelSingle',
    },
    {
        name: 'Other - HCM',
        tollKey: 'sevenOrmoreExelSingle',
    },
];

export const REALTIME_OVERVIEW_TAB = {
    DEFAULT: 'OVERVIEW',
    OVERVIEW: 'OVERVIEW',
    DIRECTIONS: 'DIRECTIONS',
    TIMELINE: 'TIMELINE',
    SPEED_GRAPH: 'SPEED_GRAPH',
    TEMP_GRAPH: 'TEMP_GRAPH',
    ALARMS: 'ALARMS',
    NEARBY: 'NEARBY',
};
export const TRIP_DETAIL_OVERVIEW_TAB = {
    DEFAULT: 'OVERVIEW',
    OVERVIEW: 'OVERVIEW',
    SPEED_GRAPH: 'SPEED_GRAPH',
    TEMP_GRAPH: 'TEMP_GRAPH',
    ALARMS: 'ALARMS',
};

export const VEHICLE_CATEGORY = {
    HEAVYDUTY: 'HEAVYDUTY',
    PASSENGER: 'PASSENGER',
};

export const SORT_VEHICLE_TYPE = {
    SPEED: 'SPEED',
    STATUS_TIME: 'STATUS_TIME',
    JOB_STATUS_TIME: 'JOB_STATUS_TIME',
    JOB_DELAY_TIME: 'JOB_DELAY_TIME',
    NAME: 'NAME',
};

export const SORT_VEHICLE_DIRECTION = {
    UP: 'UP',
    DOWN: 'DOWN',
};

export function isOffline(currentStatus, speed = true) {
    return currentStatus === VEHICLE_STATUS.OFFLINE;
}

export function isRunning(currentStatus, speed = true) {
    return toUpper(currentStatus) === VEHICLE_STATUS.RUNNING && !!speed;
}

export function isIdle(currentStatus, speed = false) {
    return (
        toUpper(currentStatus) === VEHICLE_STATUS.IDLE || (toUpper(currentStatus) === VEHICLE_STATUS.RUNNING && !speed)
    );
}

export function isParked(currentStatus) {
    return toUpper(currentStatus) === VEHICLE_STATUS.PARKED || toUpper(currentStatus) === VEHICLE_STATUS.PARKED_10_DAYS;
}

export function isUnreachable(currentStatus) {
    return (
        toUpper(currentStatus) === VEHICLE_STATUS.UNREACHABLE ||
        toUpper(currentStatus) === VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
        toUpper(currentStatus) === VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
        toUpper(currentStatus) === VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
        toUpper(currentStatus) === VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
        toUpper(currentStatus) === VEHICLE_STATUS.API_UNREACHABLE
    );
}

export function isOnBattery(currentStatus) {
    return toUpper(currentStatus) === VEHICLE_STATUS.NO_POWER;
}

export function isRemoved(currentStatus) {
    return toUpper(currentStatus) === VEHICLE_STATUS.REMOVED;
}

export function isDischarged(currentStatus) {
    return toUpper(currentStatus) === VEHICLE_STATUS.BATTERY_DISCHARGED;
}

export function isDisconnected(currentStatus) {
    return toUpper(currentStatus) === VEHICLE_STATUS.DISCONNECTED;
}

export function isEngineOn(currentStatus) {
    switch (toUpper(currentStatus)) {
        case VEHICLE_STATUS.RUNNING:
        case VEHICLE_STATUS.IDLE:
            return true;
        default:
            return false;
    }
}

export function isStopped(currentStatus) {
    switch (toUpper(currentStatus)) {
        case VEHICLE_STATUS.PARKED:
        case VEHICLE_STATUS.DISCONNECTED:
        case VEHICLE_STATUS.IMMOBILISED:
        case VEHICLE_STATUS.REMOVED:
            return true;
        default:
            return false;
    }
}

export function getRunningStatusClass(currentStatus) {
    switch (currentStatus) {
        case VEHICLE_STATUS.RUNNING:
            return 'text-success';
        case VEHICLE_STATUS.IDLE:
            return 'text-warning';
        case VEHICLE_STATUS.DISCONNECTED:
        case VEHICLE_STATUS.UNREACHABLE:
        case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
        case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
        case VEHICLE_STATUS.API_UNREACHABLE:
        case VEHICLE_STATUS.WIRING_DEFECT:
        case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
        case VEHICLE_STATUS.GPS_DEFECT:
        case VEHICLE_STATUS.GPS_0:
        case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
        case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
        case VEHICLE_STATUS.IMMOBILISED:
        case VEHICLE_STATUS.REMOVED:
        case VEHICLE_STATUS.INSHOP:
        case VEHICLE_STATUS.STANDBY:
        case VEHICLE_STATUS.NO_POWER:
        case VEHICLE_STATUS.BATTERY_DISCHARGED:
            return 'text-danger';
        case VEHICLE_STATUS.PARKED:
        case VEHICLE_STATUS.PARKED_10_DAYS:
        default:
            return '';
    }
}

export function isNetworkOutage(currentStatus, lastUpdatedAt) {
    const lastUpdatedAtTime = getMomentTime(lastUpdatedAt);
    const curTime = getMomentTime();
    return (
        (isEngineOn(currentStatus) && curTime.diff(lastUpdatedAtTime, 'minutes') > 25) ||
        (isParked(currentStatus) && curTime.diff(lastUpdatedAtTime, 'minutes') > 60)
    );
}

export function getCurrentVehicleStatus(point) {
    if (!point) {
        return VEHICLE_STATUS.PARKED;
    }
    if (point.currentStatus) {
        return point.currentStatus;
    } else if (toLower(point.status) === 'ignition on' && point.speed && point.speed > 0) {
        return VEHICLE_STATUS.RUNNING;
    } else if (toLower(point.status) === 'ignition on' && (!point.speed || point.speed == 0)) {
        return VEHICLE_STATUS.IDLE;
    } else if (toLower(point.status) === 'ignition off' && point.speed > 0) {
        // for case where assetFallback enabled in merged trip API
        return VEHICLE_STATUS.IDLE;
    } else if (toLower(point.status) === 'ignition off') {
        return VEHICLE_STATUS.PARKED;
    } else if (point.status) {
        return point.status;
    } else {
        return VEHICLE_STATUS.PARKED;
    }
}

export function getVehicleStatus(status, speed) {
    if (toLower(status) === 'ignition on' && speed && speed > 0) {
        return VEHICLE_STATUS.RUNNING;
    } else if (toLower(status) === 'ignition on' && (!speed || speed === 0)) {
        return VEHICLE_STATUS.IDLE;
    } else if (toLower(status) === 'ignition off') {
        return VEHICLE_STATUS.PARKED;
    } else if (status) {
        return status;
    } else {
        return VEHICLE_STATUS.PARKED;
    }
}

export const SENSOR_TYPE_OPTIONS = [
    {
        label: 'REFER_AC_DIN2',
        value: 'REFER_AC_DIN2',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'REFER_AC_AIN1',
        value: 'REFER_AC_AIN1',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'REFER_AC_AIN1_TEMP_1_WIRE',
        value: 'REFER_AC_AIN1_TEMP_1_WIRE',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_SENSOR_AIN1',
        value: 'DOOR_SENSOR_AIN1',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'PANIC_AIN1_DOOR_SENSOR_AIN3',
        value: 'PANIC_AIN1_DOOR_SENSOR_AIN3',
        smsProtocol: 'istartek',
        commandType: null,
        deviceTypes: ['VT200', 'VT100', 'VT200L', 'VT100L'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_SENSOR_DIN2',
        value: 'DOOR_SENSOR_DIN2',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_SENSOR_AIN1_SENSOR_2_DIN2',
        value: 'DOOR_SENSOR_AIN1_SENSOR_2_DIN2',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_SENSOR_AIN1_TAMPER_DIN2',
        value: 'DOOR_SENSOR_AIN1_TAMPER_DIN2',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'CABIN_AC_DIN2',
        value: 'CABIN_AC_DIN2',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'CABIN_AC_AIN1_HIGH_AC_OFF',
        value: 'CABIN_AC_AIN1_HIGH_AC_OFF',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB120', 'FMB920', 'FMB910', 'FMB911'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'CABIN_AC_AIN1_HIGH_AC_ON',
        value: 'CABIN_AC_AIN1_HIGH_AC_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB120', 'FMB920', 'FMB910', 'FMB911'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'CABIN_AC_AIN1_HIGH_AC_ON',
        value: 'CABIN_AC_AIN1_HIGH_AC_ON_ATLANTA',
        smsProtocol: 'atlanta',
        commandType: null,
        deviceTypes: ['EV100F', 'EV200', 'EV201'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'CABIN_AC_AIN1_HIGH_AC_OFF',
        value: 'CABIN_AC_AIN1_HIGH_AC_OFF_ATLANTA',
        smsProtocol: 'atlanta',
        commandType: null,
        deviceTypes: ['EV100F', 'EV200', 'EV201'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'FUEL_TECHNOTON_INDIA_SENSOR_RS232',
        value: 'FUEL_TECHNOTON_INDIA_SENSOR_RS232',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_FUEL_TECHNOTON_INDIA_SENSOR_RS232',
        deviceTypes: ['FMB125', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'FUEL_TECHNOTON_DUTE_SENSOR_RS232',
        value: 'FUEL_TECHNOTON_DUTE_SENSOR_RS232',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_FUEL_TECHNOTON_DUTE_SENSOR_RS232',
        deviceTypes: ['FMB125', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'FUEL_ANALOG_SENSOR_ON_AIN1',
        value: 'FUEL_SET_FUEL_ANALOG_INPUT_1',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_FUEL_VIA_ANALOG_VOLTAGE',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'FUEL SENSOR BLE 1 FUEL',
        value: 'FUEL_SENSOR_BLE',
        smsProtocol: 'teltonika',
        commandType: 'FMB_ENABLE_BLE_SENSOR_1_FUEL',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'FMB PANIC BUZZER',
        value: 'FMB_PANIC_BUZZER',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DRUM SENSOR LLS2',
        value: 'DRUM_LLS2',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DRUM SENSOR BLE 1',
        value: 'DRUM_SENSOR_BLE_1',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DRUM SENSOR BLE 1 REVERSE',
        value: 'DRUM_SENSOR_BLE_1_REVERSE',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_ANALOG_SENSOR_ON_AIN1_TELTONIKA',
        value: 'TEMP_AIN1',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_TEMP_VIA_ANALOG_VOLTAGE',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_ANALOG_SENSOR_ON_AIN1_REFER_AC_DIN2',
        value: 'TEMP_AIN1_REFER_AC_DIN2',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_TEMP_VIA_ANALOG_VOLTAGE_ENABLE_DIN2',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_ANALOG_SENSOR_ON_AIN1_DOOR_SENSOR_DIN2',
        value: 'TEMP_AIN1_DOOR_SENSOR_DIN2',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_TEMP_VIA_ANALOG_VOLTAGE_ENABLE_DIN2',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_AIN1_AIN2_DIN2',
        value: 'DOOR_AIN1_AIN2_DIN2',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_TEMP_VIA_ANALOG_VOLTAGE_ENABLE_DIN2',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_SENSOR_BLE',
        value: 'TEMP_SENSOR_BLE',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DUAL_TEMP_SENSOR_BLE',
        value: 'DUAL_TEMP_SENSOR_BLE',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DUAL_FUEL_SENSOR_BLE',
        value: 'DUAL_FUEL_SENSOR_BLE',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'SPEED_BASED_IGNITION_DEVICE_V5',
        value: 'SPEED_BASED_IGNITION_DEVICE_V5',
        smsProtocol: 'concoxgt06',
        commandType: null,
        deviceTypes: ['V5'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'SPEED_BASED_IGNITION_DEVICE_AIS',
        value: 'SPEED_BASED_IGNITION_DEVICE_AIS',
        smsProtocol: 'atlantaais',
        commandType: null,
        deviceTypes: ['WP30L'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_ANALOG_SENSOR_ON_AIN1_TRANSIGHT',
        value: 'TEMP_AIN1_TRANSIGHT',
        smsProtocol: 'transight',
        commandType: null,
        deviceTypes: ['DISCOVERY2GPLUS', 'DISCOVERY2PLUS485', 'DISCOVERY2GPLUS485'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DUAL_TEMP_AIN1_AIN2_TRANSIGHT',
        value: 'DUAL_TEMP_AIN1_AIN2_TRANSIGHT',
        smsProtocol: 'transight',
        commandType: 'TRANSIGHT_SET_INPUT_3_TO_ANALOG',
        deviceTypes: ['DISCOVERY2GPLUS', 'DISCOVERY2PLUS485', 'DISCOVERY2GPLUS485'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_AIN1_REFER_AC_AIN2_TRANSIGHT',
        value: 'TEMP_AIN1_REFER_AC_AIN2_TRANSIGHT',
        smsProtocol: 'transight',
        commandType: 'TRANSIGHT_SET_INPUT_3_TO_ANALOG',
        deviceTypes: ['DISCOVERY2GPLUS', 'DISCOVERY2PLUS485', 'DISCOVERY2GPLUS485'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_AIN1_REFER_AC_HIGH_OFF_LOW_ON_AIN2_TRANSIGHT',
        value: 'TEMP_AIN1_REFER_AC_HIGH_OFF_LOW_ON_AIN2_TRANSIGHT',
        smsProtocol: 'transight',
        commandType: 'TRANSIGHT_SET_INPUT_3_TO_ANALOG',
        deviceTypes: ['DISCOVERY2GPLUS', 'DISCOVERY2PLUS485', 'DISCOVERY2GPLUS485'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'FUEL_RS485_ON_TRANSIGHT',
        value: 'FUEL_RS485_ON_TRANSIGHT',
        smsProtocol: 'transight',
        commandType: 'TRANSIGHT_ENABLE_RS485',
        deviceTypes: ['DISCOVERY2PLUS485', 'DISCOVERY2GPLUS485'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'SPEED_BASED_IGNITION_DEVICE_WETRACK2',
        value: 'SPEED_BASED_IGNITION_DEVICE_WETRACK2',
        smsProtocol: 'concoxgt06',
        commandType: null,
        deviceTypes: ['WETRACK2'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'SPEED_BASED_IGNITION_DEVICE_GT06',
        value: 'SPEED_BASED_IGNITION_DEVICE_GT06',
        smsProtocol: 'concoxgt06',
        commandType: null,
        deviceTypes: ['GT06'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'SPEED_BASED_IGNITION_AIS',
        value: 'SPEED_BASED_IGNITION_AIS',
        smsProtocol: 'watsooais',
        commandType: null,
        deviceTypes: ['PRITHVI140', 'WP30L', 'WP30L_GPCB', 'BHARAT101'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'SPEED_BASED_IGNITION_DEVICE_GT08',
        value: 'SPEED_BASED_IGNITION_DEVICE_GT08',
        smsProtocol: 'concoxgt06',
        commandType: null,
        deviceTypes: ['GT08'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 2V',
        value: 'EXTERNAL_DEVICE_1',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 8V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_8_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 12V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_12_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 12.6V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_12_6_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 13.4V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_13_4_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 13V OFF BELOW 12V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_13_ON_12_OFF',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 24V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_24_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMB225', 'GV56'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 26V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_26_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB641', 'FMC125', 'FMB225', 'GV56'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'QUECLINK TD BLE',
        value: 'QL_TD_BLE',
        smsProtocol: 'queclink',
        commandType: null,
        deviceTypes: ['GV56'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'QUECLINK TEMP SENSOR',
        value: 'QL_TEMP_SENSOR',
        smsProtocol: 'queclink',
        commandType: null,
        deviceTypes: ['GV56'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TATA EXPRESS T EV WITH ZIPTRON BATTERY',
        value: 'TATA_EXPRESS_T_EV_WITH_ZIPTRON_BATTERY',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['TFT100'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'BB TFT MANUAL CAN',
        value: 'BB_TFT_MANUAL_CAN',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['TFT100'],
        switchedUserRoleId: [7, 94911],
    },

    {
        label: 'TATA_EXPRESS_T_EV_WITH_ZIPTRON_BATTERY_TFT100_SOS_AIN2',
        value: 'TATA_EXPRESS_T_EV_WITH_ZIPTRON_BATTERY_TFT100_SOS_AIN2',
        smsProtocol: 'teltonika',
        commandType: 'ENABLE_PANIC_BUTTON_AIN2_TFT',
        deviceTypes: ['TFT100'],
        switchedUserRoleId: [7, 94911],
    },

    {
        label: 'TATA_EXPRESS_T_EV_SOS_AIN2',
        value: 'TATA_EXPRESS_T_EV_SOS_AIN2',
        smsProtocol: 'teltonika',
        commandType: 'ENABLE_PANIC_BUTTON_AIN2_TFT',
        deviceTypes: ['TFT100'],
        switchedUserRoleId: [7, 94911],
    },

    {
        label: 'TFT100_SOS_AIN2',
        value: 'TFT100_SOS_AIN2',
        smsProtocol: 'teltonika',
        commandType: 'ENABLE_PANIC_BUTTON_AIN2_TFT',
        deviceTypes: ['TFT100'],
        switchedUserRoleId: [7, 94911],
    },

    {
        label: 'CORRIT CAN',
        value: 'CORRIT_CAN',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['TFT100'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 26.8V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_26_8_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 5V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_5_ON',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMB225', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EV WHEEL JAM',
        value: 'EV_WHEEL_JAM',
        deviceTypes: ['EG02'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: '2 CHANNEL CAMERA',
        value: 'TWO_CHANNEL_CAMERA',
        deviceTypes: ['HOWEN', 'LOTIM', 'LCD40', 'LCD02', 'LCD40-3CH', 'LCD40-4CH'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: '3 CHANNEL CAMERA',
        value: 'THREE_CHANNEL_CAMERA',
        deviceTypes: ['HOWEN', 'LOTIM', 'LCD40', 'LCD02', 'LCD40-3CH', 'LCD40-4CH'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: '4 CHANNEL CAMERA',
        value: 'FOUR_CHANNEL_CAMERA',
        deviceTypes: ['HOWEN', 'LOTIM', 'LCD40', 'LCD02', 'LCD40-3CH', 'LCD40-4CH'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'TEMP_BLE',
        value: 'TEMP_BLE',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB911'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'FUEL_BLE_TEMP_BLE',
        value: 'FUEL_BLE_TEMP_BLE',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB911'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_22_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_22_ON',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB911', 'FMB125', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_28_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_28_ON',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB120', 'FMB125', 'FMB911', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'FUEL_BLE_TEMP_DOOR_AIN2_REFER_AIN1',
        value: 'FUEL_BLE_TEMP_DOOR_AIN2_REFER_AIN1',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23, 94911],
    },
];

export const getSensorTypeOptions = (deviceType, switchedUserOriginalRoleId) => {
    return filter(SENSOR_TYPE_OPTIONS, (sensor) => {
        return (
            includes(sensor.deviceTypes, deviceType) && includes(sensor.switchedUserRoleId, +switchedUserOriginalRoleId)
        );
    });
};

export const SENSOR_SUB_TYPE_OPTIONS = [
    {
        label: 'CONSTRUCTION MACHINE SINGLE ENGINE',
        value: 'Construction_Machinery_Single_Engine',
        deviceTypes: ['FMB125', 'FMB641'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'CONSTRUCTION MACHINE DUAL ENGINE',
        value: 'Construction_Machinery_Dual_Engine',
        deviceTypes: ['FMB125', 'FMB641'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_26_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_26_ON',
        deviceTypes: ['FMB641', 'FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB120', 'FMC125', 'FMC120', 'GV56'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_12_6_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_12_6_ON',
        deviceTypes: ['FMB641', 'FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB120', 'FMC125', 'FMC120', 'GV56'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_26_8_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_26_8_ON',
        deviceTypes: ['FMB641', 'FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB120', 'GV56'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_24_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_24_ON',
        deviceTypes: ['FMB641', 'FMB125', 'FMB920', 'FMB910', 'FMB911', 'GV56'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_24_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_24_ON',
        deviceTypes: ['FMB641', 'FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB120', 'GV56'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 13V OFF BELOW 12V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_13_ON_12_OFF',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB641', 'FMB120', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 12V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_12_ON',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB641', 'FMB120', 'GV56'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'EXTERNAL DEVICE ON ABOVE 5V',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_5_ON',
        deviceTypes: ['FMB125', 'FMB920', 'FMB910', 'FMB911', 'FMB641', 'FMB120', 'GV56'],
        switchedUserRoleId: [7, 94911],
    },
    {
        label: 'CRANE_ANGLE_SENSOR',
        value: 'CRANE_ANGLE_SENSOR',
        deviceTypes: ['FMB641', 'FMB125', 'FMB920', 'FMB910', 'FMB911'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'CABIN_AC_AIN1_HIGH_AC_OFF',
        value: 'CABIN_AC_AIN1_HIGH_AC_OFF',
        deviceTypes: ['FMB125', 'FMB120', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23],
    },
    {
        label: 'REFER_AC_AIN1',
        value: 'REFER_AC_AIN1',
        deviceTypes: ['FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMC125', 'FMC130', 'FMB125', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_SENSOR_AIN1',
        value: 'DOOR_SENSOR_AIN1',
        smsProtocol: 'teltonika',
        commandType: 'FMB_SET_ANALOG_MONITORING',
        deviceTypes: ['FMB125', 'FMB120', 'FMB920', 'FMB910', 'FMB911', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_22_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_22_ON',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB911', 'FMB125', 'FMB120', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_28_ON',
        value: 'EXTERNAL_DEVICE_1_HIGH_VOLTAGE_28_ON',
        smsProtocol: 'teltonika',
        deviceTypes: ['FMB120', 'FMB125', 'FMB911', 'GV56', 'FMB641'],
        switchedUserRoleId: [7, 23, 94911],
    },
    {
        label: 'DOOR_AIN1_AIN2_DIN2',
        value: 'DOOR_AIN1_AIN2_DIN2',
        smsProtocol: 'teltonika',
        commandType: null,
        deviceTypes: ['FMB120'],
        switchedUserRoleId: [7, 23],
    },
];

export const getSensorSubTypeOptions = (deviceType, switchedUserOriginalRoleId) => {
    return filter(SENSOR_SUB_TYPE_OPTIONS, (sensor) => {
        return (
            includes(sensor.deviceTypes, deviceType) && includes(sensor.switchedUserRoleId, +switchedUserOriginalRoleId)
        );
    });
};

export function getMotionPointsFromRoutes(
    routes,
    idleDelayTimeOut,
    checkDeviceIdsInRoutes = true,
    skipConcoxHeartbeat = true
) {
    const optimizePoints = optimizeIdlePoints();
    const IDLE_DELAY_TIMEOUT = getIdleMarkerDelayTimeoutForAccount(); // 40s delay as per fidelity
    if (!routes) {
        return null;
    }
    const gpsPoints = getFilteredTripsPoints(
        filter(routes, (point) => {
            const alarmType = toLower(get(point, 'otherAttributes.alarm[0].type'));
            return point.type === 'GPS' || (point.type === 'ALARM' && alarmType === 'ignition off');
        }),
        checkDeviceIdsInRoutes,
        skipConcoxHeartbeat
    );

    const gpsPointsLength = gpsPoints.length;
    const motionsPoints = [];
    if (gpsPointsLength == 0) {
        return null;
    }

    if (gpsPointsLength == 1) {
        const point = gpsPoints[0];
        motionsPoints.push({
            status: getCurrentVehicleStatus(point),
            sDate: point.timeStamp,
            eDate: point.timeStamp,
            sLat: point.latitude,
            sLon: point.longitude,
            eLat: point.latitude,
            eLon: point.longitude,
            sOdo: 0,
            eOdo: 0,
        });
        return motionsPoints;
    }

    let startTime = null,
        startStatus = null,
        endTime = null;
    let sLat = 0,
        sLon = 0,
        eLat = 0,
        eLon = 0,
        sOdo = 0,
        eOdo = 0;
    let currentOdo = 0;
    //TODO: OM create sOdo and eOdo for asset tracking data
    let lastLat,
        lastLon,
        p2pCurrentTripOdometer = 0;
    map(gpsPoints, (point, index) => {
        const currentStatus = getCurrentVehicleStatus(point);
        if (lastLat && lastLon && point.latitude && point.longitude) {
            p2pCurrentTripOdometer += pythagorasEquirectangular(lastLat, lastLon, point.latitude, point.longitude);
        }
        lastLat = point.latitude;
        lastLon = point.longitude;
        let odometer = getTotalOdometerTillNow(point, p2pCurrentTripOdometer); //!!point.currentOdometer ? point.currentOdometer : get(point, 'otherAttributes.odometer', 0);
        if (!startStatus) {
            startStatus = currentStatus;
            startTime = point.timeStamp;
            sLat = point.latitude;
            sLon = point.longitude;
            sOdo = odometer;
            endTime = point.timeStamp;
            eLat = point.latitude;
            eLon = point.longitude;
            eOdo = odometer;
        } else if (startStatus === currentStatus && index < gpsPointsLength - 1) {
            endTime = point.timeStamp;
            eLat = point.latitude;
            eLon = point.longitude;
            eOdo = odometer;
        } else {
            if (startStatus === currentStatus) {
                endTime = point.timeStamp;
                eLat = point.latitude;
                eLon = point.longitude;
                eOdo = odometer;
            }
            const lastMotionPoint = last(motionsPoints);
            if (
                optimizePoints &&
                lastMotionPoint &&
                startStatus === VEHICLE_STATUS.IDLE &&
                lastMotionPoint.status === currentStatus &&
                getMomentTime(endTime).diff(getMomentTime(startTime)) < 0
            ) {
                //check to counter fake idles. i.e idling less than 10s
                startTime = lastMotionPoint.sDate;
                sLat = lastMotionPoint.sLat;
                sLon = lastMotionPoint.sLon;
                sOdo = lastMotionPoint.sOdo;
                motionsPoints.pop();
            } else if (
                optimizePoints &&
                lastMotionPoint &&
                startStatus === VEHICLE_STATUS.RUNNING &&
                lastMotionPoint.status === currentStatus &&
                getMomentTime(endTime).diff(getMomentTime(startTime)) < 0
            ) {
                //check to counter fake running. i.e running less than 10s
                startTime = lastMotionPoint.sDate;
                sLat = lastMotionPoint.sLat;
                sLon = lastMotionPoint.sLon;
                sOdo = lastMotionPoint.sOdo;
                motionsPoints.pop();
            } else {
                if (
                    optimizePoints &&
                    lastMotionPoint &&
                    startStatus === VEHICLE_STATUS.RUNNING &&
                    lastMotionPoint.status === VEHICLE_STATUS.IDLE &&
                    getMomentTime(lastMotionPoint.eDate).diff(getMomentTime(lastMotionPoint.sDate)) < IDLE_DELAY_TIMEOUT
                ) {
                    //check for previous point idling less than IDLE_DELAY_TIMEOUT and include that in running.
                    // for first point case.
                    startTime = lastMotionPoint.sDate;
                    sLat = lastMotionPoint.sLat;
                    sLon = lastMotionPoint.sLon;
                    sOdo = lastMotionPoint.sOdo;
                    motionsPoints.pop();
                }
                if (
                    optimizePoints &&
                    lastMotionPoint &&
                    startStatus === VEHICLE_STATUS.IDLE &&
                    getMomentTime(endTime).diff(getMomentTime(startTime)) < IDLE_DELAY_TIMEOUT
                ) {
                    //check for idling less than IDLE_DELAY_TIMEOUT and include that in running
                    startStatus = VEHICLE_STATUS.RUNNING;
                    if (lastMotionPoint.status === VEHICLE_STATUS.RUNNING) {
                        startTime = lastMotionPoint.sDate;
                        sLat = lastMotionPoint.sLat;
                        sLon = lastMotionPoint.sLon;
                        sOdo = lastMotionPoint.sOdo;
                    }
                }
                if (optimizePoints && lastMotionPoint && startStatus === VEHICLE_STATUS.PARKED) {
                    startTime = getMomentTime(getMomentTime(lastMotionPoint.eDate).valueOf() + 1000).format(
                        DATE_FORMAT_TIMESTAMP
                    );
                }
                if (optimizePoints && currentStatus !== VEHICLE_STATUS.PARKED) {
                    endTime = getMomentTime(getMomentTime(point.timeStamp).valueOf() - 1000).format(
                        DATE_FORMAT_TIMESTAMP
                    );
                }

                if (startStatus === VEHICLE_STATUS.RUNNING && optimizePoints && Math.abs(eOdo - sOdo) < 1) {
                    startStatus = VEHICLE_STATUS.IDLE;
                }

                if (optimizePoints && lastMotionPoint && startStatus === lastMotionPoint.status) {
                    //check to merge last point with current because status is same
                    startTime = lastMotionPoint.sDate;
                    sLat = lastMotionPoint.sLat;
                    sLon = lastMotionPoint.sLon;
                    sOdo = lastMotionPoint.sOdo;
                    motionsPoints.pop();
                }
                motionsPoints.push({
                    status: startStatus,
                    sDate: startTime,
                    eDate: endTime,
                    sLat,
                    sLon,
                    eLat,
                    eLon,
                    sOdo,
                    eOdo: startStatus === VEHICLE_STATUS.RUNNING ? eOdo : sOdo,
                });
                startTime = point.timeStamp;
                sLat = point.latitude;
                sLon = point.longitude;
                sOdo = odometer;
            }
            startStatus = currentStatus;
            endTime = point.timeStamp;
            eLat = point.latitude;
            eLon = point.longitude;
            eOdo = odometer;
        }
    });
    return motionsPoints;
}

export function createTripsFromMotionPoints(motionPoints, staticTripData = {}) {
    const isAssetTracking = isAssetTrackingDevice(staticTripData.deviceId);
    const PARKED_DELAY_TIMEOUT_MS = (isAssetTracking ? 35 : 1) * 60000;
    const trips = [];
    let currentTrip = null,
        currentOdo = 0,
        lastEOdo = 0;
    map(motionPoints, (mp, index) => {
        if (mp.status === VEHICLE_STATUS.PARKED) {
            const diff = getMomentTime(mp.eDate).diff(getMomentTime(mp.sDate));
            if (diff > PARKED_DELAY_TIMEOUT_MS) {
                if (currentTrip != null && currentTrip.tOdo > 0) {
                    trips.push({ ...currentTrip });
                }
                currentTrip = null;
            }
        }
        if (mp.status === VEHICLE_STATUS.IDLE || mp.status === VEHICLE_STATUS.RUNNING) {
            if (currentTrip == null) {
                currentTrip = {
                    ...staticTripData,
                    tripId: getMomentTime(mp.sDate).valueOf(),
                    sLat: mp.sLat,
                    sLon: mp.sLon,
                    sDate: mp.sDate,
                    sOdo: parseFloat(mp.sOdo),
                    eLat: mp.eLat,
                    eLon: mp.eLon,
                    eDate: mp.eDate,
                    eOdo: parseFloat(mp.eOdo),
                    tOdo: parseFloat(mp.eOdo) - parseFloat(mp.sOdo),
                };
            } else {
                currentTrip = {
                    ...currentTrip,
                    eLat: mp.eLat,
                    eLon: mp.eLon,
                    eDate: mp.eDate,
                    eOdo: parseFloat(mp.eOdo),
                    tOdo: parseFloat(mp.eOdo) - currentTrip.sOdo,
                };
            }
        }
    });
    if (currentTrip != null && currentTrip.tOdo > 0) {
        trips.push({ ...currentTrip });
        currentTrip = null;
    }

    return trips;
}

export function getMinutePointsFromRoutes(trip, routes, intervalMinutes, startTime, totalDistanceTillTripStart) {
    const diffMinutes = !!intervalMinutes ? intervalMinutes : 1;
    if (!routes) {
        return null;
    }
    const gpsPoints = filter(routes, ['type', 'GPS']);
    if (gpsPoints.length == 0) {
        return null;
    }

    const minutePoints = [],
        length = gpsPoints.length;
    let distanceNormalizationFactor = 1;
    if (trip) {
        const tripOdo = parseFloat(get(last(gpsPoints), 'otherAttributes.tripOdometer', '0'));
        const tOdo = get(trip, 'tOdo', 1);
        distanceNormalizationFactor = !!tripOdo && !!tOdo ? tripOdo / tOdo : 1;
    }
    let curMomentTime = startTime.clone();

    let distanceTillNow = 0;
    map(gpsPoints, (point, index) => {
        const isLast = get(gpsPoints, 'length', 0) == index + 1;
        if (trip) {
            if ('tripOdometer' in point.otherAttributes) {
                distanceTillNow =
                    parseFloat(get(point, 'otherAttributes.tripOdometer', '0')) / distanceNormalizationFactor;
            } else {
                // take previous distanceTillNow
            }
        } else {
            if (index > 0) {
                distanceTillNow += pythagorasEquirectangular(
                    point.latitude,
                    point.longitude,
                    gpsPoints[index - 1].latitude,
                    gpsPoints[index - 1].longitude
                );
            }
        }
        if (getMomentTime(point.timeStamp).diff(curMomentTime, 'minute') >= 0 || isLast) {
            minutePoints.push({
                ...point,
                time: getHumanizeTime(getMomentTime(point.timeStamp), true, true),
                timeStamp: getMomentTime(point.timeStamp).format(DATE_FORMAT_TIMESTAMP),
                distance: totalDistanceTillTripStart + distanceTillNow,
            });
            curMomentTime = curMomentTime.add(diffMinutes, 'minutes');
        }
    });

    return minutePoints;
}

export function getVehicleNumberDigits(licensePlate, digits) {
    if (licensePlate) {
        return trim(licensePlate).slice(-digits || -4);
    } else {
        return '';
    }
}

export function getVehicleNumberPrefix(licensePlate) {
    if (licensePlate) {
        return trim(licensePlate).substr(0, trim(licensePlate).length - 4);
    } else {
        return '';
    }
}

export function checkForAlarms(vehicles, emitter) {
    forEach(vehicles, (vehicle) => {
        try {
            const alarm = JSON.parse(get(vehicle, 'otherAttributes.alarm', '""'));
            const type = toLower(get(alarm, '0.type'));
            const lastAlarmTriggered = get(vehicle, 'otherAttributes.lastAlarmTriggered');
            const currentStatus = get(vehicle, 'currentStatus');
            const lastUpdatedAt = get(vehicle, 'lastUpdatedAt');
            if (type === 'emergency') {
                sendAppAlertToSlack(
                    `Checking for realtime notification emergency alarm ${window.ACTIVE_EMAIL_ID} lastAlarmTriggered: ${lastAlarmTriggered}`,
                    '#test'
                );
            }
            if (
                lastAlarmTriggered &&
                type === 'emergency' &&
                !includes([VEHICLE_STATUS.DISCONNECTED, VEHICLE_STATUS.UNREACHABLE], currentStatus) &&
                getMomentTime().diff(lastUpdatedAt, 'minute') < 15
            ) {
                const alarmPlayedKey = lastAlarmTriggered + '';
                const isAlarmPlayed = window.checkAlarmPlayed && window.checkAlarmPlayed(alarmPlayedKey);
                sendAppAlertToSlack(
                    `Checking for realtime notification emergency alarm ${window.ACTIVE_EMAIL_ID} isAlarmPlayed: ${isAlarmPlayed} alarmPlayedKey: ${alarmPlayedKey} lastAlarmTriggered: ${lastAlarmTriggered}`,
                    '#test'
                );
                if (!isAlarmPlayed) {
                    if (window.saveAlarmPlayed) {
                        console.log(`saving alarm to played list ${alarmPlayedKey} ${window.ACTIVE_EMAIL_ID}`);
                        sendAppAlertToSlack(
                            `saving alarm to played list ${window.ACTIVE_EMAIL_ID} alarmPlayedKey: ${alarmPlayedKey}`,
                            '#test'
                        );
                        window.saveAlarmPlayed(alarmPlayedKey);
                    }
                    const soundLink = forceNotificationFlowWithoutFirebase()
                        ? 'https://app.fleetx.io/public/sounds/fire_alarm_3mins.mp3'
                        : 'https://app.fleetx.io/public/sounds/fire_alarm.wav';
                    const data = {
                        messageTitle: `${vehicle.vehicleNumber}: Emergency Alarm`,
                        messageBody: 'Please click for details',
                        messageIcon:
                            'https://app.fleetx.io/public/img/logos/notifications/fleetx-logo-short-emergency.png?v=2',
                        messageBadge: 'https://app.fleetx.io/public/img/logos/fleetx/fleetx-logo-short.png?v=2',
                        messageRequireInteraction: true,
                        type: type,
                        clickLink: `https://app.fleetx.io/dashboard/realtime/overview/${vehicle.vehicleId}/`,
                        soundLink: soundLink,
                        soundLoop: false,
                        optionToMuteSound: false,
                        lastAlarmTriggered: lastAlarmTriggered,
                    };
                    if (forceNotificationFlowWithoutFirebase()) {
                        sendAppAlertToSlack(
                            `Will play realtime notification emergency alarm ${window.ACTIVE_EMAIL_ID}`,
                            '#test'
                        );
                        emitActionValue(emitter, EMIT_PLAY_EMERGENCY_ALARM, data);
                    }
                    if (window.showManualNotification) {
                        window.showManualNotification(data);
                    }
                }
            }
        } catch (e) {
            console.log('e', e);
            sendAppAlertToSlack(`ERROR: Realtime notification emergency alarm ${window.ACTIVE_EMAIL_ID} ${e}`, '#test');
        }
    });
}

export function isAlarmToBeRenderedOnTop(item) {
    const status = toUpper(get(item, 'currentStatus'));
    if (!isEngineOn(status) && !isParked(status)) {
        return false;
    }
    try {
        const alarm = JSON.parse(get(item, 'otherAttributes.alarm'));
        if (toLower(get(alarm, '0.type', '')) === 'emergency') {
            return 7;
        }
        if (toLower(get(alarm, '0.type', '')) === 'speeding') {
            return 6;
        }
        if (toLower(get(alarm, '0.type', '')) === 'lock tamper') {
            return 5;
        }
        if (toLower(get(alarm, '0.type', '')) === 'lock critical battery') {
            return 4;
        }
        if (toLower(get(alarm, '0.type', '')) === 'lock low battery') {
            return 3;
        }

        if (toLower(get(alarm, '0.type', '')) === 'high engine coolant temperature') {
            return 2;
        }
        if (toLower(get(alarm, '0.type', '')) === 'route deviation') {
            return 1;
        }
        return false;
    } catch (e) {
        return false;
    }
}

export function orderVehiclesByStatus(vehicles, sortVehicleFilter, sortVehicleDirection) {
    return orderBy(vehicles, function (item) {
        const currentStatus = toUpper(item.currentStatus);
        const speed = get(item, 'speed', 0);
        const statusTime = get(item, 'lastStatusTime');
        let val = padStart(9999 - toSafeInteger(getVehicleNumberDigits(item.vehicleNumber)), 13, '0');
        let p1 = 0;
        if (sortVehicleFilter) {
            if (sortVehicleFilter === SORT_VEHICLE_TYPE.SPEED) {
                if (sortVehicleDirection === SORT_VEHICLE_DIRECTION.UP) {
                    val = padStart(500 - toSafeInteger(speed), 13, '0');
                } else {
                    val = padStart(toSafeInteger(speed), 13, '0');
                }
            }
            if (sortVehicleFilter === SORT_VEHICLE_TYPE.STATUS_TIME) {
                const time = isEngineOn(currentStatus)
                    ? get(item, 'durationEngineOn')
                    : getMomentTime().valueOf() - getMomentTime(statusTime).valueOf();
                if (sortVehicleDirection === SORT_VEHICLE_DIRECTION.UP) {
                    val = padStart(9999999999999 - toSafeInteger(time), 13, '0');
                } else {
                    val = padStart(time, 13, '0');
                }
            }

            //based on key "lastJobStatusTime"("lastStatusTime")
            if (sortVehicleFilter === SORT_VEHICLE_TYPE.JOB_STATUS_TIME) {
                const lastJobStatusTime = get(item, 'lastJobStatusTime', 0);
                if (sortVehicleDirection === SORT_VEHICLE_DIRECTION.DOWN) {
                    return lastJobStatusTime;
                } else {
                    return lastJobStatusTime * -1;
                }
            }
            //based on key "predictedDelay"
            if (sortVehicleFilter === SORT_VEHICLE_TYPE.JOB_DELAY_TIME) {
                const predictedDelay = get(item, 'predictedDelay', 0);
                if (sortVehicleDirection === SORT_VEHICLE_DIRECTION.UP) {
                    return predictedDelay;
                } else {
                    return predictedDelay * -1;
                }
            }
            if (sortVehicleFilter === SORT_VEHICLE_TYPE.NAME) {
                const vehicleName = get(item, 'vehicleName', 0);
                return vehicleName;
            }
        } else if (isAlarmToBeRenderedOnTop(item)) {
            const lastAlarmTriggered = get(item, 'otherAttributes.lastAlarmTriggered');
            val = lastAlarmTriggered;
            p1 = 2 + isAlarmToBeRenderedOnTop(item);
        } else {
            if (toUpper(item.currentStatus) === VEHICLE_STATUS.PARKED) {
                val = statusTime;
            }
            p1 = 1;
        }

        switch (currentStatus) {
            case VEHICLE_STATUS.RUNNING:
            case VEHICLE_STATUS.IDLE:
                return toSafeInteger('-' + p1 + 4 + '' + val);
            case VEHICLE_STATUS.PARKED:
            case VEHICLE_STATUS.PARKEDGTXMINUTES:
            case VEHICLE_STATUS.PARKEDLTXMINUTES:
            case VEHICLE_STATUS.PARKEDGTONEANDLTTWOHOUR:
            case VEHICLE_STATUS.PARKEDGTTWOANDLTTFOURHOUR:
            case VEHICLE_STATUS.PARKEDGTFOURANDLTFIVEHOUR:
            case VEHICLE_STATUS.PARKEDGTX4ANDLTY4HOUR:
            case VEHICLE_STATUS.PARKEDGTFIVEHOUR:
            case VEHICLE_STATUS.PARKED_10_DAYS:
                return toSafeInteger('-' + p1 + 3 + '' + val);
            case VEHICLE_STATUS.IMMOBILISED:
                return toSafeInteger('-' + p1 + 2 + '' + val);
            case VEHICLE_STATUS.NO_POWER:
            case VEHICLE_STATUS.BATTERY_DISCHARGED:
            case VEHICLE_STATUS.UNREACHABLE:
            case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
            case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
            case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
            case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
            case VEHICLE_STATUS.API_UNREACHABLE:
            case VEHICLE_STATUS.WIRING_DEFECT:
            case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
            case VEHICLE_STATUS.GPS_DEFECT:
            case VEHICLE_STATUS.GPS_0:
            case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
            case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
            case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
            case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
            case VEHICLE_STATUS.REMOVED:
            case VEHICLE_STATUS.INSHOP:
            case VEHICLE_STATUS.STANDBY:
            case VEHICLE_STATUS.DISCONNECTED:
            default:
                return toSafeInteger('-' + p1 + 1 + '' + val);
        }
    });
}

export function orderVehiclesByNearestLocation(
    vehicles,
    nearestVehiclesByLocation,
    searchLocationRadius = DEFAULT_LOCATION_SEARCH_RADIUS,
    searchByLocationValue
) {
    const orderedVehicles = orderBy(vehicles, function (item, index) {
        let positionIndex = findIndex(nearestVehiclesByLocation, ['vehicleId', item.vehicleId]);
        if (positionIndex >= 0 && nearestVehiclesByLocation[positionIndex]) {
            item.distanceFromLocation = nearestVehiclesByLocation[positionIndex].distance;
            item.etaFromLocation = nearestVehiclesByLocation[positionIndex].eta;
        }
        if (positionIndex >= 0 && !!item.distanceFromLocation) {
            return toSafeInteger(positionIndex);
        } else {
            return 1000000000;
        }
    });

    return filter(orderedVehicles, ({ latitude, longitude, distanceFromLocation, currentStatus }) => {
        // if (!isEngineOn(currentStatus) && !isParked(currentStatus)) {
        //     return false;
        // }

        if (!distanceFromLocation) {
            return false;
        }

        return distanceFromLocation / 1000 <= searchLocationRadius;
    });
}

export function renderVehicleLicensePlate(loggedInUser, licensePlate, isMuted, isBrackets, isItalics) {
    if (showVehicleNumber(loggedInUser)) {
        const isBolded = showLargeUiForAccount(loggedInUser);
        isBrackets = isBrackets && !isBolded;
        return (
            <span
                className={`${isMuted && !isBolded ? 'text-muted' : ''} ${isItalics && !isBolded ? 'font-italic' : ''}`}
            >
                {isBrackets ? '(' : ''}
                <span className={isBolded ? 'font-weight-bold' : ''}>{licensePlate}</span>
                {isBrackets ? ')' : ''}
            </span>
        );
    } else {
        return <span />;
    }
}

export function getVehicleCategory(maker, model) {
    if (!maker && !model) {
        return VEHICLE_CATEGORY.PASSENGER;
    }

    const heavyDutyMakerRegex = /Leyland|Volvo|Scania|Eicher|Force|BharatBenz|Mazda|amw|Man|Isuzu|Escorts/gi;
    const heavyDutyModelRegex = /bus|truck/gi;
    if (maker && maker.match(heavyDutyMakerRegex)) {
        return VEHICLE_CATEGORY.HEAVYDUTY;
    }
    if (model && model.match(heavyDutyModelRegex)) {
        return VEHICLE_CATEGORY.HEAVYDUTY;
    }
    if (maker && maker.match(/tata/gi)) {
        if (
            model &&
            model.match(
                /Lpk|lpt|lpo|ltp|Ultra|lps|1515|407|1512|1612|3015|prima|ace|lp|sfc|skool|winger|signa|hgv|rx/gi
            )
        ) {
            return VEHICLE_CATEGORY.HEAVYDUTY;
        }
    }
    if (maker && maker.match(/mahindra/gi)) {
        if (model && model.match(/bus|Pick-Up|Bolero|Blaz|truxo|MAXXIMO|Jeeto|MN 40|3075/gi)) {
            return VEHICLE_CATEGORY.HEAVYDUTY;
        }
    }
    if (maker && maker.match(/toyota/gi)) {
        if (model && model.match(/coaster/gi)) {
            return VEHICLE_CATEGORY.HEAVYDUTY;
        }
    }
    if (maker && maker.match(/maruti/gi)) {
        if (model && model.match(/super carry/gi)) {
            return VEHICLE_CATEGORY.HEAVYDUTY;
        }
    }
    return VEHICLE_CATEGORY.PASSENGER;
}

export function refactorCurrentStatus(vehicle, loggedInUser) {
    const accountId = get(loggedInUser, 'accountId', '');
    if (
        accountId == FLEETX_ACCOUNTS.BIRLA_DURGAPUR ||
        accountId == FLEETX_ACCOUNTS.CHANDERIA ||
        accountId == FLEETX_ACCOUNTS.MARUTI_AIS_TEST
    ) {
        return vehicle;
    }

    const isSwitchedUser = get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false);
    const currentStatusActual = get(vehicle, 'currentStatus');
    const isTruck = isTruckFleetType(loggedInUser);
    const isCar = isCarFleetType(loggedInUser);
    const deviceId = get(vehicle, 'deviceId');
    const isAssetTracking = isAssetTrackingDevice(deviceId, vehicle, get(vehicle, 'otherAttributes.deviceType'));
    // if (isOnBattery(currentStatusActual) && !isSwitchedUser) {
    //     vehicle.currentStatus = VEHICLE_STATUS.PARKED;
    // }

    // if (isDischarged(currentStatusActual) && !isSwitchedUser) {
    //     if (isTruck && !isAssetTracking) {
    //         if (shouldBatteryDischargedBeMarkedDisconnectVehicle(vehicle)) {
    //             vehicle.currentStatus = VEHICLE_STATUS.DISCONNECTED;
    //         } else {
    //             vehicle.currentStatus = VEHICLE_STATUS.PARKED;
    //         }
    //     } else if (isCar) {
    //         return vehicle;
    //     } else if (!isAssetTracking) {
    //         vehicle.currentStatus = VEHICLE_STATUS.DISCONNECTED;
    //     }
    // }

    // if (isDisconnected(get(vehicle, 'currentStatus'))) {
    //     if (isTruck) {
    //         // const daysDiff = getMomentTime().diff(getMomentTime(vehicle.lastStatusTime), 'days');
    //         // if (daysDiff >= 1) {
    //         //     return { ...vehicle, currentStatus: VEHICLE_STATUS.DISCONNECTED };
    //         // } else {
    //         //     return { ...vehicle, currentStatus: VEHICLE_STATUS.PARKED };
    //         // }
    //     } else {
    //     }
    // }
    return vehicle;
}

export function fixRoutes(routes = [], maxSpeedGovernerLimit, toFixAllPointsOdometer) {
    //let newRoutes = [...routes];
    let newRoutes = routes.slice();

    if (toFixAllPointsOdometer) {
        let startOdo = 0,
            currentOdo = 0,
            lastLat = 0,
            lastLon = 0;
        //const routesToFilter = [...newRoutes];
        const routesToFilter = newRoutes.slice();
        newRoutes = [];
        map(routesToFilter, (o, index) => {
            if (o.type != 'GPS') {
                newRoutes.push(setTotalOdometerTillNow(o, startOdo, currentOdo));
                return;
            }
            if (!lastLat || !lastLon) {
                if (!!o.latitude && !!o.longitude) {
                    lastLat = o.latitude;
                    lastLon = o.longitude;
                    startOdo = getTotalOdometerTillNow(o);
                }
                newRoutes.push(setTotalOdometerTillNow(o, startOdo, currentOdo));
                return;
            }

            const diff = pythagorasEquirectangular(lastLat, lastLon, o.latitude, o.longitude);

            if (diff < GPS_FILTER_LIMIT_MAX) {
                currentOdo += diff;
                newRoutes.push(setTotalOdometerTillNow(o, startOdo, currentOdo));
            }

            lastLat = o.latitude;
            lastLon = o.longitude;
        });
    }

    if (!!maxSpeedGovernerLimit) {
        newRoutes = filter(newRoutes, (o) => o.speed <= maxSpeedGovernerLimit);
    }
    return newRoutes;
}

export function appendDataInVehicleAnalytics(vehicleAnalyticsObject, addressBook) {
    const { sLat, sLon, eLat, eLon } = vehicleAnalyticsObject;
    let nearestStartAddress = null,
        nearestEndAddress = null;
    if (sLat && sLon) {
        nearestStartAddress = getNearestAddressFromAddressbook(sLat, sLon, addressBook, true);
    }
    if (eLat && eLon) {
        nearestEndAddress = getNearestAddressFromAddressbook(eLat, eLon, addressBook, true);
    }
    return {
        ...vehicleAnalyticsObject,
        nearestStartAddress,
        nearestEndAddress,
    };
}

export function getRealtimeSummary(
    vehicles,
    vehiclesOnJobAsMap,
    vehicleAlertsAsMap,
    isShowVehicleType,
    loggedInUser = window.FLEETX_LOGGED_IN_USER,
    moreDetails = {}
) {
    if (!vehicles) {
        return {};
    }
    const totalVehicles = vehicles.length;

    const { visibleVehicleDtcsMap, tagsWithCount } = moreDetails || {};

    const vehicleStatusCount = {
            totalVehicles: totalVehicles,
            runningVehicles: 0,
            runningVehiclesLessThanXkms: 0,
            idleVehicles: 0,
            parkedVehicles: 0,
            parkedVehiclesLessThanXMinutes: 0,
            parkedVehiclesGreaterThanXMinutes: 0,
            parkedVehiclesGreaterThanZerOAndLessThantwentyFoursHours: 0,
            parkedVehiclesGreaterThan24AndLess48: 0,
            parkedVehiclesGreaterThan48h: 0,
            parkedVehiclesGreaterThanOneAndLessThanTwoHour: 0,
            parkedVehiclesGreaterThanTwoAndLessThanFourHour: 0,
            parkedVehiclesGreaterThanFourAndLessThanFiveHour: 0,
            parkedVehiclesGreaterThanX4AndLessThanY4Hour: 0,
            parkedVehiclesGreaterThanFive: 0,
            parked10dVehicles: 0,
            disconnectedVehicles: 0,
            disconnect3dVehicles: 0,
            disconnect4dVehicles: 0,
            disconnect5dVehicles: 0,
            disconnect6dVehicles: 0,
            batteryDischargedVehicles: 0,
            immobilisedVehicles: 0,
            inshopVehicles: 0,
            removedVehicles: 0,
            standbyVehicles: 0,
            apiUnreachableVehicles: 0,
            unreachableVehicles: 0,
            unreachable3dVehicles: 0,
            unreachable4dVehicles: 0,
            unreachable5dVehicles: 0,
            unreachable6dVehicles: 0,
            wiringDefectVehicles: 0,
            wiringDefectParkedVehicles: 0,
            gpsDefectVehicles: 0,
            gps0Vehicles: 0,
            nopowerVehicles: 0,
            speedingVehicles: 0,
            routeDeviatedVehicles: 0,
            misuseVehicles: 0,
            lowFuelVehicles: 0,
            idleVehiclesLessThanXMinutes: 0,
            idleVehiclesGreaterThanXMinutes: 0,
            idleVehiclesGreaterThanXAndLessThanYHour: 0,
            vehicleWithCriticalDtcsCount: 0,
            offlineVehicles: 0,
        },
        jobStatusCount = {
            onJobVehicles: 0,
            notOnJobVehicles: 0,
            notAvailableVehicles: 0,
            loadedVehicles: 0,
            loadingVehicles: 0,
            unloadedVehicles: 0,
            unloadingVehicles: 0,
            scheduledVehicles: 0,
            waitingToLoad: 0,
            waitingToUnload: 0,
            waitingInGarage: 0,
            inGarage: 0,
            unloadingComplete: 0,
            inTransit: 0,
        },
        jobTimingStatusCount = {
            earlyVehicles: 0,
            lateVehicles: 0,
            ontimeVehicles: 0,
        },
        vehicleTypeCount = {},
        plantDirectionCount = {
            TO_PLANT: 0,
            TO_CUSTOMER: 0,
        };

    const nextRoutePointCategoryTypesCount = {};
    const currentRoutePointCategoryTypesCount = {};
    const scheduleToRoutePointCategoryTypesCount = {};
    const nextRoutePointAddressBookIds = [];

    const runningVehicleOdoValue = runningVehicleDetailedSummaryAvailable(loggedInUser).value;
    const idleVehicleSummaryAvailable = idleVehicleDetailedSummaryAvailable();
    const parkVehicleSummaryAvailable = parkedVehicleDetailedSummaryAvailable(loggedInUser);
    const parkVehicleGTAnd24H = parkedVehicleGT0AndLT24Hours();
    const parkVehicleGTAnd48H = parkedVehicleGT24AndLT48Hours();
    const parkVehicleGT48H = parkedVehicleGT48Hours();
    const parkVehicleGTXAndLTXHours = parkedVehicleGTXAndLTXHours();
    const parkVehicleGT2AndLT4Hour = parkedVehicleGT2AndLT4Hour();
    const parkVehicleGT4AndLT5Hour = parkedVehicleGT4AndLT5Hour();
    const parkVehicleLT5Hour = parkedVehicleLT5Hour();
    const parkVehicleGTX4AndLTY4Hour = parkedVehicleGTX4AndLTY4Hour();

    const idleVehicleGTXAndLTYH = idleVehicleGTXAndLTYHours();
    const showNextRoutePointCategoryFilter = showNextRoutePointCategoryTypeFilterInRealtime();
    const isExpressAccount = isExpressRoadwaysAccount(loggedInUser);
    forEach(vehicles, (vehicle) => {
        const { currentStatus, vehicleId, vehicleTypeValue, lastStatusTime } = vehicle;
        const vehicleJob = vehiclesOnJobAsMap && vehiclesOnJobAsMap[vehicleId];
        const vehicleAlert = vehicleAlertsAsMap && vehicleAlertsAsMap[vehicleId];
        if (vehicleAlert) {
            const openIssues = get(vehicleAlert, 'openIssuesCount');
            map(openIssues, (issue) => {
                const alertSubType = get(issue, 'subType');
                if (toUpper(alertSubType) === 'SPEEDING') {
                    vehicleStatusCount.speedingVehicles = vehicleStatusCount.speedingVehicles + 1;
                } else if (toUpper(alertSubType) === 'ROUTE DEVIATION') {
                    vehicleStatusCount.routeDeviatedVehicles = vehicleStatusCount.routeDeviatedVehicles + 1;
                } else if (toUpper(alertSubType) === 'MISUSE') {
                    vehicleStatusCount.misuseVehicles = vehicleStatusCount.misuseVehicles + 1;
                } else if (toUpper(alertSubType) === 'LOWFUEL') {
                    vehicleStatusCount.lowFuelVehicles = vehicleStatusCount.lowFuelVehicles + 1;
                }
            });
        }
        const {
            status,
            nextAddressBookCategory,
            nextAddressBookId,
            lastCrossedAddressBookId,
            currentAddressBookCategory,
        } = vehicleJob || {};
        let isVehicleOnJob;
        const lastStatusTimeInMin = moment.duration(getMomentTime().diff(getMomentTime(lastStatusTime))).asMinutes();

        switch (currentStatus) {
            case VEHICLE_STATUS.RUNNING:
                let tripOdo = get(vehicle, 'otherAttributes.tripOdometer', 0);
                tripOdo = parseFloat(tripOdo);
                if (tripOdo <= runningVehicleOdoValue) {
                    vehicleStatusCount.runningVehiclesLessThanXkms++;
                }
                vehicleStatusCount.runningVehicles++;
                break;
            case VEHICLE_STATUS.IDLE:
                vehicleStatusCount.idleVehicles++;

                let idleTimeInMin = getIdleTimeInMs(vehicle); // in ms
                idleTimeInMin = idleTimeInMin ? idleTimeInMin / MINUTES_1 : 0;
                if (idleVehicleSummaryAvailable) {
                    if (idleTimeInMin > idleVehicleSummaryAvailable.value) {
                        vehicleStatusCount.idleVehiclesGreaterThanXMinutes++;
                    } else {
                        vehicleStatusCount.idleVehiclesLessThanXMinutes++;
                    }
                }

                if (
                    idleVehicleGTXAndLTYH.status &&
                    idleVehicleGTXAndLTYH.greaterThan < idleTimeInMin &&
                    idleTimeInMin < idleVehicleGTXAndLTYH.lessThan
                ) {
                    vehicleStatusCount.idleVehiclesGreaterThanXAndLessThanYHour++;
                }

                break;
            case VEHICLE_STATUS.PARKED:
                vehicleStatusCount.parkedVehicles++;

                if (parkVehicleSummaryAvailable.status) {
                    if (lastStatusTimeInMin > parkVehicleSummaryAvailable.value) {
                        vehicleStatusCount.parkedVehiclesGreaterThanXMinutes++;
                    } else {
                        vehicleStatusCount.parkedVehiclesLessThanXMinutes++;
                    }
                }
                if (
                    parkVehicleGTAnd24H.status &&
                    parkVehicleGTAnd24H.greaterThan < lastStatusTimeInMin &&
                    lastStatusTimeInMin < parkVehicleGTAnd24H.lessThan
                ) {
                    vehicleStatusCount.parkedVehiclesGreaterThanZerOAndLessThantwentyFoursHours++;
                }

                if (
                    parkVehicleGTAnd48H.status &&
                    parkVehicleGTAnd48H.greaterThan < lastStatusTimeInMin &&
                    lastStatusTimeInMin < parkVehicleGTAnd48H.lessThan
                ) {
                    vehicleStatusCount.parkedVehiclesGreaterThan24AndLess48++;
                }
                if (parkVehicleGT48H.status && parkVehicleGT48H.greaterThan < lastStatusTimeInMin) {
                    vehicleStatusCount.parkedVehiclesGreaterThan48h++;
                }

                if (
                    parkVehicleGTXAndLTXHours.status &&
                    parkVehicleGTXAndLTXHours.greaterThan < lastStatusTimeInMin &&
                    lastStatusTimeInMin < parkVehicleGTXAndLTXHours.lessThan
                ) {
                    vehicleStatusCount.parkedVehiclesGreaterThanOneAndLessThanTwoHour++;
                } else if (
                    parkVehicleGT2AndLT4Hour.status &&
                    parkVehicleGT2AndLT4Hour.greaterThan <= lastStatusTimeInMin &&
                    lastStatusTimeInMin < parkVehicleGT2AndLT4Hour.lessThan
                ) {
                    vehicleStatusCount.parkedVehiclesGreaterThanTwoAndLessThanFourHour++;
                } else if (
                    parkVehicleGT4AndLT5Hour.status &&
                    parkVehicleGT4AndLT5Hour.greaterThan <= lastStatusTimeInMin &&
                    lastStatusTimeInMin < parkVehicleGT4AndLT5Hour.lessThan
                ) {
                    vehicleStatusCount.parkedVehiclesGreaterThanFourAndLessThanFiveHour++;
                } else if (
                    parkVehicleGTX4AndLTY4Hour.status &&
                    parkVehicleGTX4AndLTY4Hour.greaterThan <= lastStatusTimeInMin &&
                    lastStatusTimeInMin < parkVehicleGTX4AndLTY4Hour.lessThan
                ) {
                    vehicleStatusCount.parkedVehiclesGreaterThanX4AndLessThanY4Hour++;
                } else if (parkVehicleLT5Hour.status && parkVehicleLT5Hour.greaterThan <= lastStatusTimeInMin) {
                    vehicleStatusCount.parkedVehiclesGreaterThanFive++;
                }

                break;
            case VEHICLE_STATUS.PARKED_10_DAYS:
                vehicleStatusCount.parked10dVehicles++;
                break;
            case VEHICLE_STATUS.DISCONNECTED:
                vehicleStatusCount.disconnectedVehicles++;
                break;
            case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
                vehicleStatusCount.disconnect3dVehicles++;
                break;
            case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
                vehicleStatusCount.disconnect4dVehicles++;
                break;
            case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
                vehicleStatusCount.disconnect5dVehicles++;
                break;
            case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
                vehicleStatusCount.disconnect6dVehicles++;
                break;
            case VEHICLE_STATUS.BATTERY_DISCHARGED:
                vehicleStatusCount.batteryDischargedVehicles++;
                break;
            case VEHICLE_STATUS.IMMOBILISED:
                vehicleStatusCount.immobilisedVehicles++;
                break;
            case VEHICLE_STATUS.INSHOP:
                vehicleStatusCount.inshopVehicles++;
                break;
            case VEHICLE_STATUS.REMOVED:
                vehicleStatusCount.removedVehicles++;
                break;
            case VEHICLE_STATUS.STANDBY:
                vehicleStatusCount.standbyVehicles++;
                break;
            case VEHICLE_STATUS.API_UNREACHABLE:
                vehicleStatusCount.apiUnreachableVehicles++;
                break;
            case VEHICLE_STATUS.UNREACHABLE:
                vehicleStatusCount.unreachableVehicles++;
                break;
            case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
                vehicleStatusCount.unreachable3dVehicles++;
                break;
            case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
                vehicleStatusCount.unreachable4dVehicles++;
                break;
            case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
                vehicleStatusCount.unreachable5dVehicles++;
                break;
            case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
                vehicleStatusCount.unreachable6dVehicles++;
                break;
            case VEHICLE_STATUS.WIRING_DEFECT:
                vehicleStatusCount.wiringDefectVehicles++;
                break;
            case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
                vehicleStatusCount.wiringDefectParkedVehicles++;
                break;
            case VEHICLE_STATUS.GPS_DEFECT:
                vehicleStatusCount.gpsDefectVehicles++;
                break;
            case VEHICLE_STATUS.GPS_0:
                vehicleStatusCount.gps0Vehicles++;
                break;
            case VEHICLE_STATUS.NO_POWER:
                vehicleStatusCount.nopowerVehicles++;
                break;
            case VEHICLE_STATUS.OFFLINE:
                vehicleStatusCount.offlineVehicles++;
                break;
        }

        isVehicleOnJob = true;
        switch (status) {
            case VEHICLE_JOB_STATE.LOADED:
                jobStatusCount.loadedVehicles++;
                break;
            case VEHICLE_JOB_STATE.LOADING:
                jobStatusCount.loadingVehicles++;
                break;
            case VEHICLE_JOB_STATE.UNLOADED:
                jobStatusCount.unloadedVehicles++;
                break;
            case VEHICLE_JOB_STATE.UNLOADING:
                jobStatusCount.unloadingVehicles++;
                break;
            case VEHICLE_JOB_STATE.SCHEDULED:
                jobStatusCount.scheduledVehicles++;
                break;
            case VEHICLE_JOB_STATE.WAITING_TO_LOAD:
                jobStatusCount.waitingToLoad++;
                break;
            case VEHICLE_JOB_STATE.WAITING_TO_UNLOAD:
                jobStatusCount.waitingToUnload++;
                break;
            case VEHICLE_JOB_STATE.WAITING_IN_GARAGE:
                jobStatusCount.waitingInGarage++;
                break;
            case VEHICLE_JOB_STATE.IN_GARAGE:
                jobStatusCount.inGarage++;
                break;
            case VEHICLE_JOB_STATE.UNLOADING_COMPLETE:
                jobStatusCount.unloadingComplete++;
                break;
            case VEHICLE_JOB_STATE.IN_TRANSIT:
                jobStatusCount.inTransit++;
                break;
            default:
                isVehicleOnJob = false;
        }

        const timeStatus = get(vehicleJob, 'timeStatus', 0);
        if (isVehicleOnJob) {
            if (timeStatus == 'ONTIME') {
                jobTimingStatusCount.ontimeVehicles++;
            } else if (timeStatus == 'LATE') {
                jobTimingStatusCount.lateVehicles++;
            } else if (timeStatus == 'EARLY') {
                jobTimingStatusCount.earlyVehicles++;
            }
        }
        if (isShowVehicleType) {
            forEach(VEHICLE_TYPE_VALUE, (v, k) => {
                if (isVehicleTypeValueValidForAccount(v)) {
                    if (isUndefined(vehicleTypeCount[k])) {
                        vehicleTypeCount[k] = 0;
                    }
                    if (v === vehicleTypeValue) {
                        vehicleTypeCount[k]++;
                    }
                }
            });
        }
        if (isVehicleOnJob) {
            plantDirectionCount.TO_CUSTOMER++;
        } else {
            plantDirectionCount.TO_PLANT++;
        }

        if (isExpressAccount) {
            const isTagAttachedToVehicle = (tagId) => {
                return includes(get(tagsWithCount, `${tagId}.vehicleIds`, []), vehicleId);
            };
            if (
                !isVehicleOnJob &&
                (isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.WITHOUT_DRIVER) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.ACCIDENT) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.ON_ROUTE_MAINTENANCE) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.ATN_HUB) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.MEDCHAL_HUB) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.BILASPUR_HUB) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.TALOJA_HUB) ||
                    isTagAttachedToVehicle(EXPRESS_ROADWAYS_SYSTEM_TAGS.Dhulagarh_HUB))
            ) {
                jobStatusCount.notAvailableVehicles++;
            }
        }

        if (
            showNextRoutePointCategoryFilter &&
            isVehicleOnJob &&
            currentAddressBookCategory &&
            lastCrossedAddressBookId
        ) {
            if (!currentRoutePointCategoryTypesCount[currentAddressBookCategory]) {
                currentRoutePointCategoryTypesCount[currentAddressBookCategory] = 0;
            }

            currentRoutePointCategoryTypesCount[currentAddressBookCategory]++;
        }

        if (
            showNextRoutePointCategoryFilter &&
            isVehicleOnJob &&
            !currentAddressBookCategory &&
            lastCrossedAddressBookId
        ) {
            const categoryKey = nextAddressBookCategory ? nextAddressBookCategory : NO_NEXT_ROUTE_POINT_CATEGORY;

            if (nextAddressBookId) {
                nextRoutePointAddressBookIds.push(nextAddressBookId);
            }
            if (!nextRoutePointCategoryTypesCount[categoryKey]) {
                if (!nextRoutePointCategoryTypesCount[NO_NEXT_ROUTE_POINT_CATEGORY]) {
                    nextRoutePointCategoryTypesCount[NO_NEXT_ROUTE_POINT_CATEGORY] = 0;
                }
                nextRoutePointCategoryTypesCount[categoryKey] = 0;
            }

            nextRoutePointCategoryTypesCount[categoryKey]++;
        }

        if (
            showNextRoutePointCategoryFilter &&
            isVehicleOnJob &&
            !currentAddressBookCategory &&
            !lastCrossedAddressBookId
        ) {
            const categoryKey = nextAddressBookCategory ? nextAddressBookCategory : NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY;
            if (!scheduleToRoutePointCategoryTypesCount[categoryKey]) {
                if (!scheduleToRoutePointCategoryTypesCount[NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY]) {
                    scheduleToRoutePointCategoryTypesCount[NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY] = 0;
                }
                scheduleToRoutePointCategoryTypesCount[categoryKey] = 0;
            }

            scheduleToRoutePointCategoryTypesCount[categoryKey]++;
        }

        if (isDtcOnRealtimeEnabled()) {
            const vehicleDtcArr = visibleVehicleDtcsMap[vehicleId];
            const count = vehicleDtcArr && vehicleDtcArr.length ? 1 : 0;
            vehicleStatusCount.vehicleWithCriticalDtcsCount = vehicleStatusCount.vehicleWithCriticalDtcsCount + count;
        }
    });

    const onJobVehicles =
        jobStatusCount.loadedVehicles +
        jobStatusCount.loadingVehicles +
        jobStatusCount.unloadedVehicles +
        jobStatusCount.unloadingVehicles +
        jobStatusCount.scheduledVehicles +
        jobStatusCount.waitingToLoad +
        jobStatusCount.waitingToUnload +
        jobStatusCount.waitingInGarage +
        jobStatusCount.unloadingComplete +
        jobStatusCount.inGarage;
    let notOnJobVehicles = totalVehicles - onJobVehicles - vehicleStatusCount.removedVehicles;

    return {
        vehicleStatusCount,
        jobStatusCount: {
            ...jobStatusCount,
            onJobVehicles,
            notOnJobVehicles: notOnJobVehicles >= 0 ? notOnJobVehicles : 0,
        },
        jobTimingStatusCount,
        vehicleTypeCount,
        plantDirectionCount,
        nextRoutePointCategoryTypesCount,
        currentRoutePointCategoryTypesCount,
        scheduleToRoutePointCategoryTypesCount,
        nextRoutePointAddressBookIds: uniq(nextRoutePointAddressBookIds),
    };
}

export function mappedVehicleStatus() {
    return map(VEHICLE_STATUS_NAME, (value, key) => {
        return {
            value: key,
            label: value,
        };
    });
}

export const defaultTollVehicleType = {
    value: 'TRUCK',
    label: 'Truck',
    tollKey: 'busRateMulti',
};

export const TECHNICIAN_VEHICLE_DUTY_TYPES = [
    {
        value: 'INSTALL',
        label: 'INSTALL',
    },
    {
        value: 'REPAIR',
        label: 'REPAIR',
    },
    {
        value: 'REMOVE',
        label: 'REMOVE',
    },
    {
        value: 'REMOVE_PORT',
        label: 'REMOVE PORT',
    },
    {
        value: 'REPLACE',
        label: 'REPLACE',
    },
];

export function getSep() {
    return <span className="mx-1 text-muted">|</span>;
}

export function getVehicleMakeModelOptions(vehicleList, makeOptions = true, modelOptions = true) {
    const vehicleMakeOptionsAsMap = {};
    const vehicleModelOptionsAsMap = {};
    forEach(vehicleList, (v) => {
        const vehicleModel = get(v, 'vehicleModel');
        const vehicleMake = get(v, 'vehicleModel.vehicleMaker');
        if (vehicleMake && !vehicleMakeOptionsAsMap[vehicleMake.id]) {
            vehicleMakeOptionsAsMap[vehicleMake.id] = pick(vehicleMake, ['id', 'name']);
        }
        if (vehicleModel && !vehicleModelOptionsAsMap[vehicleModel.id]) {
            vehicleModelOptionsAsMap[vehicleModel.id] = {
                ...pick(vehicleModel, ['id', 'name']),
                vehicleMakeId: vehicleMake.id,
            };
        }
    });
    let vehicleMakeOptions = [];
    let vehicleModelOptions = [];
    if (makeOptions) {
        vehicleMakeOptions = map(vehicleMakeOptionsAsMap, (value, key) => {
            return {
                label: value.name,
                value: value.id,
            };
        });
    }
    if (modelOptions) {
        vehicleModelOptions = map(vehicleModelOptionsAsMap, (value, key) => {
            return {
                ...pick(value, ['vehicleMakeId']),
                label: value.name,
                value: value.id,
            };
        });
    }
    return [vehicleMakeOptions, vehicleModelOptions];
}

export const DOCUMENT_TAGS = [
    {
        value: 'AMC',
        label: 'AMC',
    },
    {
        value: 'CLLI',
        label: 'CLLI - Carrier Legal Liability Insurance',
    },
    {
        value: 'EXPLOSIVE_LICENSE',
        label: 'Explosive License',
    },
    {
        value: 'HYDRO_TEST',
        label: 'Hydro Test',
    },
    {
        value: 'FITNESS',
        label: 'Fitness',
    },
    {
        value: 'INVARIANT_AND_VETTING_RECORD',
        label: 'Invariant and Vetting Record',
    },
    {
        value: 'INSURANCE',
        label: 'Insurance',
    },
    {
        value: 'PERMIT',
        label: 'Permit',
    },
    {
        value: 'PLI',
        label: 'PLI - Public Liability Insurance',
    },
    {
        value: 'POLLUTION_CERTIFICATE',
        label: 'Pollution Certificate',
    },
    {
        value: 'PURGING',
        label: 'Purging',
    },
    {
        value: 'RC',
        label: 'RC',
    },
    {
        value: 'ROAD_TAX',
        label: 'Road Tax',
    },
    {
        value: 'SPARK_ARRESTOR_CERTIFICATE',
        label: 'Spark Arrestor Certificate',
    },
    {
        value: 'SPEED_LIMIT_CERTIFICATE',
        label: 'Speed Limit Certificate',
    },
    {
        value: 'TANK_MAP',
        label: 'Tank Map',
    },
    {
        value: 'VEHICLE_RC_FRONT',
        label: 'Vehicle RC front',
    },
    {
        value: 'VEHICLE_RC_BACK',
        label: 'Vehicle RC back',
    },
    {
        value: 'VEHICLE_NUMBER_PLATE',
        label: 'Vehicle Number Plate',
    },
    { value: 'Bulker Tank Report', label: 'Bulker Tank Report' },
    { value: 'State Permit', label: 'State Permit' },
    {
        value: 'Vehicle Particulars',
        label: 'Vehicle Particulars',
    },
    {
        value: 'T1',
        label: 'T1',
    },
    {
        value: 'IM8',
        label: 'IM8',
    },
    {
        value: 'SubT1',
        label: 'SubT1',
    },
    {
        value: 'Invoice (INV)',
        label: 'Invoice (INV)',
    },
    {
        value: 'Packing list (PL)',
        label: 'Packing list (PL)',
    },
    {
        value: 'Movement sheet',
        label: 'Movement sheet',
    },
    {
        value: 'Bill of Lading (BL)',
        label: 'Bill of Lading (BL)',
    },
    {
        value: 'Cargo Delivery Note (CDN)',
        label: 'Cargo Delivery Note (CDN)',
    },
    {
        value: 'Danda tax of West Bengal',
        label: 'Danda tax of West Bengal',
    },
    {
        value: 'Fitness',
        label: 'Fitness',
    },
    {
        value: 'Others',
        label: 'Others',
    },
    {
        value: 'State Permit',
        label: 'State Permit',
    },
    {
        value: 'Five Year Permit',
        label: 'Five Year Permit',
    },
    {
        value: 'Cross Border Permit',
        label: 'Cross Border Permit',
    },
    {
        value: 'Zambian Insurance',
        label: 'Zambian Insurance',
    },
    {
        value: 'Identity',
        label: 'Identity',
    },
    {
        value: 'Comesa',
        label: 'Comesa',
    },
    {
        value: 'Mozambique Insurance',
        label: 'Mozambique Insurance',
    },
    {
        value: 'Beira Port Entry',
        label: 'Beira Port Entry',
    },
    {
        value: 'Zimbabwe Cvg',
        label: 'Zimbabwe Cvg',
    },
    {
        value: 'Tanzania C29',
        label: 'Tanzania C29',
    },
    {
        value: 'Mv Tax',
        label: 'Mv Tax',
    },
    {
        value: 'PURE_OTHERS',
        label: 'Others',
    },
];

export function getGroupNameForVehicleInTagHistory(licencePlate, vehicleListMiniAsMap, groupList) {
    const vehicle = find(vehicleListMiniAsMap, (item) => {
        return item.licensePlate === licencePlate;
    });
    const groupId = get(vehicle, 'groupId');
    const groupName = getGroupName(groupId, groupList);
    return groupName ? groupName : '';
}

export function getTagAttachedDurationInTagHistory(attachDate, detachDate) {
    let toValidAttachDate = attachDate;
    let toValidDetachDate = detachDate;
    if (toValidAttachDate && isString(toValidAttachDate)) {
        toValidAttachDate = getYYYYMMDDFormatStringFrom(toValidAttachDate, 'DD-MM-YYYY');
    }
    if (toValidDetachDate && isString(toValidDetachDate)) {
        toValidDetachDate = getYYYYMMDDFormatStringFrom(toValidDetachDate, 'DD-MM-YYYY');
    } else {
        toValidDetachDate = getMomentTime();
    }
    return getMomentTime(toValidDetachDate).diff(getMomentTime(toValidAttachDate));
}

export const VEHICLE_SUMMARY_TABS = {
    VEHICLE_COST: {
        key: 'VEHICLE_COST',
        order: 1,
        labelKey: 'vehicleCost',
    },
    GEAR_PREDICTION: {
        key: 'GEAR_PREDICTION',
        order: 2,
        labelKey: 'gearPrediction',
    },
};

export function getSummaryTabLabel(tabLabel) {
    return window.t(tabLabel);
}

export const downloadVehicleAttendanceReport = (dataList, reportFormat, dates) => {
    const reportName = createReportFilename(reportFormat, 'Vehicle-Attendance', null, null, dates.from, dates.to);

    let noOfCols = 0;
    const headers = [];

    noOfCols += addHeader(true, headers, 'createdDate', 'Created Date');
    noOfCols += addHeader(true, headers, 'attendance', 'Attendance');
    noOfCols += addHeader(true, headers, 'vehicleName', 'Vehicle Name');
    noOfCols += addHeader(true, headers, 'licensePlate', 'License Plate');

    const data = [];

    map(dataList, (item) => {
        const dataRow = {};

        addDataRow(true, dataRow, 'createdDate', getMomentTime(item.createdDate).format('DD-MM-YYYY'));
        addDataRow(true, dataRow, 'attendance', item.present ? 'Present' : 'Absent');
        addDataRow(true, dataRow, 'vehicleName', item.vehicle.name);
        addDataRow(true, dataRow, 'licensePlate', item.vehicle.licensePlate);

        data.push(dataRow);
    });

    const totalRow = {};

    data.push(totalRow);

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Materials Report',
        null,
        null,
        null,
        reportName,
        noOfCols,
        null
    );
};

export const VEHICLE_IMMOBILISE_COMMANDS = {
    CUT: 'CUT',
    RESTORE: 'RESTORE',
};

export const VOICE_BOX_OPTIONS = [{ label: 'FMB_SET_KALYANI_VOICE_BOX', value: 'FMB_SET_KALYANI_VOICE_BOX' }];

export const prepareListData = (checkListEntries) => {
    return map(checkListEntries, (d, key) => {
        const onDate = get(d, `[0].createdDate`);
        const totalScore = reduce(
            d,
            (acc, v) => {
                return acc + get(v, 'score', 0);
            },
            0
        );
        return {
            id: key,
            totalScore,
            onDate,
        };
    });
};
export const prepareRowDetailsData = (rowQList, questionsList) => {
    return map(questionsList, (q) => {
        const answeredQuestion = find(rowQList, { questionId: q.id }) || {
            questionId: q.id,
        };
        return {
            ...pick(q, ['question', 'checklistQuestionType', 'defaultAnswer', 'maximumScore', 'minimumScore']),
            ...answeredQuestion,
        };
    });
};

export async function downloadImagesAsZip(images, fileName = 'images.zip') {
    const zip = new JSZip();
    const fetchImage = async (image) => {
        const response = await fetchImages(image.imageUrl).promise;
        const filename = `${getFileNameFromUrl(response.config.url)}.${getMimeTypeToExtension(response.data.type)}`;
        zip.file(filename, response.data);
    };
    await Promise.allSettled(images.map(fetchImage));
    zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = URL.createObjectURL(content);
        saveAs(url, fileName);
        URL.revokeObjectURL(url);
    });
}

export function isSensorSubTypeEditAllowed() {
    const email = get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId');
    return (
        showIot() || includes(['waish.ansari@fleetx.io', 'vinod.mehra@fleetx.io', 'pawan@fleetx.io'], toLower(email))
    );
}

export const TEMPERATURE_TABS = {
    OVERALL_ANALYTICS: 'OVERALL_ANALYTICS',
    TEMPERATURE_LEVEL_GRAPH: 'TEMPERATURE_LEVEL_GRAPH',
};

export const generateDateRange = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const dates = [];

    while (start.isSameOrBefore(end)) {
        dates.push(start.format('YYYY-MM-DD'));
        start.add(1, 'days');
    }

    return dates;
};

const complianceThresholds = {
    'Vehicles Complying 95-100%': { min: 95, max: 100 },
    'Vehicles Complying 90-95%': { min: 90, max: 95 },
    'Vehicles Complying <90%': { min: 0, max: 90 },
};

export const filterByCompliance = (data, complianceLevel, date) => {
    const { min, max } = complianceThresholds[complianceLevel];
    return filter(data, (row) => {
        const compliance = row[date] || 0;
        return compliance >= min && compliance <= max;
    });
};

export function customFieldMandatoryConfigForVehicleForm() {
    const accountId = get(window, 'FLEETX_LOGGED_IN_USER.accountId');
    switch (accountId) {
        case 13518:
            return {
                3117: {
                    name: 'Reason for Vehicle Blacklisting',
                    disabled: false,
                    required: true,
                    mandatory: true,
                    id: 3117,
                },
            };
        default:
            return null;
    }
}

export function prepareCustomFieldsForVehicleSubmit(customFields) {
    const returnCustomFields = [];
    if (customFields) {
        forEach(customFields, (cf) => {
            if (!isEmpty(cf)) {
                const id = get(cf, 'id');
                const value = cf[id];
                const field = {
                    field: {
                        id: +id,
                    },
                    value: get(cf, 'value', ''),
                };
                if (+id > 0) {
                    returnCustomFields.push(field);
                }
            }
        });
    }
    return returnCustomFields;
}

export const TRUCK_LOAD_TYPE = [
    { label: 'Bag', value: 'Bag' },
    { label: 'Bulk', value: 'Bulk' },
];

export const NOOFWHEELS = [
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 6, value: 6 },
    { label: 8, value: 8 },
    { label: 10, value: 10 },
    { label: 12, value: 12 },
    { label: 14, value: 14 },
    { label: 16, value: 16 },
    { label: 18, value: 18 },
    { label: 20, value: 20 },
    { label: 22, value: 22 },
    { label: 24, value: 24 },
    { label: 26, value: 26 },
    { label: 28, value: 28 },
    { label: 30, value: 30 },
    { label: 32, value: 32 },
    { label: 34, value: 34 },
    { label: 36, value: 36 },
];

export const vehicleOwnershipMapForOrient = () => {
    return [
        {
            value: 'OWNED',
            label: 'Owned',
        },

        {
            value: 'ATTACHED',
            label: 'Attached',
        },
        {
            value: 'MARKET',
            label: 'Market',
        },
        {
            value: 'COMMON',
            label: 'Common',
        },
    ];
};

export const defaultFormData = {
    'name': '',
    'licensePlate': '',
    'year': null,
    'vehicleModel': {
        'vehicleMaker': { id: null },
        id: null,
    },
    'vehicleType': { id: null },
    'fuelType': { id: null },
    'licensePlate': '',
    'vehicleStatus': { id: null },
    'vehicleSpecs': {
        'workmode': 2,
    },
};

export const validationSchema = (fieldsMetaData) =>
    Yup.object().shape({
        name: Yup.string().required(t('vehicleNameIsRequired')),
        licensePlate: Yup.string().required(t('vehicleNumberIsRequired')),
        year: Yup.number()
            // .transform((value) => Number.isNaN(value) ? null : value )
            .required(t('vehicleYearIsRequired'))
            .nullable(),
        vehicleModel: Yup.object().shape({
            vehicleMaker: Yup.object().shape({
                id: Yup.string().nullable().required(t('vehicleMakerIsRequired')),
            }),
            id: Yup.string().nullable().required(t('vehicleModelIsRequired')),
        }),
        vehicleType: Yup.object().shape({
            id: Yup.string().nullable().required(t('vehicleTypeIsRequired')),
        }),
        fuelType: Yup.object().shape({
            id: Yup.string().nullable().required('Fuel type is required.'),
        }),
        vehicleStatus: Yup.object().shape({
            id: Yup.string().nullable().required(t('vehicleStatusIsRequired')),
        }),
        vehicleOwnership: Yup.string().test('isRequired', 'Vehicle Ownership is required', function (value) {
            if ((isFormFieldRequired() || isOrientCementAccount(getAccountId())) && !values) {
                return false;
            }

            return true;
        }),
        simTracking: Yup.object()
            .shape({
                mobileNumber: Yup.string().nullable(),
                simProvider: Yup.string().nullable(),
                expiryDate: Yup.date().nullable(),
            })
            .nullable()
            .test('simTracking-required', 'Sim Tracking is Required', function (value) {
                const { mobileNumber, simProvider, expiryDate } = value || {};
                if (mobileNumber || simProvider) {
                    if (!mobileNumber || !/^\d{10}$/.test(mobileNumber)) {
                        return this.createError({
                            path: 'simTracking.mobileNumber',
                            message: t('pleaseEnterAValid10DigitMobileNumber'),
                        });
                    }
                    if (!simProvider || !SIM_PROVIDER[simProvider]) {
                        return this.createError({
                            path: 'simTracking.simProvider',
                            message: t('pleaseSelectAMobileNetwork'),
                        });
                    }
                    console.log('expiryDate', expiryDate);
                    if (!expiryDate) {
                        return this.createError({ path: 'simTracking.expiryDate', message: t('dateRequired') });
                    }
                }

                return true;
            }),
        //for all non-mandatory fields - using this for required fields in template.
        vin: Yup.string()
            .test('isRequired', 'Vin is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'vin' });
            })
            .nullable(),
        fuelCardNumber: Yup.string()
            .test('isRequired', 'Fuel Card Number is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'fuelCardNumber' });
            })
            .nullable(),
        mileage: Yup.string()
            .test('isRequired', 'Mileage is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'mileage' });
            })
            .nullable(),
        trim: Yup.string()
            .test('isRequired', 'Trim is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'trim' });
            })
            .nullable(),
        registrationState: Yup.string()
            .test('isRequired', 'Registration State is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'registrationState' });
            })
            .nullable(),
        totalWorkingHoursCM: Yup.string()
            .test('isRequired', 'Total Working Hours CM is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'totalWorkingHoursCM' });
            })
            .nullable(),
        odometerReading: Yup.string()
            .test('isRequired', 'Odometer Reading is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'odometerReading' });
            })
            .nullable(),
        registrationDate: Yup.string()
            .test('isRequired', 'Registration Date is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'registrationDate' });
            })
            .nullable(),
        axleNumber: Yup.string()
            .test('isRequired', 'Axle Number is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'axleNumber' });
            })
            .nullable(),
        vendor: Yup.object().shape({
            id: Yup.string()
                .test('isRequired', 'Vendor is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vendor.id' });
                })
                .nullable(),
        }),
        branches: Yup.string()
            .test('isRequired', 'Branches is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'branches' });
            })
            .nullable(),
        transporterIds: Yup.string()
            .test('isRequired', 'Transporter IDs is Required', function (value) {
                if (isTransporter()) {
                    return true;
                }
                return validateFieldRequired({ value, fieldsMetaData, id: 'transporterIds' });
            })
            .nullable(),
        currentTransporterId: Yup.string()
            .test('isRequired', 'Current Transporter ID is Required', function (value) {
                if (isTransporter()) {
                    return true;
                }
                return validateFieldRequired({ value, fieldsMetaData, id: 'currentTransporterId' });
            })
            .nullable(),
        group: Yup.object().shape({
            id: Yup.string()
                .test('isRequired', 'Group is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'group.id' });
                })
                .nullable(),
        }),
        vehicleDriver: Yup.object().shape({
            id: Yup.string()
                .test('isRequired', 'Vehicle Driver is Required', function (value) {
                    if (isTransporter() && !value) {
                        return false;
                    }

                    return true;
                })
                .nullable(),
        }),
        parentId: Yup.string()
            .test('isRequired', 'Parent ID is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'parentId' });
            })
            .nullable(),
        purchaseValue: Yup.string()
            .test('isRequired', 'Purchase Value is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'purchaseValue' });
            })
            .nullable(),
        scrappingValue: Yup.string()
            .test('isRequired', 'Scrapping Value is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'scrappingValue' });
            })
            .nullable(),
        expectedPercentageDecline: Yup.string()
            .test('isRequired', 'Expected Percentage Decline is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'expectedPercentageDecline' });
            })
            .nullable(),
        lifeTimeInYear: Yup.string()
            .test('isRequired', 'Lifetime in Years is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'lifeTimeInYear' });
            })
            .nullable(),
        lifeTimeInKm: Yup.string()
            .test('isRequired', 'Lifetime in KM is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'lifeTimeInKm' });
            })
            .nullable(),
        color: Yup.string()
            .test('isRequired', 'Color is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'color' });
            })
            .nullable(),
        residualValue: Yup.string()
            .test('isRequired', 'Residual Value is Required', function (value) {
                return validateFieldRequired({ value, fieldsMetaData, id: 'residualValue' });
            })
            .nullable(),
        vehicleSpecs: Yup.object().shape({
            perDaySalary: Yup.string()
                .test('isRequired', 'Per Day Salary is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.perDaySalary' });
                })
                .nullable(),

            bodyType: Yup.string()
                .test('isRequired', 'Body Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.bodyType' });
                })
                .nullable(),

            bodySubType: Yup.string()
                .test('isRequired', 'Body Sub Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.bodySubType' });
                })
                .nullable(),

            width: Yup.string()
                .test('isRequired', 'Width is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.width' });
                })
                .nullable(),

            height: Yup.string()
                .test('isRequired', 'Height is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.height' });
                })
                .nullable(),

            length: Yup.string()
                .test('isRequired', 'Length is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.length' });
                })
                .nullable(),

            interiorVolume: Yup.string()
                .test('isRequired', 'Interior Volume is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.interiorVolume' });
                })
                .nullable(),

            maxHp: Yup.string()
                .test('isRequired', 'Max HP is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.maxHp' });
                })
                .nullable(),

            fuelTankCapacity: Yup.string()
                .test('isRequired', 'Fuel Tank Capacity is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.fuelTankCapacity' });
                })
                .nullable(),

            fuelTank2Capacity: Yup.string()
                .test('isRequired', 'Fuel Tank 2 Capacity is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.fuelTank2Capacity' });
                })
                .nullable(),

            cngCapacity: Yup.string()
                .test('isRequired', 'CNG Capacity is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.cngCapacity' });
                })
                .nullable(),

            engineDescription: Yup.string()
                .test('isRequired', 'Engine Description is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineDescription' });
                })
                .nullable(),

            engineBrand: Yup.string()
                .test('isRequired', 'Engine Brand is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineBrand' });
                })
                .nullable(),

            engineAspiration: Yup.string()
                .test('isRequired', 'Engine Aspiration is Required', function (value) {
                    if (isTransporter()) {
                        return true;
                    }
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineAspiration' });
                })
                .nullable(),

            engineCompression: Yup.string()
                .test('isRequired', 'Engine Compression is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineCompression' });
                })
                .nullable(),

            engineCylinders: Yup.string()
                .test('isRequired', 'Engine Cylinders is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineCylinders' });
                })
                .nullable(),

            fuelInduction: Yup.string()
                .test('isRequired', 'Fuel Induction is Required', function (value) {
                    if (isTransporter()) {
                        return true;
                    }
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.fuelInduction' });
                })
                .nullable(),

            fuelQuality: Yup.string()
                .test('isRequired', 'Fuel Quality is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.fuelQuality' });
                })
                .nullable(),

            maxTorque: Yup.string()
                .test('isRequired', 'Max Torque is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.maxTorque' });
                })
                .nullable(),

            transmissionType: Yup.string()
                .test('isRequired', 'Transmission Type is Required', function (value) {
                    if (isTransporter()) {
                        true;
                    }
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.transmissionType' });
                })
                .nullable(),

            transmissionGears: Yup.string()
                .test('isRequired', 'Transmission Gears is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.transmissionGears' });
                })
                .nullable(),

            driveType: Yup.string()
                .test('isRequired', 'Drive Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.driveType' });
                })
                .nullable(),

            brakeSystem: Yup.string()
                .test('isRequired', 'Brake System is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.brakeSystem' });
                })
                .nullable(),

            frontTrackWidth: Yup.string()
                .test('isRequired', 'Front Track Width is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.frontTrackWidth' });
                })
                .nullable(),

            rearTrackWidth: Yup.string()
                .test('isRequired', 'Rear Track Width is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.rearTrackWidth' });
                })
                .nullable(),

            wheelbase: Yup.string()
                .test('isRequired', 'Wheelbase is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.wheelbase' });
                })
                .nullable(),

            frontWheelDiameter: Yup.string()
                .test('isRequired', 'Front Wheel Diameter is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.frontWheelDiameter' });
                })
                .nullable(),

            rearWheelDiameter: Yup.string()
                .test('isRequired', 'Rear Wheel Diameter is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.rearWheelDiameter' });
                })
                .nullable(),

            rearAxleType: Yup.string()
                .test('isRequired', 'Rear Axle Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.rearAxleType' });
                })
                .nullable(),

            frontTireType: Yup.string()
                .test('isRequired', 'Front Tire Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.frontTireType' });
                })
                .nullable(),

            frontTirePsi: Yup.string()
                .test('isRequired', 'Front Tire PSI is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.frontTirePsi' });
                })
                .nullable(),

            rearTireType: Yup.string()
                .test('isRequired', 'Rear Tire Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.rearTireType' });
                })
                .nullable(),

            rearTirePsi: Yup.string()
                .test('isRequired', 'Rear Tire PSI is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.rearTirePsi' });
                })
                .nullable(),
            passengerVolume: Yup.string()
                .test('isRequired', 'Passenger Volume is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.passengerVolume' });
                })
                .nullable(),

            cargoVolume: Yup.string()
                .test('isRequired', 'Cargo Volume is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.cargoVolume' });
                })
                .nullable(),

            groundClearance: Yup.string()
                .test('isRequired', 'Ground Clearance is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.groundClearance' });
                })
                .nullable(),

            bedLength: Yup.string()
                .test('isRequired', 'Bed Length is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.bedLength' });
                })
                .nullable(),

            curbWeight: Yup.string()
                .test('isRequired', 'Curb Weight is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.curbWeight' });
                })
                .nullable(),

            grossVehicleWeightRating: Yup.string()
                .test('isRequired', 'Gross Vehicle Weight Rating is Required', function (value) {
                    return validateFieldRequired({
                        value,
                        fieldsMetaData,
                        id: 'vehicleSpecs.grossVehicleWeightRating',
                    });
                })
                .nullable(),

            truckLoadType: Yup.string()
                .test('isRequired', 'Truck Load Type is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.truckLoadType' });
                })
                .nullable(),

            noOfWheels: Yup.string()
                .test('isRequired', 'Number of Wheels is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.noOfWheels' });
                })
                .nullable(),

            baseTowingCapacity: Yup.string()
                .test('isRequired', 'Base Towing Capacity is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.baseTowingCapacity' });
                })
                .nullable(),

            maxPayload: Yup.string()
                .test('isRequired', 'Max Payload is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.maxPayload' });
                })
                .nullable(),

            epaCity: Yup.string()
                .test('isRequired', 'EPA City Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.epaCity' });
                })
                .nullable(),

            epaHighway: Yup.string()
                .test('isRequired', 'EPA Highway Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.epaHighway' });
                })
                .nullable(),

            epaCombined: Yup.string()
                .test('isRequired', 'EPA Combined Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.epaCombined' });
                })
                .nullable(),

            cngMileage: Yup.string()
                .test('isRequired', 'CNG Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.cngMileage' });
                })
                .nullable(),

            referMileage: Yup.string()
                .test('isRequired', 'Refer Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.referMileage' });
                })
                .nullable(),

            emptyMileage: Yup.string()
                .test('isRequired', 'Empty Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.emptyMileage' });
                })
                .nullable(),

            loadedMileage: Yup.string()
                .test('isRequired', 'Loaded Mileage is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.loadedMileage' });
                })
                .nullable(),

            adblueTankCapacity: Yup.string()
                .test('isRequired', 'AdBlue Tank Capacity is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.adblueTankCapacity' });
                })
                .nullable(),

            engineBlockType: Yup.string()
                .test('isRequired', 'Engine Block Type is Required', function (value) {
                    if (isTransporter()) {
                        return true;
                    }
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineBlockType' });
                })
                .nullable(),

            engineBore: Yup.string()
                .test('isRequired', 'Engine Bore is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineBore' });
                })
                .nullable(),

            engineCamType: Yup.string()
                .test('isRequired', 'Engine Cam Type is Required', function (value) {
                    if (isTransporter()) {
                        return true;
                    }
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineCamType' });
                })
                .nullable(),

            engineDisplacement: Yup.string()
                .test('isRequired', 'Engine Displacement is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineDisplacement' });
                })
                .nullable(),

            redlineRpm: Yup.string()
                .test('isRequired', 'Redline RPM is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.redlineRpm' });
                })
                .nullable(),

            engineStroke: Yup.string()
                .test('isRequired', 'Engine Stroke is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineStroke' });
                })
                .nullable(),

            engineValves: Yup.string()
                .test('isRequired', 'Engine Valves Count is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.engineValves' });
                })
                .nullable(),

            transmissionDescription: Yup.string()
                .test('isRequired', 'Transmission Description is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.transmissionDescription' });
                })
                .nullable(),

            transmissionBrand: Yup.string()
                .test('isRequired', 'Transmission Brand is Required', function (value) {
                    return validateFieldRequired({ value, fieldsMetaData, id: 'vehicleSpecs.transmissionBrand' });
                })
                .nullable(),
        }),
    });
