import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const AddEditFreightBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-freightbill-add-edit" */ 'components/dashboard/freightbill/forms/freight_bill/AddEditFreightBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditSupplementaryBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-supplementarybill-add-edit" */ 'components/dashboard/freightbill/forms/supplementary_bill/AddEditSupplementaryBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditNonSupplementaryBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-nonsupplementarybill-add-edit" */ 'components/dashboard/freightbillnew/forms/non_supplementary_bill/AddEditNonSupplementaryBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditJobFreightBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-jobfreightbill-add-edit" */ 'components/dashboard/freightbill/forms/job_freight_bill/AddEditJobFreightBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FreightBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-freightbill-freightbill" */ 'components/dashboard/freightbillnew/FreightBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditVendorBillComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vendorBill-add-edit" */ 'components/dashboard/freightbill/forms/vendor_bill/AddEditVendorBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewInvoiceWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-freightbill-view-invoice" */ 'components/dashboard/freightbill/ViewInvoiceWrapper'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditFreightBillComponentV2 = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-freightbillnew-add-edit" */ 'components/dashboard/freightbillnew/forms/freight_bill/AddEditFreightBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditSupplementaryBillComponentV2 = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-supplementarybillnew-add-edit" */ 'components/dashboard/freightbillnew/forms/supplementary_bill/AddEditSupplementaryBillComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const billsRoutes = [
    {
        path: 'supplementarybill/add',
        lazy: AddEditSupplementaryBillComponent,
    },
    {
        path: 'supplementarybill/edit/:id',
        lazy: AddEditSupplementaryBillComponent,
    },
    {
        path: 'supplementarybillv2/add',
        lazy: AddEditSupplementaryBillComponentV2,
    },
    {
        path: 'supplementarybillv2/edit/:id',
        lazy: AddEditSupplementaryBillComponentV2,
    },
    {
        path: 'nonsupplementarybill/add',
        lazy: AddEditNonSupplementaryBillComponent,
    },
    {
        path: 'nonsupplementarybill/edit/:id',
        lazy: AddEditNonSupplementaryBillComponent,
    },
    {
        path: 'freightbill/add',
        lazy: AddEditFreightBillComponent,
    },
    {
        path: 'freightbill/edit/:id',
        lazy: AddEditFreightBillComponent,
    },
    {
        path: 'freightbillv2/add',
        lazy: AddEditFreightBillComponentV2,
    },
    {
        path: 'freightbillv2/edit/:id',
        lazy: AddEditFreightBillComponentV2,
    },
    {
        path: 'freightbillJob/add',
        lazy: AddEditJobFreightBillComponent,
    },
    {
        path: 'freightbillJob/edit/:id',
        lazy: AddEditJobFreightBillComponent,
    },
    {
        path: 'vendorBill/add',
        lazy: AddEditVendorBillComponent,
    },
    {
        path: 'vendorBill/edit/:id',
        lazy: AddEditVendorBillComponent,
    },
    {
        path: 'freightbill',
        lazy: FreightBillComponent,
    },
    {
        path: 'viewEInvoice/:id',
        lazy: ViewInvoiceWrapper,
    },
];
export default billsRoutes;
