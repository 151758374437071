import { forEachRight, get, map, forEach, orderBy } from 'lodash';

export const PAYMENT_TABS = {
    MAKE_PAYMENT: {
        name: 'Make Payment',
        order: 1,
        key: 'MAKE_PAYMENT',
    },
    RECENT_TRANSACTIONS: {
        name: 'Recent Transactions',
        order: 2,
        key: 'RECENT_TRANSACTIONS',
    },
};

export const PAYMENT_STATUS = {
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
};

export const COLLECTION_TABS = [
    {
        value: 'payable',
        label: 'Payable',
    },
    {
        value: 'receivable',
        label: 'Receivable',
    },
];

export const RECEIVABLE_DASHBOARD_TABS = [
    {
        key: 'unsettledClient',
        label: 'Client',
    },
];

export const PAYABLE_DASHBOARD_TABS = [
    {
        key: 'unsettledVendor',
        label: 'Vendor',
    },
    {
        key: 'unsettledTransporter',
        label: 'Transporter',
    },
];

export const CHART_COLORS = ['#79a2f3', '#87dab7', '#7483a0', '#f1c949', '#e28169', '#91d0ed'];
export const SUMMARY_CHART_COLORS = ['#7483a0', '#f1c949', '#e28169', '#91d0ed', '#79a2f3', '#87dab7'];

export const SUMMARY_LEGENDS = {
    amountSettled: 'Amount Settled',
    amountUnSettled: 'Amount Unsettled',
};

export const prepareChartData = (data, placeholderName) => {
    let preparedData = map(data, (item, index) => {
        return {
            name: get(item, 'name', `${placeholderName} ${1 + index}`),
            count: Math.abs(get(item, 'unsettledAmount', 0)),
        };
    });
    const sortedData = orderBy(preparedData, ['count'], ['desc']);
    return sortedData;
};

export const prepareSummaryChartData = (data) => {
    return [
        {
            name: 'Net Payable',
            key: 'payable',
            count: Math.abs(get(data, 'payable.amount', 0)),
        },
        {
            name: 'Net Receivable',
            key: 'receivable',
            count: Math.abs(get(data, 'receivable.amount', 0)),
        },
    ];
};

export const getLabelForIndirectIncome = (nature) => {
    switch (nature) {
        case 'CN_ADVANCE':
        case 'JOB_ADVANCE':
            return 'Indirect  Expense';
        default:
            return 'Indirect  Income';
    }
};
