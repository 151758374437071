import { isViewJobsAllowedForUser } from '../components/dashboard/roles/permission_utils';
import {
    mappedAddressBookCatConfig,
    mappedBusinessTypes,
    mappedJobBookingSortOn,
    mappedPreferredTimeStamp,
    mappedRoles,
    mappedTags,
    mappedTruckLoadType,
    mappedVehicleStatus,
} from './form_utils';
import { mappedCountries } from './internationalization_utils';
import {
    billingInfoApprovalConfigEnabled,
    isTotalDurationInHistoryVisible,
    jobStartDateKeyForMergedTripsForAccount,
    satelliteTypeMapDefaultRest,
    showBillNumbersInJobDetailsForAccount,
    showMultiSelectWithCheckBoxForAccount,
    useJobEndDateForMergedTripsForAccount,
} from 'utils/account_utils';
import { INDENT_DEVICE_OPTIONS, VEHICLE_DEPRECIATION_FORMULA } from 'constant';
import { filter, get, map, orderBy } from 'lodash';
import { ACCOUNT_SPECIFIC_ALARMS_OPTIONS } from 'utils/alarm_utils';
import { getSortOptionsRealtime } from 'utils/realtime_utils';
import { mappedObjectToOptions } from 'components/utils/mapping_utils';
import {
    JOB_TRIP_END_DATE_FIELDS,
    JOB_TRIP_END_DATE_FIELDS_LABEL,
    JOB_TRIP_START_DATE_FIELDS,
    JOB_TRIP_START_DATE_FIELDS_LABEL,
} from 'utils/job_route_utils';
import { APP_JOB_DETAILS_TABS, CHECKLIST_OPTIONS, TEMP_DATA_TIME_RANGE_OPTIONS_JOBS } from './job_utils';
import { FREIGHT_PRIORITIES } from './freight_bill_utils';
import { OverviewPages } from 'components/dashboard/overview/overview_utils';
import { FORM_TEMPLATE_TYPES, FORM_TEMPLATE_TYPES_LABELS } from './formTemplatesUtils';
import { STATUS } from 'utils/vendor_utils';

export const CONFIG_CATEGORIES = {
    DEFAULT: 'DEFAULT',
    JOB: 'JOB',
    ROUTE: 'ROUTE',
    REPORT: 'REPORT',
    VEHICLE: 'VEHICLE',
    REALTIME: 'REALTIME',
    HISTORY: 'HISTORY',
    MAP: 'MAP',
    DEVICE: 'DEVICE',
    DATE_TIME: 'DATE_TIME',
    TAGS: 'TAGS',
    USERS: 'USERS',
    DRIVER_ANALYTICS: 'DRIVER_ANALYTICS',
    OVERVIEW: 'OVERVIEW',
    ADDRESS: 'ADDRESS',
    ALARM: 'ALARM',
    JOB_BOOKING: 'JOB_BOOKING',
    TYRE: 'TYRE',
    VENDOR: 'VENDOR',
    TMS: 'TMS',
    FUEL: 'FUEL',
    SERVICE: 'SERVICE',
    ERP: 'ERP',
    RECORDS: 'RECORDS',
    ANALYTICS: 'ANALYTICS',
};

export const OVERVIEW_TABS = [
    {
        label: 'Old overview',
        value: OverviewPages.oldOverview,
    },
    {
        label: 'Ownership Cost',
        value: OverviewPages.ownership,
    },
    {
        label: 'My Analysis',
        value: OverviewPages.overviewWidget,
    },
    {
        label: 'PTL Analytics',
        value: OverviewPages.ptlAnalytics,
    },
    {
        label: 'Job Overview Dashboard',
        value: OverviewPages.jobOverview,
    },
    {
        label: 'Utilization Dashboard',
        value: OverviewPages.utilization,
        disabled: true,
    },
    {
        label: 'Fuel Dashboard',
        value: OverviewPages.fuelOverview,
    },
];

export const FLAG_PLATFORM = {
    WEB: 'WEB',
    MOBILE: 'MOBILE',
    BACKEND: 'BACKEND',
    ALL: 'ALL',
};

export const initial_account_config = {
    'isRiskReportEnabled': false,
    'isSAPJobSummaryReportEnabled': false,
    'showLastTripDetailsInRealTimeReportForAccount': false,
    'isTurnoverReportEnabled': false,
    'areRiskyPointsAvailable': false,
    'isAddJobVisible': true,
    'isRiskyPointPresenceSupercedeScore': false,
    'showJobSummaryCustomFields': false,
    'isStoppagePointPresenceSupercedeScore': false,
    'isMultiJobReport': false,
    'showTripDistanceFromGPS': false,
    'onlyVerifiedDrivers': false,
    'excludeBlacklistedUsers': true,
    'showLargeUiForAccount': false,
    'isTripNormalizationEnabledForAccount': true,
    'showSmsForAccount': true,
    'isShowSmsButtonForGeofence': false,
    'showThirteenPointsOnXaxis': false,
    'showOsmMapInRealTime': true,
    'useAddressBookRadiusForGeofence': true,
    'useAddressBookRadiusForGeofenceForCategories': [],
    'showOsmInTimeLineMap': true,
    'showOsmInJobMap': true,
    'showLargeMapSmallListForAccount': false,
    'showReverseAddressColoringForAccount': false,
    'hideVehicleNameForAccount': false,
    'showAllLinksOnMapInfowindowForAccount': false,
    'showRemoveVehiclesInSearchForAccount': false,
    'showRemovedVehiclesForAccount': false,
    'allowMultipleTimestampsForAccount': false,
    'showMarkersInsteadOfPolylineAccount': false,
    'excludeOverlappingTripsForAccount': false,
    'allowExcludeOverlappingTripsToggleForAccount': false,
    'fetchGeoCodedLocationForSwitcherUserForAccount': true,
    'fetchGeocodedlocationsAlwaysForReportForAccount': false,
    'showUptimeSectionForAccount': false,
    'isShowIntevalOfTenOfTempGraph': true,
    'isRemoteSelectOpenForAccount': false,
    'isHideTripDataInRealtimeRowForAccount': false,
    'includeFuelDetails': false,
    'isSplitTrueForAcccount': false,
    'isOnlyAssetTrackingAccount': false,
    'allowDeviceSettingsForAccount': false,
    'showDeviceDetailsForAccount': false,
    'isRouteTimingsInMinutesForAccount': false,
    'allowDepartArriveTimeInRouteForAccount': false,
    'allowRealtimeVehiclesMonthReportForAccount': false,
    'showLiveOdometerForAccount': false,
    'showDownloadedReportsForAccount': false,
    'showFullnameInMapForAccount': false,
    'hideClustersForAccount': false,
    'showVehicleIconsAsMarker': true,
    'isReverseNameFormatForAccount': false,
    'restrictAddressToMajorCitiesForAccount': false,
    'showPlantDashboardInControlRoomForAccount': false,
    'isDownloadRealtimeReportOnMobileForAccount': false,
    'isMovementReportDownloadFromFrontedForAccount': false,
    'isJobSummaryReportEnabled': false,
    'isLatestJobReportEnabled': false,
    'showDashboardForAccount': true,
    'showTripsForAccount': true,
    'showVehiclesForAccount': true,
    'isVehiclePerformanceReportOpenForAccount': false,
    'showJobsForAccount': true,
    'showIdlinginSiteAnalytics': false,
    'showJobDestinationForAccount': false,
    'showRemindersForAccount': true,
    'showFinanceForAccount': true,
    'refreshHighlightedVehicleRealtime': false,
    'showFuelEntriesForAccount': true,
    'showAlarmsForAccount': true,
    'turnOnJobAlarmsFilterOn': false,
    'showDtcsForAccount': true,
    'showButtonRowForAccount': true,
    'show24HoursCalenderFormatForAccount': false,
    'show24HoursFormatEverywhereForAccount': false,
    'showSimpliedStoppageUiForAccount': false,
    'isFirstRunReportOpenForAccount': false,
    'includeIdleTimeInRunningTimeForAccount': true,
    'toShowCityForAccount': false,
    'allowUserCreationWithTechnicianMasterRole': false,
    'isJobProjectsFeatureEnabled': false,
    'showTagsHierarchyFilter': false,
    'isDriverVerificationEnabled': false,
    'isVehicleNameColorBasedOnStatus': false,
    'isHorizontalReportEnabled': false,
    'showTagNameInsteadOfJointNameForAcnt': false,
    'appendCustomJobsOnVehicleRealtime': false,
    'allowRealTimeAtTransporterSide': false,
    'isMapSingleVehicleOnly': false,
    'showLatLongOnMarker': true,
    'showTagIdsToAccount': false,
    'showDeviceIdOnMarker': false,
    'showCustomFieldsOnMarker': false,
    'showOtherFieldsOnMarker': false,
    'showJobStartDate': false,
    'canOnJobVehicleCreateJob': true,
    'isAddressBookOnlyStopAtoBTrip': true,
    'useVehicleTypeValueForAccount': null,
    'showRakeIconForAccount': false,
    'dontHonorMapActionTaken': false,
    'showJobNameKeyForAcnt': false,
    'isColCustomOrderingForAccount': false,
    'showLastStopAddress': false,
    'showMultipleVehicleInMap': false,
    'showCommentInStoppage': false,
    'showTATCompareColumnsForDiff': false,
    'isCustomRelatimeReport': false,
    'isUIJobReportOpen': false,
    'isFuelReportOpen': true,
    'enableFuelLevelIgnitionForAccount': false,
    'isTemperatureReportOpen': true,
    'isLiveFuelRateOpen': true,
    'isUIAssetDataOpen': false,
    'isVehicleTypeSummaryAvailable': false,
    'isMultiJobAccount': false,
    'runningVehicleDetailedSummaryAvailable': false,
    'parkedVehicleDetailedSummaryAvailable': false,
    'parkedVehicleGT1AndLT2Hour': false,
    'isShowParkedVehicleIn24h48h': false,
    'parkedVehicleGT2AndLT4Hour': false,
    'parkedVehicleGT4AndLT5Hour': false,
    'parkedVehicleLT5Hour': false,
    'isVehicleTypeValueEnabledOnRealtimeList': false,
    'disableJobsInFreightSettlementForNonTrack': false,
    'isjobPercentDownTimeOpenForAccount': false,
    'isCommodityFieldOpen': false,
    'isJobEndPointHorizontalReport': false,
    'isPlantDirectionSummaryAvailable': false,
    'showServiceEntriesForAccount': true,
    'isEPODEnabled': true,
    'isUserModuleEnabled': true,
    'isVehicleExpensesInRouteEnabled': false,
    'showLogoInReport': false,
    'isReportLiveViewEnabled': false,
    'isBarcodeReportEnabled': false,
    'isLossKpiReportEnable': false,
    'isJobTollReportEnable': false,
    'showMetadataInReport': false,
    'autoGenerateJobNameEnabled': false,
    'isJobStoppageReportEnabled': false,
    'autoGenerateJobBookingNameEnabled': false,
    'openLogRocket': false,
    'openCSBoxTracking': false,
    'showChatBtnForAccount': true,
    'showUploadKilometersModal': false,
    'isDefaultOpenJobSharePageToDetails': false,
    'isJobReportCommentsEnabled': false,
    'vehicleListSize': 8000,
    'vehicleListSizeForDayWiseReport': 500,
    'xDayPendingForInvoiceCreate': 0,
    'checkStopDurationForAccount': 0,
    'maxTransporterAllowedForAuction': '',
    'includeExcludeUnloadingRadius': 50000,
    'suggestedPolylineColor': '#005000',
    'SIMPolylineColor': '#131313',
    'isBulkTripBWSitesEnabled': false,
    'checkForNearestAddressMismatch': false,
    'allowAnyStoppageMinutesForAccount': false,
    'showStopNoForAccount': false,
    'isVendorOnRealtimeOpen': false,
    'isVehicleSizeOnRealtimeOpen': false,
    'preferredTimeStampFormat': 'DD/MM/YY HH:mm',
    'autoChallanType': 'NONE',
    'jobBookingSortBasedOn': 'NONE',
    'categoryForOverview': 'BRANCH',
    'excludePortableDevices': false,
    'businessType': 'BOTH',
    'maskContractAndJobBookingRate': null,
    'allowedDeviceTypesForYardManagement': [],
    'tagsForWhichConsignmentUpdateAllowedFromEPOD': [],
    'tagsForWhichArrivalDepartureIsAllowedFromEPOD': [],
    'tagsForWhichAssignVehicleTimeLimitAdded': [],
    'tagsForWhichInAssignVehicleVehicleReminderChecksAdded': [],
    'statusForAddDataAllowedAtShipperSideMaersk': [
        STATUS.ASSIGNED,
        STATUS.DEPARTUREATPICKUP,
        STATUS.ARRIVALATPICKUP,
        STATUS.LRNOTASSIGNED,
        STATUS.STARTED,
        STATUS.END,
        STATUS.CLOSED,
        STATUS.DEPARTURE,
        STATUS.ARRIVAL,
        STATUS.ARRIVALDEST,
    ],
    'statusForAddDataAllowedAtTransporterSideMaersk': [
        STATUS.ASSIGNED,
        STATUS.STARTED,
        STATUS.LRNOTASSIGNED,
        STATUS.ARRIVALATPICKUP,
        STATUS.DEPARTUREATPICKUP,
        STATUS.ARRIVAL,
        STATUS.DEPARTURE,
        STATUS.ARRIVALDEST,
    ],
    'vehicleRemindersForWhichInAssignVehicleChecksAdded': [],
    'country': 'INDIA',
    'isInvalidNetworkPolylineEnabled': false,
    'promptLockOverSimInJobFormForAccount': false,
    'isJobNotCreatedReportEnabled': false,
    'isJobAlarmsReportEnabled': false,
    'showSimTrackingInfoInRealtime': false,
    'onDemandAddressFetchForMarker': true,
    'showReturnPolylineInDifferentColorWhenABAJob': false,
    'deviceSearchKeyForAccount': '',
    'showAddressNameInLocationCol': true,
    'deviationKmOverivewMapingWithJobFilter': false,
    'unrestrictedCalendar': false,
    'skipTripListIfNoVehicleSelected': true,
    'isTotalKMMapOpenToClient': false,
    'isEPODListingEnabledForAccount': false,
    'isOriginEPODAccount': false,
    'showCreateJobButtonOnVehicleForm': false,
    'showAIRepairedDetails': true,
    'showPlantFilterInOverview': false,
    'enableSpeedStratification': false,
    'showAccountSpecificAlarms': true,
    'isTodaysKmOpenForAcccount': false,
    'isYesterdaysKmOpenForAcccount': false,
    'useLastStatusTimeForIdleTime': false,
    'showVehicleLevelAlarm': false,
    'isSIMRequestLocationOpen': false,
    'isOverallTransitTime': false,
    'isJobChecklistOpenForAccount': false,
    'thresholdForGPSDeviceMandatory': null,
    'showDeviceHealthTab': false,
    'isThirdPartyApiSummaryEnabled': false,
    'isCompletedJob100PercentInGraph': true,
    'enableVehicleListNew': false,
    'enableTyreModuleNew': false,
    'showGoogleMapsLinkOfJobTrips': false,
    'isVehicleSIMHistoryReportOpen': false,
    'showInspectionCheckListForAccount': false,
    'showPinCodeInAddressForAccount': false,
    'isjobBookingOpenToAccount': true,
    'showRoutesForAccount': true,
    'showReferAndEarnForAccount': true,
    'showAddressBookListForAccount': true,
    'showTimeOnSiteForAccount': true,
    'showClientsForAccount': true,
    'showJobsPerformanceForAccount': true,
    'EVBatteryUnit': '%',
    'allowRouteSelectOnJobEdit': false,
    'showAlarmSubFiltersForAccount': false,
    'openSimStopsAndTrail': false,
    'detailedAlarmForAcccount': false,
    'openJobDetailPageInNewTab': true,
    'useRouteNameApiInJobBooking': false,
    'useProductTypeApiInJobBooking': false,
    'isVehicleSummaryColCustomOrdering': false,
    'isViewGroupAPISummary': false,
    'isViewTransporterAPISummaryVisible': true,
    'isHistoryReportColCustomOrderingForAccount': false,
    'showOldJobBookingForAccount': false,
    'setJobBookingEndDate': false,
    'showIndentType': true,
    'showSourceAndDestinationBasedOnAddressBookType': false,
    'alarmReportColCustomOrderingForAccount': false,
    'showLicensePlateInsteadOfNameInMapForAccount': false,
    'showCloseJobModal': true,
    'showUpdateJobStatusModal': true,
    'fetchVehicleLastOdoForJob': false,
    'isVehicleScoreRatingScale': false,
    'showUpdateKMInJobModal': true,
    'allowAutoFillVehicle': false,
    'isStoreMandatoryForTyre': false,
    'satelliteTypeMapDefault': false,
    'satelliteTypeMapDefaultRest': false,
    'showViewCommentsForAccount': false,
    'isTotalDurationInHistoryVisible': false,
    'isEmptyJobKMReportEnabled': false,
    'isShareRealtimeVehicleLocationByUploaderEnabled': false,
    'showAlarmDriverSMSForAccount': false,
    'showGeoFenceInAlarmMarker': false,
    'isIdleMarkerOpenOnTimeline': false,
    'isVehicleDocumentDifferentChargesEnabled': false,
    'optimizeIdlePoints': true,
    'showSpeedViolationsInHistory': true,
    'hideVehicleCurrentStatusInRealtime': false,
    'defaultHideInfoWindowRealtimeForAccount': false,
    'isJobNameFieldNotMandatory': false,
    'workOrderStationaryMandatory': false,
    'showVideoTelematics': false,
    'isVehicleWiseParkedThresholdAllowed': false,
    'showShiftWiseReplacementDriver': false,
    'showCommentHistoryInVehicleDetail': false,
    'allowOnlyPONumberJobsInFreightSettlement': false,
    'isDispatchPlanningBulkCreateAllowed': false,
    'isDisablePTLFirstMileTracking': false,
    'enableSplitAPICallsInControlRoom': false,
    'preferredOdometerTripRateValue': false,
    'fetchChildVehiclesOnJob': false,
    'showTimelineInRealtime': false,
    'isShowTimeLineOnCardClick': false,
    'dontShowNotOnlineAndRemovedVehicle': false,
    'showRealtimeAddressCopyButton': false,
    'hideDelayAtFirstPointInJobTimeLine': false,
    'fetchJobUtilisationQueryForAccount': false,
    'useJobUtilisationForRunningVehicle': false,
    'showActualTimeInParking': false,
    'isLoadedInTransitAccount': false,
    'freightSettlementAutofillDisabled': false,
    'checkFittedInByNumberApi': false,
    'showActualDistanceKmInJobTimeline': false,
    'showOnJobNotOnJobKmsInVehicleListing': false,
    'odometerNonMandatoryAndHiddenInFuelLog': false,
    'odometerNonMandatoryAndHiddenInServiceEntries': false,
    'showStationaryNumberInRealtimeJobDetails': false,
    'showStationaryNumberWithJobNameJobListing': false,
    'taxCalculationBasedOnGSTn': false,
    'isTMSPtlFlowEnabled': false,
    'showVehiclesOfIndentedSize': false,
    'showRealtimeMapInfoWindowOnCardHover': true,
    'showFuelSensorStatusRealtime': false,
    'fastagEnabledVendor': 'disabled',
    'isBpclEnableForAccount': 'disabled',
    'fuelPageV1orV2': 'both',
    'showEvOrFuelOrBoth': 'fuelModule',
    'timeIn24HrFormatJobAndWorkOrderReport': false,
    'showPartStockV1orV2': 'new',
    'fastagCriticalAmount': 0,
    'showEmployeeCodeInUserSelect': false,
    'show2MileagesInVehicles': false,
    'weighbridgeWeightUnit': 'TON',
    'highlightTimelineCardOnPolylineClick': false,
    'nonMandatoryPickDeliveryInConsignment': false,
    'collapseOtherChargesInConsignmentForm': true,
    'enableVehicleSelectionForPTLInConsignment': false,
    'pointBasedConsignmentCreation': false,
    'mandatoryGSTNumberInBranchWithClientMapping': true,
    'enableClientContractChart': false,
    'refreshJobStationaryBeforeSubmit': false,
    'includeWithoutDriverTransactionInTripsheet': false,
    'autoBillForBilledCN': false,
    'enableClientContractChartBilling': false,
    'manualTaxCalculationForTyre': false,
    'enableDriverWiseLedgerEntry': false,
    'showAssociateVendorInCN': false,
    'epodCompletedCnInPaymentReceipt': false,
    'enableLedgerBasedtripsheetDriverBalance': false,
    'isTodaysKmRealtimeReportOpenForAccount': false,
    'isLiveRequestProtectedPassword': false,
    'includeHourlyTimeRangesInCal': true,
    'enableUserInputBoxForCalendar': false,
    'includeYearlyTimeRangesInCal': false,
    'showTollInTimeline': false,
    'checkDuplicateDriver': false,
    'showBillNumbersInJobDetailsForAccount': false,
    'showMultiSelectWithCheckBoxForAccount': false,
    'showServiceReminderDataOnRealtimeMapWindow': false,
    'showVehicleColorAccordingToServiceReminder': false,
    'excludeOverlappingTripsByBackendFlags': true,
    'isViewTimelineOnJobDetailsPageEnabled': false,
    'optimizeMergeTripAPIForTimelineInRealtime': false,
    'useJobEndDateForMergedTripsForAccount': false,
    'indentDeviceOptions': ['SIM', 'APP', 'PORTABLE'],
    'showNextRoutePointCategoryTypeFilterInRealtime': false,
    'enableRemoteVehicleListPagination': false,
    'costDepreciationModel': 'runningKMMethod',
    'accountSpecificAlarms': ['Idle Engine'],
    'overviewEnableTabs': [
        OverviewPages.ownership,
        OverviewPages.jobOverview,
        OverviewPages.utilization,
        OverviewPages.fuelOverview,
    ],
    'appJobDetailEnabledTabs': [],
    'checklistConfig': ['PLANT', 'PORT', 'PLANT_SECURITY'],
    'showDriverBehaviour': false,
    'byPassEPODConditionOnJobTimeline': false,
    'useMergeTripAPIFieldsParam': true,
    'showGearPredictionDetails': false,
    'defaultSortInRealtime': '',
    'enableTripContinuity': false,
    'showDefaultJobReport': false,
    'showTempDetailsInTripList': false,
    'showConnectDisconnectDevice': false,
    'idleVehicleDetailedSummaryAvailable': false,
    'idleVehicleGTXAndLTYHours': false,
    'jobStartDateKeyForMergedTripsForAccount': '',
    'jobEndDateKeyForMergedTripsForAccount': '',
    'dontClubStoppageForAccount': false,
    'showOdometerReadingInHistoryReport': false,
    'disableVehicleIdSearchInVehicleDropdown': false,
    'enableStationarySupportInFuelLog': false,
    'enableDriverAnalytics': false,
    'showOdometerReadingInRealTimeReport': false,
    'isMissiningFuelEnable': false,
    'ignoreZeroTrips': false,
    'freightCalculationPriority1': '',
    'freightCalculationPriority2': '',
    'freightCalculationPriority3': '',
    'freightCalculationPriority4': '',
    'viewWorkOrderOnRealtime': false,
    'disconnectStatusColorIsOrange': false,
    'disconnectStatusNamePowerDisconnect': false,
    'showConstructionMachineryDetails': false,
    'showSinglePartEntry': false,
    'workOrderApprovalConfigEnabled': false,
    'fuelLogApprovalConfigEnabled': false,
    'isJobTimelineExtendEnabled': false,
    'isCurrentTimestampAllowedForBillingDate': false,
    'isRouteRemoteSelectEnabled': false,
    'isSMSServiceEnabledInForms': false,
    'editEPODCharges': false,
    'DisablePickupAndDeliveryFromFTLConsignment': false,
    'fetchJobNotMappedToTripsheetInServiceHistory': true,
    'showTransporterDetailsColInAlertList': false,
    'showJobDetailsColInAlertList': false,
    'showCurrAddressBookCategoriesFilterOnRealtime': false,
    'showEwayBillAsArrayInConsignment': false,
    'showNewJobListingPage': false,
    'isSAPPayloadInJobDetailsEnabled': false,
    'excludeCompletedJobBookingInJobCreate': false,
    'showJobBookingInConsignment': false,
    'isDeviationTypeFilterEnabled': false,
    'showVehicleStatusOnJobByLive': true,
    'showJobCheckListDashboard': false,
    'useLatestJobSummaryAPI': false,
    'findNearestAddressWithMinDistance': false,
    'showDaywiseUptimeReport': false,
    'branchBasedRateCharge': false,
    'showNewTimeOnSitePage': true,
    'dailyUtilisationKMLimit': null,
    'realtimeReminderConfig': true,
    'enableDeviationAlertInJobFormByDefault': false,
    'showTempDataAtJobLevel': false,
    'multipleFreightBillForCN': false,
    'showOpenBiddingInAuction': false,
    'tempDataTimeRangeAtJobLevel': '',
    'showTimelineChangesForCustomerLogin': false,
    'enableCustomerDetentionReport': false,
    'showUnloadingCompleteInRealtime': false,
    'enableAutoScrollOnControlRoom': false,
    'enableNewSettingsPage': false,
    'CNOrMaterialBasedEInvoicing': 'CNBased',
    'showImeiDetailsInSensorAndTracking': false,
    'transactionsApprovalConfigEnabled': false,
    'serviceEntryApprovalConfigEnabled': false,
    'billingInfoApprovalConfigEnabled': false,
    'vehicleDocumentApprovalMatrixConfigEnabled': false,
    'enableMoveOneLocationToAnotherSmoothlyRealtimeShareMap': false,
    'includeGroupInJobForm': false,
    'isTransporterAnalyticsDrillDownFlagEnabled': false,
    'parkedVehicleGTX4AndLTY4HourFlag': false,
    'isAntdDateRangePickerEnabled': false,
    'enableTransactionOldApprovalFlow': true,
    'showBulkEpodConsignment': false,
    'showOpenIssuesOnRealtimeCard': true,
    'showStoppagesCountBreakdownInVehicleSummaryReport': false,
    'showVoiceVoiceCallOptionInRealtime': false,
    'showSignatureOptionOnEPODShare': false,
    'isSalesVisualizationEnabled': false,
    'showVehicleReconciliationReportButton': false,
    'showJobAnalytics': false,
    'showAccidentTagToAccount': false,
    'issueFuelFromTransaction': false,
    'showDetentionAnalytics': false,
    'routeBudgetMandatoryInJobForm': false,
    'jobMandatoryInTransactionForm': false,
    'allVehiclesLocationReport': false,
    'isIssueUpdateAllowedOnTransporterSide': false,
    'confirmationForAlreadyAssignedVehicle': false,
    'defaultValueForCloseJobBeforeAssign': true,
    'driverAnalyticsWithoutJob': false,
    'isCeilingPriceRequiredForAuction': true,
    'showDtcOnRealtime': false,
    'addDownloadedDocsInJob': false,
    'showUnloadingClusterInTransporterAnalyticsMapView': true,
    'showOBDLinkedModuleDataForAccount': true,
    'showInstantFuelConsumptionDetails': false,
    'useRouteLatLonInsteadAddressBookInJobMapForMarker': false,
    'showJobRefreshButton': false,
    'showDeviceTypeIconsOnRealTimeCard': false,
    'isBarrelUnitSupportInContract': false,
    'showPayAsYouGoReport': false,
    'showACTemperatureReport': false,
    'showRouteSequenceReport': false,
    'chargeHeadBasedCalculationInVehicleDocuments': true,
    'showJobRouteDetailsForSelectedVehicleOnRealtime': false,
    'isBulkNearestAddressAPIEnabled': false,
    'routeExpenseTypeBudget': false,
    'showLocationOnTransporterAnalyticsMapViewWithinKMRadius': 100,
    'sortCustomFieldsInCNByName': false,
    'firstPageCNforERPAccount': true,
    'approvedTransactionInTripsheet': false,
    'multipleHireSlipOnCN': false,
    'showDriverTagsVisible': false,
    'incomingAlertsOnJobCreationFromCN': false,
    'showInstantFuelInJob': false,
    'fuelV3VehicleFuelSensorOnly': false,
    'showDeviationAnalyticsForAccount': false,
    'showGridInChart': false,
    'isWebWorkerEnabledComputationForAccount': true,
    'removeCountryRestrictionOnGooglePlaceAPISearchForAccount': false,
    'enableUnitPartCostInServiceHistory': false,
    'checkVehicleWorkOrderStatus': false,
    'useVehicleSizeInRouteBudget': false,
    'jobMileage': false,
    'shareJobOnRealtimeVehicle': false,
    'isSecondaryAnalyticsEnabled': false,
    'isAddressBookAnalyticsEnabled': '',
    'showTemperatureComplianceDashboard': false,
    'amountBasedTaxInServiceEntry': false,
    'sortJobLevelCustomFieldInAlphabeticalOrder': false,
    'consignmentBasedLorryArrival': false,
    'tagTypeForStatusVisibilityAnalytics': 'PointAnalytics',
    'isOldTripUpdateFalseByDefaultInEditJob': false,
    'isMonthWiseSalaryEnabled': true,
    'oneQuantityInCnMaterial': false,
    'isCustomDateRangesEnabledInCalendar': false,
    'customDateRanges': [],
    'showJobStoppageType': false,
    'filterAddressBasedOnUserTag': false,
    'hideEPODQuantityDetails': false,
    'ShowProjectedFuel': false,
    'excludeVehicleStatus': [],
    'isEPODDocMandatoryForTransporter': false,
    'showGenerateAndSignButtonEnabaled': false,
    'jobTranshipmentFlowEnabled': false,
    'showDoorStatusForJob': false,
    'isContainerPrintEnabledForVendorConsignment': false,
    'allowAddVehiclesAtTransporterSide': false,
    'challanApprovalConfigEnabled': false,
    'mandatoryJobBookingInJobForm': false,
    'transactionSecondaryFuel': false,
    'epodImageMandatoryForShipper': false, //for mobile
    'epodImageMandatoryForCustomer': false, //for mobile
    'epodImageMandatoryForTransporter': false, //for mobile
    'stationaryNumberMandatoryInJobForm': false,
    'showOwnershipOnAssignVehicle': false,
    'isOpeningOdometerRequiredInJobForm': false,
    'isEwayBillAndInvoiceMandatoryInCNForm': false,
    'defaultTdsRateInTransaporterMaster': 0,
    'jobBasedVehicleInEwayBill': true,
    'hideTransporterRankingOnAuctionForRoleIds': [],
    'defaultTemplateMandatoryForms': [],
    'secondaryDriverChangeEnabled': false,
    'showTripRateforFTLDispatchPlanner': false,
    'showTareWeightAndGrossWeightInCN': false,
    'sendApprovalOnCheapestTransporterForFTL': false,
    'vehicleUtilizationVolumn': 0,
    'vehicleUtilizationWeight': 0,
    'showPresentDriversOnly': false,
    'showVehicleV2page': false,
};

export const getInitialWidgetConfig = (scope) => {
    return {
        'UNDEPLOYED_COUNT': isViewJobsAllowedForUser(scope),
        'UNLOADING_DURATION': isViewJobsAllowedForUser(scope),
        'LOADING_DURATION': false,
        'ETA_DELAY': false,
        'REMINDERS': false,
        'DEVICE_STATUS': true,
        'DEVIATION_KM': isViewJobsAllowedForUser(scope),
        'VEHICLES_STATUS': false,
        'VEHICLES_TOWARD_PLANT': false,
        'VEHICLE_STATUS_GRAPH': false,
        'JOB_STATUS': isViewJobsAllowedForUser(scope),
        'FUEL_SUMMARY': true,
        'MOST_ALARMS': true,
        'MAXIMUM_MILEAGE_CHANGE': true,
        'LOW_SCORE_DRIVER_VEHICLES': true,
        'HIGH_SCORE_DRIVER_VEHICLES': true,
        'VEHICLE_DEC_MILEAGE': true,
        'VEHICLE_INC_MILEAGE': true,
        'DISTANCE_GRAPH': false,
        'PING_COUNT': false,
        'EPOD_SUMMARY': false,
        'OVERALL_TAT': false,
        'PLANT_PERFORMANCE': false,
    };
};

export const auto_job_config = {
    'startJobWithDriver': false,
    'vehicleRouteAssigned': false,
};

export const auto_job_address_config = {
    'startAtLoadingEntry': false,
    'startAtLoadingExit': false,
    'startAtUnloadingEntry': false,
    'startAtUnloadingExit': false,
    'endAtUnloadingEntry': false,
    'endAtUnloadingExit': false,
    'endPrevAtLoadingEntry': false,
    'emptyJob': false,
    'addressBookId': null,
    'routeId': null,
};

export const settings_metadata = ({ rolesList, tags, vehicleStatusList }) => {
    return {
        isRiskReportEnabled: {
            name: 'isRiskReportEnabled',
            description: 'Enables Risk Report For Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
            platform: FLAG_PLATFORM.WEB,
        },
        isSAPJobSummaryReportEnabled: {
            name: 'isSAPJobSummaryReportEnabled',
            description: 'Enables Job Summary Report. This report is for accounts that send data via SAP ',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showLastTripDetailsInRealTimeReportForAccount: {
            name: 'showLastTripDetailsInRealTimeReportForAccount',
            description: 'if true, last trip start location, start time, end time will be shown in real time report ',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isTurnoverReportEnabled: {
            name: 'isTurnoverReportEnabled',
            description: 'Enable Job Turnover report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        autoChallanType: {
            name: 'autoChallanType',
            label: 'Select Truck Load Type',
            description: 'Select preferred Truck Load to create auto challans from Job Form',
            type: 'select',
            configType: 'account_config',
            default: 'NONE',
            isSwitchedUserOnly: false,
            optionFunc: mappedTruckLoadType,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        jobBookingSortBasedOn: {
            name: 'jobBookingSortBasedOn',
            label: 'Select Job Booking sort based on',
            description: 'Select preferred Truck Load to create auto challans from Job Form',
            type: 'select',
            configType: 'account_config',
            default: 'NONE',
            isSwitchedUserOnly: false,
            optionFunc: mappedJobBookingSortOn,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        includeGroupInJobForm: {
            name: 'includeGroupInJobForm',
            label: 'Include Group In Job Form',
            description: 'Include Group In Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        addDownloadedDocsInJob: {
            name: 'addDownloadedDocsInJob',
            label: 'Add Downloaded Docs In Job',
            description: 'Add Downloaded Docs In Job',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableSplitAPICallsInControlRoom: {
            name: 'enableSplitAPICallsInControlRoom',
            label: 'Enable Split API Calls In Control Room',
            description: 'Include Group In Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        jobMileage: {
            name: 'jobMileage',
            label: 'Job Mileage',
            description: 'Include Job Mileage in Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        sortJobLevelCustomFieldInAlphabeticalOrder: {
            name: 'sortJobLevelCustomFieldInAlphabeticalOrder',
            label: 'Sort Job Level Custom Field In Alphabetical Order',
            description: 'Sort Job Level Custom Field In Alphabetical Order',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        categoryForOverview: {
            name: 'categoryForOverview',
            description: 'Select Address Category to for overview',
            type: 'select',
            configType: 'account_config',
            default: 'NONE',
            optionFunc: mappedAddressBookCatConfig,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.OVERVIEW,
            allowedForOriginalUserRoleIds: [],
        },
        overviewEnableTabs: {
            name: 'overviewEnableTabs',
            description: 'Enable tabs in overview',
            type: 'select',
            configType: 'account_config',
            default: [
                OverviewPages.ownership,
                OverviewPages.jobOverview,
                OverviewPages.utilization,
                OverviewPages.fuelOverview,
            ],
            optionFunc: () => OVERVIEW_TABS,
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.OVERVIEW,
            allowedForOriginalUserRoleIds: [],
        },
        appJobDetailEnabledTabs: {
            // to be used in mobile app
            name: 'appJobDetailEnabledTabs',
            description: 'Enable tabs in Job details page in Mobile App',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => APP_JOB_DETAILS_TABS,
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
            platform: FLAG_PLATFORM.MOBILE,
        },
        excludePortableDevices: {
            name: 'excludePortableDevices',
            description: 'Include Portable Devices in overview',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.OVERVIEW,
            allowedForOriginalUserRoleIds: [],
        },
        businessType: {
            name: 'businessType',
            label: 'Select Business Type',
            description: 'Select business type',
            type: 'select',
            configType: 'account_config',
            default: 'NONE',
            isSwitchedUserOnly: false,
            optionFunc: mappedBusinessTypes,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        areRiskyPointsAvailable: {
            name: 'areRiskyPointsAvailable',
            description: 'Are known risky points available for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isAddJobVisible: {
            name: 'isAddJobVisible',
            description: 'If turned off, add job button will not be visible in the account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        byPassEPODConditionOnJobTimeline: {
            name: 'byPassEPODConditionOnJobTimeline',
            description: 'If true this will skip the checks to show epod option on job timeline',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showUploadKilometersModal: {
            name: 'showUploadKilometersModal',
            description:
                'If turned On, Upload KiloMeters Modal Button will be visible in the All Jobs Page for the account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isRiskyPointPresenceSupercedeScore: {
            name: 'isRiskyPointPresenceSupercedeScore',
            description: 'Overall job is marked risky, if a risky area stoppage is present ',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobSummaryCustomFields: {
            name: 'showJobSummaryCustomFields',
            description: 'Show mandatory job level custom fields in Job Summary report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isStoppagePointPresenceSupercedeScore: {
            name: 'isStoppagePointPresenceSupercedeScore',
            description: 'Overall job is marked risky, if stoppage point score is present ',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isMultiJobReport: {
            name: 'isMultiJobReport',
            description: 'Enables creation of multiple jobs, by selecting multiple vehicles in job form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showTripDistanceFromGPS: {
            name: 'showTripDistanceFromGPS',
            description: 'Shows actual trip distance using snapped roads and gps lat, lngs',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        onlyVerifiedDrivers: {
            name: 'onlyVerifiedDrivers',
            description: 'Add Only Verified Drivers to Job and Vehicle',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        excludeBlacklistedUsers: {
            name: 'excludeBlacklistedUsers',
            description: 'On True Blacklisted Users will be excluded else all users will be fetched',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        isTripNormalizationEnabledForAccount: {
            name: 'isTripNormalizationEnabledForAccount',
            description: 'Consider Normalization factior for the trip distance calculation',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showLargeUiForAccount: {
            name: 'showLargeUiForAccount',
            description: 'Show Large UI in realtime vehicle row list',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showUnloadingCompleteInRealtime: {
            name: 'showUnloadingCompleteInRealtime',
            description: 'Show Unloading Complete in Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showDeviceTypeIconsOnRealTimeCard: {
            name: 'showDeviceTypeIconsOnRealTimeCard',
            description: 'Show Device Type Icons On RealTime Card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showPayAsYouGoReport: {
            name: 'showPayAsYouGoReport',
            description: 'Show Pay As You Go Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showACTemperatureReport: {
            name: 'showACTemperatureReport',
            description: 'Show AC Temperature Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showRouteSequenceReport: {
            name: 'showRouteSequenceReport',
            description: 'Show Route Sequence Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showSmsForAccount: {
            name: 'showSmsForAccount',
            description:
                'Show Sms option in alert for the account(For above 8000 accounts No, show based on alert Name)',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        isShowSmsButtonForGeofence: {
            name: 'isShowSmsButtonForGeofence',
            description: 'Enable To Show Sms option in alert for the account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        showThirteenPointsOnXaxis: {
            name: 'showThirteenPointsOnXaxis',
            description: 'Show 30 poitns on x axis of temperature graph',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },
        showSinglePartEntry: {
            name: 'showSinglePartEntry',
            description: 'Enables single Part Entry Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },
        workOrderApprovalConfigEnabled: {
            name: 'workOrderApprovalConfigEnabled',
            description: 'Enables work order approval config for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },
        fuelLogApprovalConfigEnabled: {
            name: 'fuelLogApprovalConfigEnabled',
            description: 'Enables fuel log approval config for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },
        showOsmInTimeLineMap: {
            name: 'showOsmMapInRealTime',
            description: 'Show osm map in Timeline map',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        useAddressBookRadiusForGeofence: {
            name: 'useAddressBookRadiusForGeofence',
            description:
                'When set to true, the addressBookRadius will be used by default for geofencing. If set to false, the radius will fall back to the route point radius.',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        useAddressBookRadiusForGeofenceForCategories: {
            name: 'useAddressBookRadiusForGeofenceForCategories',
            description:
                'If defined, the addressBookRadius will apply only to geofences with these categories. If not defined, the default behavior (as specified by useAddressBookRadiusForGeofence  flag) will apply to all jobs.',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: mappedAddressBookCatConfig,
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showOsmInJobMap: {
            name: 'showOsmInJobMap',
            description: 'Show osm map in Job',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },

        showOsmMapInRealTime: {
            name: 'showOsmMapInRealTime',
            description: 'Show osm map in Realtime map',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showLargeMapSmallListForAccount: {
            name: 'showLargeMapSmallListForAccount',
            description: 'Show Large Map Small List For Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showReverseAddressColoringForAccount: {
            name: 'showReverseAddressColoringForAccount',
            description: 'Address darker and Nearest Address lighter on realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        hideVehicleNameForAccount: {
            name: 'hideVehicleNameForAccount',
            description: 'Hides vehicle name in realtime card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showAllLinksOnMapInfowindowForAccount: {
            name: 'showAllLinksOnMapInfowindowForAccount',
            description: 'Shows links like history, latest, share, details etc on realtime marker',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showRemoveVehiclesInSearchForAccount: {
            name: 'showRemoveVehiclesInSearchForAccount',
            description: 'Shows removed vehicles in search for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showRemovedVehiclesForAccount: {
            name: 'showRemovedVehiclesForAccount',
            description: 'Shows removed vehicles as part of fleet for account.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        allowMultipleTimestampsForAccount: {
            name: 'allowMultipleTimestampsForAccount',
            description: 'Shows multiple nearest addresses as per list of timestamps',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showMarkersInsteadOfPolylineAccount: {
            name: 'showMarkersInsteadOfPolylineAccount',
            description: 'shows markers instead of polylines',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        excludeOverlappingTripsForAccount: {
            name: 'excludeOverlappingTripsForAccount',
            description: 'Excludes overlapping trips in history',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        allowExcludeOverlappingTripsToggleForAccount: {
            name: 'allowExcludeOverlappingTripsToggleForAccount',
            description: 'Shows Toggle for excluding overlapping trips',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        isHistoryReportColCustomOrderingForAccount: {
            name: 'Custom Column in History Report On for Account',
            description: 'Add Custom column names with orders in History Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        fetchGeoCodedLocationForSwitcherUserForAccount: {
            name: 'fetchGeoCodedLocationForSwitcherUserForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        fetchGeocodedlocationsAlwaysForReportForAccount: {
            name: 'fetchGeocodedlocationsAlwaysForReportForAccount',
            description: 'If true, realtime report would show reverse address irrespective of number of vehicles',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showUptimeSectionForAccount: {
            name: 'showUptimeSectionForAccount',
            description: 'Shows uptime in vehicle list.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isShowIntevalOfTenOfTempGraph: {
            name: 'isShowIntevalOfTenOfTempGraph',
            description: 'Show Y axis of interval of 10',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isRemoteSelectOpenForAccount: {
            name: 'isRemoteSelectOpenForAccount',
            description: 'Makes vehicle dropdown remote',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isHideTripDataInRealtimeRowForAccount: {
            name: 'isHideTripDataInRealtimeRowForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isSplitTrueForAcccount: {
            name: 'isSplitTrueForAcccount',
            description: 'If true, vehicle listing will ignore extra running',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isOnlyAssetTrackingAccount: {
            name: 'isOnlyAssetTrackingAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [7],
        },
        allowDeviceSettingsForAccount: {
            name: 'allowDeviceSettingsForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [7],
        },
        showDeviceDetailsForAccount: {
            name: 'showDeviceDetailsForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [7],
        },
        isRouteTimingsInMinutesForAccount: {
            name: 'isRouteTimingsInMinutesForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ROUTE,
            allowedForOriginalUserRoleIds: [],
        },
        allowDepartArriveTimeInRouteForAccount: {
            name: 'allowDepartArriveTimeInRouteForAccount',
            description: 'Shows depart time text field in route',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ROUTE,
            allowedForOriginalUserRoleIds: [],
        },
        allowRealtimeVehiclesMonthReportForAccount: {
            name: 'allowRealtimeVehiclesMonthReportForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showFullnameInMapForAccount: {
            name: 'showFullnameInMapForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showLicensePlateInsteadOfNameInMapForAccount: {
            name: 'showLicensePlateInsteadOfNameInMapForAccount',
            description: 'If true, realtime map vehicle number is shown as label and not vehicle name',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        hideClustersForAccount: {
            name: 'hideClustersForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showVehicleIconsAsMarker: {
            name: 'showVehicleIconsAsMarker',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        isReverseNameFormatForAccount: {
            name: 'isReverseNameFormatForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        restrictAddressToMajorCitiesForAccount: {
            name: 'restrictAddressToMajorCitiesForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showPlantDashboardInControlRoomForAccount: {
            name: 'showPlantDashboardInControlRoomForAccount',
            description: 'Opens the dashboard for plant view in control room',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        isDownloadRealtimeReportOnMobileForAccount: {
            name: 'isDownloadRealtimeReportOnMobileForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isMovementReportDownloadFromFrontedForAccount: {
            name: 'isMovementReportDownloadFromFrontedForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isJobSummaryReportEnabled: {
            name: 'isJobSummaryReportEnabled',
            description: 'Enables job summary report in job list view',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isLatestJobReportEnabled: {
            name: 'isLatestJobReportEnabled',
            description: 'Shows latest job report in job list view[RMC] ',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showDashboardForAccount: {
            name: 'showDashboardForAccount',
            description: 'Shows overview section to account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showTripsForAccount: {
            name: 'showTripsForAccount',
            description: 'Shows history section in account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showVehiclesForAccount: {
            name: 'showVehiclesForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isVehiclePerformanceReportOpenForAccount: {
            name: 'isVehiclePerformanceReportOpenForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showJobsForAccount: {
            name: 'showJobsForAccount',
            description: 'Show Jobs section inside header',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showTollInTimeline: {
            name: 'showTollInTimeline',
            description: 'Showing Toll in Timeline and Job details',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        checkDuplicateDriver: {
            name: 'checkDuplicateDriver',
            description: 'Check if the driver is already assigned to different job',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobDestinationForAccount: {
            name: 'showJobDestinationForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showRemindersForAccount: {
            name: 'showRemindersForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        refreshHighlightedVehicleRealtime: {
            name: 'refreshHighlightedVehicleRealtime',
            description: 'Higlighted/Selected vehicle will be moved in case of latest data',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showFuelEntriesForAccount: {
            name: 'showFuelEntriesForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showAlarmsForAccount: {
            name: 'showAlarmsForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        turnOnJobAlarmsFilterOn: {
            name: 'turnOnJobAlarmsFilterOn',
            description: 'Turn on job alarms filter on',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        showDtcsForAccount: {
            name: 'showDtcsForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showButtonRowForAccount: {
            name: 'showButtonRowForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        show24HoursCalenderFormatForAccount: {
            name: 'show24HoursCalenderFormatForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        show24HoursFormatEverywhereForAccount: {
            name: 'show24HoursFormatEverywhereForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        showSimpliedStoppageUiForAccount: {
            name: 'showSimpliedStoppageUiForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isFirstRunReportOpenForAccount: {
            name: 'isFirstRunReportOpenForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        includeIdleTimeInRunningTimeForAccount: {
            name: 'includeIdleTimeInRunningTimeForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        toShowCityForAccount: {
            name: 'toShowCityForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        allowUserCreationWithTechnicianMasterRole: {
            name: 'allowUserCreationWithTechnicianMasterRole',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        isJobProjectsFeatureEnabled: {
            name: 'isJobProjectsFeatureEnabled',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showLiveOdometerForAccount: {
            name: 'showLiveOdometerForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showDownloadedReportsForAccount: {
            name: 'showDownloadedReportsForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showTagsHierarchyFilter: {
            name: 'showTagsHierarchyFilter',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TAGS,
            allowedForOriginalUserRoleIds: [],
        },
        isDriverVerificationEnabled: {
            name: 'isDriverVerificationEnabled',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.USERS,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleNameColorBasedOnStatus: {
            name: 'isVehicleNameColorBasedOnStatus',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isHorizontalReportEnabled: {
            name: 'isHorizontalReportEnabled',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showTagNameInsteadOfJointNameForAcnt: {
            name: 'showTagNameInsteadOfJointNameForAcnt',
            description: '-',
            type: 'boolean',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TAGS,
            allowedForOriginalUserRoleIds: [],
        },
        allowRealTimeAtTransporterSide: {
            name: 'allowRealTimeAtTransporterSide',
            description: 'Allow RealTime At Transporter Side',
            type: 'boolean',
            default: false,
            isSwitchedUserOnly: true,
            configType: 'account_config',
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        appendCustomJobsOnVehicleRealtime: {
            name: 'appendCustomJobsOnVehicleRealtime',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isMapSingleVehicleOnly: {
            name: 'isMapSingleVehicleOnly',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showLatLongOnMarker: {
            name: 'showLatLongOnMarker',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showTagIdsToAccount: {
            name: 'showTagIdsToAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TAGS,
            allowedForOriginalUserRoleIds: [],
        },
        showDeviceIdOnMarker: {
            name: 'showDeviceIdOnMarker',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showCustomFieldsOnMarker: {
            name: 'showCustomFieldsOnMarker',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showOtherFieldsOnMarker: {
            name: 'showOtherFieldsOnMarker',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobStartDate: {
            name: 'showJobStartDate',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        canOnJobVehicleCreateJob: {
            name: 'canOnJobVehicleCreateJob',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isAddressBookOnlyStopAtoBTrip: {
            name: 'isAddressBookOnlyStopAtoBTrip',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        useVehicleTypeValueForAccount: {
            name: 'useVehicleTypeValueForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showJobNameKeyForAcnt: {
            name: 'showJobNameKeyForAcnt',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showRakeIconForAccount: {
            name: 'showRakeIconForAccount',
            description: 'Shows Rake (Rail) icon instead of vehicle icon for this account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        dontHonorMapActionTaken: {
            name: 'dontHonorMapActionTaken',
            description: 'Refresh realtime even if action has been taken on map',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        isColCustomOrderingForAccount: {
            name: 'isColCustomOrderingForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showLastStopAddress: {
            name: 'showLastStopAddress',
            description: 'Show last stop address on realtime card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showCommentInStoppage: {
            name: 'showCommentInStoppage',
            description: 'Enable comment section in history, stoppage',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showMultipleVehicleInMap: {
            name: 'showMultipleVehicleInMap',
            description: 'Not show single vehicle in Realtime on hover or filter',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        openJobDetailPageInNewTab: {
            name: 'openJobDetailPageInNewTab',
            description: 'Open Job detail Page in new tab',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showTATCompareColumnsForDiff: {
            name: 'showTATCompareColumnsForDiff',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isCustomRelatimeReport: {
            name: 'isCustomRelatimeReport',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isUIJobReportOpen: {
            name: 'isUIJobReportOpen',
            description: 'Opens job report in Reports section (RMC, Castrol etc)',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isFuelReportOpen: {
            name: 'isFuelReportOpen',
            description: 'Shows fuel level in Reports section',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        enableFuelLevelIgnitionForAccount: {
            name: 'enableFuelLevelIgnitionForAccount',
            description: 'Enable fuel level Ignition for the account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        isTemperatureReportOpen: {
            name: 'isTemperatureReportOpen',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isLiveFuelRateOpen: {
            name: 'isLiveFuelRateOpen',
            description: 'Shows date wise fuel price in Reports section',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isUIAssetDataOpen: {
            name: 'isUIAssetDataOpen',
            description: 'Shows asset data in Reports section',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleTypeSummaryAvailable: {
            name: 'isVehicleTypeSummaryAvailable',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isMultiJobAccount: {
            name: 'isMultiJobAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        runningVehicleDetailedSummaryAvailable: {
            name: 'runningVehicleDetailedSummaryAvailable',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        parkedVehicleDetailedSummaryAvailable: {
            name: 'parkedVehicleDetailedSummaryAvailable',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        parkedVehicleGTX4AndLTY4HourFlag: {
            name: 'parkedVehicleGTX4AndLTY4HourFlag',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        parkedVehicleGT1AndLT2Hour: {
            name: 'parkedVehicleGT1AndLT2Hour',
            description:
                'On True, It shows a filter button in Realtime page for filtering vehicles that are parked for greater than X hour and less than X hours',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isShowParkedVehicleIn24h48h: {
            name: 'isShowParkedVehicleIn24h48h',
            description:
                'On True, It shows filter buttons in Realtime page for filtering vehicles that are parked from (less than 24h), (greater than 24h and less than 48h), (greater than 48h)',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        parkedVehicleGT2AndLT4Hour: {
            name: 'parkedVehicleGT2AndLT4Hour',
            description:
                'On True, It shows a filter button in Realtime page for filtering vehicles that are parked for greater than 2 hours and less than 4 hours',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        parkedVehicleGT4AndLT5Hour: {
            name: 'parkedVehicleGT4AndLT5Hour',
            description:
                'On True, It shows a filter button in Realtime page for filtering vehicles that are parked for greater than 4 hours and less than 5 hours',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        parkedVehicleLT5Hour: {
            name: 'parkedVehicleLT5Hour',
            description:
                'On True, It shows a filter button in Realtime page for filtering vehicles that are parked for greater than 5 hours',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleSummaryColCustomOrdering: {
            name: 'isVehicleSummaryColCustomOrdering',
            description: 'Add Custom column names with orders in all Vehicle Summary report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isViewGroupAPISummary: {
            name: 'isViewGroupAPISummary',
            description: 'View Group API Summary',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isViewTransporterAPISummaryVisible: {
            name: 'isViewTransporterAPISummaryVisible',
            description: 'View Transporter API Summary',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showOldJobBookingForAccount: {
            name: 'showOldJobBookingForAccount',
            description: 'Show Old Job Booking Form To Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB_BOOKING,
            allowedForOriginalUserRoleIds: [],
        },
        setJobBookingEndDate: {
            name: 'setJobBookingEndDate',
            description: 'Set Job Booking End Date',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB_BOOKING,
            allowedForOriginalUserRoleIds: [],
        },
        showIndentType: {
            name: 'showIndentType',
            description: 'Show Indent Type in Job Booking',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB_BOOKING,
            allowedForOriginalUserRoleIds: [],
        },
        showSourceAndDestinationBasedOnAddressBookType: {
            name: 'showSourceAndDestinationBasedOnAddressBookType',
            description: 'Show source and destination address based on address bookType',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        allowOnlyPONumberJobsInFreightSettlement: {
            name: 'allowOnlyPONumberJobsInFreightSettlement',
            description: 'Allow Only PO Number Jobs In Freight Settlement',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleTypeValueEnabledOnRealtimeList: {
            name: 'isVehicleTypeValueEnabledOnRealtimeList',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isjobPercentDownTimeOpenForAccount: {
            name: 'isjobPercentDownTimeOpenForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isCommodityFieldOpen: {
            name: 'isCommodityFieldOpen',
            description: 'Enables commodity fiels on jobs',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isJobEndPointHorizontalReport: {
            name: 'isJobEndPointHorizontalReport',
            description: 'Enables Job End Point Report [Sai Ganesh]',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isPlantDirectionSummaryAvailable: {
            name: 'isPlantDirectionSummaryAvailable',
            description: 'Shows plant direction summary on Realtime Summay [RMC]',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showServiceEntriesForAccount: {
            name: 'showServiceEntriesForAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        isEPODEnabled: {
            name: 'isEPODEnabled',
            description: 'Enable EPOD for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isUserModuleEnabled: {
            name: 'isUserModuleEnabled',
            description: 'Enables Custom Roles and Modules',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.USERS,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleExpensesInRouteEnabled: {
            name: 'isVehicleExpensesInRouteEnabled',
            description: 'Vehicle Expenses In Route',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ROUTE,
            allowedForOriginalUserRoleIds: [],
        },
        isReportLiveViewEnabled: {
            name: 'isReportLiveViewEnabled',
            description: 'Opens live job report view On UI',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showLogoInReport: {
            name: 'showLogoInReport',
            description: 'Show Client Logo In All Reports',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isBarcodeReportEnabled: {
            name: 'isBarcodeReportEnabled',
            description: 'Enable RF and Barcode report download',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isLossKpiReportEnable: {
            name: 'isLossKpiReportEnable',
            description: 'Enable Loss KPI report download',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isJobTollReportEnable: {
            name: 'isJobTollReportEnable',
            description: 'Enable Job Toll Report to download',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showMetadataInReport: {
            name: 'showMetadataInReport',
            description: 'Show Metadata In All Reports',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        autoGenerateJobNameEnabled: {
            name: 'autoGenerateJobNameEnabled',
            description: 'Auto Generate Job Name',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isJobStoppageReportEnabled: {
            name: 'isJobStoppageReportEnabled',
            description: 'Opens Job Stoppage Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        autoGenerateJobBookingNameEnabled: {
            name: 'autoGenerateJobBookingNameEnabled',
            description: 'Auto Generate Booking Number',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        openLogRocket: {
            name: 'openLogRocket',
            description: 'Enables Log Rocket',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        openCSBoxTracking: {
            name: 'openCSBoxTracking',
            description: 'Enables CS Box Tracking',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showChatBtnForAccount: {
            name: 'showChatBtnForAccount',
            description: 'Shows Chat Button For Account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        isDefaultOpenJobSharePageToDetails: {
            name: 'isDefaultOpenJobSharePageToDetails',
            description: 'Realtime job share modal, will show job details toggle as default selected',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isJobReportCommentsEnabled: {
            name: 'isJobReportCommentsEnabled',
            description: 'Enable Commments in Job Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleListSize: {
            name: 'vehicleListSize',
            description: 'Set Vehicle  List API Size',
            type: 'text',
            configType: 'account_config',
            default: 8000,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleListSizeForDayWiseReport: {
            name: 'vehicleListSizeForDayWiseReport',
            description:
                'Set Vehicle list size breakpoint after which download report will be disabled for multi vehicle ',
            type: 'text',
            configType: 'account_config',
            default: 500,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        xDayPendingForInvoiceCreate: {
            name: 'xDayPendingForInvoiceCreate',
            description: 'only show last x days jobs in invoice create',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        checkStopDurationForAccount: {
            name: 'checkStopDurationForAccount',
            description: 'Show only stops greater than threshold(mins) on job map',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },

        includeExcludeUnloadingRadius: {
            name: 'includeExcludeUnloadingRadius',
            description: 'Enter Radius for for include and exclude Unloading point for job map',
            type: 'text',
            configType: 'account_config',
            default: 50000,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        suggestedPolylineColor: {
            name: 'suggestedPolylineColor',
            description: 'Set Suggested Polyline Color on job map',
            type: 'text',
            configType: 'account_config',
            default: '#005000',
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        SIMPolylineColor: {
            name: 'SIMPolylineColor',
            description: 'Set SIM tracking polyline Color on job map',
            type: 'text',
            configType: 'account_config',
            default: '#005000',
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableVehicleListNew: {
            name: 'enableVehicleListNew',
            description: 'Enable new vehicle list design',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isBulkTripBWSitesEnabled: {
            name: 'isBulkTripBWSitesEnabled',
            description: 'Enables bulk trip Bw Sites',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        isCompletedJob100PercentInGraph: {
            name: 'isCompletedJob100PercentInGraph',
            description: 'Is completed job shown as 100% in graph',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        checkForNearestAddressMismatch: {
            name: 'checkForNearestAddressMismatch',
            description: 'Check for nearest address mismatch',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        allowAnyStoppageMinutesForAccount: {
            name: 'allowAnyStoppageMinutesForAccount',
            description: 'Allow any stoppages minutes in trips',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showStopNoForAccount: {
            name: 'showStopNoForAccount',
            description: 'Show stop number on marker for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isVendorOnRealtimeOpen: {
            name: 'isVendorOnRealtimeOpen',
            description: 'Shows vehicles vendor on realtime card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleSizeOnRealtimeOpen: {
            name: 'isVehicleSizeOnRealtimeOpen',
            description: 'Shows vehicle size on realtime card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        preferredTimeStampFormat: {
            name: 'preferredTimeStampFormat',
            label: 'select timestamp format',
            description: 'Select preferred timestamp to be shown in reports',
            type: 'select',
            configType: 'account_config',
            default: 'DD/MM/YY HH:mm',
            isSwitchedUserOnly: false,
            optionFunc: mappedPreferredTimeStamp,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        country: {
            name: 'country',
            label: 'select country',
            description: 'Country to which the account belongs',
            type: 'select',
            configType: 'account_config',
            default: 'INDIA',
            isSwitchedUserOnly: false,
            optionFunc: mappedCountries,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showAddressNameInLocationCol: {
            name: 'showAddressNameInLocationCol',
            description: 'If true, location col will show address book name in realtime report',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        deviationKmOverivewMapingWithJobFilter: {
            name: 'deviationKmOverivewMapingWithJobFilter',
            description:
                'If true, while clicking on deviationKm in Overivew will be get redirect to Job and options will get mapped',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.OVERVIEW,
            allowedForOriginalUserRoleIds: [],
        },
        isInvalidNetworkPolylineEnabled: {
            name: 'isInvalidNetworkPolylineEnabled',
            description: 'Shows invalid network polyline on map for trip',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        promptLockOverSimInJobFormForAccount: {
            name: 'promptLockOverSimInJobFormForAccount',
            description: 'Shows Add Lock instead of Add SIM when a vehicle without device is selected in Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isJobNotCreatedReportEnabled: {
            name: 'isJobNotCreatedReportEnabled',
            description: 'Shows job not created report for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isJobAlarmsReportEnabled: {
            name: 'isJobAlarmsReportEnabled',
            description: 'Shows job alarm created report for account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showSimTrackingInfoInRealtime: {
            name: 'showSimTrackingInfoInRealtime',
            description: 'Shows sim end trackind date on realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        onDemandAddressFetchForMarker: {
            name: 'onDemandAddressFetchForMarker',
            description: 'Enable onDemand Address Fetch For Marker',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        showReturnPolylineInDifferentColorWhenABAJob: {
            name: 'showReturnPolylineInDifferentColorWhenABAJob',
            description:
                'Show return polyline in differentColor when ABA(Job starts from point A ' +
                'goes to B and comes back to A) Job map',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        deviceSearchKeyForAccount: {
            name: 'deviceSearchKeyForAccount',
            description: 'Set Device Search Key',
            type: 'text',
            configType: 'account_config',
            default: '',
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [7],
        },
        unrestrictedCalendar: {
            name: 'unrestrictedCalendar',
            description: 'Remove 31 day check from calendar',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        skipTripListIfNoVehicleSelected: {
            name: 'skipTripListIfNoVehicleSelected',
            description: "Don't show trip list if vehicle not selected",
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        isTotalKMMapOpenToClient: {
            name: 'isTotalKMMapOpenToClient',
            description: 'Open Get Total Km (Map) to Accounts',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        isEPODListingEnabledForAccount: {
            name: 'isEPODListingEnabledForAccount',
            description: 'Open EPOD Listing on Job Page for Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showCreateJobButtonOnVehicleForm: {
            name: 'showCreateJobButtonOnVehicleForm',
            description: 'Shows Submit And Create Job Button on vehicle and sim form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isOriginEPODAccount: {
            name: 'isOriginEPODAccount',
            description: 'This account will upload epod on origin too. So expected total EPOD count will change',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showPlantFilterInOverview: {
            name: 'showPlantFilterInOverview',
            description: 'Show Plant Selection dropdown inside account overview',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        enableSpeedStratification: {
            name: 'enableSpeedStratification',
            description: 'Enable speed range configuration',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showAccountSpecificAlarms: {
            name: 'showAccountSpecificAlarms',
            description: 'Show account specific alarms inside job details',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isTodaysKmOpenForAcccount: {
            name: 'isTodaysKmOpenForAcccount',
            description: 'Shows todays km on realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isYesterdaysKmOpenForAcccount: {
            name: 'isYesterdaysKmOpenForAcccount',
            description: 'Shows yesterdays km on realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        useLastStatusTimeForIdleTime: {
            name: 'useLastStatusTimeForIdleTime',
            description: 'Use last status time for idle duration calculatiion',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showVehicleLevelAlarm: {
            name: 'showVehicleLevelAlarm',
            description: 'Show vehicle level temperature alarm',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        isSIMRequestLocationOpen: {
            name: 'isSIMRequestLocationOpen',
            description: 'Shows Request Location button on realtime card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isOverallTransitTime: {
            name: 'isOverallTransitTime',
            description: 'Shows Overall Transit Time Field in route and job form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isJobChecklistOpenForAccount: {
            name: 'isJobChecklistOpenForAccount',
            description: 'Shows Checkist on Jobs details page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        thresholdForGPSDeviceMandatory: {
            name: 'thresholdForGPSDeviceMandatory',
            description: 'Set Threshold for GPS Device Mandatory(Number of Jobs)',
            type: 'text',
            configType: 'account_config',
            default: null,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [],
        },
        showDeviceHealthTab: {
            name: 'showDeviceHealthTab',
            description: 'Shows Device Health Tab for Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [],
        },
        isThirdPartyApiSummaryEnabled: {
            name: 'isThirdPartyApiSummaryEnabled',
            description: 'Shows Third Party Api Summary Dashboard in vehicle section',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showGoogleMapsLinkOfJobTrips: {
            name: 'showWapPointsOnGoogleMaps',
            description: 'Shows Google maps link button that contains way point in Job Details page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleSIMHistoryReportOpen: {
            name: 'isVehicleSIMHistoryReportOpen',
            description: 'Shows Vehicle and SIM History Logs on Vehicle page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        showInspectionCheckListForAccount: {
            name: 'showInspectionCheckListForAccount',
            description: 'Shows Vehicle Inspection CheckList and Question Module for Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showAIRepairedDetails: {
            name: 'showAIRepairedDetails',
            description: 'Show AI Repaired Details',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showPinCodeInAddressForAccount: {
            name: 'showPinCodeInAddressForAccount',
            description: 'Address will show pin code for Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ADDRESS,
            allowedForOriginalUserRoleIds: [],
        },
        isjobBookingOpenToAccount: {
            name: 'isjobBookingOpenToAccount',
            description: 'Shows job booking for account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showRoutesForAccount: {
            name: 'showRoutesForAccount',
            description: 'Shows routes in account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ROUTE,
            allowedForOriginalUserRoleIds: [],
        },
        showReferAndEarnForAccount: {
            name: 'showReferAndEarnForAccount',
            description: 'Shows Refer And Earn in account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showAddressBookListForAccount: {
            name: 'showAddressBookListForAccount',
            description: 'Shows Address Book List for account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ADDRESS,
            allowedForOriginalUserRoleIds: [],
        },
        showTimeOnSiteForAccount: {
            name: 'showTimeOnSiteForAccount',
            description: 'Shows Time On Site for account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ADDRESS,
            allowedForOriginalUserRoleIds: [],
        },
        showClientsForAccount: {
            name: 'showClientsForAccount',
            description: 'Shows Clients for account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showJobsPerformanceForAccount: {
            name: 'showJobsPerformanceForAccount',
            description: 'Shows Jobs Performance for account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            category: CONFIG_CATEGORIES.JOB,
            isSwitchedUserOnly: true,
        },
        EVBatteryUnit: {
            name: 'EVBatteryUnit',
            description: 'Battery Unit to be shown in ev section %, V etc',
            type: 'text',
            configType: 'account_config',
            default: '%',
            category: CONFIG_CATEGORIES.DEFAULT,
            isSwitchedUserOnly: true,
        },
        allowRouteSelectOnJobEdit: {
            name: 'allowRouteSelectOnJobEdit',
            description: 'Allow Route Select in Job Edit',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        refreshJobStationaryBeforeSubmit: {
            name: 'refreshJobStationaryBeforeSubmit',
            description: 'Refresh Job Stationary Before Submit',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showAlarmSubFiltersForAccount: {
            name: 'showAlarmSubFiltersForAccount',
            description: 'Enable Alarm subtype filter',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
        },
        openSimStopsAndTrail: {
            name: 'openSimStopsAndTrail',
            description: 'Will show sim stops and sim trail in job details page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        detailedAlarmForAcccount: {
            name: 'detailedAlarmForAcccount',
            description: 'Detailed alarm list will be fetched for the acccount',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleWiseParkedThresholdAllowed: {
            name: 'isVehicleWiseParkedThresholdAllowed',
            description: 'Enable vehicle wise threshold for parked on job alarm',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        isDispatchPlanningBulkCreateAllowed: {
            name: 'isDispatchPlanningBulkCreateAllowed',
            description: 'Enable multiple dispatch planning config creation for lane',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        disableJobsInFreightSettlementForNonTrack: {
            name: 'disableJobsInFreightSettlementForNonTrack',
            description: 'Disable Jobs In Freight Settlement For Non Track',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        isBarrelUnitSupportInContract: {
            name: 'isBarrelUnitSupportInContract',
            description: 'Is Barrel unit support enabled in contract',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        editEPODCharges: {
            name: 'editEPODCharges',
            description: 'Edit EPOD Charges',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        showSignatureOptionOnEPODShare: {
            name: 'showSignatureOptionOnEPODShare',
            description: 'Show Signature Option On EPOD Share',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        preferredOdometerTripRateValue: {
            name: 'preferredOdometerTripRateValue',
            label: 'Preffer ODOMETER over GPS',
            description: 'Preferred format to be shown in freight settelemet add invoices',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        isDisablePTLFirstMileTracking: {
            name: 'isDisablePTLFirstMileTracking',
            description: 'Enable PTL First Mile Tracking',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        showCommentHistoryInVehicleDetail: {
            name: 'showCommentHistoryInVehicleDetail',
            description: 'Enable comment history tab in vehicle detail',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        alarmReportColCustomOrderingForAccount: {
            name: 'alarmReportColCustomOrderingForAccount',
            description: 'customise column ordering for the acccount',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        useRouteNameApiInJobBooking: {
            name: 'useRouteNameApiInJobBooking',
            description: 'Use Route Name Field in job booking for the acccount',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB_BOOKING,
            allowedForOriginalUserRoleIds: [],
        },
        useProductTypeApiInJobBooking: {
            name: 'useProductTypeApiInJobBooking',
            description: 'Use Product Name Field in job booking for the acccount',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB_BOOKING,
            allowedForOriginalUserRoleIds: [],
        },
        showCloseJobModal: {
            name: 'showCloseJobModal',
            description: 'Shows close job modal In All Jobs and Control room page',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showUpdateJobStatusModal: {
            name: 'showUpdateJobStatusModal',
            description: 'Shows update job status modal In All Jobs, Job Detail page',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        fetchVehicleLastOdoForJob: {
            name: 'fetchVehicleLastOdoForJob',
            description: 'fetch Vehicle Last-Odo in Add Job',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleScoreRatingScale: {
            name: 'isVehicleScoreRatingScale',
            description: 'Shows Vehicle Score in 1-5 scale and not 1-100, with 1 being good',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showUpdateKMInJobModal: {
            name: 'showUpdateKMInJobModal',
            description: 'Shows update km text field in update job status modal.',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        hideVehicleCurrentStatusInRealtime: {
            name: 'hideVehicleCurrentStatusInRealtime',
            description: 'Hide vehicle current status details in realtime.',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        defaultHideInfoWindowRealtimeForAccount: {
            name: 'defaultHideInfoWindowRealtimeForAccount',
            description: 'Vehicle Information infowindow will not open by default on selecting a vehicle.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        workOrderStationaryMandatory: {
            name: 'workOrderStationaryMandatory',
            description: 'Make stationary selection mandatory in work order form',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },
        isJobNameFieldNotMandatory: {
            name: 'isJobNameFieldNotMandatory',
            description: 'Hide Job name field and pick name from stationary number.',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        allowAutoFillVehicle: {
            name: 'allowAutoFillVehicle',
            description: 'Allow auto fill in vehicle form.',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isStoreMandatoryForTyre: {
            name: 'isStoreMandatoryForTyre',
            description: 'If true, It will be mandatory to put tyre back in store in case of removing a tyre',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TYRE,
            allowedForOriginalUserRoleIds: [],
        },
        showViewCommentsForAccount: {
            name: 'showViewCommentsForAccount',
            description: 'Shows Comments Modal on Realtime page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isEmptyJobKMReportEnabled: {
            name: 'isEmptyJobKMReportEnabled',
            description: 'Enable Empty Job KM Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REPORT,
            allowedForOriginalUserRoleIds: [],
        },
        isShareRealtimeVehicleLocationByUploaderEnabled: {
            name: 'isShareRealtimeVehicleLocationByUploaderEnabled',
            description: 'Enable Realtime Vehicle Location Share By Uploader',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        enableTyreModuleNew: {
            name: 'enableTyreModuleNew',
            description: 'Enable new tyre module',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TYRE,
            allowedForOriginalUserRoleIds: [],
        },
        showAlarmDriverSMSForAccount: {
            name: 'showAlarmDriverSMSForAccount',
            description: 'Show Send SMS to Driver on Alarm Setting',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        satelliteTypeMapDefaultRest: {
            name: 'satelliteTypeMapDefaultRest',
            description: 'Satellite Type Map Default Everywhere except Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.MAP,
            allowedForOriginalUserRoleIds: [],
        },
        satelliteTypeMapDefault: {
            name: 'satelliteTypeMapDefault',
            description: 'Show Satellite Map as default on realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isTotalDurationInHistoryVisible: {
            name: 'isTotalDurationInHistoryVisible',
            description: 'Show Total Duration in History Summary',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showGeoFenceInAlarmMarker: {
            name: 'showGeoFenceInAlarmMarker',
            description: 'Show Geofence in address marker on realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isIdleMarkerOpenOnTimeline: {
            name: 'isIdleMarkerOpenOnTimeline',
            description: 'Shows Idle Markers by default in timeline',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        isVehicleDocumentDifferentChargesEnabled: {
            name: 'isVehicleDocumentDifferentChargesEnabled',
            description: 'Different charges for Other charges in Vehicle Document',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        optimizeIdlePoints: {
            name: 'optimizeIdlePoints',
            description: 'Dont optimize idle points in realtime overview',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showSpeedViolationsInHistory: {
            name: 'showSpeedViolationsInHistory',
            description: 'Show speed violation in trip / history section',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showShiftWiseReplacementDriver: {
            name: 'showShiftWiseReplacementDriver',
            description: 'Show Shift Wise Replacement Driver',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.USERS,
            allowedForOriginalUserRoleIds: [],
        },
        showVideoTelematics: {
            name: 'showVideoTelematics',
            description: 'Show Video Telematics In Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        fetchChildVehiclesOnJob: {
            name: 'fetchChildVehiclesOnJob',
            description: 'Job detail page will show children polyline in account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showTimelineInRealtime: {
            name: 'showTimelineInRealtime',
            description: 'Show Timeline In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isShowTimeLineOnCardClick: {
            name: 'isShowTimeLineOnCardClick',
            description: 'Show Timeline in card click',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showRealtimeAddressCopyButton: {
            name: 'showRealtimeAddressCopyButton',
            description: 'Show Address Copy Icon In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        hideDelayAtFirstPointInJobTimeLine: {
            name: 'hideDelayAtFirstPointInJobTimeLine',
            description: 'Hide delay at first point of job timeline ',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        fetchJobUtilisationQueryForAccount: {
            name: 'fetchJobUtilisationQueryForAccount',
            description: 'Fetch job utilisation in vehicle analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        useJobUtilisationForRunningVehicle: {
            name: 'useJobUtilisationForRunningVehicle',
            description: 'Fetch job utilisation in vehicle Running analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showActualTimeInParking: {
            name: 'showActualTimeInParking',
            description: 'Show Actual Time In Parked Status Of Realtime Card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isLoadedInTransitAccount: {
            name: 'isLoadedInTransitAccount',
            description: 'Show loaded status as In Transit',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        freightSettlementAutofillDisabled: {
            name: 'freightSettlementAutofillDisabled',
            description: 'Auto filled fields will be disabled when creating contract',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        maxTransporterAllowedForAuction: {
            name: 'maxTransporterAllowedForAuction',
            type: 'text',
            description: 'Max Transporter Allowed For Auction',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        checkFittedInByNumberApi: {
            name: 'checkFittedInByNumberApi',
            description: 'In By Number api call, device which are fitted (device installed by fleetx), will be sent',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showActualDistanceKmInJobTimeline: {
            name: 'showActualDistanceKmInJobTimeline',
            description: 'Show KM of both upward and downward journey in the job details',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showOnJobNotOnJobKmsInVehicleListing: {
            name: 'showOnJobNotOnJobKmsInVehicleListing',
            description: 'Show On Job and Not On Job Kms In Vehicle Listing',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        odometerNonMandatoryAndHiddenInFuelLog: {
            name: 'odometerNonMandatoryAndHiddenInFuelLog',
            description: 'hide odometer field and make non mandatory in fuel log',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        enableStationarySupportInFuelLog: {
            name: 'enableStationarySupportInFuelLog',
            description: 'Enable Stationary in fuel log',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        odometerNonMandatoryAndHiddenInServiceEntries: {
            name: 'odometerNonMandatoryAndHiddenInServiceEntries',
            description: 'hide odometer field and make non mandatory in Service History',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.SERVICE,
            allowedForOriginalUserRoleIds: [],
        },
        showStationaryNumberInRealtimeJobDetails: {
            name: 'showStationaryNumberInRealtimeJobDetails',
            description: 'Show Stationary Number In Realtime Job More Info',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showStationaryNumberWithJobNameJobListing: {
            name: 'showStationaryNumberWithJobNameJobListing',
            description: 'Show Stationary Number In JobListing With Job Name',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        taxCalculationBasedOnGSTn: {
            name: 'taxCalculationBasedOnGSTn',
            description: 'Tax Calculation Based On GSTn in freight settlement',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        isTMSPtlFlowEnabled: {
            name: 'isTMSPtlFlowEnabled',
            description: 'Enable PTL Flow in Job ,Indent,contract,EPOD and Freight bill  and at many other places',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        isIssueUpdateAllowedOnTransporterSide: {
            name: 'isIssueUpdateAllowedOnTransporterSide',
            description: 'Enable issue update on transporter side',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        confirmationForAlreadyAssignedVehicle: {
            name: 'confirmationForAlreadyAssignedVehicle',
            description: 'Cofirmation for Already Assigned Vehicle',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        defaultValueForCloseJobBeforeAssign: {
            name: 'defaultValueForCloseJobBeforeAssign',
            description: 'If true, by default close job before assign flag will be passed as true',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showVehiclesOfIndentedSize: {
            name: 'showVehiclesOfIndentedSize',
            description: 'Show Vehicles Of Indented Size In Indent Listing',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showRealtimeMapInfoWindowOnCardHover: {
            name: 'showRealtimeMapInfoWindowOnCardHover',
            description: 'Show Realtime Map Info Window On Card Hover',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showFuelSensorStatusRealtime: {
            name: 'showFuelSensorStatusRealtime',
            description: 'Shows fuel sensor status in filter page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        maskContractAndJobBookingRate: {
            name: 'maskContractAndJobBookingRate',
            description: 'Mask contract rate based on role ids',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: () => mappedRoles(rolesList),
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        statusForAddDataAllowedAtShipperSideMaersk: {
            name: 'statusForAddDataAllowedAtShipperSideMaersk',
            description: 'Status for Add Data Allowed at Shipper Side (Maersk)',
            type: 'select',
            configType: 'account_config',
            default: [
                STATUS.ASSIGNED,
                STATUS.DEPARTUREATPICKUP,
                STATUS.ARRIVALATPICKUP,
                STATUS.LRNOTASSIGNED,
                STATUS.STARTED,
                STATUS.END,
                STATUS.CLOSED,
                STATUS.DEPARTURE,
                STATUS.ARRIVAL,
                STATUS.ARRIVALDEST,
            ],
            optionFunc: () => map(STATUS, (key, value) => ({ value: value, label: key })),
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        statusForAddDataAllowedAtTransporterSideMaersk: {
            name: 'statusForAddDataAllowedAtTransporterSideMaersk',
            description: 'Status for Add Data Allowed at Transporter Side (Maersk)',
            type: 'select',
            configType: 'account_config',
            default: [
                STATUS.ASSIGNED,
                STATUS.STARTED,
                STATUS.LRNOTASSIGNED,
                STATUS.ARRIVALATPICKUP,
                STATUS.DEPARTUREATPICKUP,
                STATUS.ARRIVAL,
                STATUS.DEPARTURE,
                STATUS.ARRIVALDEST,
            ],
            optionFunc: () => map(STATUS, (key, value) => ({ value: value, label: key })),
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        tagsForWhichConsignmentUpdateAllowedFromEPOD: {
            name: 'tagsForWhichConsignmentUpdateAllowedFromEPOD',
            description: 'Tags For Which Consignment Update Allowed From EPODs',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => mappedTags(tags),
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        allowedDeviceTypesForYardManagement: {
            name: 'allowedDeviceTypesForYardManagement',
            description: 'Allowed devices for which yard management is enabled',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => filter(INDENT_DEVICE_OPTIONS, (op) => op.value !== 'FITTED_DEVICE'),
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        tagsForWhichArrivalDepartureIsAllowedFromEPOD: {
            name: 'tagsForWhichArrivalDepartureIsAllowedFromEPOD',
            description: 'Tags For Which Arrival Departure Update Allowed From EPODs',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => mappedTags(tags),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        tagsForWhichAssignVehicleTimeLimitAdded: {
            name: 'tagsForWhichAssignVehicleTimeLimitAdded',
            description: 'Tags For Which Assign Vehicle Time Limit Added',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => mappedTags(tags),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        tagsForWhichInAssignVehicleVehicleReminderChecksAdded: {
            name: 'tagsForWhichInAssignVehicleVehicleReminderChecksAdded',
            description: 'Tags For Which in Assign Vehicle,  Vehicle reminder check is added',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => mappedTags(tags),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleRemindersForWhichInAssignVehicleChecksAdded: {
            name: 'vehicleRemindersForWhichInAssignVehicleChecksAdded',
            description: 'Vehicle Reminders For Which In Assign Vehicle Checks is Added',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () =>
                ['Insurance', 'Registration', 'PUC', 'Permit', 'Fitness'].map((i) => ({ value: i, label: i })),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        showIdlinginSiteAnalytics: {
            name: 'showIdlingInSiteAnalytics',
            description: 'Display Show Idling Button in Site Analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        show2MileagesInVehicles: {
            name: 'show2MileagesInVehicles',
            description: 'Show 2 Mileages In Vehicles',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        timeIn24HrFormatJobAndWorkOrderReport: {
            name: 'timeIn24HrFormatJobAndWorkOrderReport',
            description: '',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        weighbridgeWeightUnit: {
            name: 'weighbridgeWeightUnit',
            description: 'Set unit for weight received from weighbridge ',
            type: 'text',
            configType: 'account_config',
            default: 'TON',
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        highlightTimelineCardOnPolylineClick: {
            name: 'highlightTimelineCardOnPolylineClick',
            description: 'Highlight Timeline Card On Polyline Click(Reverse)',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        nonMandatoryPickDeliveryInConsignment: {
            name: 'nonMandatoryPickDeliveryInConsignment',
            description: 'Make pickup delivery details non mandatory in consignment',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        collapseOtherChargesInConsignmentForm: {
            name: 'collapseOtherChargesInConsignmentForm',
            description: 'Collapse other charges in consignment form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        enableVehicleSelectionForPTLInConsignment: {
            name: 'enableVehicleSelectionForPTLInConsignment',
            description: 'Enable Vehicle Selection In case of PTL',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showEwayBillAsArrayInConsignment: {
            name: 'showEwayBillAsArrayInConsignment',
            description: 'Show Eway Bill as a List in Consignment',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showNewJobListingPage: {
            name: 'showNewJobListingPage',
            description: 'show New Job Listing Page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        pointBasedConsignmentCreation: {
            name: 'pointBasedConsignmentCreation',
            description: 'Enable Point Based ERP Consignment Creation',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        DisablePickupAndDeliveryFromFTLConsignment: {
            name: 'DisablePickupAndDeliveryFromFTLConsignment',
            description: 'Disable Pickup And Delivery From FTL ERP Consignment',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        fetchJobNotMappedToTripsheetInServiceHistory: {
            name: 'fetchJobNotMappedToTripsheetInServiceHistory',
            description: 'fetch Job Not Mapped To Tripsheet In Service History',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        mandatoryGSTNumberInBranchWithClientMapping: {
            name: 'mandatoryGSTNumberInBranchWithClientMapping',
            description: 'Make GST number mandatory in branch for client mapping',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        enableClientContractChart: {
            name: 'enableClientContractChart',
            description: 'Enable Client Contract Chart Functionality',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },

        chargeHeadBasedCalculationInVehicleDocuments: {
            name: 'chargeHeadBasedCalculationInVehicleDocuments',
            description: 'Charge Head Based Calculation In Vehicle Documents',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        includeWithoutDriverTransactionInTripsheet: {
            name: 'includeWithoutDriverTransactionInTripsheet',
            description: 'Include Without Driver Transaction In Tripsheet',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        multipleFreightBillForCN: {
            name: 'multipleFreightBillForCN',
            description: 'Include Consignments with multiple Freight Bill',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        isMonthWiseSalaryEnabled: {
            name: 'isMonthWiseSalaryEnabled',
            description: 'Enable Month Wise Salary',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        stationaryNumberMandatoryInJobForm: {
            name: 'stationaryNumberMandatoryInJobForm',
            description: 'Make Stationary Name Mandatory In Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        transactionSecondaryFuel: {
            name: 'transactionSecondaryFuel',
            description: 'Show Secondary Fuel In Transaction',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showOpenBiddingInAuction: {
            name: 'showOpenBiddingInAuction',
            description: 'Show Open Bidding In Auction Page',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },

        showAssociateVendorInCN: {
            name: 'showAssociateVendorInCN',
            description: 'Show Associate Vendor In CN',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        enableDriverWiseLedgerEntry: {
            name: 'enableDriverWiseLedgerEntry',
            description: 'Enable Driver Wise Ledger Entry',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        epodCompletedCnInPaymentReceipt: {
            name: 'epodCompletedCnInPaymentReceipt',
            description: 'Show EPOD Completed CN In Payment Receipt',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        enableLedgerBasedtripsheetDriverBalance: {
            name: 'enableLedgerBasedtripsheetDriverBalance',
            description: 'Enable Ledger Based Tripsheet Driver Balance',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },

        manualTaxCalculationForTyre: {
            name: 'manualTaxCalculationForTyre',
            description: 'Manual Tax Calculation For Tyre',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        autoBillForBilledCN: {
            name: 'autoBillForBilledCN',
            description: 'Auto Bill For Billed CN',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        enableClientContractChartBilling: {
            name: 'enableClientContractChartBilling',
            description: 'Enable billing on the basis of Client Contract Chart',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },

        sortCustomFieldsInCNByName: {
            name: 'sortCustomFieldsInCNByName',
            description: 'Sort Custom Fields In CN By Name',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        multipleHireSlipOnCN: {
            name: 'multipleHireSlipOnCN',
            description: 'Multiple Hire Slip In CN',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },

        incomingAlertsOnJobCreationFromCN: {
            name: 'incomingAlertsOnJobCreationFromCN',
            description: 'Incoming Alerts On Job Creation From CN',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        consignmentBasedLorryArrival: {
            name: 'consignmentBasedLorryArrival',
            description: 'Consignment Based Lorry Arrival',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        oneQuantityInCnMaterial: {
            name: 'oneQuantityInCnMaterial',
            description: 'One Quantity In CN Material',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        jobTranshipmentFlowEnabled: {
            name: 'jobTranshipmentFlowEnabled',
            description: 'Enable Job Transhipment Flow',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        isOpeningOdometerRequiredInJobForm: {
            name: 'isOpeningOdometerRequiredInJobForm',
            description: 'Opening Odometer Required In Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        isEwayBillAndInvoiceMandatoryInCNForm: {
            name: 'isEwayBillAndInvoiceMandatoryInCNForm',
            description: 'Eway Bill And Invoice Mandatory In CN Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        defaultTdsRateInTransaporterMaster: {
            name: 'defaultTdsRateInTransaporterMaster',
            description: 'Default Tds Rate in Transporter Master',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        defaultTemplateMandatoryForms: {
            name: 'defaultTemplateMandatoryForms',
            description: 'Default Template Mandatory Forms',
            type: 'select',
            configType: 'account_config',
            default: [],
            optionFunc: () => mappedObjectToOptions(FORM_TEMPLATE_TYPES, FORM_TEMPLATE_TYPES_LABELS),
            multi: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },

        isTodaysKmRealtimeReportOpenForAccount: {
            name: 'isTodaysKmRealtimeReportOpenForAccount',
            description: 'Todays Km and idle/stoppages duration in Realtime Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },

        isLiveRequestProtectedPassword: {
            name: 'isLiveRequestProtectedPassword',
            description: 'Enable Password Protect for realtime Live request',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        viewWorkOrderOnRealtime: {
            name: 'viewWorkOrderOnRealtime',
            description: 'Show Work Order In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        disconnectStatusColorIsOrange: {
            name: 'disconnectStatusColorIsOrange',
            description: 'Color of disconnect status will be orange',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        disconnectStatusNamePowerDisconnect: {
            name: 'disconnectStatusNamePowerDisconnect',
            description: 'Name of disconnect status will be changed to Power Disconnect',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        routeExpenseTypeBudget: {
            name: 'routeExpenseTypeBudget',
            description: 'Route Expense Type Budget',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        approvedTransactionInTripsheet: {
            name: 'approvedTransactionInTripsheet',
            description: 'Approved Transaction In Tripsheet',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        jobBasedVehicleInEwayBill: {
            name: 'jobBasedVehicleInEwayBill',
            description: 'Job Based Vehicle In Eway Bill',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        amountBasedTaxInServiceEntry: {
            name: 'amountBasedTaxInServiceEntry',
            description: 'Amount Based Tax In Service Entry',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        fuelV3VehicleFuelSensorOnly: {
            name: 'fuelV3VehicleFuelSensorOnly',
            description: 'Enable Fuel V3 Vehicle Fuel Sensor Only',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        isBpclEnableForAccount: {
            name: 'isBpclEnableForAccount',
            description: 'Enable BPCL Vendor',
            type: 'select',
            configType: 'vehicle_config',
            default: 'disabled',
            optionFunc: () => [
                {
                    label: 'Disabled',
                    value: 'disabled',
                },
                {
                    label: 'Show Daily Balance',
                    value: 'showDaily',
                },
                {
                    label: 'Show Montlhy Balance',
                    value: 'showMonthly',
                },
                {
                    label: 'Show Card Balance',
                    value: 'showCardBalance',
                },
            ],
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },

        showPartStockV1orV2: {
            name: 'showPartStockV1orV2',
            description: 'Enable Part Inventory page v1 or v2 or both',
            type: 'select',
            configType: 'vehicle_config',
            default: 'both',
            optionFunc: () => [
                {
                    label: 'Both',
                    value: 'both',
                },
                {
                    label: 'Show Part Inventory V1 page',
                    value: 'old',
                },
                {
                    label: 'Show Part Inventory V2 page',
                    value: 'new',
                },
            ],
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },

        fuelPageV1orV2: {
            name: 'fuelPageV1orV2',
            description: 'Enable Fuel page v1 or v2 or both',
            type: 'select',
            configType: 'vehicle_config',
            default: 'both',
            optionFunc: () => [
                {
                    label: 'Both',
                    value: 'both',
                },
                {
                    label: 'Show old fuel page',
                    value: 'old',
                },
                {
                    label: 'Show new Fuel page',
                    value: 'new',
                },
            ],
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        showEvOrFuelOrBoth: {
            name: 'showEvOrFuelOrBoth',
            description: 'Enable Fuel Module, Ev module or both',
            type: 'select',
            configType: 'vehicle_config',
            default: 'both',
            optionFunc: () => [
                {
                    label: 'Show both Ev & Fuel',
                    value: 'both',
                },
                {
                    label: 'Show Ev modules only',
                    value: 'evModule',
                },
                {
                    label: 'Show Fuel modules only',
                    value: 'fuelModule',
                },
            ],
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },

        fastagEnabledVendor: {
            name: 'fastagEnabledVendor',
            description: 'Enable FAStag/Choose Vendor',
            type: 'select',
            configType: 'vehicle_config',
            default: 'disabled',
            optionFunc: () => [
                {
                    label: 'Disabled',
                    value: 'disabled',
                },
                {
                    label: 'IDFC',
                    value: 'IDFC',
                },
                {
                    label: 'LOCONAV',
                    value: 'LOCONAV',
                },
            ],
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        costDepreciationModel: {
            name: 'costDepreciationModel',
            label: 'select a formula',
            description: 'Vehicle Depreciation Formula',
            type: 'select',
            default: false,
            configType: 'account_config',
            isSwitchedUserOnly: false,
            optionFunc: () => VEHICLE_DEPRECIATION_FORMULA,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
            actionButton: (
                <a target="_blank" href="/public/pdf/depreciation_graphs.pdf">
                    Vehicle Depreciation Info
                </a>
            ),
        },
        fastagCriticalAmount: {
            name: 'fastagCriticalAmount',
            description: 'FAStag Critical Amount',
            type: 'text',
            configType: 'vehicle_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showEmployeeCodeInUserSelect: {
            name: 'showEmployeeCodeInUserSelect',
            description: 'Show Employee Code In User Select',
            type: 'boolean',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        disableVehicleIdSearchInVehicleDropdown: {
            name: 'disableVehicleIdSearchInVehicleDropdown',
            description: 'Disable Search By Vehicle Id in dropdown',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        includeHourlyTimeRangesInCal: {
            name: 'includeHourlyTimeRangesInCal',
            description: 'Show hourly time ranges in calendar',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        includeYearlyTimeRangesInCal: {
            name: 'includeYearlyTimeRangesInCal',
            description: 'Show yearly time ranges in calendar',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        enableUserInputBoxForCalendar: {
            name: 'enableUserInputBoxForCalendar',
            description: 'Allow user to input date range manually in input box',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DATE_TIME,
            allowedForOriginalUserRoleIds: [],
        },
        showBillNumbersInJobDetailsForAccount: {
            name: 'showBillNumbersInJobDetailsForAccount',
            description: 'Show BillNumbers tab In Job Details',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableTripContinuity: {
            name: 'enableTripContinuity',
            description: 'Enable Trip Continuity',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showMultiSelectWithCheckBoxForAccount: {
            name: 'showMultiSelectWithCheckBoxForAccount',
            description: 'Show Multi Select With Check Box For Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showServiceReminderDataOnRealtimeMapWindow: {
            name: 'showServiceReminderDataOnRealtimeMapWindow',
            description: 'Show Service Reminder Data On Realtime Map Window',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showVehicleColorAccordingToServiceReminder: {
            name: 'showVehicleColorAccordingToServiceReminder',
            description: 'Show Vehicle Color According To Service Reminder',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        excludeOverlappingTripsByBackendFlags: {
            name: 'excludeOverlappingTripsByBackendFlags',
            description: 'Exclude Overlapping Trips By Backend Flags if enabled',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        isViewTimelineOnJobDetailsPageEnabled: {
            name: 'isViewTimelineOnJobDetailsPageEnabled',
            description: 'Show View Timeline Button On Job Details Page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        optimizeMergeTripAPIForTimelineInRealtime: {
            name: 'optimizeMergeTripAPIForTimelineInRealtime',
            description: 'Optimize Merge Trip API For Timeline In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        useJobEndDateForMergedTripsForAccount: {
            name: 'useJobEndDateForMergedTripsForAccount',
            description: 'Use Job End Date For Merged Trips in job map',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        indentDeviceOptions: {
            name: 'indentDeviceOptions',
            description: 'Indent device  type options in vehicle assign',
            type: 'select',
            configType: 'account_config',
            default: ['SIM', 'APP', 'PORTABLE'],
            multi: true,
            optionFunc: () => filter(INDENT_DEVICE_OPTIONS, (op) => op.value !== 'FITTED_DEVICE'),
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        showNextRoutePointCategoryTypeFilterInRealtime: {
            name: 'showNextRoutePointCategoryTypeFilterInRealtime',
            description: 'Show Next Route Point Category Type Filter In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        enableRemoteVehicleListPagination: {
            name: 'enableRemoteVehicleListPagination',
            description: 'if enabled will  use remote pagination or will fetch only 1 page data at a time',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        includeFuelDetails: {
            name: 'includeFuelDetails',
            description: 'Include Fuel details in Fuel Tab',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        accountSpecificAlarms: {
            name: 'accountSpecificAlarms',
            description:
                'Select Account specific alarms. if showAccountSpecificAlarms is checked then only these alarms will be used.',
            type: 'select',
            configType: 'account_config',
            default: ['Idle Engine'],
            optionFunc: () => orderBy(ACCOUNT_SPECIFIC_ALARMS_OPTIONS, 'value'),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showDriverBehaviour: {
            name: 'showDriverBehaviour',
            description: 'Show driver behaviour in job summary',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        useMergeTripAPIFieldsParam: {
            name: 'useMergeTripAPIFieldsParam',
            description: 'Use fields param in  merge trip api to fetch only desired fields to optimize api',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showGearPredictionDetails: {
            name: 'showGearPredictionDetails',
            description: 'Show Gear Prediction Details in vehicle',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        defaultSortInRealtime: {
            name: 'defaultSortInRealtime',
            description: 'Select default sort in realtime',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: () => getSortOptionsRealtime(),
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
            selectOptions: {
                allowClear: true,
            },
        },
        showDefaultJobReport: {
            name: 'showDefaultJobReport',
            description: 'Skip column mapping config for account if any in job report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showTempDetailsInTripList: {
            name: 'showTempDetailsInTripList',
            description: 'Show temperature details in trip list, like min,max and avg temp',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showConnectDisconnectDevice: {
            name: 'showConnectDisconnectDevice',
            description: 'Enable to show connect disconnect device timeline.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        dontShowNotOnlineAndRemovedVehicle: {
            name: 'dontShowNotOnlineAndRemovedVehicle',
            description: 'Do not Show Not Online And Removed Vehicle',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        idleVehicleDetailedSummaryAvailable: {
            name: 'idleVehicleDetailedSummaryAvailable',
            description: 'idle filter in realtime summary',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        idleVehicleGTXAndLTYHours: {
            name: 'idleVehicleGTXAndLTYHours',
            description: 'X <idle <Y filter in realtime summary',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        jobStartDateKeyForMergedTripsForAccount: {
            name: 'jobStartDateKeyForMergedTripsForAccount',
            description: 'Select date to use as job start date for fetching merge trip data.',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: () => mappedObjectToOptions(JOB_TRIP_START_DATE_FIELDS, JOB_TRIP_START_DATE_FIELDS_LABEL),
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        jobEndDateKeyForMergedTripsForAccount: {
            name: 'jobEndDateKeyForMergedTripsForAccount',
            description: 'Select date to use as job  end for fetching merge trip data.',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: () => mappedObjectToOptions(JOB_TRIP_END_DATE_FIELDS, JOB_TRIP_END_DATE_FIELDS_LABEL),
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        dontClubStoppageForAccount: {
            name: 'dontClubStoppageForAccount',
            description: 'Dont merge stoppage based on same address and odometer reading',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showOdometerReadingInHistoryReport: {
            name: 'showOdometerReadingInHistoryReport',
            description: 'Show odometer reading in history report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        enableDriverAnalytics: {
            name: 'enableDriverAnalytics',
            description: 'Enable driver analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DRIVER_ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        driverAnalyticsWithoutJob: {
            name: 'driverAnalyticsWithoutJob',
            description: 'Enable driver analytics without job',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DRIVER_ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        isCeilingPriceRequiredForAuction: {
            name: 'isCeilingPriceRequiredForAuction',
            description: 'Is Ceiling price required for Auctions',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showOdometerReadingInRealTimeReport: {
            name: 'showOdometerReadingInRealTimeReport',
            description: 'Show Odometer Reading in Real Time Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        ignoreZeroTrips: {
            name: 'ignoreZeroTrips',
            description: 'ignore zero trips in driver analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DRIVER_ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        isMissiningFuelEnable: {
            name: 'isMissiningFuelEnable',
            description: 'Enable Missing fuel for the account,(Enable for all switch users)',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        checklistConfig: {
            name: 'checklistConfig',
            description: 'Select Configuration of Checklist',
            type: 'select',
            configType: 'account_config',
            default: ['PLANT', 'PORT', 'PLANT_SECURITY'],
            optionFunc: () => orderBy(CHECKLIST_OPTIONS, 'value'),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        freightCalculationPriority1: {
            name: 'freightCalculationPriority1',
            description: '',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: (config) => {
                const p2 = get(config, 'freightCalculationPriority2');
                const p3 = get(config, 'freightCalculationPriority3');
                const p4 = get(config, 'freightCalculationPriority4');
                return filter(FREIGHT_PRIORITIES, (pr) => pr.value != p2 && pr.value != p3 && pr.value != p4);
            },
            selectOptions: {
                allowClear: true,
            },
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        freightCalculationPriority2: {
            name: 'freightCalculationPriority2',
            description: '',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: (config) => {
                const p1 = get(config, 'freightCalculationPriority1');
                const p3 = get(config, 'freightCalculationPriority3');
                const p4 = get(config, 'freightCalculationPriority4');
                return filter(FREIGHT_PRIORITIES, (pr) => pr.value != p1 && pr.value != p3 && pr.value != p4);
            },
            selectOptions: {
                allowClear: true,
            },
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        CNOrMaterialBasedEInvoicing: {
            name: 'CNOrMaterialBasedEInvoicing',
            description: 'Enable CN Or Material Based E-Invoicing',
            type: 'select',
            configType: 'account_config',
            default: 'CNBased',
            optionFunc: () => [
                {
                    label: 'CNBased',
                    value: 'CN Based',
                },
                {
                    label: 'MaterialBased',
                    value: 'Material Based',
                },
                {
                    label: 'Job Booking Based',
                    value: 'Job Booking Based',
                },
            ],
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        freightCalculationPriority3: {
            name: 'freightCalculationPriority3',
            description: '',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: (config) => {
                const p2 = get(config, 'freightCalculationPriority2');
                const p1 = get(config, 'freightCalculationPriority1');
                const p4 = get(config, 'freightCalculationPriority4');
                return filter(FREIGHT_PRIORITIES, (pr) => pr.value != p1 && pr.value != p2 && pr.value != p4);
            },
            selectOptions: {
                allowClear: true,
            },
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        freightCalculationPriority4: {
            name: 'freightCalculationPriority4',
            description: '',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: (config) => {
                const p1 = get(config, 'freightCalculationPriority1');
                const p2 = get(config, 'freightCalculationPriority2');
                const p3 = get(config, 'freightCalculationPriority3');
                return filter(FREIGHT_PRIORITIES, (pr) => pr.value != p1 && pr.value != p2 && pr.value != p3);
            },
            selectOptions: {
                allowClear: true,
            },
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showConstructionMachineryDetails: {
            name: 'showConstructionMachineryDetails',
            description: 'Show Construction Machinery Details like Fuel, Running time ,Mileage etc',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        isJobTimelineExtendEnabled: {
            name: 'isJobTimelineExtendEnabled',
            description: 'Enable Extend job timeline feature to account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isCurrentTimestampAllowedForBillingDate: {
            name: 'isCurrentTimestampAllowedForBillingDate',
            description: 'Show Option To Choose Current Time For Billing Date',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.RECORDS,
            allowedForOriginalUserRoleIds: [],
        },
        isRouteRemoteSelectEnabled: {
            name: 'isRouteRemoteSelectEnabled',
            description: 'Enable Route remote search for selecting routes',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ROUTE,
            allowedForOriginalUserRoleIds: [],
        },
        isSMSServiceEnabledInForms: {
            name: 'isSMSServiceEnabledInForms',
            description: 'Enable selection of SMS. forms like service reminder etc',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.SERVICE,
            allowedForOriginalUserRoleIds: [],
        },
        showTransporterDetailsColInAlertList: {
            name: 'showTransporterDetailsColInAlertList',
            description: 'Show Transporter Details Col In AlertList',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        showJobDetailsColInAlertList: {
            name: 'showJobDetailsColInAlertList',
            description: 'Show Job Details Col In AlertList',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ALARM,
            allowedForOriginalUserRoleIds: [],
        },
        showCurrAddressBookCategoriesFilterOnRealtime: {
            name: 'showCurrAddressBookCategoriesFilterOnRealtime',
            description: 'Show Current AddressBook Category  Filter In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isSAPPayloadInJobDetailsEnabled: {
            name: 'isSAPPayloadInJobDetailsEnabled',
            description: 'Enable SAP payload in job details more info',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        excludeCompletedJobBookingInJobCreate: {
            name: 'excludeCompletedJobBookingInJobCreate',
            description: 'Enable to exclude complete job booking.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobBookingInConsignment: {
            name: 'showJobBookingInConsignment',
            description: 'Enable to show job booking in consignment.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        isDeviationTypeFilterEnabled: {
            name: 'isDeviationTypeFilterEnabled',
            description: 'Enable to show deviation type filter.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showVehicleStatusOnJobByLive: {
            name: 'showVehicleStatusOnJobByLive',
            description: 'Show vehicle status in job same as realtime(using live api)',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobCheckListDashboard: {
            name: 'showJobCheckListDashboard',
            description: 'Enable to show job checklist generation dashboard.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        useLatestJobSummaryAPI: {
            name: 'useLatestJobSummaryAPI',
            description: 'use latest job summary api in jobs,control room  etc.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        findNearestAddressWithMinDistance: {
            name: 'findNearestAddressWithMinDistance',
            description: 'find nearest address to which vehicle is closest and inside',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showDaywiseUptimeReport: {
            name: 'showDaywiseUptimeReport',
            description: 'Enable daywise uptime report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        branchBasedRateCharge: {
            name: 'branchBasedRateCharge',
            description: 'Branch Based Rate Charge',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showNewTimeOnSitePage: {
            name: 'showNewTimeOnSitePage',
            description: 'Show new time on site (Stoppage Analytics)',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        dailyUtilisationKMLimit: {
            name: 'dailyUtilisationKMLimit',
            description: 'Daily Utilisation KM Limit',
            type: 'text',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.OVERVIEW,
            allowedForOriginalUserRoleIds: [],
        },
        realtimeReminderConfig: {
            name: 'realtimeReminderConfig',
            description: 'Show All Vehicle Reminders Config',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        enableDeviationAlertInJobFormByDefault: {
            name: 'enableDeviationAlertInJobFormByDefault',
            description: 'Enable Deviation Alert In Job Form By Default',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showTempDataAtJobLevel: {
            name: 'showTempDataAtJobLevel',
            description: 'Enable this flag to show temp data at job listing,job details and job report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        tempDataTimeRangeAtJobLevel: {
            name: 'tempDataTimeRangeAtJobLevel',
            description: 'Select time range for temp data at job listing,job details and job report',
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: (config) => {
                return mappedObjectToOptions(TEMP_DATA_TIME_RANGE_OPTIONS_JOBS);
            },
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableCustomerDetentionReport: {
            name: 'enableCustomerDetentionReport',
            description: 'Enable Customer Detention Report',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableAutoScrollOnControlRoom: {
            name: 'enableAutoScrollOnControlRoom',
            description: 'Enable Auto Scroll in control room',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        transactionsApprovalConfigEnabled: {
            name: 'transactionsApprovalConfigEnabled',
            description: 'Enable Transactions Approval Config',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        serviceEntryApprovalConfigEnabled: {
            name: 'serviceEntryApprovalConfigEnabled',
            description: 'Enable Service Entry Approval Config',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        billingInfoApprovalConfigEnabled: {
            name: 'billingInfoApprovalConfigEnabled',
            description: 'Enable Billing Info Approval Config',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleDocumentApprovalMatrixConfigEnabled: {
            name: 'vehicleDocumentApprovalMatrixConfigEnabled',
            description: 'Enable Vehicle Document Approval Config',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showTimelineChangesForCustomerLogin: {
            name: 'showTimelineChangesForCustomerLogin',
            description: 'Show Job Timeline for Customer login',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableNewSettingsPage: {
            name: 'enableNewSettingsPage',
            description: 'Enable New Settings Page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showImeiDetailsInSensorAndTracking: {
            name: 'showImeiDetailsInSensorAndTracking',
            description: 'Enable IMEI number in Sensor & Tracking module',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [],
        },
        enableMoveOneLocationToAnotherSmoothlyRealtimeShareMap: {
            name: 'enableMoveOneLocationToAnotherSmoothlyRealtimeShareMap',
            description: 'Enable Smooth Realtime Share Map',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isTransporterAnalyticsDrillDownFlagEnabled: {
            name: 'isTransporterAnalyticsDrillDownFlagEnabled',
            description: 'If enabled job listing will be shown ship to party wise and map view',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        isAntdDateRangePickerEnabled: {
            name: 'isAntdDateRangePickerEnabled',
            description: 'Enable Antd Date range picker',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        enableTransactionOldApprovalFlow: {
            name: 'enableTransactionOldApprovalFlow',
            description: 'Enable Transaction Old Flow',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showBulkEpodConsignment: {
            name: 'showBulkEpodConsignment',
            description: 'Enable Epod Bulk Consignment Uploader',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        routeBudgetMandatoryInJobForm: {
            name: 'routeBudgetMandatoryInJobForm',
            description: 'Route Budget Mandatory In Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        jobMandatoryInTransactionForm: {
            name: 'jobMandatoryInTransactionForm',
            description: 'Job Mandatory In Transaction Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showOpenIssuesOnRealtimeCard: {
            name: 'showOpenIssuesOnRealtimeCard',
            description: 'Show Open Issues On Realtime Card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showStoppagesCountBreakdownInVehicleSummaryReport: {
            name: 'showStoppagesCountBreakdownInVehicleSummaryReport',
            description: 'Show Stoppages Count Breakdown In Vehicle Summary Report',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showVoiceVoiceCallOptionInRealtime: {
            name: 'showVoiceVoiceCallOptionInRealtime',
            description: 'Show Voice Call Option In Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showVehicleReconciliationReportButton: {
            name: 'showVehicleReconciliationReportButton',
            description: 'Enable to show vehicle reconciliation report button',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.HISTORY,
            allowedForOriginalUserRoleIds: [],
        },
        showJobAnalytics: {
            name: 'showJobAnalytics',
            description: 'Enable to show job analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showAccidentTagToAccount: {
            name: 'showAccidentTagToAccount',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TAGS,
            allowedForOriginalUserRoleIds: [],
        },
        showPresentDriversOnly: {
            name: 'showPresentDriversOnly',
            description: '-',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        issueFuelFromTransaction: {
            name: 'issueFuelFromTransaction',
            description: 'Issue Fuel From Transaction',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showDetentionAnalytics: {
            name: 'showDetentionAnalytics',
            description: 'Show Detention Analytics for account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        allVehiclesLocationReport: {
            name: 'allVehiclesLocationReport',
            description: 'Enable all vehicles location report at selected time.',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showDtcOnRealtime: {
            name: 'showDtcOnRealtime',
            description: 'Show DTC details on realtime card',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showUnloadingClusterInTransporterAnalyticsMapView: {
            name: 'showUnloadingClusterInTransporterAnalyticsMapView',
            description: 'Show Unloading Cluster In Transporter Analytics Map View',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showOBDLinkedModuleDataForAccount: {
            name: 'showOBDLinkedModuleDataForAccount',
            description: 'Show OBD Linked Module Data For Account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEVICE,
            allowedForOriginalUserRoleIds: [],
        },
        showInstantFuelConsumptionDetails: {
            name: 'showInstantFuelConsumptionDetails',
            description: 'Show Instant Fuel Consumption Details at History, vehicles and others places',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        useRouteLatLonInsteadAddressBookInJobMapForMarker: {
            name: 'useRouteLatLonInsteadAddressBookInJobMapForMarker',
            description: 'Use Route Lat Lon Instead Address Book In Job Map For Route Point  Marker',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobRefreshButton: {
            name: 'showJobRefreshButton',
            description: 'Show Job refresh button in legend drop down.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showJobRouteDetailsForSelectedVehicleOnRealtime: {
            name: 'showJobRouteDetailsForSelectedVehicleOnRealtime',
            description: 'Show Job Route Details For Selected Vehicle On Realtime',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        isBulkNearestAddressAPIEnabled: {
            name: 'isBulkNearestAddressAPIEnabled',
            description: 'Bulk nearest address api enabled',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.REALTIME,
            allowedForOriginalUserRoleIds: [],
        },
        showLocationOnTransporterAnalyticsMapViewWithinKMRadius: {
            name: 'showLocationOnTransporterAnalyticsMapViewWithinKMRadius',
            description:
                'Show AddressBook Locations(Depot, Customer, Dealer etc) On Transporter Analytics MapView Within KM Radius',
            type: 'text',
            configType: 'account_config',
            default: 100,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        firstPageCNforERPAccount: {
            name: 'firstPageCNforERPAccount',
            description: 'Show CN as first page(After Login) for ERP account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        showDriverTagsVisible: {
            name: 'showDriverTagsVisible',
            description: 'Show Driver Tags on Vehicle Card.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
        showInstantFuelInJob: {
            name: 'showInstantFuelInJob',
            description: 'Show Instant Fuel in Job',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showDeviationAnalyticsForAccount: {
            name: 'showDeviationAnalyticsForAccount',
            description: 'Show Deviation Analytics For Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showDoorStatusForJob: {
            name: 'showDoorStatusForJob',
            description: 'Enable door status for job details page.',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showGridInChart: {
            name: 'showGridInChart',
            description: 'Show Grid in Chart',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        isWebWorkerEnabledComputationForAccount: {
            name: 'isWebWorkerEnabledComputationForAccount',
            description: 'Enable Web Worker Computation For Account',
            type: 'boolean',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        removeCountryRestrictionOnGooglePlaceAPISearchForAccount: {
            name: 'removeCountryRestrictionOnGooglePlaceAPISearchForAccount',
            description: 'Remove Country Restriction On Google Place API Search For Account',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        useVehicleSizeInRouteBudget: {
            name: 'useVehicleSizeInRouteBudget',
            description: 'Use Vehicle Size in Route Budger',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ROUTE,
            allowedForOriginalUserRoleIds: [],
        },
        shareJobOnRealtimeVehicle: {
            name: 'shareJobOnRealtimeVehicle',
            description: 'Share Job on Realtime Vehicle Page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        enableUnitPartCostInServiceHistory: {
            name: 'enableUnitPartCostInServiceHistory',
            description: 'Show cost as per Unit/ Total Cost',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        checkVehicleWorkOrderStatus: {
            name: 'checkVehicleWorkOrderStatus',
            description: 'Allow to call vehicle work order status api',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        isSecondaryAnalyticsEnabled: {
            name: 'isSecondaryAnalyticsEnabled',
            description: 'Enable Secondary Analytics',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        tagTypeForStatusVisibilityAnalytics: {
            name: 'tagTypeForStatusVisibilityAnalytics',
            description: 'Tag Type For Status Visibility Analytics',
            type: 'text',
            configType: 'account_config',
            default: 'PointAnalytics',
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        isAddressBookAnalyticsEnabled: {
            name: 'isAddressBookAnalyticsEnabled',
            description: 'Enable AddressBook Analytics',
            type: 'text',
            configType: 'account_config',
            default: '',
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        showTemperatureComplianceDashboard: {
            name: 'showTemperatureComplianceDashboard',
            description: 'Show Temperature Compliance Dashboard',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ANALYTICS,
            allowedForOriginalUserRoleIds: [],
        },
        isOldTripUpdateFalseByDefaultInEditJob: {
            name: 'isOldTripUpdateFalseByDefaultInEditJob',
            description: 'If Enabled oldTripUpdate flag will be set False By Default In Edit Job',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        isCustomDateRangesEnabledInCalendar: {
            name: 'isCustomDateRangesEnabledInCalendar',
            description: 'Add Custom Date filter Range',
            type: 'link',
            pathname: 'dashboard/settings/custom-date-range',
            configType: 'account_config',
            default: true,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showJobStoppageType: {
            name: 'showJobStoppageType',
            description: 'Show Job Stoppage Type',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        filterAddressBasedOnUserTag: {
            name: 'filterAddressBasedOnUserTag',
            description: 'Filter Address Based on User Tag',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        hideEPODQuantityDetails: {
            name: 'hideEPODMaterialDetails',
            description: 'Hidden fiels of EPOD Form - Wrong Delivery, Excess Quantity, Damaged Quantity',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        ShowProjectedFuel: {
            name: 'ShowProjectedFuel',
            description: 'Enable Projected Fuel',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.FUEL,
            allowedForOriginalUserRoleIds: [],
        },
        excludeVehicleStatus: {
            name: 'excludeVehicleStatus',
            description: 'Select vehicle status to exclude',
            type: 'select',
            configType: 'account_config',
            default: null,
            optionFunc: () => mappedVehicleStatus(vehicleStatusList),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
            selectOptions: {
                allowClear: true,
            },
        },
        isEPODDocMandatoryForTransporter: {
            name: 'isEPODDocMandatoryForTransporter',
            description: 'Document Mandatory for EPOD Material Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showGenerateAndSignButtonEnabaled: {
            name: 'showGenerateAndSignButtonEnabaled',
            description: 'Enable Generate and Sign Button for Template',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.OVERVIEW,
            allowedForOriginalUserRoleIds: [],
        },
        showOwnershipOnAssignVehicle: {
            name: 'showOwnershipOnAssignVehicle',
            description: 'Show Ownership field on Assign Vehicle',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
        },
        challanApprovalConfigEnabled: {
            name: 'challanApprovalConfigEnabled',
            description: 'Enable Challan Approval Config',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        allowAddVehiclesAtTransporterSide: {
            name: 'allowAddVehiclesAtTransporterSide',
            description: 'Allow Vehicles At Transporter Side',
            type: 'boolean',
            default: false,
            isSwitchedUserOnly: true,
            configType: 'account_config',
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },

        isContainerPrintEnabledForVendorConsignment: {
            name: 'isContainerPrintEnabledForVendorConsignment',
            description: 'Show Container Print on Vendor Consignment Listing Page',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.VENDOR,
            allowedForOriginalUserRoleIds: [],
        },
        mandatoryJobBookingInJobForm: {
            name: 'mandatoryJobBookingInJobForm',
            description: 'Mandatory Job Booking in Job Form',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        epodImageMandatoryForShipper: {
            name: 'epodImageMandatoryForShipper',
            description: 'Enable Epod Image Mandatory For Shipper',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        epodImageMandatoryForTransporter: {
            name: 'epodImageMandatoryForTransporter',
            description: 'Enable Epod Image Mandatory For Transporter',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        epodImageMandatoryForCustomer: {
            name: 'epodImageMandatoryForCustomer',
            description: 'Enable Epod Image Mandatory For Customer',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showTareWeightAndGrossWeightInCN: {
            name: 'showTareWeightAndGrossWeightICN',
            description: 'Show Tare Weight and Gross Weight ICN',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.ERP,
            allowedForOriginalUserRoleIds: [],
        },
        hideTransporterRankingOnAuctionForRoleIds: {
            name: 'hideTransporterRankingOnAuctionForRoleIds',
            description: `Hide transporter ranking on Auction based on role ids`,
            type: 'select',
            configType: 'account_config',
            default: '',
            optionFunc: () => mappedRoles(rolesList),
            multi: true,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.TMS,
            allowedForOriginalUserRoleIds: [],
        },
        showTripRateforFTLDispatchPlanner: {
            name: 'showTripRateforFTLDispatchPlanner',
            description: 'Show Trip Rate for FTL Dispatch Planner on Transporter Dropdown',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        sendApprovalOnCheapestTransporterForFTL: {
            name: 'sendApprovalOnCheapestTransporterForFTL',
            description: 'Send approval call when cheapest Transporter is not selected',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleUtilizationVolumn: {
            name: 'vehicleUtilizationVolumn',
            description: 'Set Vehicle Utilization Volumn (%)',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleUtilizationWeight: {
            name: 'vehicleUtilizationWeight',
            description: 'Set Vehicle Utilization Weight (%)',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        secondaryDriverChangeEnabled: {
            name: 'secondaryDriverChangeEnabled',
            description: 'Enable Secondary Driver Change',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.DEFAULT,
            allowedForOriginalUserRoleIds: [],
        },
        showTripRateforFTLDispatchPlanner: {
            name: 'showTripRateforFTLDispatchPlanner',
            description: 'Show Trip Rate for FTL Dispatch Planner on Transporter Dropdown',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        sendApprovalOnCheapestTransporterForFTL: {
            name: 'sendApprovalOnCheapestTransporterForFTL',
            description: 'Send approval call when cheapest Transporter is not selected',
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleUtilizationVolumn: {
            name: 'vehicleUtilizationVolumn',
            description: 'Set Vehicle Utilization Volumn (%)',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        vehicleUtilizationWeight: {
            name: 'vehicleUtilizationWeight',
            description: 'Set Vehicle Utilization Weight (%)',
            type: 'text',
            configType: 'account_config',
            default: 0,
            isSwitchedUserOnly: true,
            category: CONFIG_CATEGORIES.JOB,
            allowedForOriginalUserRoleIds: [],
        },
        showVehicleV2page: {
            name: 'showVehicleV2page',
            description: `Show Vehicle new Add edit`,
            type: 'boolean',
            configType: 'account_config',
            default: false,
            isSwitchedUserOnly: false,
            category: CONFIG_CATEGORIES.VEHICLE,
            allowedForOriginalUserRoleIds: [],
        },
    };
};

export const widgets_metadata = {
    'UNDEPLOYED_COUNT': {
        name: 'UNDEPLOYED_COUNT',
        description: 'Vehicles Not On Job',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'DEVICE_STATUS': {
        name: 'DEVICE_STATUS',
        description: 'Device Status',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'DEVIATION_KM': {
        name: 'DEVIATION_KM',
        description: 'Deviation in Km',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'UNLOADING_DURATION': {
        name: 'UNLOADING_DURATION',
        description: 'Unloading Duration',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'LOADING_DURATION': {
        name: 'LOADING_DURATION',
        description: 'Loading Duration',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'ETA_DELAY': {
        name: 'ETA_DELAY',
        description: 'Job Delay',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'JOB_STATUS': {
        name: 'JOB_STATUS',
        description: 'Job Status',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'REMINDERS': {
        name: 'REMINDERS',
        description: 'Reminders',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'FUEL_SUMMARY': {
        name: 'FUEL_SUMMARY',
        description: 'Fuel Summary',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'MOST_ALARMS': {
        name: 'MOST_ALARMS',
        description: 'Vehicles With Most Alarms',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'MAXIMUM_MILEAGE_CHANGE': {
        name: 'MAXIMUM_MILEAGE_CHANGE',
        description: 'Vehicles with Decreased Mileage',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'LOW_SCORE_DRIVER_VEHICLES': {
        name: 'LOW_SCORE_DRIVER_VEHICLES',
        description: 'Low Score Drivers/Vehicles',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'HIGH_SCORE_DRIVER_VEHICLES': {
        name: 'HIGH_SCORE_DRIVER_VEHICLES',
        description: 'High Score Drivers/Vehicles',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'VEHICLE_DEC_MILEAGE': {
        name: 'VEHICLE_DEC_MILEAGE',
        description: 'Vehicles with Most Mileage',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'VEHICLE_INC_MILEAGE': {
        name: 'VEHICLE_INC_MILEAGE',
        description: 'Vehicles with Least Mileage',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'DISTANCE_GRAPH': {
        name: 'DISTANCE_GRAPH',
        description: 'Odometer Graph',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'VEHICLES_STATUS': {
        name: 'VEHICLES_STATUS',
        description: 'Vehicles Status',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'VEHICLE_STATUS_GRAPH': {
        name: 'VEHICLE_STATUS_GRAPH',
        description: 'Vehicle Status Graph',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'VEHICLES_TOWARD_PLANT': {
        name: 'VEHICLES_TOWARD_PLANT',
        description: 'Vehicles Toward Plant',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'PING_COUNT': {
        name: 'PING_COUNT',
        description: 'Sim Ping Counter and Graph',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'EPOD_SUMMARY': {
        name: 'EPOD_SUMMARY',
        description: 'EPOD Details',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'OVERALL_TAT': {
        name: 'OVERALL_TAT',
        description: 'Overall TAT details',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
    'PLANT_PERFORMANCE': {
        name: 'PLANT_PERFORMANCE',
        description: 'Plant Performance',
        type: 'boolean',
        configType: 'widget_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.OVERVIEW,
        allowedForOriginalUserRoleIds: [],
    },
};

export const auto_job_metadata = {
    'startJobWithDriver': {
        name: 'startJobWithDriver',
        description: '',
        type: 'boolean',
        configType: 'auto_job_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'vehicleRouteAssigned': {
        name: 'vehicleRouteAssigned',
        description: '',
        type: 'boolean',
        configType: 'auto_job_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
};

export const auto_job_address_metadata = {
    'startAtLoadingEntry': {
        name: 'startAtLoadingEntry',
        description: 'Start at loading entry',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'startAtLoadingExit': {
        name: 'startAtLoadingExit',
        description: 'Start at loading exit',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'startAtUnloadingEntry': {
        name: 'startAtUnloadingEntry',
        description: 'Start at unloading entry',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'startAtUnloadingExit': {
        name: 'startAtUnloadingExit',
        description: 'Start at unloading exit',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'endAtUnloadingEntry': {
        name: 'endAtUnloadingEntry',
        description: 'End at unloading entry',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'endAtUnloadingExit': {
        name: 'endAtUnloadingExit',
        description: 'End at unloading exit',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'endPrevAtLoadingEntry': {
        name: 'endPrevAtLoadingEntry',
        description: 'End previous job at loading entry',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'emptyJob': {
        name: 'emptyJob',
        description: 'Empty Job',
        type: 'boolean',
        configType: 'auto_job_address_config',
        default: false,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },

    'addressBookId': {
        name: 'addressBookId',
        description: 'Address',
        type: 'select',
        configType: 'auto_job_address_config',
        default: null,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
    'routeId': {
        name: 'routeId',
        description: 'Route',
        type: 'select',
        configType: 'auto_job_address_config',
        default: null,
        isSwitchedUserOnly: true,
        category: CONFIG_CATEGORIES.JOB,
        allowedForOriginalUserRoleIds: [],
    },
};
