import { jsx as _jsx } from "react/jsx-runtime";
const AdminHomeComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-home" */ 'components/dashboard/admin/admin_home_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditAccountForm = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-add-edit-account" */ 'components/dashboard/admin/add_edit_account_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewAccountsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-view-accounts" */ 'components/dashboard/admin/view_accounts/view_accounts_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewActiveDeviceInstallations = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-view-active-device-installations" */ 'components/dashboard/internal_tools/operations/view_active_device_installations'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AssignDutyComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-assign-duty" */ 'components/dashboard/internal_tools/operations/technicianDuty/assign_duty_technician_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AssignDevicesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-assign-devices" */ 'components/dashboard/internal_tools/operations/assign_device_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AssignAssetsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-assign-assets" */ 'components/dashboard/internal_tools/operations/assets/assign_assets/AssignAssetsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const RfidMapping = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-rfid-mapping" */ 'components/dashboard/internal_tools/operations/rfid_mapping'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const SimActivation = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-sim-activation" */ 'components/dashboard/internal_tools/operations/SimActivation'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const JobsQueueDashboard = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-jobsQueue-dashboard" */ 'components/dashboard/internal_tools/operations/jobsQueue-dashboard/JobsQueueDashboardComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const VerifyDeviceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-verify-device" */ 'components/dashboard/internal_tools/operations/verify_device_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseInstallsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-installs" */ 'components/dashboard/internal_tools/operations/metabase_installs_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditTechnicianDutyComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-add-edit-technician-duty" */ 'components/dashboard/internal_tools/operations/technicianDuty/add_edit_technician_duty_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseDutiesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-duties" */ 'components/dashboard/internal_tools/operations/metabase_duties_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseIotSupportComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-iot-support" */ 'components/dashboard/internal_tools/operations/metabase_iot_support_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseInventoryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-inventory" */ 'components/dashboard/internal_tools/operations/metabase_inventory_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseLockConfigurationLogComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-lock-configuration-log" */ 'components/dashboard/internal_tools/operations/metabase_lock_configuration_log_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseSettingsLogComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-settings-log" */ 'components/dashboard/internal_tools/operations/metabase_settings_log_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseDeviceWithoutVehicleComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-device-without-vehicle" */ 'components/dashboard/internal_tools/operations/metabase_device_without_vehicle_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const TechnicianRunningReportComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-technician-running-report" */ 'components/dashboard/internal_tools/operations/technician_running_report_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const SendShipmentFormComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-send-shipment" */ 'components/dashboard/internal_tools/operations/send_shipment_form_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const IncomingTranporterVehicle = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-incoming-transporter-vehicle" */ 'components/dashboard/internal_tools/operations/incoming_transporter_vehicle_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const Reportuploader = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-report-uploader" */ 'components/dashboard/internal_tools/operations/report_uploader_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const DeviceDetachUploader = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-device-detach-uploader" */ 'components/dashboard/internal_tools/operations/DeviceDetachUploader'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const SimInventoryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-sim-inventory" */ 'components/dashboard/internal_tools/operations/sim_inventory/sim_inventory_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseDeviceAuditHistorySearchComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-device-audit-history-search" */ 'components/dashboard/internal_tools/operations/MetabaseDeviceAuditHistorySearchComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseDeviceSearchByComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-device-search-by" */ 'components/dashboard/internal_tools/operations/MetabaseDeviceSearchByComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseIssuedToListingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-issued-to-listing" */ 'components/dashboard/internal_tools/operations/MetabaseIssuedToListingComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const BillingReports = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-billing-reports" */ 'components/dashboard/internal_tools/operations/BillingReport'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseSimInventoryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-sim-inventory" */ 'components/dashboard/internal_tools/operations/MetabaseSimInventoryComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const HulInventoryCountComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-hul-inventory" */ 'components/dashboard/internal_tools/operations/hul_inventory/HulInventoryCountComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ENVFilesDetailsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-env-details" */ 'components/dashboard/internal_tools/operations/envDashboard/ENVDetailsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const EnterpriseDashboardCountComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-enterprise-dashboard" */ 'components/dashboard/internal_tools/operations/enterprise_dashboard/EnterpriseDashboardCountComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ManageAssetsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-manage-assets" */ 'components/dashboard/internal_tools/operations/assets/manage_assets/ManageAssetsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditVehicleModelComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-add-edit-vehicle-model" */ 'components/dashboard/internal_tools/operations/make_model/AddEditVehicleModelComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ControlTowerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-control-tower" */ 'components/dashboard/internal_tools/control_tower/ControlTowerComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ControlTowerComponentNew = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-control-tower" */ 'components/dashboard/internal_tools/control_tower_new/ControlTowerComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ControlTowerDetails = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-control-tower-details" */ 'components/dashboard/internal_tools/control_tower/ControlTowerDetails'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseERPUsagaeComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-erp-usage" */ 'components/dashboard/internal_tools/operations/metabase/metabaseERPIssuesComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const MetabaseAccountDashboardComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-metabase-balco-dashboard" */ 'components/dashboard/internal_tools/operations/metabase/MetabaseAccountDashboardComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const LockManagement = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-lock-management" */ 'components/dashboard/admin/lock_management/lock_management_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const FuelCalibrationComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-fuel-calibration" */ 'components/dashboard/admin/fuel_calibration/fuel_calibration_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AccountDeviceStatusComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-account-device-status" */ 'components/dashboard/admin/reverse_logistics/account_device_status_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ViewAccountsHandoverComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-view-accounts-handover" */ 'components/dashboard/admin/view_accounts/view_accounts_handover_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const JobAuditkManagement = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-job-audit" */ 'components/dashboard/admin/job_audit/job_audit_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const ActivityAuditComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-activity-audit" */ 'components/dashboard/admin/activity_audit/activityAudit'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const RemoveBulkVehicle = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-remove-bulk-vehicle" */ 'components/dashboard/internal_tools/operations/remove_bulk_vehicle_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
import internalFinanceRoutes from './internalFinance';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import { map } from 'lodash';
import { getMetabaseHeaderListsForAccount, getMetabaseHeaderListsForMasterDashboard } from 'utils/metabaseUtils';
const metabaseDashboardsForAccount = getMetabaseHeaderListsForAccount();
const metabaseDashboardsForMasterDashboard = getMetabaseHeaderListsForMasterDashboard();
const adminRoutes = [
    {
        path: 'home',
        lazy: AdminHomeComponent,
    },
    {
        path: 'accounts/add',
        lazy: AddEditAccountForm,
    },
    {
        path: 'accounts/edit/:id',
        lazy: AddEditAccountForm,
    },
    {
        path: 'accounts/view',
        lazy: ViewAccountsComponent,
    },
    {
        path: 'accounts/viewhandover',
        lazy: ViewAccountsHandoverComponent,
    },
    {
        path: 'manage/locks',
        lazy: LockManagement,
    },
    {
        path: 'tools/job-audit',
        lazy: JobAuditkManagement,
    },
    {
        path: 'tools/activity-audit',
        lazy: ActivityAuditComponent,
    },
    {
        path: 'tools/fuel-calibration',
        lazy: FuelCalibrationComponent,
    },
    {
        path: 'tools/reverse-logistics',
        lazy: AccountDeviceStatusComponent,
    },
    {
        path: 'tools/operations/billing-reports',
        lazy: BillingReports,
    },
    {
        path: 'tools/operations/active',
        lazy: ViewActiveDeviceInstallations,
    },
    {
        path: 'tools/operations/assignduty/add',
        lazy: AddEditTechnicianDutyComponent,
    },
    {
        path: 'tools/operations/assignduty/edit/:id',
        lazy: AddEditTechnicianDutyComponent,
    },
    {
        path: 'tools/operations/assignduty',
        lazy: AssignDutyComponent,
    },
    {
        path: 'tools/operations/sendshipment',
        lazy: SendShipmentFormComponent,
    },
    {
        path: 'tools/operations/incomingvehicles',
        lazy: IncomingTranporterVehicle,
    },
    {
        path: 'tools/operations/reportuploader',
        lazy: Reportuploader,
    },
    {
        path: 'tools/operations/devicedetach',
        lazy: DeviceDetachUploader,
    },
    {
        path: 'tools/operations/technicianrunningreport',
        lazy: TechnicianRunningReportComponent,
    },
    {
        path: 'verify/device',
        lazy: VerifyDeviceComponent,
    },
    {
        path: 'tools/operations/assigndevices',
        lazy: AssignDevicesComponent,
    },
    {
        path: 'tools/operations/assignassets',
        lazy: AssignAssetsComponent,
    },
    {
        path: 'tools/operations/manageassets',
        lazy: ManageAssetsComponent,
    },
    {
        path: 'tools/operations/rfid-mapping',
        lazy: RfidMapping,
    },
    {
        path: 'tools/operations/siminventory',
        lazy: SimInventoryComponent,
    },
    {
        path: 'tools/operations/simactivation',
        lazy: SimActivation,
    },
    {
        path: 'tools/operations/metabase/installs',
        lazy: MetabaseInstallsComponent,
    },
    {
        path: 'tools/operations/metabase/duties',
        lazy: MetabaseDutiesComponent,
    },
    {
        path: 'tools/operations/metabase/iotsupport',
        lazy: MetabaseIotSupportComponent,
    },
    {
        path: 'tools/operations/metabase/inventory',
        lazy: MetabaseInventoryComponent,
    },
    {
        path: 'tools/operations/metabase/settingslog',
        lazy: MetabaseSettingsLogComponent,
    },
    {
        path: 'tools/operations/metabase/lockconfigurationlog',
        lazy: MetabaseLockConfigurationLogComponent,
    },
    {
        path: 'tools/operations/metabase/devicewithoutvehicle',
        lazy: MetabaseDeviceWithoutVehicleComponent,
    },
    {
        path: 'tools/operations/metabase/deviceaudithistorysearch',
        lazy: MetabaseDeviceAuditHistorySearchComponent,
    },
    {
        path: 'tools/operations/metabase/devicesearchby',
        lazy: MetabaseDeviceSearchByComponent,
    },
    {
        path: 'tools/operations/metabase/erpusages',
        lazy: MetabaseERPUsagaeComponent,
    },
    ...map(metabaseDashboardsForAccount, (dashboard) => {
        return {
            key: dashboard.dashboardId,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/:id',
            lazy: MetabaseAccountDashboardComponent,
        };
    }),
    ...map(metabaseDashboardsForMasterDashboard, (dashboard) => {
        return {
            key: dashboard.dashboardId,
            path: '/dashboard/admin/tools/operations/metabase/dashboard/:id',
            lazy: MetabaseAccountDashboardComponent,
        };
    }),
    {
        path: 'tools/operations/metabase/issuedtolisting',
        lazy: MetabaseIssuedToListingComponent,
    },
    {
        path: 'tools/operations/metabase/siminventory',
        lazy: MetabaseSimInventoryComponent,
    },
    {
        path: 'tools/operations/enterprise-dashboard',
        lazy: EnterpriseDashboardCountComponent,
    },
    {
        path: 'tools/operations/hul-inventory',
        lazy: HulInventoryCountComponent,
    },
    {
        path: 'tools/operations/make-model/add',
        lazy: AddEditVehicleModelComponent,
    },
    {
        path: 'tools/control-tower',
        lazy: ControlTowerComponent,
    },
    {
        path: 'tools/control-tower-new',
        lazy: ControlTowerComponentNew,
    },
    {
        path: 'tools/control-tower/:id',
        lazy: ControlTowerDetails,
    },
    {
        path: 'tools/operations/jobsQueue-dashboard',
        lazy: JobsQueueDashboard,
    },
    {
        path: 'tools/finance/*',
        children: internalFinanceRoutes,
    },
    {
        path: 'tools/operations/remove-bulk-vehicle',
        lazy: RemoveBulkVehicle,
    },
    {
        path: 'tools/operations/envDashboard',
        lazy: ENVFilesDetailsComponent,
    },
];
export default adminRoutes;
