/**
 * Created by udbhav on 18/07/17.
 */
import { TRIP_VIEW_TYPE } from '../../constant';
import { get } from 'lodash';
import { getDefaultState } from '../../reducers/dashboard/reducer_local';

export const SET_LOCAL_DATE_PREFERENCE = 'set_local_date_preference';
export const SET_LOCAL_VEHICLE_ID_PREFERENCE = 'set_local_vehicle_id_preference';
export const SET_LOCAL_TRIP_VIEW_TYPE_PREFERENCE = 'set_local_trip_view_type_preference';
export const SET_LOCAL_TRANSPORTER_ACCOUNT = 'set_local_transporter_account';
export const SET_LOCAL_GEOCODED_LOCATIONS = 'set_local_geocoded_locations';
export const SET_LOCAL_ROUTE_ONLY_POINTS = 'set_local_route_only_points';
export const SET_LOCAL_DEFAULT_GROUP_ID = 'set_local_default_group_id';
export const SET_LOCAL_MINIMIZE_OVERSPEED_GRAPH = 'set_local_minimize_overspeed_graph';
export const SET_LOCAL_JS_VERSION = 'set_local_js_version';
export const SET_LOCAL_SEARCH_LOCATION_RADIUS = 'set_local_search_location_radius';
export const SET_LOCAL_HISTORICAL_SEARCH_TIMESTAMP = 'set_local_historical_search_timestamp';
export const SET_LOCAL_SOS_CHECK_FROM_USING_LIVE_DATA_TIMESTAMP = 'set_local_sos_check_using_live_data_timestamp';
export const SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_RADIUS = 'set_local_control_room_location_dashboard_radius';
export const SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_SELECTED_ADDRESSES =
    'set_local_control_room_location_dashboard_selected_addresses';
export const SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_MAXKM = 'set_local_control_room_running_dashboard_max_km';
export const SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_ONLYJOBS = 'set_local_control_room_running_dashboard_only_jobs';
export const SET_LOCAL_STOPPAGE_TIME_MINUTES = 'set_local_stoppage_time_minutes';
export const SET_LOCAL_RESET_LOCAL_SETTINGS = 'set_local_reset_initial_state';
export const SET_LOCAL_SHOW_OPEN_APP_DRAWER = 'set_local_show_open_app_drawer';
export const SET_LAST_REFERRAL_SHOWN_TIME = 'set_last_referral_shown_time';
export const SET_LOCAL_TYRE_ID_PREFERENCE = 'set_local_tyre_id_preference';
export const SET_SHOW_NEW_UI_FLAG = 'set_show_new_ui_flag';
export const SET_DEFAULT_NEW_JOB_FORM_FLAG = 'set_default_new_job_form_flag';
export const SET_TEMP_GRAPH_INTERVAL = 'set_temp_graph_interval';
export const SET_NEW_FEATURE_ANNOUNCEMENT_INFO = 'set_new_feature_announcement_info';
export const SET_USER_PERSONALISATION = 'set_user_personalisation';
export const SET_REATIME_CURRENT_VIEW = 'set_realtime_current_view';
export const SET_REATIME_TOUR_SHOWN = 'set_realtime_tour_shown';
export const SET_SHOW_NEW_ROUTE_FORM = 'set_show_new_route_form';
export const SET_CURRENT_LANGUAGE = 'set_current_language';
export const SET_EV_BATTERY_LEVEL_GRAPH_INTERVAL = 'set_ev_battery_level_graph_interval';
export const SET_REALTIME_NOTIFICATION_INFO = 'set_realtime_notification_info';
export const SET_VEHICLE_VIEW_ON_JOB_MAP = 'SET_VEHICLE_VIEW_ON_JOB_MAP';
export const SET_OVERRIDE_DEFAULT_VEHICLE_VIEW_ON_JOB_MAP = 'SET_OVERRIDE_DEFAULT_VEHICLE_VIEW_ON_JOB_MAP';
export const SET_CONSIGNMENT_TRACKING_LOGIN_DETAILS = 'SET_CONSIGNMENT_TRACKING_LOGIN_DETAILS';
export const SET_NAVIGATION_STYLE = 'SET_NAVIGATION_STYLE';

export const DEFAULT_LOCATION_SEARCH_RADIUS = 50;
export const DEFAULT_RUNNING_MAX_KM = 500;
export const DEFAULT_TEMP_GRAPH_INTERVAL = 5; //MINUTES
export const DEFAULT_EV_BATTERY_LEVEL_GRAPH_INTERVAL = 5;
export const SET_LOCAL_CUSTOMER_ID = 'SET_LOCAL_CUSTOMER_ID';
export const SET_NEW_FEATURE_ANNOUNCEMENT_LAST_CLOSED_TIME = 'SET_NEW_FEATURE_ANNOUNCEMENT_LAST_CLOSED_TIME';

export const SET_ANNOUNCEMENT_POPUP_INFO = 'SET_ANNOUNCEMENT_POPUP_INFO';

export const TEMP_GRAPH_TOUR = 'TEMP_GRAPH_TOUR';
export const FUEL_GRAPH_TOUR = 'FUEL_GRAPH_TOUR';
export const SET_TABLE_CONFIG = 'SET_TABLE_CONFIG';
export const SET_LRU_CONFIG = 'SET_LRU_CONFIG';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';

export const TOGGLE_BANNER = 'TOGGLE_BANNER';

export function setLocalDate(startDateMoment, endDateMoment) {
    return {
        type: SET_LOCAL_DATE_PREFERENCE,
        payload: {
            startDate: startDateMoment.valueOf(),
            endDate: endDateMoment ? endDateMoment.valueOf() : null,
        },
    };
}

export function setLocalVehicleId(vehicleId) {
    return {
        type: SET_LOCAL_VEHICLE_ID_PREFERENCE,
        payload: {
            vehicleId: vehicleId ? vehicleId : null,
        },
    };
}

export function setLocalTyreId(tyreId) {
    return {
        type: SET_LOCAL_TYRE_ID_PREFERENCE,
        payload: {
            tyreId: tyreId ? tyreId : null,
        },
    };
}

export function toggleBanner(newValue) {
    return {
        type: TOGGLE_BANNER,
        payload: newValue,
    };
}

export function setLocalTripViewType(tripViewType) {
    return {
        type: SET_LOCAL_TRIP_VIEW_TYPE_PREFERENCE,
        payload: {
            tripViewType: tripViewType ? tripViewType : TRIP_VIEW_TYPE.DEFAULT,
        },
    };
}

export function setLocalTransporterAccount(accountId) {
    return {
        type: SET_LOCAL_TRANSPORTER_ACCOUNT,
        payload: {
            localTransporterAccount: accountId ? accountId : null,
        },
    };
}

export function setLocalGeocodedLocations(geoCodedLocations) {
    return {
        type: SET_LOCAL_GEOCODED_LOCATIONS,
        payload: {
            geoCodedLocations: geoCodedLocations ? geoCodedLocations : null,
        },
    };
}

export function setLocalRouteOnlyPoints(onlyPoints) {
    return {
        type: SET_LOCAL_ROUTE_ONLY_POINTS,
        payload: {
            routeOnlyPoints: onlyPoints,
        },
    };
}

export function setLocalDefaultGroupId(defaultGroupId) {
    window.DEFAULT_GROUP_ID = defaultGroupId;
    return {
        type: SET_LOCAL_DEFAULT_GROUP_ID,
        payload: {
            defaultGroupId: defaultGroupId,
        },
    };
}

export function setLocalMinimizeOverspeedGraph(minimizeOverspeedGraph) {
    return {
        type: SET_LOCAL_MINIMIZE_OVERSPEED_GRAPH,
        payload: {
            minimizeOverspeedGraph: minimizeOverspeedGraph,
        },
    };
}

export function setJsVersion(jsVersion) {
    return {
        type: SET_LOCAL_JS_VERSION,
        payload: {
            jsVersion: jsVersion,
        },
    };
}

export function setLocalSearchLocationRadius(radiusInKm = DEFAULT_LOCATION_SEARCH_RADIUS) {
    if (!radiusInKm || parseFloat(radiusInKm) <= 0) {
        radiusInKm = DEFAULT_LOCATION_SEARCH_RADIUS;
    }
    return {
        type: SET_LOCAL_SEARCH_LOCATION_RADIUS,
        payload: {
            searchLocationRadius: parseFloat(radiusInKm),
        },
    };
}

export function setLocalHistoricalSearchTimestamp(historicalSearchTimeStamp) {
    return {
        type: SET_LOCAL_HISTORICAL_SEARCH_TIMESTAMP,
        payload: {
            historicalSearchTimeStamp: historicalSearchTimeStamp,
        },
    };
}

export function setLocalSosCheckUsingLiveDataTimestamp(timestamp) {
    return {
        type: SET_LOCAL_SOS_CHECK_FROM_USING_LIVE_DATA_TIMESTAMP,
        payload: {
            sosCheckUsingLiveDataTimestamp: timestamp,
        },
    };
}

export function setLocalControlRoomLocationDashboardRadius(radiusInKm = DEFAULT_LOCATION_SEARCH_RADIUS) {
    return {
        type: SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_RADIUS,
        payload: {
            controlRoomLocationDashboardRadius: radiusInKm,
        },
    };
}

export function setLocalcontrolRoomLocationDashboardSelectedAddresses(selectedAddresses = []) {
    return {
        type: SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_SELECTED_ADDRESSES,
        payload: {
            controlRoomLocationDashboardSelectedAddresses: selectedAddresses,
        },
    };
}

export function setLocalControlRoomRunningDashboardMaxKm(newKm = DEFAULT_RUNNING_MAX_KM) {
    return {
        type: SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_MAXKM,
        payload: {
            controlRoomRunningDashboardMaxKm: newKm,
        },
    };
}

export function setLocalControlRoomRunningDashboardOnlyJobs(controlRoomRunningDashboardOnlyJobs) {
    return {
        type: SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_ONLYJOBS,
        payload: {
            controlRoomRunningDashboardOnlyJobs: controlRoomRunningDashboardOnlyJobs,
        },
    };
}

export function setLocalStoppageTimeMinutes(stoppageTimeMinutes) {
    return {
        type: SET_LOCAL_STOPPAGE_TIME_MINUTES,
        payload: {
            stoppageTimeMinutes: stoppageTimeMinutes,
        },
    };
}

export function resetLocalSettings() {
    return {
        type: SET_LOCAL_RESET_LOCAL_SETTINGS,
        payload: {
            ...getDefaultState(),
        },
    };
}

export function setLocalShowOpenAppDrawer(openAppDrawer) {
    return {
        type: SET_LOCAL_SHOW_OPEN_APP_DRAWER,
        payload: {
            showOpenAppDrawer: openAppDrawer,
        },
    };
}

export function setLastReferralShownTime(data) {
    return {
        type: SET_LAST_REFERRAL_SHOWN_TIME,
        payload: {
            data,
        },
    };
}

export function setNewUiFlag(show_new_ui) {
    return {
        type: SET_SHOW_NEW_UI_FLAG,
        payload: show_new_ui,
    };
}

export function setLocalDefaultNewJobForm(set_default_new_job_form) {
    return {
        type: SET_DEFAULT_NEW_JOB_FORM_FLAG,
        payload: set_default_new_job_form,
    };
}

export function setTempGraphInterval(newValue) {
    return {
        type: SET_TEMP_GRAPH_INTERVAL,
        payload: newValue,
    };
}

export function setEVBatteryLevelGraphInterval(newValue) {
    return {
        type: SET_EV_BATTERY_LEVEL_GRAPH_INTERVAL,
        payload: newValue,
    };
}

export function setNewFeatureAnnouncementInfo(newValue) {
    return {
        type: SET_NEW_FEATURE_ANNOUNCEMENT_INFO,
        payload: newValue,
    };
}

//setAnnouncementPopupInfo

export function setAnnouncementPopupInfo(newValue) {
    return {
        type: SET_ANNOUNCEMENT_POPUP_INFO,
        payload: newValue,
    };
}

export function setRealtimeNotificationInfo(newValue) {
    return {
        type: SET_REALTIME_NOTIFICATION_INFO,
        payload: newValue,
    };
}

export function setUserPersonalisationConfig(newValue) {
    return {
        type: SET_USER_PERSONALISATION,
        payload: newValue,
    };
}

export function setRealtimeCurrentView(newValue) {
    return {
        type: SET_REATIME_CURRENT_VIEW,
        payload: newValue,
    };
}

export function setLocalRealtimeTourShown(newValue) {
    return {
        type: SET_REATIME_TOUR_SHOWN,
        payload: newValue,
    };
}

export function setShowNewRouteForm(newValue) {
    return {
        type: SET_SHOW_NEW_ROUTE_FORM,
        payload: newValue,
    };
}

export function setCurrentLanguage(lan) {
    return {
        type: SET_CURRENT_LANGUAGE,
        payload: lan,
    };
}

export function setIsVehicleViewEnableOnJobMap(val) {
    return {
        type: SET_VEHICLE_VIEW_ON_JOB_MAP,
        payload: val,
    };
}

export function setIsOverrideDefaultVehicleViewEnableOnJobMap(val) {
    return {
        type: SET_OVERRIDE_DEFAULT_VEHICLE_VIEW_ON_JOB_MAP,
        payload: val,
    };
}

export function setConsignmentTrackingLoginDetails(val) {
    return {
        type: SET_CONSIGNMENT_TRACKING_LOGIN_DETAILS,
        payload: val,
    };
}

export function setLocalCustomerId(customerId) {
    return {
        type: SET_LOCAL_CUSTOMER_ID,
        payload: {
            localCustomerId: customerId ? customerId : null,
        },
    };
}

export function setNewFeatureAnnouncementLastClosedTime(newValue) {
    return {
        type: SET_NEW_FEATURE_ANNOUNCEMENT_LAST_CLOSED_TIME,
        payload: newValue,
    };
}

export function setTempGraphTourInfo(newValue) {
    return {
        type: TEMP_GRAPH_TOUR,
        payload: newValue,
    };
}

export function setFuelGraphTourInfo(newValue) {
    return {
        type: FUEL_GRAPH_TOUR,
        payload: newValue,
    };
}

export function setTableConfig(newValue) {
    return {
        type: SET_TABLE_CONFIG,
        payload: newValue,
    };
}
export function setNavigationStyle(newValue) {
    return {
        type: SET_NAVIGATION_STYLE,
        payload: newValue,
    };
}

export function setLRUConfig(newValue) {
    return {
        type: SET_LRU_CONFIG,
        payload: newValue,
    };
}

export function setUserGroups(newValue) {
    return {
        type: SET_USER_GROUPS,
        payload: newValue,
    };
}
