export const getFieldCustomMetaData = (nature) => {
    switch (nature) {
        /*  Inward case start */
        case 'TYRE_AGAINST_CLAIM_MRN':
            return {
                'TYRE_NUMBER': {
                    label: 'Old Tyre Number',
                },
                'NEW_TYRE_NUMBER': {
                    label: 'New Tyre Number',
                },
                'CURRENT_AMOUNT': {
                    label: 'Current Price',
                },
                'EXTRA_AMOUNT': {
                    label: 'Claim Amount',
                },
            };
        case 'REMOULD_TYRE_MRN':
            return {
                'CURRENT_AMOUNT': {
                    label: 'Current Price',
                },
                'EXTRA_AMOUNT': {
                    label: 'Remoulding Price',
                },
            };
        case 'REPAIR_TYRE_MRN':
            return {
                'CURRENT_AMOUNT': {
                    label: 'Current Price',
                },
                'EXTRA_AMOUNT': {
                    label: 'Repair Price',
                },
            };
        case 'CHASSIS_MRN':
            return {
                'PURCHASE_AMOUNT': {
                    label: 'Estimated Price',
                },
            };
        case 'TYRE_STOCK_IN':
            return {
                'PURCHASE_AMOUNT': {
                    label: 'Current Price',
                },
            };
        /* Inward case end */
        /*  Outward case start */
        case 'TYRE_THEFT':
            return {
                'CURRENT_AMOUNT': {
                    label: 'Final Price',
                },
            };
        case 'TYRE_SCRAPPING':
            return {
                'CURRENT_AMOUNT': {
                    label: 'Scrap Price',
                },
            };
        /* Outward case end */
        default:
            return null;
    }
};
//TODO: Remove duplicate config
export function getTyreFieldsByNature(nature) {
    const fieldsMapping = {
        // INWARD EVENTS
        'TYRE_MRN': {
            globalFields: ['MRN_DATE', 'STORE', 'PURCHASE_DATE', 'PO_NUMBER', 'PO_DATE', 'BILL_NUMBER', 'VENDOR'],
            tyreFields: [
                'TYRE_NUMBER',
                'TYPE',
                'PRODUCTION_DATE',
                'MODEL',
                'BRAND',
                'PURCHASE_AMOUNT',
                'KM_ISSUED',
                'OPENING_KM',
                'PLY_RATING',
                'TREAD_DEPTH',
                'TYRE_SIZE',
                'RF_TAG',
                'REMARKS',
            ],
        },
        'CHASSIS_MRN': {
            globalFields: [
                'MRN_DATE',
                'STORE',
                'PURCHASE_DATE',
                'PO_NUMBER',
                'PO_DATE',
                'CHASIS_NUMBER',
                'BILL_NUMBER',
                'VENDOR',
            ],
            tyreFields: [
                'TYRE_NUMBER',
                'TYPE',
                'PRODUCTION_DATE',
                'MODEL',
                'BRAND',
                'PURCHASE_AMOUNT',
                'KM_ISSUED',
                'OPENING_KM',
                'PLY_RATING',
                'TREAD_DEPTH',
                'TYRE_SIZE',
                'RF_TAG',
                'REMARKS',
            ],
        },
        'REMOULD_TYRE_MRN': {
            globalFields: ['MRN_DATE', 'STORE', 'PURCHASE_DATE', 'PO_NUMBER', 'PO_DATE', 'BILL_NUMBER', 'VENDOR'],
            tyreFields: [
                'TYRE_NUMBER_SELECT',
                'TYPE',
                'PRODUCTION_DATE',
                'MODEL',
                'BRAND',
                'CURRENT_AMOUNT',
                'EXTRA_AMOUNT',
                'KM_ISSUED',
                'OPENING_KM',
                'PLY_RATING',
                'TREAD_DEPTH',
                'TYRE_SIZE',
                'REMARKS',
            ],
        },
        'REPAIR_TYRE_MRN': {
            globalFields: ['MRN_DATE', 'STORE', 'PURCHASE_DATE', 'PO_NUMBER', 'PO_DATE', 'BILL_NUMBER', 'VENDOR'],
            tyreFields: [
                'TYRE_NUMBER_SELECT',
                'TYPE',
                'PRODUCTION_DATE',
                'MODEL',
                'BRAND',
                'CURRENT_AMOUNT',
                'EXTRA_AMOUNT',
                'KM_ISSUED',
                'OPENING_KM',
                'PLY_RATING',
                'TREAD_DEPTH',
                'TYRE_SIZE',
                'REMARKS',
            ],
        },
        'TYRE_AGAINST_CLAIM_MRN': {
            globalFields: ['MRN_DATE', 'STORE', 'PURCHASE_DATE', 'PO_NUMBER', 'PO_DATE', 'BILL_NUMBER', 'VENDOR'],
            tyreFields: [
                'NEW_TYRE_NUMBER',
                'TYRE_NUMBER_SELECT',
                'TYPE',
                'PRODUCTION_DATE',
                'MODEL',
                'BRAND',
                'CURRENT_AMOUNT',
                'EXTRA_AMOUNT',
                'KM_ISSUED',
                'OPENING_KM',
                'PLY_RATING',
                'TREAD_DEPTH',
                'TYRE_SIZE',
                'REMARKS',
            ],
        },
        'TYRE_STOCK_IN': {
            globalFields: ['MRN_DATE', 'INWARD_STORE', 'OUTWARD_STORE', 'STOCK_OUT_STATIONARY_NUMBER'],
            tyreFields: [
                'TYRE_NUMBER_SELECT',
                'TYPE',
                'PRODUCTION_DATE',
                'MODEL',
                'BRAND',
                'CURRENT_AMOUNT',
                'KM_ISSUED',
                'OPENING_KM',
                'PLY_RATING',
                'TREAD_DEPTH',
                'TYRE_SIZE',
                'REMARKS',
            ],
        },
        // OUTWARD EVENTS
        'SENT_TO_REMOULD': {
            globalFields: ['MRN_DATE', 'STORE', 'VENDOR'],
            tyreFields: ['TYRE_NUMBER_SELECT', 'BRAND', 'MODEL', 'CURRENT_AMOUNT'],
        },
        'SENT_TO_REPAIR': {
            globalFields: ['MRN_DATE', 'STORE', 'VENDOR'],
            tyreFields: ['TYRE_NUMBER_SELECT', 'BRAND', 'MODEL', 'CURRENT_AMOUNT'],
        },
        'SENT_TO_CLAIM': {
            globalFields: ['MRN_DATE', 'STORE', 'VENDOR'],
            tyreFields: ['TYRE_NUMBER_SELECT', 'BRAND', 'MODEL', 'CURRENT_AMOUNT', 'REMARKS'],
        },
        'TYRE_THEFT': {
            globalFields: ['MRN_DATE', 'STORE', 'VENDOR', 'DRIVER', 'COMMENT'],
            tyreFields: ['TYRE_NUMBER_SELECT', 'BRAND', 'MODEL', 'CURRENT_AMOUNT', 'RECOVERY_AMOUNT'],
        },
        'TYRE_SCRAPPING': {
            globalFields: ['MRN_DATE', 'STORE', 'BUYER', 'COMMENT'],
            tyreFields: ['TYRE_NUMBER_SELECT', 'BRAND', 'MODEL', 'CURRENT_AMOUNT', 'RECOVERY_AMOUNT', 'REMARKS'],
        },
        'TYRE_STOCK_OUT': {
            globalFields: ['MRN_DATE', 'OUTWARD_STORE', 'OUTWARD_DATE', 'INWARD_STORE', 'INWARD_DATE', 'COMMENT'],
            tyreFields: ['TYRE_NUMBER_SELECT', 'BRAND', 'MODEL', 'CURRENT_AMOUNT'],
        },
    };
    return fieldsMapping[nature];
}
