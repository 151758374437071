import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useState, useEffect, useReducer, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { concat, forEach, get, map, omit, set, sumBy, toSafeInteger, uniqBy, unset, } from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import JobAdvanceFormComponent from './JobAdvanceFormComponent';
import { isViewCustomerAllowedForUser, isViewLedgersAllowedForUser, isViewRoutesAllowedForUser, } from 'components/dashboard/roles/permission_utils';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';
import { DATE_FORMAT_TIMESTAMP, getEndDate, getFormattedTimeStringForAPI, getMomentTime } from 'utils/date_utils';
import { queryString } from 'utils/string_utils';
import { filtersReducer } from 'utils/form_utils';
import JobAdvanceFilterComponent from '../jobs/JobAdvanceFilterComponent';
import { FxButton, FxCard, FxFlex, FxTypography } from 'sharedV2/index';
import { fetchRoutesMiniList } from 'actions/dashboard/route_dispatch/routes_action';
import { fetchJobListNewV2 } from 'actions/dashboard/route_dispatch/jobs_action';
import JobAdvanceJobListingComponent from '../jobs/JobAdvanceJobListingComponent';
import { fetchCustomers } from 'actions/dashboard/finances/customer_action';
import { useFxAlert, useFxMessage } from 'components/dashboard/customHooks';
import { createJobAdvance, fetchJobAdvance, updateJobAdvance } from 'actions/dashboard/payment_forms/jobAdvnaceActions';
const initialFiltersState = {
    from: getMomentTime().subtract(7, 'days'),
    to: getEndDate(),
    name: '',
    routeId: null,
    page: 1,
    customerId: null,
};
const AddEditJobAdvanceComponent = (props) => {
    const { accesstoken, login } = props;
    const { scope } = login;
    const { t } = useTranslation();
    const params = useParams();
    const editId = params.id;
    const [initialValues, setInitialValues] = useState({
        paymentMappings: [],
        bookType: 'JOB_ADVANCE',
        ledgerDetails: {
            debitLedger: {
                ledgerId: null,
                amount: 0,
            },
            indirectIncome: {
                ledgerId: null,
                amount: 0,
            },
            tdsLedger: {
                ledgerId: null,
                amount: 0,
            },
        },
    });
    const [jobList, setJobList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [jobListLoading, setJobListLoading] = useState(false);
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [selectedJobsData, setSelectedJobsData] = useState([]);
    const initialValueSetRef = useRef(false);
    const jobListRef = useRef(false);
    const [enableTransactionDetails, setEnableTransactionDetails] = useState(!editId);
    const showAlert = useFxAlert();
    const navigate = useNavigate();
    const showMessage = useFxMessage();
    const [filters, dispatchFilters] = useReducer(filtersReducer, {
        ...initialFiltersState,
    }, (state) => {
        const parsed = queryString.parse(location.hash);
        const parsedFilters = {};
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        if (parsed.currentPage) {
            parsedFilters.currentPage = toSafeInteger(parsed.currentPage);
        }
        return { ...state, ...parsedFilters };
    });
    const validationSchema = React.useMemo(() => {
        return Yup.object().shape({
            branch: Yup.string().required('Required').nullable(),
            paymentDate: Yup.string().required('Required').nullable(),
            creditAccountId: Yup.string().required('Required').nullable(),
            ledgerDetails: Yup.object().shape({
                debitLedger: Yup.object().shape({
                    ledgerId: Yup.number().when('amount', {
                        is: (amount) => amount > 0,
                        then: Yup.number().required('Required').nullable(),
                        otherwise: Yup.number().nullable(),
                    }),
                    amount: Yup.number().required('Required').min(1, 'Amount should be greater than 0'),
                }),
                indirectIncome: Yup.object().shape({
                    ledgerId: Yup.number().when('amount', {
                        is: (amount) => amount > 0,
                        then: Yup.number().required('Required').nullable(),
                        otherwise: Yup.number().nullable(),
                    }),
                }),
                tdsLedger: Yup.object().shape({
                    ledgerId: Yup.number().when('amount', {
                        is: (amount) => amount > 0,
                        then: Yup.number().required('Required').nullable(),
                        otherwise: Yup.number().nullable(),
                    }),
                }),
            }),
        });
    }, []);
    const permissions = {
        viewLedger: isViewLedgersAllowedForUser(scope),
        viewRoutes: isViewRoutesAllowedForUser(scope),
        viewCustomers: isViewCustomerAllowedForUser(scope),
    };
    const { from, to, routeId, name, page, customerId } = filters;
    useEffect(() => {
        if (permissions.viewLedger)
            props.fetchLedgers(accesstoken);
        if (permissions.viewRoutes)
            props.fetchRoutesMiniList(accesstoken);
        if (permissions.viewCustomers)
            props.fetchCustomers(accesstoken);
    }, []);
    useEffect(() => {
        if (editId) {
            props.fetchJobAdvance(editId).then((resp) => {
                let data = get(resp, 'payload.data', {});
                set(data, 'bookType', 'JOB_ADVANCE');
                set(data, 'branch', get(data, 'branchId', null));
                set(data, 'stationaryNumber', get(data, 'advanceNumber', ''));
                data = omit(data, 'advanceNumber');
                data = omit(data, 'branchId');
                initialValueSetRef.current = true;
                setInitialValues({ ...data });
            });
        }
    }, [editId]);
    useEffect(() => {
        if (editId && jobListRef.current && initialValueSetRef.current) {
            let mergedJobs = [];
            const paymentMappings = get(initialValues, 'paymentMappings', []);
            forEach(paymentMappings, (paymentMapping) => {
                mergedJobs.push({
                    id: get(paymentMapping, 'entityId'),
                    name: get(paymentMapping, 'jobName'),
                    startDate: get(paymentMapping, 'jobStartDate'),
                    branchId: get(paymentMapping, 'jobBranchId'),
                    route: {
                        name: get(paymentMapping, 'routeName'),
                    },
                    vehicle: {
                        licensePlate: get(paymentMapping, 'licensePlate'),
                    },
                    jobAdvance: {
                        agreedAdvanceAmount: get(paymentMapping, 'jobAdvanceDetail.agreedAdvanceAmount'),
                    },
                    receivedAdvanceAmount: get(paymentMapping, 'jobAdvanceDetail.receivedAdvanceAmount', 0) -
                        get(paymentMapping, 'paymentAmount', 0),
                    paymentMappings: {
                        paymentAmount: get(paymentMapping, 'paymentAmount', 0),
                        remark: get(paymentMapping, 'remark', ''),
                    },
                    vehicleId: get(paymentMapping, 'vehicleId'),
                    jobAnalytics: [
                        {
                            freightFare: get(paymentMapping, 'jobFreight', 0),
                        },
                    ],
                });
            });
            jobListRef.current = false;
            initialValueSetRef.current = false;
            setSelectedJobs(map(mergedJobs, 'id'));
            setSelectedJobsData(mergedJobs);
            mergedJobs = uniqBy(concat(mergedJobs, jobList), 'id');
            setJobList(mergedJobs);
        }
    }, [editId, initialValues, jobList]);
    const fetchJobData = () => {
        const params = {
            from: from.valueOf(),
            to: to.valueOf(),
            routeId,
            jobName: name,
            size: 10,
            page,
            customerId,
            jobAdvancePaid: false,
        };
        setJobListLoading(true);
        props.fetchJobListNewV2(accesstoken, params).then((resp) => {
            setJobListLoading(false);
            setTotalElements(get(resp, 'payload.data.totalElements', 0));
            jobListRef.current = true;
            const oldJobData = JSON.parse(JSON.stringify(selectedJobsData));
            const newJobs = get(resp, 'payload.data.content', []);
            const mergedJobs = uniqBy(concat(oldJobData, newJobs), 'id');
            setJobList(mergedJobs);
        });
    };
    useEffect(() => {
        fetchJobData();
    }, [from.valueOf(), to.valueOf(), routeId, name, page, customerId]);
    const handleFilterChange = (key, value) => {
        const newFilersState = {};
        if (key === 'timeFilter') {
            newFilersState['from'] = get(value, '[0]', null);
            newFilersState['to'] = get(value, '[1]', null);
        }
        else {
            newFilersState[key] = value;
        }
        dispatchFilters({ payload: newFilersState });
    };
    const goBack = () => {
        navigate('/dashboard/advance/all#paymentNature=JOB_ADVANCE');
    };
    const onSubmit = (formValues) => {
        const data = JSON.parse(JSON.stringify(formValues));
        const paymentDate = getFormattedTimeStringForAPI(data.paymentDate, DATE_FORMAT_TIMESTAMP);
        set(data, 'paymentDate', paymentDate);
        set(data, 'branchId', data.branch);
        set(data, 'advanceNumber', get(data, 'stationaryNumber', ''));
        const paymentMappings = map(selectedJobsData, (job) => {
            const paymentMappings = get(job, 'paymentMappings', {});
            return {
                entityId: get(job, 'id'),
                paymentAmount: get(paymentMappings, 'paymentAmount', 0),
                remark: get(paymentMappings, 'remark', ''),
                driverId: get(job, 'driverId'),
                vehicleId: get(job, 'vehicleId'),
            };
        });
        set(data, 'paymentMappings', paymentMappings);
        const totalPaymentMappingAmount = sumBy(paymentMappings, (row) => +get(row, 'paymentAmount', 0));
        const totalLedgerAmount = +get(data, 'ledgerDetails.debitLedger.amount', 0) +
            +get(data, 'ledgerDetails.indirectIncome.amount', 0) +
            +get(data, 'ledgerDetails.tdsLedger.amount', 0);
        if (totalPaymentMappingAmount !== totalLedgerAmount) {
            showAlert('Total Payment Mapping Amount should be equal to Total Ledger Amount');
            return;
        }
        const recoveryDate = getFormattedTimeStringForAPI(get(data, 'transactionDetails.recoveryDate'), DATE_FORMAT_TIMESTAMP);
        set(data, 'transactionDetails.recoveryDate', recoveryDate);
        // remove ledgers from ledgerdetails if amount is zero
        if (get(data, 'ledgerDetails.debitLedger.amount', 0) === 0) {
            unset(data, 'ledgerDetails.debitLedger');
        }
        if (get(data, 'ledgerDetails.indirectIncome.amount', 0) === 0) {
            unset(data, 'ledgerDetails.indirectIncome');
        }
        if (get(data, 'ledgerDetails.tdsLedger.amount', 0) === 0) {
            unset(data, 'ledgerDetails.tdsLedger');
        }
        if (editId) {
            props.updateJobAdvance(data).then((resp) => {
                if (resp.error) {
                    showAlert('Failed to update job advance');
                }
                else {
                    showMessage('Job advance updated successfully');
                    goBack();
                }
            });
        }
        else {
            props.createJobAdvance(data).then((resp) => {
                if (resp.error) {
                    showAlert('Failed to create job advance');
                }
                else {
                    showMessage('Job advance created successfully');
                    goBack();
                }
            });
        }
    };
    return (_jsxs("div", { className: "animated fadeIn", children: [_jsx(Helmet, { title: `${editId ? 'Edit' : 'Add'} Job Advance - fleetx.io` }), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: validationSchema, enableReinitialize: true, children: (formikProps) => {
                    const { values, errors } = formikProps;
                    return (_jsx(Form, { children: _jsxs(FxCard, { className: "mt-4", children: [_jsxs("h4", { children: [editId ? 'Edit' : 'Add', " Job Advance"] }), _jsx("hr", {}), _jsx(JobAdvanceFormComponent, { formikProps: formikProps, editId: editId, enableTransactionDetails: enableTransactionDetails, setEnableTransactionDetails: setEnableTransactionDetails, handleFilterChange: handleFilterChange }), _jsx(FxTypography.Title, { className: "mt-4", level: 4, children: "Filters" }), _jsx("hr", {}), _jsx(JobAdvanceFilterComponent, { filters: filters, handleFilterChange: handleFilterChange }), _jsx(FxTypography.Title, { className: "mt-4", level: 4, children: "Job Details" }), _jsx("hr", {}), _jsx(JobAdvanceJobListingComponent, { handleFilterChange: handleFilterChange, jobList: jobList, jobListLoading: jobListLoading, totalElements: totalElements, filters: filters, formikProps: formikProps, selectedJobs: selectedJobs, setSelectedJobs: setSelectedJobs, setJobList: setJobList, setSelectedJobsData: setSelectedJobsData, selectedJobsData: selectedJobsData }), _jsx(FxFlex, { justify: "end", className: "mt-4", children: _jsx(FxButton, { type: "primary", htmlType: "submit", children: "Submit" }) })] }) }));
                } })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchLedgers,
        fetchRoutesMiniList,
        fetchJobListNewV2,
        fetchCustomers,
        createJobAdvance,
        fetchJobAdvance,
        updateJobAdvance,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditJobAdvanceComponent);
