import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../constant';
import { getMomentTime } from 'utils/date_utils';
import { isArray, join } from 'lodash';
import { getSortByStringForAPI } from 'utils/antdLibraryMappingUtils';
export const FETCH_TRANSPORTER_ANALYTICS_LIST = 'FETCH_TRANSPORTER_ANALYTICS_LIST';
export const FETCH_TRANSPORTER_ANALYTICS_SUMMARY = 'FETCH_TRANSPORTER_ANALYTICS_SUMMARY';
export const FETCH_TRANSPORTER_ANALYTICS_JOB_LISTING = 'FETCH_TRANSPORTER_ANALYTICS_JOB_LISTING';
export const FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_SUMMARY = 'FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_SUMMARY';
export const FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_MAP_VIEW = 'FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_MAP_VIEW';
export const TRANSPORTER_ANALYTICS_DEVICE_SUMMARY = 'TRANSPORTER_ANALYTICS_DEVICE_SUMMARY';
export const FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME = 'FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME';
export const FETCH_DISTRICT_NAMES_BY_STATE = 'FETCH_DISTRICT_NAMES_BY_STATE';
export const DOWNLOAD_TRANSPORTER_ANALYTICS_REPORT = 'DOWNLOAD_TRANSPORTER_ANALYTICS_REPORT';
export const DOWNLOAD_SHIP_TO_PARTY_ANALYTICS_REPORT = 'DOWNLOAD_SHIP_TO_PARTY_ANALYTICS_REPORT';
export function fetchTransporterAnalyticsList(accesstoken, params = {}) {
    const { startDate, endDate, vehicleId, routeId, shipToParty, soldToParty, industry, commodity, jobGroupId, transporterIds, tagIds, size, page = 1, transporterId, segment, businessType, movementType, state, district, city, groupId, byStartDate, } = params || {};
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_TRANSPORTER_ANALYTICS_LIST';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromDate: getMomentTime(startDate).valueOf(),
            toDate: getMomentTime(endDate).valueOf(),
            vehicleId,
            routeId,
            shipToParty,
            soldToParty,
            industry,
            commodity,
            jobGroupId,
            transporterIds: isArray(transporterIds) ? join(transporterIds, ',') : transporterIds || transporterId,
            tagIds,
            segment,
            businessType,
            movementType,
            state,
            district,
            city,
            size,
            page: page - 1,
            groupId,
            byStartDate,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/listing`, config);
    return {
        type: FETCH_TRANSPORTER_ANALYTICS_LIST,
        promise: request,
    };
}
export function fetchTransporterAnalyticsSummary(accesstoken, params = {}) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_TRANSPORTER_ANALYTICS_SUMMARY';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { startDate, endDate, vehicleId, routeId, shipToParty, soldToParty, industry, commodity, jobGroupId, transporterIds, tagIds, } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromDate: getMomentTime(startDate).valueOf(),
            toDate: getMomentTime(endDate).valueOf(),
            vehicleId,
            routeId,
            shipToParty,
            soldToParty,
            industry,
            commodity,
            jobGroupId,
            transporterIds: isArray(transporterIds) ? join(transporterIds, ',') : transporterIds,
            tagIds,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/summary`, config);
    return {
        type: FETCH_TRANSPORTER_ANALYTICS_SUMMARY,
        promise: request,
    };
}
export function fetchTransporterAnalyticsJobListing(accesstoken, params = {}) {
    const { size = 10, page = 1, startDate, endDate, transporterId, shipToParty, segment, businessType, movementType, state, district, city, commodity, jobGroupId, } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            size,
            page: page - 1,
            fromDate: getMomentTime(startDate).valueOf(),
            toDate: getMomentTime(endDate).valueOf(),
            transporterId,
            shipToParty,
            segment,
            businessType,
            movementType,
            state,
            district,
            city,
            commodity,
            jobGroupId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/job-listing`, config);
    return {
        type: FETCH_TRANSPORTER_ANALYTICS_JOB_LISTING,
        promise: request,
    };
}
export function fetchTransporterAnalyticsShipToPartyJobSummary(accesstoken, params = {}) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_TRANSPORTER_ANALYTICS_SUMMARY';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { startDate, endDate, transporterId, size = 10, page = 1, city, shipToParty, segment, businessType, movementType, state, district, commodity, sortName, sortOrder, jobGroupId, groupId, byStartDate, } = params || {};
    const sortBy = getSortByStringForAPI(sortName, sortOrder);
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromDate: getMomentTime(startDate).valueOf(),
            toDate: getMomentTime(endDate).valueOf(),
            transporterId,
            size,
            page: page - 1,
            city,
            shipToParty,
            segment,
            businessType,
            movementType,
            state,
            district,
            commodity,
            sortBy,
            jobGroupId,
            groupId,
            byStartDate,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/ship-to-party-summary`, config);
    return {
        type: FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_SUMMARY,
        promise: request,
    };
}
export function fetchLatLngBoundriesByDistrict(accesstoken, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/district-coordinates`, config);
    return {
        type: FETCH_LAT_LNG_DISTRICT_BOUNDRIES_BY_NAME,
        promise: request,
    };
}
export function fetchDistrictsNameByState(accesstoken, params = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/district`, config);
    return {
        type: FETCH_DISTRICT_NAMES_BY_STATE,
        promise: request,
    };
}
export function fetchTransporterAnalyticsShipToPartyJobMapView(accesstoken, params = {}) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_MAP_VIEW';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const { startDate, endDate, transporterId, page = 1, size = 10000, city, shipToParty, segment, businessType, movementType, state, district, commodity, jobGroupId, groupId, byStartDate, } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromDate: getMomentTime(startDate).valueOf(),
            toDate: getMomentTime(endDate).valueOf(),
            transporterId,
            page: page - 1,
            size,
            city,
            shipToParty,
            segment,
            businessType,
            movementType,
            state,
            district,
            commodity,
            jobGroupId,
            groupId,
            byStartDate,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/map-view-jobs`, config);
    return {
        type: FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_MAP_VIEW,
        promise: request,
    };
}
export function fetchTransporterAnalyticsDeviceSummary(accesstoken, params = {}) {
    const { startDate, endDate, transporterId, city, shipToParty, segment, businessType, movementType, state, district, commodity, jobGroupId, groupId, } = params || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            fromDate: getMomentTime(startDate).valueOf(),
            toDate: getMomentTime(endDate).valueOf(),
            transporterId,
            city,
            shipToParty,
            segment,
            businessType,
            movementType,
            state,
            district,
            commodity,
            jobGroupId,
            groupId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporter-analytics/device-summary`, config);
    return {
        type: FETCH_TRANSPORTER_ANALYTICS_SHIP_TO_PARTY_JOB_MAP_VIEW,
        promise: request,
    };
}
export function fetchTransporterReport(payload) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerTransporterReport`, payload, config);
    return {
        type: DOWNLOAD_TRANSPORTER_ANALYTICS_REPORT,
        promise: request,
    };
}
export function fetchShipToPartyReport(payload) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerShipToPartyReport`, payload, config);
    return {
        type: DOWNLOAD_SHIP_TO_PARTY_ANALYTICS_REPORT,
        promise: request,
    };
}
