import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, map, uniqBy, remove, pick, values, forEach, merge, toSafeInteger, groupBy, keys, filter, reduce, split, flatten, forOwn, isEmpty, set, includes, uniq, round, find, toNumber, } from 'lodash';
import { Input } from 'reactstrap';
import { FxReactSelect as Select } from '../../../../shared';
import '../../vendor_management/FreightSettlement/index.scss';
const GenericERPTableListingComponent = (props) => {
    const { data = [], login, currentPage, sizePerPage, setEntityAdvances, formikProps, isEdit, routeList, branches, groupedVehicles, listType, ListComponent, defaultDriverValues = {}, epodDetails, jobDetails, onOpenEpodModal, setCurrentCNViewEpod, selectedEntities, setSelectedEntities, onAccountListing, preSelectedRows, setPreSelectedRows, } = props;
    const refs = useRef({});
    const isDriverAdvance = get(formikProps, 'values.paymentNature') === 'DRIVER_ADVANCE';
    const isPR = get(formikProps, 'values.paymentNature') === 'PAYMENT_RECEIPT';
    const isBalancePayement = get(formikProps, 'values.paymentNature') === 'HIRE_SLIP';
    const { onAccountFormId } = formikProps.values;
    const [entityList, setEntityList] = useState([]);
    const [entityIdToAdvanceAmountMap, setEntityIdToAdvanceAmountMap] = useState({});
    const [driverValue, setDriverValue] = useState({});
    const [totalRowAmount, setTotalRowAmount] = useState({});
    const [freightBillPaymentMapping, setFreightBillPaymentMapping] = useState({});
    // Merge selected and fetched entities (uniq only)
    useEffect(() => {
        setEntityList([...data]);
        if (isEdit) {
            // Pre select entities in edit view
            if (listType == 'FREIGHT') {
                const preselectedEntities = map(data, (el) => {
                    return map(get(el, 'consignmentBillDetailsList'), (bill) => {
                        return `${get(bill, 'consignmentEntityDetailsDto.consignmentId')}-${get(bill, 'id')}`;
                    });
                });
                setPreSelectedRows(flatten(preselectedEntities));
            }
            else {
                setPreSelectedRows(map(keys(groupBy(data, 'id')), (elem, index) => toSafeInteger(elem)));
            }
            setSelectedEntities(data);
            let entityIdToAdvanceAmountMapData = entityIdToAdvanceAmountMap;
            let driverValuesToSet = driverValue;
            forEach(data, (row) => {
                // onRowSelect(row, true);
                if (listType == 'FREIGHT') {
                    const totalRowAmountTemp = {};
                    forEach(get(row, 'consignmentBillDetailsList'), (bill) => {
                        const entityId = get(bill, 'id');
                        const mapping = {
                            [`${bill.consignmentEntityDetailsDto.consignmentId}-${bill.id}`]: {
                                paymentAmount: +get(bill, 'paymentAmount', 0),
                                onAccountPaymentAmount: +get(bill, 'onAccountPaymentAmount', 0),
                                totalMappingAmount: +get(bill, 'totalMappingAmount', 0),
                                remark: get(bill, 'remark'),
                                entityId: +entityId,
                            },
                        };
                        totalRowAmountTemp[`${bill.consignmentEntityDetailsDto.consignmentId}-${bill.id}`] =
                            mapping[`${bill.consignmentEntityDetailsDto.consignmentId}-${bill.id}`];
                        entityIdToAdvanceAmountMapData = merge(entityIdToAdvanceAmountMapData, mapping);
                    });
                    setTotalRowAmount(totalRowAmountTemp);
                }
                else if (listType == 'JOB_FREIGHT') {
                    const entityId = get(row, 'id');
                    const paymentAmount = get(row, 'paymentAmount', 0);
                    const mapping = {
                        [entityId]: {
                            paymentAmount: +paymentAmount,
                            totalMappingAmount: +paymentAmount,
                            remark: get(row, 'remarks'),
                            entityId: +entityId,
                        },
                    };
                    entityIdToAdvanceAmountMapData = merge(entityIdToAdvanceAmountMapData, mapping);
                }
                else {
                    const entityId = get(row, 'id');
                    const totalMappingAmount = +get(row, 'advanceDetails.advanceAmountEntry', 0) +
                        +get(row, 'advanceDetails.onAccountPaymentAmount', 0);
                    const mapping = {
                        [entityId]: {
                            paymentAmount: +get(row, 'advanceDetails.advanceAmountEntry', 0),
                            onAccountPaymentAmount: +get(row, 'advanceDetails.onAccountPaymentAmount', 0),
                            totalMappingAmount: +get(row, 'advanceDetails.totalMappingAmount', totalMappingAmount),
                            remark: get(row, 'remarks'),
                            entityId: +entityId,
                        },
                    };
                    if (isDriverAdvance) {
                        set(mapping, `${entityId}.driverId`, get(row, 'driverId'));
                        const driverValueMapping = {
                            [entityId]: get(row, 'driverId'),
                        };
                        driverValuesToSet = merge(driverValuesToSet, driverValueMapping);
                        setDriverValue(driverValuesToSet);
                    }
                    entityIdToAdvanceAmountMapData = merge(entityIdToAdvanceAmountMapData, mapping);
                }
            });
            setEntityIdToAdvanceAmountMap(entityIdToAdvanceAmountMapData);
        }
        else {
            if (!isEmpty(defaultDriverValues) && isDriverAdvance) {
                setDriverValue(defaultDriverValues);
                forOwn(defaultDriverValues, (value, key) => {
                    if (!entityIdToAdvanceAmountMap[key])
                        entityIdToAdvanceAmountMap[key] = {};
                    set(entityIdToAdvanceAmountMap, `${key}.driverId`, value);
                });
                setEntityIdToAdvanceAmountMap(entityIdToAdvanceAmountMap);
            }
            if (listType !== 'FREIGHT') {
                setPreSelectedRows([]);
                setSelectedEntities([]);
            }
        }
    }, [data]);
    useEffect(() => {
        const selectedAdvances = pick(entityIdToAdvanceAmountMap, preSelectedRows);
        setEntityAdvances(selectedAdvances);
        const adviceAmount = reduce(values(selectedAdvances), (acc, elem, index) => {
            return +acc + +get(elem, 'paymentAmount', 0) + +get(elem, 'onAccountPaymentAmount', 0);
        }, 0);
        if (isBalancePayement || isPR) {
            const totalMappingOnAccountAmount = reduce(values(selectedAdvances), (acc, elem, index) => {
                return +acc + +get(elem, 'onAccountPaymentAmount', 0);
            }, 0);
            formikProps.setFieldValue('onAccountLedger.amount', totalMappingOnAccountAmount);
        }
        formikProps.setFieldValue('adviceAmount', round(adviceAmount, 2));
    }, [entityIdToAdvanceAmountMap, preSelectedRows]);
    useEffect(() => {
        const totalAmountOfSelectedRows = pick(totalRowAmount, preSelectedRows);
        const totalAmountRows = {};
        map(totalAmountOfSelectedRows, (el, index) => {
            totalAmountRows[el?.freightBillId] =
                get(totalAmountRows, `${el?.freightBillId}`, 0) + (el?.totalMappingAmount || 0);
        });
        setFreightBillPaymentMapping(totalAmountRows);
    }, [totalRowAmount, preSelectedRows]);
    useEffect(() => {
        const totalAmountOfSelectedRows = pick(totalRowAmount, preSelectedRows);
        if (isPR) {
            const totalPaymentAmount = reduce(values(totalAmountOfSelectedRows), (acc, elem, index) => {
                return +acc + +get(elem, 'paymentAmount', 0);
            }, 0);
            const totalOnAccountPaymentAmount = reduce(values(totalAmountOfSelectedRows), (acc, elem, index) => {
                return +acc + +get(elem, 'onAccountPaymentAmount', 0);
            }, 0);
            const totalLedgersAmount = +get(formikProps.values, 'debitLedger.amount', 0) +
                +get(formikProps.values, 'indirectIncome.amount', 0) +
                +get(formikProps.values, 'tdsLedger.amount', 0);
            const currentOnAccountPayment = get(onAccountListing, onAccountFormId, null);
            const amountAvailable = +get(currentOnAccountPayment, 'adviceAmount', 0) - +get(currentOnAccountPayment, 'closingAmount', 0);
            formikProps.setFieldValue('remainingPayment', round(totalLedgersAmount - totalPaymentAmount, 2));
            formikProps.setFieldValue('remainingOnAccountPayment', round(amountAvailable - totalOnAccountPaymentAmount, 2));
        }
    }, [
        preSelectedRows,
        entityIdToAdvanceAmountMap,
        get(formikProps.values, 'tdsLedger.amount'),
        get(formikProps.values, 'indirectIncome.amount'),
        get(formikProps.values, 'debitLedger.amount'),
        get(formikProps.values, 'onAccountFormId'),
        totalRowAmount,
    ]);
    const handleAdvanceQuantityChangeHelper = (e, id, balanceAdvanceAmount, advanceAmount, freightBillId) => {
        if (e.target.value && toSafeInteger(e.target.value) < 0) {
            refs.current[`advance-${id}`].value = '';
            alert('Please enter a valid number.');
            return false;
        }
        const validBalanceAdvanceAmount = isEdit ? +balanceAdvanceAmount + +advanceAmount : +balanceAdvanceAmount;
        const onAccountPaymentAmount = +refs.current[`onAccount-${id}`]?.value || 0;
        const totalMappingAmount = +e.target.value + +onAccountPaymentAmount;
        if (+e.target.value + +onAccountPaymentAmount > validBalanceAdvanceAmount) {
            refs.current[`advance-${id}`].value = '';
            if ((isBalancePayement || isPR) && refs.current[`onAccount-${id}`])
                refs.current[`onAccount-${id}`].value = '';
            alert("Amount can't be greater than balance amount.");
            return false;
        }
        const currentIdMapping = {
            paymentAmount: +e.target.value,
            freightBillId,
            totalMappingAmount,
        };
        if (isBalancePayement || isPR) {
            set(currentIdMapping, 'onAccountPaymentAmount', onAccountPaymentAmount);
        }
        const mapping = {
            [id]: {
                paymentAmount: +e.target.value,
                remark: refs.current[`remarks-${id}`].value,
                totalMappingAmount,
                //@ts-ignore
                entityId: listType == 'FREIGHT' ? +get(split(id, '-', 2), '1', null) : +id,
                fuelRate: refs.current[`fuelRate-${id}`]?.value || null,
                fuelQuantity: refs.current[`fuelQuantity-${id}`]?.value || null,
            },
        };
        if (isBalancePayement || isPR) {
            set(mapping, `${id}.onAccountPaymentAmount`, onAccountPaymentAmount);
        }
        if (isDriverAdvance) {
            set(mapping, `${id}.driverId`, get(driverValue, id));
        }
        return { currentIdMapping, mapping };
    };
    const handleAdvanceQuantityChange = (e, id, balanceAdvanceAmount, advanceAmount, freightBillId) => {
        const { currentIdMapping, mapping } = handleAdvanceQuantityChangeHelper(e, id, balanceAdvanceAmount, advanceAmount, freightBillId);
        setTotalRowAmount({
            ...totalRowAmount,
            ...{
                [id]: currentIdMapping,
            },
        });
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMap, ...mapping });
    };
    const handleJobPaymentAmountChange = (e, id, balanceAdvanceAmount, freightBillId) => {
        if (e.target.value && toSafeInteger(e.target.value) <= 0) {
            refs.current[`advance-${id}`].value = '';
            alert('Please enter a valid number.');
            return false;
        }
        const validBalanceAdvanceAmount = +balanceAdvanceAmount;
        const totalMappingAmount = +e.target.value;
        if (+e.target.value > validBalanceAdvanceAmount) {
            refs.current[`advance-${id}`].value = '';
            alert("Amount can't be greater than balance amount.");
            return false;
        }
        const currentIdMapping = {
            paymentAmount: +e.target.value,
            freightBillId,
            totalMappingAmount,
        };
        setTotalRowAmount({
            ...totalRowAmount,
            ...{
                [id]: currentIdMapping,
            },
        });
        const mapping = {
            [id]: {
                paymentAmount: +e.target.value,
                remark: refs.current[`remarks-${id}`].value,
                totalMappingAmount,
                //@ts-ignore
                entityId: +id,
                fuelRate: refs.current[`fuelRate-${id}`]?.value || null,
                fuelQuantity: refs.current[`fuelQuantity-${id}`]?.value || null,
            },
        };
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMap, ...mapping });
    };
    const handleOnAccountPaymentAmountChange = (e, id, balanceAdvanceAmount, advanceAmount, freightBillId) => {
        if (e.target.value && toSafeInteger(e.target.value) <= 0) {
            refs.current[`advance-${id}`].value = '';
            alert('Please enter a valid number.');
            return false;
        }
        const currentOnAccountPayment = get(onAccountListing, onAccountFormId, null);
        const amountAvailable = get(currentOnAccountPayment, 'adviceAmount', 0) - get(currentOnAccountPayment, 'closingAmount', 0);
        if (e.target.value > amountAvailable) {
            refs.current[`onAccount-${id}`].value = '';
            alert('Amount can not be greater than available amount.');
            return false;
        }
        const paymentAmount = +refs.current[`advance-${id}`]?.value || 0;
        const totalMappingAmount = +e.target.value + +paymentAmount;
        const validBalanceAdvanceAmount = isEdit ? +balanceAdvanceAmount + +advanceAmount : +balanceAdvanceAmount;
        if (+e.target.value + paymentAmount > validBalanceAdvanceAmount) {
            refs.current[`advance-${id}`].value = '';
            refs.current[`onAccount-${id}`].value = '';
            alert("Amount can't be greater than balance amount.");
            return false;
        }
        setTotalRowAmount({
            ...totalRowAmount,
            ...{
                [id]: {
                    onAccountPaymentAmount: +e.target.value,
                    paymentAmount,
                    freightBillId,
                    totalMappingAmount,
                },
            },
        });
        const mapping = {
            [id]: {
                onAccountPaymentAmount: +e.target.value,
                paymentAmount,
                remark: refs.current[`remarks-${id}`].value,
                totalMappingAmount,
                //@ts-ignore
                entityId: listType == 'FREIGHT' ? +get(split(id, '-', 2), '1', null) : +id,
                fuelRate: refs.current[`fuelRate-${id}`]?.value || null,
                fuelQuantity: refs.current[`fuelQuantity-${id}`]?.value || null,
            },
        };
        if (isDriverAdvance) {
            set(mapping, `${id}.driverId`, get(driverValue, id));
        }
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMap, ...mapping });
    };
    const handleRemarksChange = (e, id) => {
        const mapping = {
            [id]: {
                paymentAmount: +refs.current[`advance-${id}`].value,
                remark: e.target.value,
                totalMappingAmount: (+refs.current[`advance-${id}`]?.value || 0) + (+refs.current[`onAccount-${id}`]?.value || 0),
                //@ts-ignore
                entityId: listType == 'FREIGHT' ? +get(split(id, '-', 2), '1', null) : +id,
                fuelRate: refs.current[`fuelRate-${id}`]?.value || null,
                fuelQuantity: refs.current[`fuelQuantity-${id}`]?.value || null,
            },
        };
        if (isDriverAdvance) {
            set(mapping, `${id}.driverId`, get(driverValue, id));
        }
        if (isBalancePayement || isPR) {
            const onAccountPaymentAmount = +refs.current[`onAccount-${id}`]?.value || 0;
            set(mapping, `${id}.onAccountPaymentAmount`, onAccountPaymentAmount);
        }
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMap, ...mapping });
    };
    const handleFuelQuantityChange = (e, id) => {
        const mapping = {
            [id]: {
                paymentAmount: +refs.current[`advance-${id}`].value,
                remark: refs.current[`remarks-${id}`].value,
                totalMappingAmount: (+refs.current[`advance-${id}`]?.value || 0) + (+refs.current[`onAccount-${id}`]?.value || 0),
                //@ts-ignore
                entityId: listType == 'FREIGHT' ? +get(split(id, '-', 2), '1', null) : +id,
                fuelRate: refs.current[`fuelRate-${id}`]?.value || null,
                fuelQuantity: e.target.value,
            },
        };
        if (isDriverAdvance) {
            set(mapping, `${id}.driverId`, get(driverValue, id));
        }
        if (isBalancePayement || isPR) {
            const onAccountPaymentAmount = +refs.current[`onAccount-${id}`]?.value || 0;
            set(mapping, `${id}.onAccountPaymentAmount`, onAccountPaymentAmount);
        }
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMap, ...mapping });
    };
    const handleFuelRateChange = (e, row) => {
        const id = get(row, 'id');
        const jobId = get(row, 'jobId');
        const mapping = {
            [id]: {
                paymentAmount: +refs.current[`advance-${id}`].value,
                remark: refs.current[`remarks-${id}`].value,
                totalMappingAmount: (+refs.current[`advance-${id}`]?.value || 0) + (+refs.current[`onAccount-${id}`]?.value || 0),
                //@ts-ignore
                entityId: listType == 'FREIGHT' ? +get(split(id, '-', 2), '1', null) : +id,
                fuelRate: e.target.value,
                fuelQuantity: refs.current[`fuelQuantity-${id}`]?.value || null,
            },
        };
        if (isDriverAdvance) {
            set(mapping, `${id}.driverId`, get(driverValue, id));
        }
        if (isBalancePayement || isPR) {
            const onAccountPaymentAmount = +refs.current[`onAccount-${id}`]?.value || 0;
            set(mapping, `${id}.onAccountPaymentAmount`, onAccountPaymentAmount);
        }
        const entityIdToAdvanceAmountMapCopy = JSON.parse(JSON.stringify(entityIdToAdvanceAmountMap));
        if (jobId) {
            forOwn(entityIdToAdvanceAmountMapCopy, (value, key) => {
                const cnDetails = find(entityList, (el) => get(el, 'id') === toNumber(key));
                const jobIdOfCN = get(cnDetails, 'jobId', []);
                if (jobIdOfCN == jobId) {
                    set(value, `fuelRate`, e.target.value);
                    refs.current[`fuelRate-${key}`].value = e.target.value;
                }
            });
        }
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMapCopy, ...mapping });
    };
    const handleDriverSelectChange = (value, id) => {
        const mapping = {
            [id]: {
                paymentAmount: +refs.current[`advance-${id}`].value,
                totalMappingAmount: (+refs.current[`advance-${id}`]?.value || 0) + (+refs.current[`onAccount-${id}`]?.value || 0),
                remark: refs.current[`remarks-${id}`].value,
                //@ts-ignore
                entityId: listType == 'FREIGHT' ? +get(split(id, '-', 2), '1', null) : +id,
                driverId: value,
                fuelRate: refs.current[`fuelRate-${id}`]?.value || null,
                fuelQuantity: refs.current[`fuelQuantity-${id}`]?.value || null,
            },
        };
        if (isBalancePayement || isPR) {
            const onAccountPaymentAmount = +refs.current[`onAccount-${id}`]?.value || 0;
            set(mapping, `${id}.onAccountPaymentAmount`, onAccountPaymentAmount);
        }
        setDriverValue({ ...driverValue, [id]: value });
        setEntityIdToAdvanceAmountMap({ ...entityIdToAdvanceAmountMap, ...mapping });
    };
    const renderDriverSelectField = (cell, row, formatExtraData) => {
        const { placeholder, name, options } = formatExtraData;
        return (_jsx("div", { onClick: (e) => {
                e.stopPropagation();
            }, children: _jsx("div", { style: { padding: '3rem 0', overflow: 'visible' }, id: "driversList", children: _jsx(Select, { "data-id": name, placeholder: placeholder, options: options, clearable: false, simpleValue: true, value: get(driverValue, get(row, 'id')), onChange: (value) => handleDriverSelectChange(value, get(row, 'id')) }, `${get(row, 'id')}`) }) }));
    };
    const renderAdvanceQuantityField = (cell, row, formatExtraData, rowIndex) => {
        return (_jsx(Input, { onClick: (e) => {
                e.stopPropagation();
            }, onChange: (e) => handleAdvanceQuantityChange(e, get(row, 'id'), get(row, 'advanceDetails.balanceAdvanceAmount'), get(row, 'advanceDetails.advanceAmountEntry', get(row, 'paymentAmount')), get(row, 'freightBillId')), min: 0, placeholder: "Enter Amount", style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: isEdit ? get(row, 'advanceDetails.advanceAmountEntry') : '', innerRef: (element) => {
                refs.current[`advance-${get(row, 'id')}`] = element;
            }, step: "any", type: "number", className: "form-control rounded no-spinner", onWheel: (e) => e.target.blur() }, `${get(row, 'id')}`));
    };
    const renderJobFreightPaymentField = (cell, row, formatExtraData, rowIndex) => {
        return (_jsx(Input, { type: "number", onClick: (e) => {
                e.stopPropagation();
            }, onChange: (e) => handleJobPaymentAmountChange(e, get(row, 'id'), get(row, 'voucher.pendingAmount'), get(row, 'freightBillId')), min: 1, placeholder: "Enter Amount", style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: isEdit ? get(row, 'paymentAmount') : '', innerRef: (element) => {
                refs.current[`advance-${get(row, 'id')}`] = element;
            }, step: "any" }, `${get(row, 'id')}`));
    };
    const renderOnAccountSettledAmountField = (cell, row, formatExtraData, rowIndex) => {
        return (_jsx(Input, { type: "number", onClick: (e) => {
                e.stopPropagation();
            }, min: 0, placeholder: "Enter Amount", style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: isEdit ? get(row, 'advanceDetails.onAccountPaymentAmount') : '', innerRef: (element) => {
                refs.current[`onAccount-${get(row, 'id')}`] = element;
            }, onChange: (e) => handleOnAccountPaymentAmountChange(e, get(row, 'id'), get(row, 'advanceDetails.balanceAdvanceAmount'), get(row, 'advanceDetails.advanceAmountEntry', get(row, 'paymentAmount')), get(row, 'freightBillId')), step: "any" }, `${get(row, 'id')}`));
    };
    const renderRemarksField = (cell, row, formatExtraData, rowIndex) => {
        return (_jsx(Input, { type: "text", onClick: (e) => {
                e.stopPropagation();
            }, onChange: (e) => handleRemarksChange(e, get(row, 'id')), min: 1, placeholder: "Enter Remarks", style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: isEdit ? get(row, 'remarks') : '', innerRef: (element) => {
                refs.current[`remarks-${get(row, 'id')}`] = element;
            }, step: "any" }, `${get(row, 'id')}`));
    };
    const renderFuelRateField = (cell, row, formatExtraData, rowIndex) => {
        return (_jsx(Input, { type: "number", onClick: (e) => {
                e.stopPropagation();
            }, onChange: (e) => handleFuelRateChange(e, row), min: 1, placeholder: "Enter Fuel Rate", style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: isEdit ? get(row, 'fuelRate') : '', innerRef: (element) => {
                refs.current[`fuelRate-${get(row, 'id')}`] = element;
            }, step: "0.01" }, `${get(row, 'id')}`));
    };
    const renderFuelQuantityField = (cell, row, formatExtraData, rowIndex) => {
        return (_jsx(Input, { type: "number", onClick: (e) => {
                e.stopPropagation();
            }, onChange: (e) => handleFuelQuantityChange(e, get(row, 'id')), min: 1, placeholder: "Enter Fuel Quantity", style: { height: '40px', marginBottom: '10px', marginTop: '10px' }, defaultValue: isEdit ? get(row, 'fuelQuantity') : '', innerRef: (element) => {
                refs.current[`fuelQuantity-${get(row, 'id')}`] = element;
            }, step: "0.01" }, `${get(row, 'id')}`));
    };
    const handleSelectAll = (isSelected, rows) => {
        if (!isSelected) {
            const preSelectedRowsNew = JSON.parse(JSON.stringify(preSelectedRows));
            forEach(rows, (row) => {
                remove(preSelectedRowsNew, (id) => get(row, 'id') == id);
            });
            const selectedEntitiesCopy = filter(selectedEntities, (entity) => {
                return includes(preSelectedRowsNew, entity.id);
            });
            setPreSelectedRows(preSelectedRowsNew);
            setSelectedEntities(selectedEntitiesCopy);
        }
        else {
            setPreSelectedRows(uniq([...map(rows, (row) => get(row, 'id')), ...preSelectedRows]));
            setSelectedEntities(uniqBy([...rows, ...selectedEntities], 'id'));
        }
    };
    // TODO: Move this logic to challan utils
    const onRowSelect = (row, isSelected) => {
        let selectedEntitiesCopy = JSON.parse(JSON.stringify(selectedEntities));
        if (!isSelected) {
            const newSelectedRows = filter(preSelectedRows, (elem, index) => elem != row.id);
            setPreSelectedRows(newSelectedRows);
            if (listType == 'FREIGHT') {
                // let cnBillId = get(split(row.id, '-'), '1');
                // let cnId = get(split(row.id, '-'), '0');
                // selectedEntitiesCopy = map(selectedEntitiesCopy, (cp) => {
                //     let updateBills: any = [];
                //     map(cp.consignmentBillDetailsList, (bill) => {
                //         if (`${bill.consignmentEntityDetailsDto.consignmentId}-${bill.id}` != `${cnId}-${cnBillId}`) {
                //             updateBills.push(bill);
                //         }
                //     });
                //     cp.consignmentBillDetailsList = updateBills;
                //     return cp;
                // });
                remove(selectedEntitiesCopy, (entity) => entity.consignmentId === row.consignmentId);
            }
            else {
                remove(selectedEntitiesCopy, (entity) => entity.id === row.id);
            }
            setSelectedEntities(selectedEntitiesCopy);
        }
        else {
            setPreSelectedRows([...preSelectedRows, ...[row.id]]);
            setSelectedEntities([...selectedEntities, ...[row]]);
        }
    };
    const copyBalanceAmountToPaymentAmount = (data) => {
        let totalAmountRowsCopy = JSON.parse(JSON.stringify(totalRowAmount));
        let entityIdToAdvanceAmountMapCopy = JSON.parse(JSON.stringify(entityIdToAdvanceAmountMap));
        forEach(data, (row) => {
            const balanceAdvanceAmount = get(row, 'advanceDetails.balanceAdvanceAmount');
            const id = get(row, 'id');
            refs.current[`advance-${id}`].value = balanceAdvanceAmount;
            const { currentIdMapping, mapping } = handleAdvanceQuantityChangeHelper({ target: { value: balanceAdvanceAmount } }, get(row, 'id'), get(row, 'advanceDetails.balanceAdvanceAmount'), get(row, 'advanceDetails.advanceAmountEntry', get(row, 'paymentAmount')), get(row, 'freightBillId'));
            totalAmountRowsCopy = {
                ...totalAmountRowsCopy,
                ...{
                    [id]: currentIdMapping,
                },
            };
            entityIdToAdvanceAmountMapCopy = {
                ...entityIdToAdvanceAmountMapCopy,
                ...mapping,
            };
        });
        setTotalRowAmount(totalAmountRowsCopy);
        setEntityIdToAdvanceAmountMap(entityIdToAdvanceAmountMapCopy);
    };
    const selectRowProp = {
        mode: get(formikProps, 'values.autoTriggered') ? 'none' : 'checkbox',
        clickToSelect: !get(formikProps, 'values.autoTriggered'),
        bgColor: '#c1ffef',
        onSelect: onRowSelect,
        selected: preSelectedRows,
        onSelectAll: handleSelectAll,
    };
    const options = {
        noDataText: 'No entry found.',
        // paginationPosition: 'both',
        // hideSizePerPage: false,
        // sizePerPage,
        // page: currentPage,
        alwaysShowAllBtns: true,
        // onPageChange: props.onPageChange,
    };
    return (_jsx(ListComponent, { tableOptions: options, selectRowProp: selectRowProp, entityList: entityList, renderRemarksField: renderRemarksField, renderAdvanceQuantityField: renderAdvanceQuantityField, routeList: routeList, branches: branches, groupedVehicles: groupedVehicles, formikProps: formikProps, isEdit: isEdit, driverList: props.driverList, renderDriverSelectField: renderDriverSelectField, totalRowAmount: freightBillPaymentMapping, epodDetails: epodDetails, jobDetails: jobDetails, onOpenEpodModal: onOpenEpodModal, setCurrentCNViewEpod: setCurrentCNViewEpod, renderOnAccountSettledAmountField: renderOnAccountSettledAmountField, renderJobFreightPaymentField: renderJobFreightPaymentField, copyBalanceAmountToPaymentAmount: copyBalanceAmountToPaymentAmount, renderFuelQuantityField: renderFuelQuantityField, renderFuelRateField: renderFuelRateField, entityIdToAdvanceAmountMap: entityIdToAdvanceAmountMap }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GenericERPTableListingComponent);
