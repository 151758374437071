import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Stearing = () => {
    return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_1301_103278", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "17", height: "16", children: _jsx("rect", { x: "0.0253906", width: "16", height: "16", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_1301_103278)", children: _jsx("path", { d: "M8.02639 14.3332C7.15039 14.3332 6.32701 14.1669 5.55623 13.8345C4.78545 13.5021 4.11501 13.0509 3.54489 12.481C2.97478 11.9111 2.52339 11.2409 2.19073 10.4705C1.85817 9.70006 1.69189 8.87689 1.69189 8.001C1.69189 7.125 1.85812 6.30161 2.19056 5.53084C2.52301 4.76006 2.97417 4.08961 3.54406 3.5195C4.11395 2.94939 4.78412 2.498 5.55456 2.16534C6.32501 1.83278 7.14817 1.6665 8.02406 1.6665C8.90006 1.6665 9.72345 1.83273 10.4942 2.16517C11.265 2.49761 11.9355 2.94878 12.5056 3.51867C13.0757 4.08856 13.5271 4.75873 13.8597 5.52917C14.1923 6.29962 14.3586 7.12278 14.3586 7.99867C14.3586 8.87467 14.1923 9.69806 13.8599 10.4688C13.5275 11.2396 13.0763 11.9101 12.5064 12.4802C11.9365 13.0503 11.2663 13.5017 10.4959 13.8343C9.72545 14.1669 8.90228 14.3332 8.02639 14.3332ZM7.52523 13.2793V10.9588C6.89701 10.8297 6.36239 10.5447 5.92139 10.1037C5.48039 9.66267 5.19534 9.12806 5.06623 8.49984H2.74573C2.85339 9.77506 3.35812 10.8563 4.25989 11.7435C5.16156 12.6306 6.25001 13.1426 7.52523 13.2793ZM8.52523 13.2793C9.80045 13.1631 10.8889 12.6563 11.7906 11.7588C12.6923 10.8614 13.1971 9.77506 13.3047 8.49984H10.9842C10.8551 9.12806 10.5701 9.66267 10.1291 10.1037C9.68806 10.5447 9.15345 10.8297 8.52523 10.9588V13.2793ZM2.74573 7.49984H5.02523L6.83289 5.6665H9.21756L11.0252 7.49984H13.3047C13.1765 6.12117 12.6081 4.97095 11.5996 4.04917C10.591 3.12739 9.39956 2.6665 8.02523 2.6665C6.65089 2.6665 5.45945 3.12739 4.45089 4.04917C3.44234 4.97095 2.87395 6.12117 2.74573 7.49984Z", fill: "#7E2393" }) })] }));
};
const StearingIconV3 = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Stearing, ...props });
};
export default StearingIconV3;
