import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FxCol, FxRow } from 'sharedV2/FxGrid';
import CalendarComponent from 'components/utils/calendar_component';
import FxSelect from 'sharedV2/FxSelect';
import { mappedRoutes } from 'utils/form_utils';
import FxInput from 'sharedV2/FxInput';
const JobAdvanceFilterComponent = (props) => {
    const { accesstoken, filters, handleFilterChange, routeList } = props;
    const { from, to, routeId, name } = filters;
    const { t } = useTranslation();
    const options = {
        routes: useMemo(() => {
            return mappedRoutes(routeList);
        }, [routeList]),
    };
    const setTextFilter = debounce((key, term) => {
        handleFilterChange(key, term);
    }, 1000);
    const handleOnTextFilterChange = (event) => {
        setTextFilter(event.target.dataset.datafield, event.target.value);
    };
    return (_jsxs(FxRow, { gutter: 16, className: "mt-4", children: [_jsx(FxCol, { md: 8, xs: 12, children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                        handleFilterChange('timeFilter', [startDate, endDate]);
                    }, startDate: from, endDate: to }) }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(FxSelect, { placeholder: "Select route", value: routeId, options: options.routes, onChange: (value) => {
                        handleFilterChange('routeId', value);
                    } }) }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(FxInput, { "data-gacategory": "Job Advance Form", "data-gaaction": "Click", "data-galabel": "Job Name", type: "text", name: "name", placeholder: `Job Name`, className: "form-control rounded Select-control grey-placeholder", defaultValue: name, onChange: handleOnTextFilterChange, "data-datafield": 'name' }) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        routeList: state.routes.routeListMiniNew,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(JobAdvanceFilterComponent);
