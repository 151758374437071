import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get, isObject } from 'lodash';
import { ConfigProvider, Form, Input, InputNumber, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DEFAULT_SIZE } from '../theme/utils';
export default (passedProps) => {
    const { field, form, isRequired, prependText, postpendText = false, postsup = false, infoTooltipText = false, helpTextClassName, labelClass: labelClassPassed, onChange, onBlur, errorStyle, ...props } = passedProps;
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');
    let Inp;
    switch (props.type) {
        case 'password':
            Inp = Input.Password;
            break;
        case 'number':
            Inp = InputNumber;
            break;
        case 'textArea':
            Inp = Input.TextArea;
        default:
            Inp = Input;
    }
    const getValue = (event) => {
        let value = event;
        if (isObject(event)) {
            value = get(event, 'currentTarget.value');
        }
        return value;
    };
    return (_jsxs(Form.Item, { label: props.label, required: isRequired, validateStatus: isTouched && error ? 'error' : '', help: (isTouched && error) || props.help, labelCol: { span: 24 }, className: props.mb === 0 ? 'mb-0' : props.customClass ?? '', children: [_jsx(ConfigProvider, { theme: {
                    components: {
                        Input: {
                            borderRadiusLG: 4,
                        },
                    },
                }, children: _jsx(Inp, { size: DEFAULT_SIZE, "data-testid": field.name, ...field, ...props, onChange: (event) => {
                        const value = getValue(event);
                        form.setFieldValue(field.name, value);
                        if (onChange) {
                            onChange(value);
                        }
                    }, onBlur: (event) => {
                        const value = getValue(event);
                        form.setFieldTouched(field.name);
                        if (onBlur) {
                            onBlur(value);
                        }
                    }, className: "stack-label-field-2 w-100", onWheel: (e) => e.currentTarget.blur() }) }), infoTooltipText && (_jsx(Tooltip, { title: infoTooltipText, children: _jsx(QuestionCircleOutlined, { style: { marginLeft: '5px' } }) }))] }));
};
