import axios from 'axios';
import { ROOT_API_ALFRED, parseQueryParams, ROOT_REPORTING_NODE_API_URL, ROOT_API_URL, ROOT_API_ALFRED_V2, } from '../../../constant';
import { join } from 'lodash';
export const CREATE_INDENT = 'create_indent';
export const FETCH_INDENT = 'fetch_indent';
export const FETCH_INDENTS = 'fetch_indents';
export const SEARCH_INDENTS = 'search_indents';
export const UPDATE_INDENT = 'update_indent';
export const UPDATE_BULK_INDENT = 'update_bulk_indent';
export const DELETE_INDENT = 'delete_indent';
export const REQUEST_NEW_VEHICLE = 'request_new_vehicle';
export const ASSIGN_VEHICLE_INDENT = 'assign_vehicle_indent';
export const FETCH_INDENT_VEHICLES = 'fetch_indent_vehicle';
export const FETCH_INDENT_USERS = 'fetch_indent_users';
export const CREATE_TRANSPORTER_VEHICLE = 'create_transporter_vehicle';
export const CREATE_INDENT_USER = 'create_indent_user';
export const FETCH_ASSIGNED_INDENT_VEHICLES = 'fetch_assigned_indent_vehicles';
export const ASSIGN_VEHICLE_TO_MULTIPLE_INDENTS = 'assign_vehicles_to_multiple_indents';
export const REMOVE_ASSIGNED_INDENT_VEHICLES = 'remove_assigned_indent_vehicles';
export const DOWNLOAD_INDENT_REPORT = 'download_indent_report';
export const EMAIL_INDENT_REPORT = 'email_indent_report';
export const FETCH_INDENT_SUMMARY = 'fetch_indent_summary';
export const DOWNLOAD_INDENT_BACKEND_REPORT = 'download_indent_backend_report';
export const DOWNLOAD_EMPTY_INDENT_BACKEND_REPORT = 'download_empty_indent_backend_report';
export const DOWNLOAD_TRANSPORTER_PERFORMANCE_REPORT = 'download_transporter_performance_report';
export const UPDATE_TRANSPORTER_VEHICLE = 'update_transporter_vehicle';
export const MANIFEST_INDENT = 'manifest_indent';
export const REQUEST_CHANGE_VEHICLE = 'REQUEST_CHANGE_VEHICLE';
export const UPDATE_BILL_NUMBER_EXPIRY_INDENT = 'update_bill_number_expiry_indent';
export const DOWNLOAD_INDENT_TRACKING_REPORT = 'DOWNLOAD_INDENT_TRACKING_REPORT';
export const ADD_REMOVE_CONSIGNMENT_FROM_INDENT = 'ADD_REMOVE_CONSIGNMENT_FROM_INDENT';
export const PUSH_INDENT_DATA = 'PUSH_INDENT_DATA';
export function createIndent(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ALFRED}/indent/`, values, config);
    return {
        type: CREATE_INDENT,
        promise: request,
    };
}
export function assignVehicleToIndent(accesstoken, values, id, accountId, closeJobBeforeAssign) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            closeExistingJob: closeJobBeforeAssign,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/indent/${id}/vehicles`, values, config);
    return {
        type: ASSIGN_VEHICLE_INDENT,
        promise: request,
    };
}
export function updateIndent(accesstoken, values, id, accountId, addVehicle = false, closeJobBeforeAssign = true) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            addVehicle,
            closeExistingJob: closeJobBeforeAssign,
        }),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/indent`, values, config);
    return {
        type: UPDATE_INDENT,
        promise: request,
    };
}
export function updateBulkIndent(accesstoken, indentIds, status, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            status,
        }),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/indent/bulk/status`, indentIds, config);
    return {
        type: UPDATE_BULK_INDENT,
        promise: request,
    };
}
export function assignVehicleToMultipleIndents(accesstoken, payload, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED_V2}/indent/vehicles/bulk`, payload, config);
    return {
        type: ASSIGN_VEHICLE_TO_MULTIPLE_INDENTS,
        promise: request,
    };
}
export function fetchIndents(accesstoken, accountId = null, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ accountId, from, to }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent`, config);
    return {
        type: FETCH_INDENTS,
        promise: request,
    };
}
export function searchIndents({ accountId = null, from, to, routeIds, vehicleTypeIds, transporterIds, consigneeIds, statuses, delayStatus, page = 1, size = 4000, jobBookingIds, senderIds, receiverIds, createdBy, region, sortQuery, type, id, bookingNumber, dropoffId, factoryId, pickupId, code, ptl, lrNumber, groupId, billNumberValue, vehicleId, customFieldId, customFieldValue, customKeyName, containerNumber, referenceNumber, tagIds, } = {}) {
    const allParams = {
        accountId,
        from,
        to,
        routeIds,
        vehicleTypeIds,
        transporterIds,
        consigneeIds,
        statuses,
        delayStatus,
        size,
        page: page - 1,
        jobBookingIds,
        senderIds,
        receiverIds,
        createdBy,
        region,
        sort: sortQuery,
        type,
        id,
        bookingNumber,
        dropoffId,
        factoryId,
        pickupId,
        code,
        lrNumber,
        groupId,
        billNumberValue,
        vehicleId,
        customFieldId,
        customFieldValue,
        customKeyName,
        ptl,
        containerNumber,
        referenceNumber,
        tagId: join(tagIds, ','),
    };
    if (bookingNumber || lrNumber || id) {
        delete allParams.from;
        delete allParams.to;
    }
    const config = {
        params: parseQueryParams(allParams),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/search`, config);
    return {
        type: SEARCH_INDENTS,
        promise: request,
    };
}
export function fetchIndent(id, accesstoken, accountId = null) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/${id}`, config);
    return {
        type: FETCH_INDENT,
        promise: request,
    };
}
export function fetchIndentVehicles(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/vehicles`, config);
    return {
        type: FETCH_INDENT_VEHICLES,
        promise: request,
    };
}
export function fetchIndentVehicle(accesstoken, accountId, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}/vehicles/${id}`, config);
    return {
        type: FETCH_INDENT_VEHICLES,
        promise: request,
    };
}
export function fetchIndentUsers(accesstoken, accountId) {
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/users`, config);
    return {
        type: FETCH_INDENT_USERS,
        promise: request,
    };
}
export function deleteIndent(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_ALFRED}/indent/${id}`, config);
    return {
        type: DELETE_INDENT,
        promise: request,
    };
}
export function requestNewVehicle(accesstoken, id, vId, remark) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_ALFRED}/indent/${id}/vehicles/${vId}?remark=${remark}`, config);
    return {
        type: REQUEST_NEW_VEHICLE,
        promise: request,
    };
}
export function createTransporterVehicle(accesstoken, values) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...values,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/vehicles`, values, config);
    return {
        type: CREATE_TRANSPORTER_VEHICLE,
        promise: request,
    };
}
export function updateTransporterVehicle(accesstoken, values, id, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            // ...values,
            accountId,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}/vehicles/${id}`, values, config);
    return {
        type: UPDATE_TRANSPORTER_VEHICLE,
        promise: request,
    };
}
export function createTransporterUser(accesstoken, values) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId: values.accountId,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/users`, values, config);
    return {
        type: CREATE_TRANSPORTER_VEHICLE,
        promise: request,
    };
}
export function fetchAssignedVehiclesToIndent(accesstoken, indentId, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/${indentId}/vehicles`, config);
    return {
        type: FETCH_ASSIGNED_INDENT_VEHICLES,
        promise: request,
    };
}
export function removeVehicleFromIndent(accesstoken, indentId, vehicleId, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.delete(`${ROOT_API_ALFRED}/indent/${indentId}/vehicles/${vehicleId}`, config);
    return {
        type: REMOVE_ASSIGNED_INDENT_VEHICLES,
        promise: request,
    };
}
export function fetchDownloadIndentReport(accesstoken, fromDate, toDate, reportFormat, size = 4000, localTransporterAccount, consigneeIds, statuses, delayStatus, senderIds, receiverIds, createdBy, region, { bookingNumber, dropoffId, factoryId, pickupId, type, id, transporterIds } = {}) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            fromDate,
            toDate,
            reportFormat,
            size,
            localTransporterAccount,
            consigneeIds,
            statuses,
            delayStatus,
            senderIds,
            receiverIds,
            createdBy,
            region,
            bookingNumber,
            dropoffId,
            factoryId,
            pickupId,
            type,
            id,
            transporterIds,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/triggerIndentReport`, config);
    return {
        type: DOWNLOAD_INDENT_REPORT,
        promise: request,
    };
}
export function emailIndentReport(accesstoken, params = {}, data = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/indent/report/email`, data, config);
    return {
        type: EMAIL_INDENT_REPORT,
        promise: request,
    };
}
export function fetchDownloadShipperReport(accesstoken, from, to, consigneeIds, statuses, delayStatus, senderIds, receiverIds, createdBy, region, { bookingNumber, dropoffId, factoryId, pickupId, type, id, transporterIds } = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
            consigneeIds,
            statuses,
            delayStatus,
            senderIds,
            receiverIds,
            createdBy,
            region,
            bookingNumber,
            dropoffId,
            factoryId,
            pickupId,
            type,
            id,
            transporterIds,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/report`, config);
    return {
        type: DOWNLOAD_INDENT_BACKEND_REPORT,
        promise: request,
    };
}
export function fetchTransporterPerformanceReport(accesstoken, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/job-epod-report`, config);
    return {
        type: DOWNLOAD_TRANSPORTER_PERFORMANCE_REPORT,
        promise: request,
    };
}
export function fetchDownloadEmptyIndentReport(accesstoken, from, to, accountId, movementType) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
            accountId,
            movementType,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/report/movement-type/empty`, config);
    return {
        type: DOWNLOAD_EMPTY_INDENT_BACKEND_REPORT,
        promise: request,
    };
}
export function fetchIndentSummary(accesstoken, from, to, consigneeIds, senderIds, receiverIds, createdBy, region, accountId, { bookingNumber, dropoffId, factoryId, pickupId, code, ptl, transporterIds, lrNumber, groupId, customFieldId, customFieldValue, customKeyName, containerNumber, referenceNumber, tagIds, } = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            from,
            to,
            consigneeIds,
            senderIds,
            receiverIds,
            createdBy,
            region,
            bookingNumber,
            dropoffId,
            factoryId,
            pickupId,
            code,
            lrNumber,
            groupId,
            customFieldId,
            customFieldValue,
            customKeyName,
            ptl,
            containerNumber,
            referenceNumber,
            transporterIds,
            tagIds: join(tagIds, ','),
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/summary`, config);
    return {
        type: FETCH_INDENT_SUMMARY,
        promise: request,
    };
}
export function updateDocketStatus(accesstoken, accountId, payload) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/ptl-vendor-tracking`, payload, config);
    return {
        type: CREATE_INDENT,
        promise: request,
    };
}
export function manifestIndent(accesstoken, indentId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            indentId,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/indent/push-to-third-party`, {}, config);
    return {
        type: MANIFEST_INDENT,
        promise: request,
    };
}
export const updateBillNumberAndDateOnConsignment = (id, params) => {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.patch(`${ROOT_API_ALFRED}/indent/bill-number/${id}`, params, config);
    return {
        type: UPDATE_BILL_NUMBER_EXPIRY_INDENT,
        promise: request,
    };
};
export function requestChangeVehicle(data, jobId, params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.patch(`${ROOT_API_ALFRED}/indent/vehicles/${jobId}`, data, config);
    return {
        type: REQUEST_CHANGE_VEHICLE,
        promise: request,
    };
}
export function downloadIndentTrackingReport(payload) {
    const config = {};
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/indentTrackingReport`, payload, config);
    return {
        type: DOWNLOAD_INDENT_TRACKING_REPORT,
        promise: request,
    };
}
export function AddRemoveConsignmentFromIndent(indentId, params = {}, data = []) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/indent/consignment/${indentId}`, data, config);
    return {
        type: ADD_REMOVE_CONSIGNMENT_FROM_INDENT,
        promise: request,
    };
}
export function triggerPushToSapIndent(params) {
    const data = [];
    const config = { params: parseQueryParams(params) };
    const request = axios.post(`${ROOT_API_ALFRED}/indent/push-shipment-data`, data, config);
    return {
        type: PUSH_INDENT_DATA,
        promise: request,
    };
}
