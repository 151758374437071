import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Alarm = () => {
    return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_1301_103272", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "17", height: "16", children: _jsx("rect", { x: "0.595215", width: "16", height: "16", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_1301_103272)", children: _jsx("path", { d: "M2.33887 6.60884C2.33887 5.62595 2.54759 4.71867 2.96503 3.887C3.38259 3.05545 3.94609 2.35334 4.65553 1.78067L5.27203 2.55884C4.67803 3.03917 4.20731 3.62806 3.85987 4.3255C3.51253 5.02295 3.33887 5.78406 3.33887 6.60884H2.33887ZM13.8515 6.60884C13.8515 5.78406 13.6779 5.02189 13.3305 4.32234C12.9831 3.62278 12.5124 3.03278 11.9184 2.55234L12.5349 1.78067C13.2443 2.35334 13.8078 3.05545 14.2254 3.887C14.6428 4.71867 14.8515 5.62595 14.8515 6.60884H13.8515ZM3.5952 12.5895V11.5897H4.80037V6.61517C4.80037 5.71862 5.07709 4.92589 5.63053 4.237C6.18387 3.54811 6.89431 3.1075 7.76187 2.91517V2.49984C7.76187 2.26906 7.84309 2.0725 8.00553 1.91017C8.16787 1.74773 8.36442 1.6665 8.5952 1.6665C8.82598 1.6665 9.02253 1.74773 9.18487 1.91017C9.34731 2.0725 9.42853 2.26906 9.42853 2.49984V2.91517C10.2961 3.1075 11.0065 3.54811 11.5599 4.237C12.1133 4.92589 12.39 5.71862 12.39 6.61517V11.5897H13.5952V12.5895H3.5952ZM8.5952 14.4613C8.26276 14.4613 7.97881 14.3436 7.74337 14.1082C7.50781 13.8727 7.39003 13.5887 7.39003 13.2562H9.80037C9.80037 13.5887 9.68259 13.8727 9.44703 14.1082C9.21159 14.3436 8.92765 14.4613 8.5952 14.4613ZM5.80037 11.5897H11.39V6.61517C11.39 5.84339 11.1172 5.18467 10.5715 4.639C10.0258 4.09323 9.36698 3.82034 8.5952 3.82034C7.82342 3.82034 7.16465 4.09323 6.61887 4.639C6.0732 5.18467 5.80037 5.84339 5.80037 6.61517V11.5897Z", fill: "#FA6714" }) })] }));
};
const AlarmIconV3 = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Alarm, ...props });
};
export default AlarmIconV3;
