import * as React from 'react';
import { get, map } from 'lodash';
import { Field } from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import { mappedCommodities } from '../../components/utils/mapping_utils';
import SelectInput from '../../components/utils/SelectInput';

const renderField = (field) => {
    const {
        input,
        name,
        type,
        label,
        isRequired,
        help,
        disableOnEdit,
        min,
        step,
        direction,
        labelClassName,
        meta: { touched, error },
    } = field;
    const className = `${isRequired && touched && error ? 'has-danger' : ''}`;
    const labelClass = `${labelClassName ? labelClassName : ''} ${isRequired && touched && error ? 'text-danger' : ''}`;

    return (
        <div className={className}>
            <div>
                <label htmlFor={name} className={labelClass}>
                    {label}
                    {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                </label>
            </div>
            <div>
                {min && (
                    <input
                        type={type}
                        className="form-control"
                        {...(step ? { step: step } : {})}
                        min={min}
                        {...input}
                        disabled={disableOnEdit}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                )}
                {!min && (
                    <input
                        type={type}
                        className="form-control"
                        {...(step ? { step: step } : {})}
                        {...input}
                        disabled={disableOnEdit}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                )}
                {error && <div className="text-danger">{touched ? error : '  '}</div>}
                {!error && help && <div className="text-muted">{help}</div>}
            </div>
        </div>
    );
};

const CommoditiesList = ({ fields, commodities, name, commodityList }) => {
    const commodityListSize = get(commodities, 'length');
    return (
        <div>
            {map(commodities, (commodity, commodityIndex) => {
                const commodityKey = `${name}.[${commodityIndex}]`;

                return (
                    <Row key={commodityKey}>
                        <Col md={4} sm={12}>
                            <Field
                                name={`${commodityKey}.commodity.id`}
                                label={'Commodity'}
                                component={SelectInput}
                                options={mappedCommodities(commodityList, commodity, commodities)}
                                isRequired={true}
                            />
                        </Col>

                        <Col md={5} sm={12}>
                            <Field
                                name={`${commodityKey}.materialCount`}
                                label={'Scheduled Count'}
                                component={renderField}
                                min="0"
                                type="number"
                                step={1}
                                isRequired={!commodity.scheduledQuantity}
                            />
                        </Col>

                        <Col md={3} sm={12} className="d-flex">
                            <Field
                                name={`${commodityKey}.materialQuantity`}
                                label={'Scheduled Material'}
                                component={renderField}
                                min="0"
                                type="number"
                                step="any"
                                isRequired={!commodity.scheduledCount}
                            />
                            <Button className="ml-2" color="link" onClick={() => fields.remove(commodityIndex)}>
                                Remove
                            </Button>
                        </Col>
                    </Row>
                );
            })}

            <div className="mt-4">
                <button
                    type="button"
                    className="btn"
                    style={{
                        borderRadius: '4px',
                        'border': 'solid 1px #20a8d8',
                        'color': '#20a8d8',
                        height: '2.5rem',
                    }}
                    onClick={() =>
                        fields.push({
                            type: 'DROP',
                        })
                    }
                >
                    Add {commodityListSize ? 'More' : ''} Commodity
                </button>
            </div>
        </div>
    );
};

export default CommoditiesList;
