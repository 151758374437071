import axios from 'axios';
import {
    getDeviceTypeFromDeviceId,
    ROOT_API_URL,
    sendCommandToDevice,
    WORK_MODE_LIST,
    parseQueryParams,
    ROOT_NODE_API_URL,
    DEVICE_TYPE,
} from '../../constant';
// import { DEVICE_TYPE } from 'fx-utils';
import { find, toSafeInteger, toUpper, trim, set } from 'lodash';
import { getDeviceFuelType } from '../../utils/fuel_utils';
import { queryString } from '../../utils/string_utils';
import { sendAppAlertToSlack } from '../../utils/analytics_utils';
import { getMomentTime, DATE_FORMAT_TIMESTAMP } from '../../utils/date_utils';
import { isManageSimDevices, isViewSimTrackingAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { FETCH_GROUPS } from 'actions/dashboard/group_action';

export const FETCH_DEVICES = 'fetch_devices';
export const FETCH_SELECTABLE_DEVICES = 'fetch_selectable_devices';
export const UPDATE_DEVICE = 'update_device';
export const FETCH_DEVICE = 'fetch_device';
export const UPDATE_DEVICE_WITH_VEHICLE = 'update_device_with_vehicle';
export const SEND_SETTINGS_TO_DEVICE = 'send_settings_to_device';
export const FETCH_DEVICE_TYPES_COUNT_USER_HAS = 'fetch_device_types_count_user_has';
export const ADD_SIM_ASSET_FOR_TRACKING = 'add_sim_asset_for_tracking';
export const CHECK_CONSENT_FOR_ASSET_TRACKING = 'check_consent_for_asset_tracking';
export const DELETE_SIM_ASSET_FOR_TRACKING = 'delete_sim_asset_for_tracking';
export const ISSUE_DEVICES = 'issue_devices';
export const RFID_MAPPING = 'rfid_mapping';
export const TRANSPORTER_MAPPING = 'transporter_mapping';
export const UPDATE_DEVICE_SIM = 'UPDATE_DEVICE_SIM';
export const FETCH_ALL_LOCKS = 'fetch_all_locks';
export const UNLOCK_LOCK = 'unlock_lock';
export const ADD_APP_BASED_TRACKING_TO_VEHICLE = 'add_app_based_tracking_to_vehicle';
export const FETCH_DEVICES_FOR_USER = 'fetch_devices_for_user';
export const VERIFY_DEVICES = 'verify_devices';
export const DEVICES_HEALTH = 'devices_health';
export const GENERATE_DEVICE_OTP = 'GENERATE_DEVICE_OTP';
export const SEND_GENERATE_DEVICE_OTP = 'SEND_GENERATE_DEVICE_OTP';
export const FETCH_DEVICE_FX_IDS = 'FETCH_DEVICE_FX_IDS';
export const SET_SIM_STATUS = 'SET_SIM_STATUS';
export const SET_SIM_STATUS_BY_SIM_NUMBER = 'SET_SIM_STATUS_BY_SIM_NUMBER';
export const GENERATE_KEYPAD_DEVICE_OTP = 'GENERATE_KEYPAD_DEVICE_OTP';
export const SEND_GENERATE_KEYPAD_DEVICE_OTP = 'SEND_GENERATE_KEYPAD_DEVICE_OTP';
export const DETACH_DEVICE = 'DETACH_DEVICE';
export const DRIVER_HISTORY = 'DRIVER_HISTORY';

export function generateDeviceOtp(accesstoken, deviceId, timestamp) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ deviceId, timestamp }),
    };

    const request = axios.get(`${ROOT_API_URL}devices/generate-otp`, config);

    return {
        type: GENERATE_DEVICE_OTP,
        promise: request,
    };
}

export function sendGeneratedDeviceOtp(accesstoken, deviceId, mobileNumber, otp) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ deviceId, mobileNumber, otp }),
    };

    const request = axios.get(`${ROOT_API_URL}devices/send-ble-sms`, config);

    return {
        type: SEND_GENERATE_DEVICE_OTP,
        promise: request,
    };
}

export function fetchDeviceFXIds(accesstoken, deviceIdsList) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}devices/fx-id`, deviceIdsList, config);
    return {
        type: FETCH_DEVICE_FX_IDS,
        promise: request,
    };
}

export function fetchDevices(accesstoken, searchStr) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.get(
        `${ROOT_API_URL}devices/?page=0&size=20&serialNumber=${toUpper(trim(searchStr))}&id=${toUpper(
            trim(searchStr)
        )}`,
        config
    );

    return {
        type: FETCH_DEVICES,
        promise: request,
    };
}

export function fetchDevicesForUser(accesstoken, userId = null) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.get(`${ROOT_API_URL}devices/user/${userId ? userId : ''}`, config);

    return {
        type: FETCH_DEVICES_FOR_USER,
        promise: request,
    };
}

export function fetchSelectableDevices(accesstoken, searchStr, includeAttached, searchKey, accountId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const includeAttachedQuery = includeAttached ? '&includeAttached=true' : '';
    const searchKeyQuery = searchKey ? `&searchKey=${searchKey}` : '';
    const accountIdQuery = accountId ? `&accountId=${accountId}` : '';
    //const accountIdQuery = '';

    const request = axios.get(
        `${ROOT_API_URL}devices/selectable?serialNumber=${toUpper(trim(searchStr))}&id=${toUpper(
            trim(searchStr)
        )}&rfid=${toUpper(trim(searchStr))}${includeAttachedQuery}${searchKeyQuery}${accountIdQuery}`,
        config
    );

    return {
        type: FETCH_SELECTABLE_DEVICES,
        promise: request,
    };
}

export function updateDevice(accesstoken, id, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}devices/${id}`, data, config);
    return {
        type: UPDATE_DEVICE,
        promise: request,
    };
}

export function createDevice(accesstoken, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}devices/`, data, config);
    return {
        type: UPDATE_DEVICE,
        promise: request,
    };
}

export function detachDevice(accesstoken, vehicleId, issuedTo, issuedToUserId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            issuedToUserId,
            issuedTo,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}devices/detach-device`, null, config);
    return {
        type: DETACH_DEVICE,
        promise: request,
    };
}

export function fetchDevice(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.get(`${ROOT_API_URL}devices/${id}`, config);

    return {
        type: FETCH_DEVICE,
        promise: request,
    };
}

export function updateDeviceWithVehicle(accesstoken, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.post(`${ROOT_API_URL}vehicles/device`, data, config);

    return {
        type: UPDATE_DEVICE_WITH_VEHICLE,
        promise: request,
    };
}

export function sendSettingsToDevice(
    accesstoken,
    deviceId,
    workMode,
    fuelType,
    capacity,
    odometer,
    sos,
    ignitionNotConnected,
    licensePlate,
    bikemode,
    accountId,
    issuedTo = '',
    sosVoltage,
    serialNumber,
    webInstaller
) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    issuedTo = issuedTo.replace(/ /gi, '').replace(/[^\w-]/gi, '');
    serialNumber = serialNumber ? serialNumber.replace(/ /gi, '').replace(/[^\w-]/gi, '') : '';
    webInstaller = webInstaller ? webInstaller.replace(/ /gi, '').replace(/[^\w-]/gi, '') : '';
    const data = {
        deviceId,
        setInitialSettings: true,
        licensePlate:
            licensePlate +
            (serialNumber ? `::SERIALNO_${serialNumber}_` : '') +
            (accountId ? `::ACCID_${accountId}_` : '') +
            (accountId ? `::ISSUE_${issuedTo}_` : '') +
            (accountId ? `::INSTALLERWEB_${webInstaller}_` : ''),
    };

    data.workMode = find(WORK_MODE_LIST, ['id', parseInt(workMode)]).commandSubType;

    const deviceType = getDeviceTypeFromDeviceId(deviceId);
    if (deviceType.name === DEVICE_TYPE.FMB920.name) {
        //Force workmode tracker for FMB920
        data.workMode = find(WORK_MODE_LIST, ['id', 2]).commandSubType;
        data.sosVoltage = !!sosVoltage ? 1 : 0;
        if (!!bikemode) {
            data.ignitionSrc = 2;
            sendCommandToDevice(accesstoken, deviceId, deviceId, 'FMB_SET_BIKE_MODE', () => {}, webInstaller);
        } else if (!!ignitionNotConnected) {
            data.ignitionSrc = 2;
            sendCommandToDevice(accesstoken, deviceId, deviceId, 'FMB_SET_IGNITION_2_FORCE', () => {}, webInstaller);
        } else {
            data.ignitionSrc = 1;
            if (!!sosVoltage) {
                // sendCommandToDevice(accesstoken, deviceId, deviceId, 'SET_SOS_VOLTAGE');
                sendAppAlertToSlack(
                    '```' + `New Device for SOS Voltage    ::    ${deviceId}` + '```',
                    '#sos_voltage_devices'
                );
            } else {
                //sendCommandToDevice(accesstoken, deviceId, deviceId, 'SET_IGNITION_1_DEFAULT');
            }
        }
    } else if (
        deviceType.name === DEVICE_TYPE.GDP213.name ||
        deviceType.name === DEVICE_TYPE.GL213.name ||
        deviceType.name === DEVICE_TYPE.GDPA213.name ||
        deviceType.name === DEVICE_TYPE['GN213-OBD200'].name
    ) {
        data.fuelType = getDeviceFuelType(fuelType, { accountId });
        if (capacity) {
            data.capacity = capacity;
        }
        // adding sos for 213GL & GD Devices.
        if ((deviceType.name === DEVICE_TYPE.GL213.name || deviceType.name === DEVICE_TYPE.GDPA213.name) && sos) {
            data.sos = 1;
        }
    } else {
        //Force workmode tracker
        data.workMode = find(WORK_MODE_LIST, ['id', 2]).commandSubType;
        data.firstPacket = 1;
    }

    if (toSafeInteger(odometer)) {
        data.odometer = toSafeInteger(odometer);
    }

    const request = axios.post(`${ROOT_API_URL}devices/setting`, queryString.stringify(data), config);

    return {
        type: SEND_SETTINGS_TO_DEVICE,
        promise: request,
    };
}

export function fetchDeviceTypesCountForUser(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.get(`${ROOT_API_URL}devices/type/count/user?userId=${id}`, config);

    return {
        type: FETCH_DEVICE_TYPES_COUNT_USER_HAS,
        promise: request,
    };
}

export function addSimAssetForTracking(accesstoken, mobileNumber, simProvider, expiryDate, vehicleId, pingFrequency) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' } };
    const data = {
        'mobileNumber': mobileNumber,
        'simProvider': simProvider,
        'expiryDate': getMomentTime(expiryDate).format(DATE_FORMAT_TIMESTAMP),
        vehicleId,
        pingFrequency,
        startTracking: true,
    };
    const request = axios.post(`${ROOT_API_URL}devices/sim`, data, config);

    return {
        type: ADD_SIM_ASSET_FOR_TRACKING,
        promise: request,
    };
}

export function checkConsentForAssetTracking(accesstoken, mobileNumber) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isManageSimDevices(scope) && !isViewSimTrackingAllowedForUser(scope)) {
        return {
            type: FETCH_GROUPS,
            promise: Promise.resolve({}),
        };
    }
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}devices/sim/status?mobile=${mobileNumber}`, config);

    return {
        type: CHECK_CONSENT_FOR_ASSET_TRACKING,
        promise: request,
    };
}

export function deleteSimTracking(accesstoken, mobileNumber) {
    // https://github.com/axios/axios/issues/897
    const request = axios({
        method: 'DELETE',
        url: `${ROOT_API_URL}devices/sim`,
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        data: {
            'mobileNumber': mobileNumber,
        },
    });
    return {
        type: DELETE_SIM_ASSET_FOR_TRACKING,
        promise: request,
    };
}

export function issueDevices(accesstoken, deviceIds, issuedToUserId, issuedTo) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            deviceIds,
            issuedToUserId,
            issuedTo,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}devices/issued-to`, null, config);

    return {
        type: ISSUE_DEVICES,
        promise: request,
    };
}

export function attachRfid(accesstoken, serialNumber, rfid) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' },
        params: parseQueryParams({
            serialNumber,
            rfid,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}devices/rfid-attach`, null, config);

    return {
        type: RFID_MAPPING,
        promise: request,
    };
}

export function attachTransporter(accesstoken, serialNumber, transporterId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' },
        params: parseQueryParams({
            transporterId,
        }),
    };
    const data = serialNumber;
    const request = axios.put(`${ROOT_API_URL}devices/transporter-mapping`, data, config);

    return {
        type: TRANSPORTER_MAPPING,
        promise: request,
    };
}

export function verifyDevices(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}asset-verification/verify`, data, config);

    return {
        type: VERIFY_DEVICES,
        promise: request,
    };
}

export function updateDeviceSim(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}devices/sim`, values, config);

    return {
        type: UPDATE_DEVICE_SIM,
        promise: request,
    };
}

export function addAppBasedTrackingToVehicle(accesstoken, vehicleId, mobileNumber) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const data = {
        vehicleId,
        mobileNumber,
    };
    const request = axios.post(`${ROOT_API_URL}devices/app`, data, config);

    return {
        type: ADD_APP_BASED_TRACKING_TO_VEHICLE,
        promise: request,
    };
}

export function deleteAppBasedTracking(accesstoken, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.delete(`${ROOT_API_URL}devices/app`, config);

    return {
        type: ADD_APP_BASED_TRACKING_TO_VEHICLE,
        promise: request,
    };
}

export function fetchAllLocks() {
    const accesstoken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJvcGVuYXBwIiwiZXhwIjoxNTc4NzI4ODY2LCJ1c2VySWQiOiIxZjE2Mmt2MDAwMDAwMDAiLCJ1c2VyTWV0YSI6eyJpZCI6IjFmMTYya3YwMDAwMDAwMCIsImJ1c2luZXNzIjpbeyJpZCI6IjFmMTYya3YwMDAwMDAwMCIsImF1dGhVc2VySWQiOiIxZjE2Mmt2MDAwMDAwMDAiLCJvcmdhbmlzYXRpb25JZCI6IjFlODg4anQwMDAwMDAwMCIsImNvbXBhbnlJZCI6IjFlODg4anQwMDAwMDAwMCIsInN1YkNvbXBhbnlJZCI6IjFlODg4anQwMDAwMDAwMCIsInJvbGUiOiIxZTg4OGp0MDAwMDAwMDUifV19LCJjbGllbnQiOnsiY2xpZW50SWQiOiJzZWN1cmUtY2M5ZWM4N2Y2OTc1YTVkYTZmMzVhZWM5MzUxMzBlMDMifSwiaWF0IjoxNTc4NjQyNDY2fQ.wrXm4P3JiKrg1HiXa_dAKsHneeliEt5Ls7Z9LLOeeLI';
    const config = { headers: { Authorization: `Bearer ${accesstoken}`, 'x-company-id': '1e888jt00000000' } };
    const request = axios.get(`https://apib2b.openapp.co/api/v1/business/web/locks/all`, config);

    return {
        type: FETCH_ALL_LOCKS,
        promise: request,
    };
}

export function unlockLock(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}`, 'Content-Type': 'application/json' } };
    const data = {
        'deviceId': '8045861116',
        'hostTransactionId': '8b8d492c-9a35-4161-9d9e-617f8469c3ef',
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/gpslock/unlock`, data, config);

    return {
        type: UNLOCK_LOCK,
        promise: request,
    };
}

export function fetchDevicesHealth(accesstoken, { phoneNumber, vehicleNumber, simOperator, accountId }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            phoneNumber,
            vehicleNumber,
            simOperator,
            accountId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}devices/health`, config);

    return {
        type: DEVICES_HEALTH,
        promise: request,
    };
}

export const setSimStatus = (accesstoken, id, activate) => {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: {
            activate,
        },
    };

    const data = {
        id,
    };

    const request = axios.post(`${ROOT_API_URL}inventory/change/sim-status`, data, config);

    return {
        type: SET_SIM_STATUS,
        promise: request,
    };
};

export const setSimStatusBySimNumber = (accesstoken, simNumber, activate) => {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: {
            activate,
        },
    };

    const request = axios.put(`${ROOT_API_URL}inventory/sim-status/${simNumber}`, '', config);

    return {
        type: SET_SIM_STATUS_BY_SIM_NUMBER,
        promise: request,
    };
};

export function generateKeyPadDeviceOtp(accesstoken, deviceId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ deviceId }),
    };

    const request = axios.get(`${ROOT_API_URL}devices/keypad-code`, config);

    return {
        type: GENERATE_KEYPAD_DEVICE_OTP,
        promise: request,
    };
}

export function sendGeneratedKeypadDeviceOtp(accesstoken, deviceId, mobileNumber, code) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ deviceId, mobileNumber, code }),
    };

    const request = axios.get(`${ROOT_API_URL}devices/keypad-unlock-sms`, config);

    return {
        type: SEND_GENERATE_KEYPAD_DEVICE_OTP,
        promise: request,
    };
}

export function fetchDriverHistory(params) {
    const config = {
        params: parseQueryParams(params),
    };

    const request = axios.get(`${ROOT_API_URL}devices/tracking/history`, config);

    return {
        type: DRIVER_HISTORY,
        promise: request,
    };
}
