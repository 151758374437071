import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, get, includes, keyBy, set, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import FxTable from 'sharedV2/FxTable';
import { useMemo } from 'react';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { useFxAlert } from 'components/dashboard/customHooks';
import FxInput from 'sharedV2/FxInput';
const JobAdvanceJobLListingComponent = (props) => {
    const { accesstoken, jobList, jobListLoading, branches, handleFilterChange, totalElements, filters, formikProps, selectedJobs, setSelectedJobs, setJobList, setSelectedJobsData, selectedJobsData, } = props;
    const { page } = filters;
    const { values, setFieldValue } = formikProps;
    const showAlert = useFxAlert();
    const { t } = useTranslation();
    const mappedBranches = useMemo(() => {
        return keyBy(branches, 'id');
    }, [branches]);
    const onPageChange = (page) => {
        handleFilterChange('page', page);
    };
    const handleAdviceAmountChange = () => {
        const totalAmount = sumBy(selectedJobsData, (row) => {
            return +get(row, 'paymentMappings.paymentAmount', 0);
        });
        setFieldValue('advanceAmount', totalAmount);
    };
    const renderPaymentAmountField = (field, row, formikProps) => {
        const { setFieldValue } = formikProps;
        const jobId = get(row, 'id');
        const value = get(row, 'paymentMappings.paymentAmount', 0);
        const balanceAmount = get(row, 'jobAdvance.agreedAdvanceAmount', 0) - get(row, 'receivedAdvanceAmount', 0);
        const disabled = !includes(selectedJobs, jobId);
        return (_jsx(FxInput, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), placeholder: "Enter Amount", min: 0, value: value, disabled: disabled, type: "number", onChange: (e) => {
                handleInputFieldChange(e.target.value, 'paymentAmount', jobId);
            }, onBlur: (e) => {
                if (+e.target.value > balanceAmount) {
                    handleInputFieldChange(0, 'paymentAmount', jobId);
                    showAlert('Payment amount cannot be greater than balance amount');
                    return;
                }
                handleAdviceAmountChange();
            } }));
    };
    const renderRemarksField = (field, row, formikProps) => {
        const jobId = get(row, 'id');
        const value = get(row, 'paymentMappings.remark', '');
        const disabled = !includes(selectedJobs, jobId);
        return (_jsx(FxInput, { placeholder: "Enter Remarks", disabled: disabled, value: value, onChange: (e) => {
                handleInputFieldChange(e.target.value, 'remark', jobId);
            } }));
    };
    const handleInputFieldChange = (val, key, jobId) => {
        const tempJobList = JSON.parse(JSON.stringify(jobList));
        const currentJob = find(tempJobList, { id: jobId });
        set(currentJob, `paymentMappings.${key}`, val);
        if (includes(selectedJobs, jobId)) {
            const selectedJob = find(selectedJobsData, { id: jobId });
            set(selectedJob, `paymentMappings.${key}`, val);
        }
        setJobList(tempJobList);
    };
    const columns = [
        {
            title: 'Job name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'Branch',
            dataIndex: 'branchId',
            key: 'branchId',
            render: (col, row) => {
                const branchId = get(row, 'branchId', '');
                const branchDetails = get(mappedBranches, branchId, {});
                return get(branchDetails, 'officeName', '');
            },
        },
        {
            title: 'Route',
            dataIndex: ['route', 'name'],
            key: 'route',
            width: 200,
        },
        {
            title: 'Freight',
            dataIndex: 'freight',
            key: 'freight',
            render: (col, row) => sumBy(get(row, 'jobAnalytics', []), 'freightFare') || 0,
        },
        {
            title: 'Vehicle',
            dataIndex: ['vehicle', 'licensePlate'],
            key: 'vehicle',
        },
        {
            title: 'Agreed Amount',
            dataIndex: ['jobAdvance', 'agreedAdvanceAmount'],
            key: 'agreedAmount',
        },
        {
            title: 'Received Amount',
            dataIndex: 'receivedAdvanceAmount',
            key: 'receivedAmount',
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balanceAmount',
            key: 'balanceAmount',
            render: (col, row) => {
                const agreedAmount = get(row, 'jobAdvance.agreedAdvanceAmount', 0);
                const receivedAmount = get(row, 'receivedAdvanceAmount', 0);
                return agreedAmount - receivedAmount;
            },
        },
        {
            title: 'Received Payment Amount',
            dataIndex: 'receivedAdvanceAmount',
            key: 'receivedPaymentAmount',
            render: (field, row) => renderPaymentAmountField(field, row, formikProps),
            width: 200,
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            render: (record, row) => renderRemarksField(record, row, formikProps),
            width: 200,
        },
    ];
    return (_jsx("div", { className: "mt-4", children: _jsx(FxTable, { columns: columns, dataSource: jobList, loading: jobListLoading, rowKey: "id", pagination: {
                position: ['bottomRight'],
                total: totalElements,
                current: page,
                pageSize: 10,
                onChange: onPageChange,
                showSizeChanger: false,
            }, scroll: { x: true }, rowSelection: {
                type: 'checkbox',
                selectedRowKeys: selectedJobs,
                onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedJobs(selectedRowKeys);
                    setSelectedJobsData(selectedRows);
                    handleAdviceAmountChange();
                },
            } }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        branches: get(state, 'branch.branches', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(JobAdvanceJobLListingComponent);
