import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, get, includes, round } from 'lodash';
import { Field } from 'formik';
import { mappedObjectToOptions, mappedOptionsFromArray } from 'components/utils/mapping_utils';
import { INSTRUMENT_TYPES } from 'utils/consignmentsUtils';
import { FxCol, FxRow, FxSelectField, FxTextFieldStackedLabelV2 } from 'sharedV2/index';
import { DatePicker } from 'sharedV2/FxFormItems';
import { getLabelForIndirectIncome } from 'utils/payment_utils';
import LedgerDetailsComponentV2 from 'components/dashboard/ledger_details/ledger_details_component_v2';
const RecoveryComponentJobAdvance = (props) => {
    const { formikProps, editId, loggedInUser, accesstoken, enableTransactionDetails, setEnableTransactionDetails, ledgers, } = props;
    const { values } = formikProps;
    const baseKey = 'ledgerDetails';
    const isDebitLedgerSelected = get(values, `${baseKey}.debitLedger.ledgerId`);
    const getTdsLedgerOptions = (ledgers) => {
        return editId
            ? mappedOptionsFromArray(ledgers, 'ledgerName')
            : mappedOptionsFromArray(filter(ledgers, (ledger) => includes([11], get(ledger, 'group.baseGroupId'))), 'ledgerName');
    };
    const getDebitLedgersFilter = (ledgers) => {
        return editId
            ? mappedOptionsFromArray(ledgers, 'ledgerName')
            : mappedOptionsFromArray(filter(ledgers, (ledger) => includes([6, 7, 41], get(ledger, 'group.baseGroupId'))), 'ledgerName');
    };
    const getIndirectIncomeLedgerFilters = (ledgers) => {
        return editId
            ? mappedOptionsFromArray(ledgers, 'ledgerName')
            : mappedOptionsFromArray(filter(ledgers, (ledger) => includes([17], get(ledger, 'group.baseGroupId'))), 'ledgerName');
    };
    console.log('enableTransactionDetails-->', enableTransactionDetails);
    const indirectIncomeSibling = () => {
        return (_jsxs(_Fragment, { children: [_jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { name: `transactionDetails.instrumentType`, component: FxSelectField, options: mappedObjectToOptions(INSTRUMENT_TYPES), label: "Payment Mode", onChange: (val) => { }, isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails }) }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { type: 'text', name: `transactionDetails.instrumentNumber`, component: FxTextFieldStackedLabelV2, label: "Instrument No.", isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails }) }), _jsx(DatePicker, { name: `transactionDetails.recoveryDate`, label: "Recovery Date", required: true, "data-testid": "basic-details-billing-date", colSpan: 8 }), _jsx(FxCol, { md: 8, xs: 12, children: _jsx(Field, { type: 'text', name: `transactionDetails.bankName`, component: FxTextFieldStackedLabelV2, isRequired: isDebitLedgerSelected && enableTransactionDetails, disabled: !!editId || !isDebitLedgerSelected || !enableTransactionDetails, label: "Bank Name" }) })] }));
    };
    const debitLedgerOnChangeHandler = (ledgers, val) => {
        const selectedLedger = filter(ledgers, (ledger) => get(ledger, 'id') == val);
        const isCashInHandLedger = get(selectedLedger, '0.group.baseGroupId') == 7;
        setEnableTransactionDetails(!isCashInHandLedger);
    };
    const ledgerDetailsFields = [
        {
            name: `${baseKey}.debitLedger.ledgerId`,
            onChange: debitLedgerOnChangeHandler,
            disabled: !!editId,
            label: 'Debit Ledger',
            optionsFilter: getDebitLedgersFilter,
            shouldShowAmountField: true,
            isRequired: +get(values, `${baseKey}.debitLedger.amount`, 0) > 0,
            amountFieldInfo: {
                label: 'Amount',
                name: `${baseKey}.debitLedger.amount`,
                isAmountFieldRequired: true,
                onBlur: (val) => {
                    if (val) {
                        formikProps.setFieldValue(`${baseKey}.debitLedger.amount`, round(+val, 2));
                    }
                },
                type: 'number',
            },
        },
        {
            name: `${baseKey}.indirectIncome.ledgerId`,
            disabled: !!editId,
            label: getLabelForIndirectIncome('JOB_ADVANCE'),
            optionsFilter: getIndirectIncomeLedgerFilters,
            shouldShowField: true,
            shouldShowAmountField: true,
            amountFieldInfo: {
                label: 'Amount',
                name: `${baseKey}.indirectIncome.amount`,
                onBlur: (val) => {
                    if (val) {
                        formikProps.setFieldValue(`${baseKey}.indirectIncome.amount`, round(+val, 2));
                    }
                },
                type: 'number',
            },
            sibling: indirectIncomeSibling,
            hasSibling: true,
            isRequired: +get(values, `${baseKey}.indirectIncome.amount`, 0) > 0,
        },
        {
            name: `${baseKey}.tdsLedger.ledgerId`,
            disabled: !!editId,
            label: 'TDS Ledger',
            optionsFilter: getTdsLedgerOptions,
            shouldShowField: true,
            shouldShowAmountField: true,
            amountFieldInfo: {
                label: 'Amount',
                name: `${baseKey}.tdsLedger.amount`,
                onBlur: (val) => {
                    if (val) {
                        formikProps.setFieldValue(`${baseKey}.tdsLedger.amount`, round(+val, 2));
                    }
                },
                type: 'number',
            },
            isRequired: +get(values, `${baseKey}.tdsLedger.amount`, 0) > 0,
        },
    ];
    return (_jsx(FxRow, { children: _jsx(LedgerDetailsComponentV2, { ledgerDetailsFields: ledgerDetailsFields, ledgersFromParent: ledgers, isNewRow: false }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        loggedInUser: state.settings.loggedInUser,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RecoveryComponentJobAdvance);
