import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Bolt = () => {
    return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_1301_103269", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "17", height: "16", children: _jsx("rect", { x: "0.880371", width: "16", height: "16", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_1301_103269)", children: _jsx("path", { d: "M7.91315 12.133L11.3632 7.99965H8.69649L9.17982 4.21632L6.09649 8.66632H8.41315L7.91315 12.133ZM6.59782 14.2945L7.26449 9.66632H4.18115L9.67465 1.73682H10.4952L9.83499 6.99965H13.5015L7.41832 14.2945H6.59782Z", fill: "#107C10" }) })] }));
};
const BoltIconV3 = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Bolt, ...props });
};
export default BoltIconV3;
