import axios from 'axios';
import { parseQueryParams, ROOT_API_ALFRED } from '../../../constant';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const FETCH_CONTRACTS = 'FETCH_CONTRACTS';
export const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS';
export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const FETCH_SHARE_OF_BUSINESS_FOR_VEHICLE_SIZES = 'FETCH_SHARE_OF_BUSINESS_FOR_VEHICLE_SIZES';
export const FETCH_CONTRACTS_BY_ROUTES = 'FETCH_CONTRACTS_BY_ROUTES';
export const FETCH_VEHICLE_CONTRACT_SUMMARY = 'FETCH_VEHICLE_CONTRACT_SUMMARY';
export const CREATE_CONTRACT_CONFIG = 'CREATE_CONTRACT_CONFIG';
export const FETCH_DISPTACH_PLANNING = 'FETCH_DISPTACH_PLANNING';
export const FETCH_CONTRACT_CONFIG = 'FETCH_CONTRACT_CONFIG';
export const FETCH_BASE_RATE_DEVIATION = 'FETCH_BASE_RATE_DEVIATION';
export const FETCH_HISTORY_BY_ID = 'FETCH_HISTORY_BY_ID';
export const CREATE_SHARE_OF_BUSINESS_FOR_VEHICLE_SIZES = 'CREATE_SHARE_OF_BUSINESS_FOR_VEHICLE_SIZES';
export const UPDATE_TRANSPORTER_WISE_ALLOCATION = 'UPDATE_TRANSPORTER_WISE_ALLOCATION';
export const CREATE_INDENT_FALLBACK = 'CREATE_INDENT_FALLBACK';
export const GET_INDENT_FALLBACK = 'GET_INDENT_FALLBACK';
export function createContract(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ALFRED}/contract`, values, config);
    return {
        type: CREATE_CONTRACT,
        promise: request,
    };
}
export function createContractConfig(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_ALFRED}/contract/config`, values, config);
    return {
        type: CREATE_CONTRACT_CONFIG,
        promise: request,
    };
}
export function updateContract(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_ALFRED}/contract`, values, config);
    return {
        type: UPDATE_CONTRACT,
        promise: request,
    };
}
export function fetchContract(accesstoken, id, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: {
            accountId,
        },
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/${id}`, config);
    return {
        type: FETCH_CONTRACT,
        promise: request,
    };
}
export function fetchBaseRateDeviation(accesstoken, contractId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: {
            contractId,
        },
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/base-rate-deviations`, config);
    return {
        type: FETCH_BASE_RATE_DEVIATION,
        promise: request,
    };
}
export function deleteFTLContract(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_ALFRED}/contract/${id}`, config);
    return {
        type: FETCH_CONTRACT,
        promise: request,
    };
}
export function deletePTLContract(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_ALFRED}/contract/ptl/${id}`, config);
    return {
        type: FETCH_CONTRACT,
        promise: request,
    };
}
export function fetchContracts(accesstoken, params = {}) {
    const { accountId = null, size, page = 1, sort, startDate, endDate, routeIds, excludeExpired, tagIds } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            size,
            page: page - 1,
            sort,
            from: startDate,
            to: endDate,
            routeIds,
            excludeExpired,
            tagIds,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract`, config);
    return {
        type: FETCH_CONTRACTS,
        promise: request,
    };
}
export function searchContracts(accesstoken, params = {}) {
    const { accountId = null, size, page = 1, sort, startDate, endDate, routeIds, excludeExpired, sourceId, destinationIds, _type, } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            size,
            page: page - 1,
            sort,
            from: startDate,
            to: endDate,
            routeIds,
            excludeExpired,
            sourceId,
            destinationIds,
            type: _type,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/search`, config);
    return {
        type: SEARCH_CONTRACTS,
        promise: request,
    };
}
export function fetchDisptachPlanning(accesstoken, routeId, vehicleSizeId, noOfVehicles) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            routeId,
            vehicleSizeId,
            noOfVehicles,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/dispatch/planning`, config);
    return {
        type: FETCH_DISPTACH_PLANNING,
        promise: request,
    };
}
export function fetchContractConfig(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/config`, config);
    return {
        type: FETCH_CONTRACT_CONFIG,
        promise: request,
    };
}
export function fetchShareOfBusinessForVehicleSizes(accesstoken, routeId, excludeExpired = false) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            routeId,
            excludeExpired,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/share/sob`, config);
    return {
        type: FETCH_SHARE_OF_BUSINESS_FOR_VEHICLE_SIZES,
        promise: request,
    };
}
export function fetchContractsByRoutes(accesstoken, params = {}) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/contract/route/details`, config);
    return {
        type: FETCH_CONTRACTS_BY_ROUTES,
        promise: request,
    };
}
export function fetchVehicleContractSummary(accesstoken, vehicleId, start, end, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            start,
            end,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/vehicles/vehicle-contract-summary`, config);
    return {
        type: FETCH_VEHICLE_CONTRACT_SUMMARY,
        promise: request,
    };
}
export function fetchHistoryById(url, accesstoken, accountId, baseURL) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get((baseURL ? baseURL : ROOT_API_ALFRED + '/') + url, config);
    return {
        type: FETCH_HISTORY_BY_ID,
        promise: request,
    };
}
export function createShareOfBusinessForVehicleSizes(accesstoken, routeId, excludeExpired = false, excludeTransporterId, data) {
    const config = {
        params: parseQueryParams({
            routeId,
            excludeExpired,
            excludeTransporterId,
        }),
    };
    const request = axios.post(`${ROOT_API_ALFRED}/contract/share/sob`, data, config);
    return {
        type: CREATE_SHARE_OF_BUSINESS_FOR_VEHICLE_SIZES,
        promise: request,
    };
}
export function updateTransporterWiseAllocation(params, data) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/contract/contract-vehicle`, data, config);
    return {
        type: UPDATE_TRANSPORTER_WISE_ALLOCATION,
        promise: request,
    };
}
export function createIndentFallback(data) {
    const config = {};
    const request = axios.post(`${ROOT_API_ALFRED}/indent/indent-fallback/bulk`, data, config);
    return {
        type: CREATE_INDENT_FALLBACK,
        promise: request,
    };
}
export function getIndentFallback(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/indent-fallback/`, config);
    return {
        type: GET_INDENT_FALLBACK,
        promise: request,
    };
}
